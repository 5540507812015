import React from 'react';
import { Dialog } from '@blueprintjs/core';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/@setproduct-ui/core/Button';
import styles from './style.module.css';
import { replaceDocument } from '../../redux/actions/document';
import { createLoadingSelector } from '../../redux/api/loading';

const loadingSelector = createLoadingSelector(['REPLACE_DOCUMENT']);

export default function DialogReplace({ isOpen, onClose, documentId }) {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(null);
  const [replaceMode, setReplaceMode] = React.useState({
    value: 'no-replace',
    label: "Don't replace anything",
  });
  const [removeTasks, setRemoveTasks] = React.useState('no');
  const [replacePages, setReplacePages] = React.useState('');
  const loading = useSelector(loadingSelector);
  const handleChangeFile = (event) => {
    setFile(event.target.files[0]);
  };
  const handleChangeRemoveTasks = (event) => {
    const { value } = event.target;
    setRemoveTasks(value);
  };
  const handleProceed = async () => {
    const formData = new FormData();
    formData.set('file', file);
    formData.set(
      'config',
      JSON.stringify({ replacePages, removeTasks: removeTasks === 'yes' })
    );
    const isSuccess = await dispatch(replaceDocument(documentId, formData));
    if (isSuccess) onClose();
  };
  const validate = () => {
    if (file !== null && file?.name !== `${documentId}.pdf`) return false;
    if (replaceMode.value === 'replace-partial' && replacePages === '')
      return false;
    return true;
  };

  React.useEffect(() => {
    if (replaceMode.value === 'replace-all') setReplacePages('all');
    else setReplacePages('');
  }, [replaceMode]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={styles.replace_document_container}
    >
      <h2 className={styles.replace_document_header}>
        Replace Document "{documentId}"
      </h2>
      <form onSubmit={handleProceed} className={styles.replace_document_form}>
        <div>
          <label htmlFor="file">Document: </label>
          <input type="file" name="file" onChange={handleChangeFile} />
        </div>
        <div>
          <label htmlFor="replaceMode">Boundaries Replacement: </label>
          <Select
            name="replaceMode"
            value={replaceMode}
            options={[
              { value: 'no-replace', label: "Don't replace anything" },
              { value: 'replace-partial', label: 'Replace selected pages' },
              { value: 'replace-all', label: 'Replace all' },
            ]}
            onChange={(value) => setReplaceMode(value)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'black',
              },
            })}
          />
          {replaceMode.value === 'replace-partial' && (
            <div className={styles.replace_document_page_definition}>
              <label htmlFor="replacePages">Replace pages:</label>
              <textarea
                name="replacePages"
                value={replacePages}
                onChange={(e) => setReplacePages(e.target.value)}
              />
            </div>
          )}
        </div>
        <div>
          <label htmlFor="removeTasks">Delete All Related Tasks:</label>
          <div className={styles.replace_document_radio}>
            <input
              type="radio"
              name="removeTasks"
              value="yes"
              onChange={handleChangeRemoveTasks}
              checked={removeTasks === 'yes'}
            />
            <span>Yes</span>
          </div>
          <div className={styles.replace_document_radio}>
            <input
              type="radio"
              name="removeTasks"
              value="no"
              onChange={handleChangeRemoveTasks}
              checked={removeTasks === 'no'}
            />
            <span>No</span>
          </div>
        </div>
      </form>
      <div className={styles.replace_document_button_container}>
        <Button
          view="outlined"
          color="danger"
          text="Cancel"
          style={{ marginRight: 12 }}
          onClick={onClose}
        />
        <Button
          view="filled"
          color="primary"
          text="Proceed"
          loading={loading}
          disabled={!validate()}
          onClick={handleProceed}
        />
      </div>
    </Dialog>
  );
}
