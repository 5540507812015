const initialState = {
  block_status: 0,
  role: ""
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_USER_DATA_SUCCESS":
    case "GET_USER_PROFILE_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "RESET_USER_PROFILE":
      return initialState;
    default:
      return state;
  }
};

export default userProfileReducer;
