import { apiRequest } from "../utils/apiRequest";

import { actionType } from "../reducers/documentGroup";
import {
  GroupDocumentEntity,
  DocumentEntity,
} from "../../Pages/DocumentGroup/entities";

const DocumentFaker = {
  group: (number) => {
    let items = [];
    for (let i = 0; i < number; i++) {
      let docItem = new GroupDocumentEntity({
        _id: i + 1,
        name: `Dummy document ${i}`,
        thumb: "icon",
      });
      let members = DocumentFaker.document(3);

      docItem.members = members;
      docItem.totalMember = members.length;

      items.push(docItem);
    }
    return items;
  },

  document: (number) => {
    let items = [];

    for (let i = 0; i < number; i++) {
      let type = i % 2 === 0 ? "annual_report" : "sustainability_report";
      let name = Math.random().toString(36).substring(7);
      let document = new DocumentEntity({
        id: i + 1,
        filename: `${name}-${type}.pdf`,
        metadata: {
          type,
        },
        thumb: "icon",
      });
      items.push(document);
    }

    return items;
  },
};

export function Action(propDispatch) {
  const initData = async () => {
    return propDispatch(async (dispatch) => {
      // Dummy holder as requested
      //let groups = DocumentFaker.group(10);
      let groups = [];
      let members = [];
      // dispatch({
      //   type: actionType.InitData,
      //   payload: { groups, members },
      // });

      const results = await Promise.all([
        apiRequest(dispatch, actionType.LoadGroups, "get", "groups/document"),
        apiRequest(
          dispatch,
          actionType.LoadDocuments,
          "get",
          "annotation/documents"
        ),
      ]);

      groups = __extractGroup(results[0]);
      members = __extractDocument(results[1]);

      //   groups = DocumentFaker.group(15)
      //   members = DocumentFaker.document(3)

      dispatch({
        type: actionType.InitData,
        payload: { groups, members },
      });
    });
  };

  const __extractGroup = (result) => {
    if (result.type === actionType.LoadGroups + "_SUCCESS") {
      return result.payload.map((item) => {
        let group = new GroupDocumentEntity(item);

        let members = item.members.map((item) => {
          group.registeredIds.push(item.id);
          return new DocumentEntity(item);
        });

        group.members = members;
        group.totalMember = group.members.length;
        return group;
      });
    }
    return [];
  };

  const __extractDocument = (result) => {
    if (result.type === actionType.LoadDocuments + "_SUCCESS") {
      return result.payload.map((item) => new DocumentEntity(item));
    }
    return [];
  };

  const createDocumentGroup = async (group) => {
    return propDispatch(async (dispatch) => {
      let members = group.members.map((member) => member.asData());
      let result = await apiRequest(
        dispatch,
        actionType.CreateDocumentGroup,
        "post",
        "groups/document",
        {
          name: group.name,
          members,
        }
      );

      if (result.type !== actionType.CreateDocumentGroup + "_SUCCESS") {
        return false;
      }

      dispatch({ type: actionType.ClearSelectedObject });

      result = await apiRequest(
        dispatch,
        actionType.LoadGroups,
        "get",
        "groups/document"
      );

      let groups = __extractGroup(result);
      dispatch({
        type: actionType.ReplaceList,
        groups,
      });

      return true;
    });
  };

  const updateGroup = async (group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectGroup,
        group,
      });

      let members = group.members.map((member) => member.asData());
      return apiRequest(
        dispatch,
        actionType.UpdateDocumentGroup,
        "put",
        `groups/${group.id}`,
        {
          name: group.name,
          members,
        }
      );
    });
  };

  const deleteGroup = async (group) => {
    return propDispatch(async (dispatch) => {
      let result = await apiRequest(
        dispatch,
        actionType.DeleteDocumentGroup,
        "delete",
        `groups/${group.id}`
      );

      result = await apiRequest(
        dispatch,
        actionType.LoadGroups,
        "get",
        "groups/document"
      );

      dispatch({
        type: actionType.ReplaceList,
        groups: __extractGroup(result),
      });
    });
  };

  const addMemberToGroup = async (member, group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectGroupAndMember,
        member,
        group,
      });
      if (group.registeredIds.indexOf(member.id) > -1) {
        return true;
      }
      return apiRequest(
        dispatch,
        actionType.AddMemberToGroup,
        "post",
        `groups/member/add/${group.id}`,
        member.asData()
      );
    });
  };

  const removeMemberFromGroup = async (member, group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectedRemovedMember,
        member,
        group,
      });

      return apiRequest(
        dispatch,
        actionType.RemoveMemberFromGroup,
        "post",
        `groups/member/delete/${group.id}`,
        member.asData()
      );
    });
  };

  const selectGroup = async (group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectGroup,
        group,
      });
    });
  };

  const clearSelectedObject = () => {
    return propDispatch((dispatch) => {
      dispatch({
        type: actionType.ClearSelectedObject,
      });
    });
  };

  return Object.freeze({
    initData,
    createDocumentGroup,
    updateGroup,
    deleteGroup,
    addMemberToGroup,
    removeMemberFromGroup,
    selectGroup,
    clearSelectedObject,
  });
}
