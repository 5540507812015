import { actionType } from '../reducers/document';
import { apiRequest } from '../utils/apiRequest';
import { getNumPages } from '../../utils/uploadFile';

const listDocumentSuccess = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_SUCCESS,
    payload: authData,
  };
};

const listDocumentFailure = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_FAILURE,
    payload: authData,
  };
};

export const listDocument = (data) => async (dispatch) => {
  const documentList = await apiRequest(
    dispatch,
    'LIST_DOCUMENT',
    'get',
    '/annotation/documents/list',
    data
  );
  if (documentList.type === actionType.LIST_DOCUMENT_SUCCESS) {
    dispatch(listDocumentSuccess(documentList.payload));
  } else {
    dispatch(listDocumentFailure(documentList));
  }
};

export const getDocumentSummary = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_DOCUMENT_SUMMARY',
    'get',
    '/annotation/documents/summary'
  );
};

export const changeDocumentFilter = (filter) => ({
  type: 'CHANGE_DOCUMENT_FILTER',
  payload: filter,
});

export const changePageDataOnDocument = (pageData) => ({
  type: 'CHANGE_PAGE_DATA_ON_DOCUMENT',
  payload: pageData,
});

export const runBulkOcr = (documents, storeInDb) => async (dispatch) => {
  const requests = [];
  dispatch({ type: 'RUN_BULK_OCR_REQUEST' });
  for (const document of documents) {
    const req = apiRequest(
      dispatch,
      'RUN_OCR',
      'post',
      `/annotation/document/${document.id}/preprocessing`,
      {
        storeWords: true,
        mode: storeInDb ? 'initial' : 'no-store-db',
      }
    ).then((data) => ({ ...data, id: document.id }));
    requests.push(req);
  }
  const results = await Promise.allSettled(requests).then((res) =>
    res.map(({ value }) => value)
  );
  dispatch({ type: 'RUN_BULK_OCR_SUCCESS' });
  const success = results
    .filter((res) => res.type.endsWith('SUCCESS'))
    .map(({ id }) => id);
  const fail = results
    .filter((res) => res.type.endsWith('FAILURE'))
    .map(({ id, payload }) => ({ id, message: payload.message }));
  return { success, fail };
};

export const runOcr = (documentId, storeInDb) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'RUN_OCR',
    'post',
    `/annotation/document/${documentId}/preprocessing`,
    {
      storeWords: true,
      mode: storeInDb ? 'initial' : 'no-store-db',
    }
  );
  return actionDispatched.type === 'RUN_OCR_SUCCESS';
};

export const deleteDocument = (paramsId) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'DELETE_DOCUMENT',
    'delete',
    `/annotation/document/${paramsId}`
  );
  return actionDispatched.type === 'DELETE_DOCUMENT_SUCCESS';
};

export const selectDocument = (documents) => ({
  type: 'SELECT_DOCUMENT',
  payload: documents,
});

export const upload = (files, onSuccess) => async (dispatch) => {
  const requests = [];
  for (const file of files) {
    try {
      dispatch(storeFileUpload(file.name, file));
      const formData = new FormData();
      const numPages = await getNumPages(file);
      formData.append('file', file);
      formData.append('numPages', numPages.length);
      const req = apiRequest(
        dispatch,
        'UPLOAD_FILE',
        'post',
        '/annotation/document',
        formData,
        'multipart/form-data',
        () => {
          dispatch(setUploadComplete(file.name));
        },
        (error) => {
          dispatch(setUploadError(file.name, error));
        },
        (progress) => {
          const { loaded, total } = progress;
          const percentageProgress = Math.floor((loaded / total) * 100);
          dispatch(changeUploadProgress(file.name, percentageProgress));
        }
      );
      requests.push(req);
    } catch (error) {
      dispatch(setUploadError(file.name, error));
    }
  }
  Promise.allSettled(requests).then(onSuccess);
};

const storeFileUpload = (name, file) => ({
  type: 'STORE_FILE_UPLOAD',
  payload: {
    name,
    file,
  },
});

const changeUploadProgress = (name, progress) => ({
  type: 'CHANGE_UPLOAD_PROGRESS',
  payload: {
    name,
    progress,
  },
});

export const removeUpload = (names) => ({
  type: 'REMOVE_UPLOAD',
  payload: names,
});

const setUploadComplete = (name) => ({
  type: 'SET_UPLOAD_COMPLETE',
  payload: name,
});

const setUploadError = (name, error) => ({
  type: 'SET_UPLOAD_ERROR',
  payload: { name, error },
});

export const estimateOcr = (documents) => async (dispatch) => {
  dispatch({ type: 'ESTIMATE_BULK_OCR_REQUEST' });
  const requests = await Promise.all(
    documents.map((document) => {
      return apiRequest(
        dispatch,
        'ESTIMATE_OCR',
        'get',
        `/annotation/document/${document.id}/estimation`
      );
    })
  );
  dispatch({ type: 'ESTIMATE_BULK_OCR_SUCCESS' });
  const estimation = requests.reduce((total, item) => {
    total += item.payload.estimation;
    return total;
  }, 0);
  return estimation;
};

export const getAllDocuments = (filter) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'GET_ALL_DOCUMENTS',
    'get',
    '/annotation/documents/list',
    filter
  );
  if (actionDispatched.type === 'GET_ALL_DOCUMENTS_SUCCESS')
    return actionDispatched.payload.list;
  else return [];
};

export const replaceDocument = (documentId, formData) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'REPLACE_DOCUMENT',
    'patch',
    `/annotation/document/${documentId}/replace`,
    formData,
    'multipart/form-data'
  );
  return actionDispatched.type === 'REPLACE_DOCUMENT_SUCCESS';
};
