import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import IconButton from '@material-ui/core/IconButton';
import { Done, DoneAll, Clear as Cross } from '@material-ui/icons';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import Heading from '../../components/Heading';
import {
  setSelectedSection,
  changeSelectedChapter,
  updateAnnotation,
  getSections,
  setEditSelectedBoundary,
  highlightBoundary,
  removeSelectedBoundingBoxByIndex,
} from '../../redux/actions/annotation';
import { createLoadingSelector } from '../../redux/api/loading';
import styles from './style.module.css';
import { upperFirst } from 'lodash';

const loadingSelectorMutation = createLoadingSelector(['UPDATE_ANNOTATION']);

const displayAnnotationStatusIcon = (status) => {
  if (status === 2)
    return <Done style={{ fontSize: 18, color: 'var(--green50)' }} />;
  if (status === 3)
    return <DoneAll style={{ fontSize: 18, color: 'var(--green50)' }} />;
  if (status === 4)
    return <Cross style={{ fontSize: 18, color: 'var(--red50)' }} />;
  return null;
};

function isAccepted(status) {
  return status === 3;
}

function AnnotationSection(props) {
  const dispatch = useDispatch();
  const loadingMutation = useSelector((state) =>
    loadingSelectorMutation(state)
  );
  const sections = useSelector((state) => state.annotation.sections);
  const sectionId = useSelector((state) => state.annotation.sectionId);
  const type = useSelector((state) => state.annotation.type);
  const selectedSection = useSelector(
    (state) => state.annotation.selectedSection
  );
  const selectedBoundingBox = useSelector(
    (state) => state.annotation.selectedBoundingBox
  );
  const selectedPage = useSelector((state) => state.annotation.selectedPage);
  const selectedChapter = useSelector(
    (state) => state.annotation.selectedChapter
  );
  const chapterList = useSelector((state) => state.annotation.chapterList);
  const loadingChapterList = useSelector((state) =>
    createLoadingSelector(['GET_CHAPTER_DETAIL', 'GET_CHAPTER_LIST_OPTION'])(
      state
    )
  );
  const loadingAnnotationData = useSelector(
    createLoadingSelector([
      'GET_ANNOTATION_CHAPTER',
      'GET_ANNOTATION_SUBCHAPTER',
      'GET_TAKEN_BOUNDARIES',
    ])
  );
  const isError = useSelector(
    (state) => state.annotation.isErrorSectionAnnotation
  );
  const editSelectedBoundary = useSelector(
    (state) => state.annotation.editSelectedBoundary
  );
  const handleUpdateAnnotation = () =>
    dispatch(updateAnnotation(selectedSection));

  return (
    <>
      <Heading text={`${type}S`} style={{ marginLeft: 10 }} />
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        padding="10px"
        style={{ fontSize: 12 }}
      >
        <div
          style={{
            maxHeight: '20vh',
            overflowY: 'auto',
          }}
        >
          {!isError &&
            Object.keys(sections).map((sectionId) => (
              <FlexWrapper
                key={sectionId}
                justifyContent="space-between"
                padding="5px"
                margin="0 0 2px 0"
                style={{
                  cursor: 'pointer',
                  background:
                    selectedSection === sectionId ? 'var(--grey10)' : '',
                  borderRadius: 3,
                }}
                onClick={() => {
                  !loadingMutation && dispatch(setSelectedSection(sectionId));
                }}
              >
                <FlexWrapper>
                  <Icon
                    icon="folder-close"
                    iconSize={16}
                    style={{
                      color: 'var(--grey50)',
                      marginRight: 10,
                    }}
                  />
                  <h4 style={{ margin: 0 }}>{sections[sectionId].name}</h4>
                </FlexWrapper>
                {sections[sectionId].annotation.id &&
                  displayAnnotationStatusIcon(
                    sections[sectionId].annotation.status
                  )}
              </FlexWrapper>
            ))}
        </div>
      </FlexWrapper>
      {!isError &&
        selectedPage[selectedSection] &&
        selectedBoundingBox[selectedSection] && (
          <>
            <Heading
              text="Selected Boundaries"
              style={{ marginTop: 10, marginLeft: 10, marginBottom: 5 }}
            />
            <FlexWrapper
              flexFlow="column"
              justifyContent="flex-start"
              alignItems="stretch"
              padding="10px"
              style={{ fontSize: 12 }}
            >
              {selectedPage[selectedSection].length ||
              selectedBoundingBox[selectedSection].length ? (
                <div
                  style={{
                    maxHeight: '20vh',
                    overflowY: 'auto',
                  }}
                >
                  {selectedPage[selectedSection].map((page, index) => (
                    <div
                      key={index}
                      className={styles.selected_boundary_id_wrapper}
                      style={{
                        background:
                          editSelectedBoundary.id === page.id &&
                          editSelectedBoundary.index === index
                            ? 'var(--yellow5)'
                            : 'var(--white)',
                      }}
                    >
                      <h4
                        style={{ margin: 0, color: 'var(--grey100)' }}
                        onClick={() =>
                          props.handleClickBoundary(page.pageNumber)
                        }
                      >
                        {`Page_${page.pageNumber}`}
                      </h4>
                      <div>
                        <IconButton
                          size="small"
                          onClick={() => {
                            dispatch(
                              setEditSelectedBoundary({ ...page, index })
                            );
                          }}
                        >
                          <Icon
                            icon="edit"
                            iconSize={16}
                            style={{ color: 'var(--grey50)' }}
                          />
                        </IconButton>
                        {!isAccepted(
                          sections[selectedSection].annotation.status
                        ) && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              props.handleRemoveSelectedPage(page.id)
                            }
                          >
                            <Icon
                              icon="cross"
                              iconSize={16}
                              style={{ color: 'var(--grey50)' }}
                            />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))}
                  {selectedBoundingBox[selectedSection].map((node, index) => (
                    <div
                      key={index}
                      onMouseEnter={() => dispatch(highlightBoundary(node.id))}
                      onMouseLeave={() => dispatch(highlightBoundary(null))}
                      className={styles.selected_boundary_id_wrapper}
                      style={{
                        background:
                          editSelectedBoundary.id === node.id &&
                          editSelectedBoundary.index === index
                            ? 'var(--yellow5)'
                            : 'var(--white)',
                      }}
                    >
                      <h4
                        style={{ margin: 0, color: 'var(--grey100)' }}
                        onClick={() =>
                          props.handleClickBoundary(node.pageNumber)
                        }
                      >
                        {`Page_${node.pageNumber} ${upperFirst(node.id)}`}
                        {node.text && (
                          <span className={styles.selected_boundary_id_edited}>
                            Edited
                          </span>
                        )}
                      </h4>
                      <div>
                        <IconButton
                          size="small"
                          onClick={() => {
                            dispatch(
                              setEditSelectedBoundary({ ...node, index })
                            );
                          }}
                        >
                          <Icon
                            icon="edit"
                            iconSize={16}
                            style={{ color: 'var(--grey50)' }}
                          />
                        </IconButton>
                        {!isAccepted(
                          sections[selectedSection].annotation.status
                        ) && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              dispatch(removeSelectedBoundingBoxByIndex(index))
                            }
                          >
                            <Icon
                              icon="cross"
                              iconSize={16}
                              style={{ color: 'var(--grey50)' }}
                            />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span style={{ marginLeft: 5, color: 'var(--grey50)' }}>
                  No selected boundaries
                </span>
              )}
              {type === 'SUBCHAPTER' &&
              (selectedPage[selectedSection].length ||
                selectedBoundingBox[selectedSection].length) ? (
                <>
                  <label
                    htmlFor="chapterLocation"
                    style={{
                      marginTop: 10,
                      display: 'block',
                      fontFamily: 'var(--inter)',
                      fontSize: 12,
                      color: '#656565',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                    }}
                  >
                    Chapter Location
                  </label>
                  <div style={{ margin: '10px 0' }}>
                    {loadingChapterList ? (
                      'Loading...'
                    ) : (
                      <Select
                        name="chapterLocation"
                        value={chapterList.find(
                          (chapter) =>
                            chapter.value === selectedChapter[selectedSection]
                        )}
                        options={chapterList}
                        onChange={({ value }) =>
                          !isAccepted(
                            sections[selectedSection].annotation.status
                          )
                            ? dispatch(
                                changeSelectedChapter(selectedSection, value)
                              )
                            : null
                        }
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: 80,
                          }),
                        }}
                      />
                    )}
                  </div>
                </>
              ) : null}
              {sections[selectedSection] &&
                !isAccepted(sections[selectedSection].annotation.status) && (
                  <FlexWrapper margin="10px 0 0 0">
                    <Button
                      view="flat"
                      text="Discard"
                      color="danger"
                      fill={true}
                      dense={true}
                      disabled={loadingMutation}
                      onClick={props.handleResetSelected}
                      style={{
                        fontSize: 'var(--font_size_body_small)',
                        marginRight: 5,
                      }}
                    />
                    <Button
                      view="filled"
                      text="Save"
                      color="primary"
                      fill={true}
                      dense={true}
                      loading={loadingMutation}
                      disabled={false}
                      onClick={handleUpdateAnnotation}
                      style={{ fontSize: 'var(--font_size_body_small)' }}
                    />
                  </FlexWrapper>
                )}
            </FlexWrapper>
          </>
        )}
      {isError && (
        <FlexWrapper
          flexFlow="column"
          justifyContent="flex-start"
          alignItems="stretch"
          padding="10px"
          style={{ fontSize: 12, marginTop: -20 }}
        >
          <p style={{ marginBottom: 10 }}>An error occurred</p>
          <Button
            view="filled"
            text="Reload annotation data"
            loading={loadingAnnotationData}
            disabled={false}
            style={{ marginRight: 12, fontWeight: 500 }}
            onClick={async () => {
              dispatch(getSections(sectionId));
            }}
          />
        </FlexWrapper>
      )}
    </>
  );
}

export default AnnotationSection;
