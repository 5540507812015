import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import CustomSkeleton from '../../../../components/Skeleton';

import { TableCell, TableRow, Box, makeStyles } from '@material-ui/core'


const initStyle = makeStyles({
    radius10: {
        borderRadius: 10
    },
    rightSpace5: {
        marginRight: 5
    },
    rightSpace10: {
        marginRight: 10
    },
    bottomSpace5:{
        marginBottom: 8
    },
    cell: {
        textAlign: "left",
        padding: "12px 0",
        backgroundColor: "#fff",
        border: "none"
    }
})




export const LoadingRowTable = (props) => {
    const styles = initStyle()

    return (
        <TableRow>
            <TableCell width="10%" padding="checkbox" className={styles.cell}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CustomSkeleton height={11} width={11} />
                </Box>
            </TableCell>
            <TableCell width="57%" className={styles.cell}>
                <Box display="flex" alignItems="center">
                    <CustomSkeleton circle={true} height={30} width={30} className={styles.rightSpace10} />
                    <CustomSkeleton width={179} height={16} className={styles.borderRadius} />   
                </Box>
            </TableCell>
            <TableCell width="35%" className={styles.cell}>
                <Box display="flex" justifyContent="flex-end">
                    <CustomSkeleton circle={true} height={30} width={30} count={3} className={styles.rightSpace5}/>
                </Box>
            </TableCell>
            <TableCell width="5%" className={styles.cell}></TableCell>
        </TableRow>
    )

}



export const MemberLoading = () => {
    return (
        <SkeletonTheme color="#EDF3F6" highlightColor="#d3dadd">
            <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Skeleton width={124} height={33} style={{borderRadius: 25, marginRight: 10}} />
            </Box>
        </SkeletonTheme>
    )
}