import React from 'react';
import Button from '../../components/@setproduct-ui/core/Button';
import Dialog from '../../components/@setproduct-ui/core/Dialog';

export default function DeleteTaskDialog(props) {
  return (
    <Dialog
      view="raised"
      title="Delete Task"
      text="Are you sure you want to delete this task?"
      backdropOpacity={40}
      rightButton={
        <>
          <Button
            view="flat"
            color="default"
            dense={false}
            onClick={props.onCloseDialog}
            disabled={props.loading}
            text="Cancel"
            style={{ marginRight: 10 }}
          />
          <Button
            view="filled"
            color="danger"
            dense={false}
            onClick={props.handleDelete}
            loading={props.loading}
            text="Delete"
          />
        </>
      }
      isOpen={props.data.open}
      onClose={props.onCloseDialog}
    />
  );
}
