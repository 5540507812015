import { Form, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { push } from "connected-react-router";
import withAuthentication from "../../utils/withAuthentication";
import Input from "../../components/Input";
import FormMessage from "../../components/FormMessage";
import Button from "../../components/@setproduct-ui/core/Button";
import useViewport from "../../hooks/useViewport"
import { resetPassword } from "../../redux/actions/auth";
import { createLoadingSelector } from "../../redux/api/loading";
import { createErrorMessageSelector } from "../../redux/api/error";

const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Password length must be at least 6 characters")
    .max(100, "Maximum 100 characters")
    .required("Required"),
  retypePassword: yup
    .string()
    .min(6, "Password length must be at least 6 characters")
    .max(100, "Maximum 100 characters")
    .required("Required")
});

const loadingSelector = createLoadingSelector(["RESET_PASSWORD"]);
const errorMessageSelector = createErrorMessageSelector(["RESET_PASSWORD"]);

function ResetPasswordPage({
  firebase,
  urlParams,
  errorMessage,
  loading,
  resetPassword,
  push
}) {
  const { oobCode: actionCode } = urlParams;
  const [message, setMessage] = useState({ text: "", type: "" });
  const { screenWidth } = useViewport()

  return (
    <div
      style={{
        minWidth: screenWidth > 900 ? "500px" : 0,
        width: screenWidth > 900 ? "40%" : "100%",
      }}
    >
      <Formik
        enableReinitialize
        validationSchema={ResetPasswordSchema}
        validateOnSubmit
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          password: "",
          retypePassword: ""
        }}
        onSubmit={async (values, actions) => {
          const { password, retypePassword } = values;
          const isSuccess = await resetPassword(
            actionCode,
            password,
            retypePassword
          );
          if (isSuccess) {
            setMessage({
              type: "success",
              text:
                "Successfully reset your account password. You will be redirected to Login page."
            });
          } else {
            setMessage({ type: "error", text: errorMessage });
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              padding: "0 70px"
            }}
          >
            <h1 style={{ marginTop: 0 }}>Reset Password</h1>
            {message.type && (
              <div style={{ marginBottom: 10 }}>
                <FormMessage type={message.type}>{message.text}</FormMessage>
              </div>
            )}
            <Input
              name="password"
              placeholder="New Password"
              type="password"
              view="outlined"
              color="default"
              value={values.password}
              onInput={handleChange}
              fill={true}
              error={errors.hasOwnProperty("password")}
              errorMessage={errors.password}
            />
            <Input
              name="retypePassword"
              placeholder="Confirm New Password"
              type="password"
              view="outlined"
              color="default"
              value={values.retypePassword}
              onInput={handleChange}
              fill={true}
              error={errors.hasOwnProperty("retypePassword")}
              errorMessage={errors.retypePassword}
              style={{ marginTop: 20 }}
            />
            <Button
              type="default"
              view="filled"
              color="primary"
              text="Reset Password"
              fill={true}
              style={{ marginTop: 30 }}
              loading={isSubmitting || loading}
              onClick={handleSubmit}
            />
            <Button
              type="default"
              view="outlined"
              color="default"
              text="Back to Login"
              fill={true}
              disabled={message.type === "success"}
              style={{ marginTop: 10 }}
              onClick={() => push("/login")}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  errorMessage: errorMessageSelector(state),
  urlParams: state.router.location.query
});

const mapDispatchToProps = dispatch => ({
  resetPassword: (actionCode, newPassword, retypePassword) =>
    dispatch(resetPassword(actionCode, newPassword, retypePassword)),
  push: path => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(ResetPasswordPage));
