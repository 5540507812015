import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@blueprintjs/core";

function ReviewButtons({ status }) {
  return (
    <>
      <IconButton
        size="small"
        style={{
          background: status === 4 ? "var(--grey30)" : "var(--grey0)",
          marginLeft: 5,
          marginRight: 5
        }}
      >
        <Icon
          icon="cross"
          iconSize={12}
          style={{ color: status === 4 ? "var(--grey0)" : "var(--grey50)" }}
        />
      </IconButton>
      <IconButton
        size="small"
        style={{ background: status === 3 ? "var(--grey30)" : "var(--grey0)" }}
      >
        <Icon
          icon="tick"
          iconSize={12}
          style={{ color: status === 3 ? "var(--grey0)" : "var(--grey50)" }}
        />
      </IconButton>
    </>
  )
}

export default ReviewButtons;
