import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import CustomDialog from "../../components/CustomDialog";
import Select from "react-select";
import FlexWrapper from "../../components/FlexWrapper";
import Button from "../../components/@setproduct-ui/core/Button";
import Input from "../../components/Input";
import styles from "./style.module.css";
import {
  getAvailableAnnotators,
  getAvailableReviewers,
  updateTask
} from "../../redux/actions/admin";
import { createLoadingSelector } from "../../redux/api/loading";

const loadingSelector = createLoadingSelector(["UPDATE_TASK"]);

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: "2.5rem"
  })
};

function FormEdit(props) {
  const { isOpen, getAvailableAnnotators, getAvailableReviewers } = props;
  useEffect(() => {
    if (isOpen) {
      getAvailableAnnotators();
      getAvailableReviewers();
    }
  }, [isOpen, getAvailableAnnotators, getAvailableReviewers]);

  const { form } = props;

  return (
    <CustomDialog
      view="raised"
      title="Update Task"
      backdropOpacity={40}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {form.annotator && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            taskName: form.name,
            annotator: {
              value: form.annotator.id,
              label: form.annotator.profile.name
            },
            reviewer: {
              value: form.reviewer.id,
              label: form.reviewer.profile.name
            }
          }}
          onSubmit={async (values, actions) => {
            const data = {
              id: form.id,
              name: values.taskName,
              annotatorId: values.annotator.value,
              reviewerId: values.reviewer.value
            };
            const isSuccess = await props.updateTask(data);
            if (isSuccess) {
              props.onClose();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "column",
                justifyContent: "center"
              }}
            >
              <label htmlFor="taskPrefix" className={styles.formLabel}>
                Task Name
              </label>
              <Input
                name="taskName"
                type="text"
                view="outlined"
                color="default"
                onBlur={handleBlur}
                onInput={handleChange}
                value={values.taskName}
                fill={true}
                error={errors.hasOwnProperty("taskName")}
                errorMessage={errors.taskName}
                style={{ marginBottom: 10 }}
              />
              <label htmlFor="annotators" className={styles.formLabel}>
                Assigned annotator
              </label>
              <Select
                name="annotator"
                value={values.annotator}
                placeholder=""
                options={props.annotators.map(annotator => ({
                  value: annotator.id,
                  label: annotator.name
                }))}
                onChange={annotator => setFieldValue("annotator", annotator)}
                className={styles.select}
                styles={customSelectStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black"
                  }
                })}
              />
              <label htmlFor="reviewer" className={styles.formLabel}>
                Assigned reviewer
              </label>
              <Select
                name="reviewer"
                defaultValue={values.reviewer}
                placeholder=""
                options={props.reviewers.map(reviewer => ({
                  value: reviewer.id,
                  label: reviewer.name
                }))}
                onChange={selected => setFieldValue("reviewer", selected)}
                className={styles.select}
                styles={customSelectStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black"
                  }
                })}
              />
              <FlexWrapper justifyContent="flex-end">
                <Button
                  view="flat"
                  color="default"
                  text="Cancel"
                  loading={isSubmitting || props.loading}
                  onClick={props.onClose}
                />
                <Button
                  view="filled"
                  color="primary"
                  text="Save"
                  style={{ marginLeft: 20 }}
                  loading={isSubmitting || props.loading}
                  onClick={handleSubmit}
                />
              </FlexWrapper>
            </Form>
          )}
        </Formik>
      )}
    </CustomDialog>
  );
}

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  annotators: state.admin.availableAnnotators,
  reviewers: state.admin.availableReviewers
});

const mapDispatchToProps = dispatch => ({
  getAvailableAnnotators: () => dispatch(getAvailableAnnotators()),
  getAvailableReviewers: () => dispatch(getAvailableReviewers()),
  updateTask: data => dispatch(updateTask(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormEdit);
