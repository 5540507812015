import React from 'react';
import {
  TableRow, 
  TableCell,
  Checkbox,
  Avatar,
  Menu,
  MenuItem
} from '@material-ui/core';


import { Icon } from "@blueprintjs/core";
import FileIcon from '../../styles/icons/file';
import { useTableStyle } from "../../../../components/AdvancedTable/tableStyle";
import userPlaceholder from "../../../../assets/images/user.png";
import styles from './style.module.css';
import mainStyles from '../../style.module.css';
import { bodyCallbackListener } from "../../../../utils/bodyListener";

import LiveSearch from "../../../../components/LiveSearch";



/**
 * Members items
 */
function Members(props) {
  const {group, rowState} = props
  
  const eventRemoveMember = (member) => {
    // confirm is from main index.jsx
    props.confirm.setRemoved({member, group})
  }

  const members = props.group.members.map(member => {
    return (
      <div key={`member-item-${member.id}`} className={styles.memberWrapper}>
        <div className={styles.memberItem}>
          <div className={`${styles.memberDelete}`}
            onClick={e => eventRemoveMember(member)}
          >
            <Icon icon="cross" iconSize="16" />
          </div>
          <div style={{display: "flex"}}>
            <FileIcon color="#787885" className={styles.memberIcon} />
            <span>{member.name}</span>
          </div>
        </div>
      </div>
    )
  })

  

  return members
}

/**
 * Member section element
 */
function MemberSection(props) {
  const {group, rowState} = props

  return <>
    <div className={`${mainStyles.flex} ${mainStyles.flexEnd}` }>
      {props.group.members.length > 0 ? 
        <Members {...props} />
      :
        (
          <div className={mainStyles.relative}>
            <div
              className={styles.toggleAddMember}
              data-target={`live-search-annotator-group-${group.id}`}
              onClick={e => rowState.setLiveSearch(e.currentTarget)}
            >
              Add Member
            </div>
          </div>
        )
      }
    </div>
  </>
}

/**
 * Option Section
 */
function OptionSection(props) {
  const {rowState, group, members, action} = props

  const eventEdit = (e) => {
    props.eventFormOpen(e);
    action.selectGroup(group);
  };

  const eventOpenMenu = (e) => {
    e.preventDefault()
    rowState.setMenuElm(e.target)
  }

  const eventDelete = () => {
    // this props event from Index.jsx
    props.groupConfirm.setGroup(group)
  }
  
  return (
    <div style={{position: "relative"}}>
      <div 
        className={mainStyles.pointer} 
        aria-controls={`menu-${group.id}`}
        aria-haspopup="true"
        onClick={eventOpenMenu}
        onClose={() => rowState.setMenuElm(null)}
      >
        <Icon icon="more" iconSize="16" />
      </div>
    
      <Menu
        style={{ marginTop: 40 }}
        id={`menu-${group.id}`}
        anchorEl={rowState.menuElm}
        open={Boolean(rowState.menuElm)}
      >
        <MenuItem onClick={eventEdit}>Edit</MenuItem>
        <MenuItem onClick={eventDelete}>Delete Group</MenuItem>
      </Menu>

      <LiveSearch
        id={`live-search-annotator-group-${group.id}`}
        group={group}
        anchorEl={rowState.liveSearchElm}
        onClose={() => rowState.setLiveSearch()}
        onSuccessCallback={() => {}}
        addMemberCallback={async (member, group) => {
            if (group.registeredIds.indexOf(member.id) > -1) {
                return action.removeMemberFromGroup(member, group)
            }
            return action.addMemberToGroup(member, group)
        }}
        members={rowState.members}
      />
    </div>
  )
}


/**
 * Main View
 */
function Row(props) {
  const {group} = props
  const classes = useTableStyle()

  const [menuElm, setMenuElm] = React.useState(null)
  const [liveSearchElm, setLiveSearch] = React.useState(null)
  const [members, setMembers] = React.useState(props.documents);

  React.useEffect(() => {
    let items = props.documents.filter(item => {
      return item.documentType !== group.groupType
    })
    setMembers(items)
  }, [props.documents])

  const states = {
    menuElm,
    setMenuElm,

    liveSearchElm,
    setLiveSearch,

    members,
    setMembers
  }

  const inputRef = {
    value: null
  }

  bodyCallbackListener("menuOption", "whatever-element-is", () =>
    setMenuElm(null)
  );

  return (
    <TableRow>
      <TableCell className={classes.checkbox}>
        <Checkbox />
      </TableCell>
      <TableCell 
        scope="row"
        className={`${classes.firstCell} ${classes.cell}`}
        size="medium"
      >
        <div className={mainStyles.flex}>
          <div className={mainStyles.groupIconWrapper}>
            <FileIcon color="#787885" />
          </div>
          
          <div className={styles.nameWrapper}>
            <span className={styles.groupName}>{group.name}</span>
            <span className={styles.groupTypeName}>{group.groupTypeLabel}</span>
          </div>

        </div>
      </TableCell>
      <TableCell className={classes.cell}>
        <MemberSection {...props} rowState={states} />
      </TableCell>
      <TableCell className={`${classes.cell} ${mainStyles.lastCell}`}>
        <OptionSection {...props} rowState={states} />
      </TableCell>
    </TableRow>
  )
}

export default Row
