import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@blueprintjs/core";
import TreeItem from "@material-ui/lab/TreeItem";
import IconButton from "@material-ui/core/IconButton";
import Input from "../../components/Input";
import { addSection, updateSection } from "../../redux/actions/admin";

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5
  },
  item: {
    paddingLeft: 0
  },
  text: {
    fontSize: "var(--font_size_caption)"
  }
});

function CustomTree(props) {
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState("");

  const renderButton = type => {
    if (!editMode) {
      return (
        <div>
          {type !== "SUBCHAPTER" && (
            <IconButton
              aria-label="add"
              size="small"
              onClick={e => {
                e.preventDefault();
                setFormOpen(!formOpen);
              }}
            >
              <Icon
                icon="plus"
                iconSize={16}
                style={{ color: "var(--grey50)" }}
              />
            </IconButton>
          )}
          <IconButton
            aria-label="edit"
            size="small"
            onClick={e => {
              e.preventDefault();
              setEditMode(!editMode);
              setEditData(props.data.name);
            }}
          >
            <Icon
              icon="edit"
              iconSize={16}
              style={{ color: "var(--grey50)" }}
            />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={e => {
              e.preventDefault();
              props.handleDelete(props.data.sectionId);
              console.log("delete section");
            }}
          >
            <Icon
              icon="trash"
              iconSize={16}
              style={{ color: "var(--grey50)" }}
            />
          </IconButton>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex" }}>
          <IconButton
            aria-label="save"
            size="small"
            onClick={async e => {
              e.preventDefault();
              const isSuccess =
                editData === props.data.name ||
                (await props.updateSection(props.data.sectionId, editData));
              if (isSuccess) {
                setEditMode(false);
                setEditData("");
              }
            }}
            disabled={editData === ""}
            style={{ opacity: editData !== "" ? 1 : 0.3 }}
          >
            <Icon
              icon="tick"
              iconSize={16}
              style={{ color: "var(--grey50)" }}
            />
          </IconButton>
          <IconButton
            aria-label="cancel"
            size="small"
            onClick={e => {
              e.preventDefault();
              setEditMode(false);
            }}
          >
            <Icon
              icon="cross"
              iconSize={16}
              style={{ color: "var(--grey50)" }}
            />
          </IconButton>
        </div>
      );
    }
  };

  return (
    <TreeItem
      key={props.data.sectionId}
      nodeId={props.data.sectionId}
      onIconClick={() => {
        props.onClick(props.data);
      }}
      onLabelClick={() => {
        props.onClick(props.data);
      }}
      label={
        <div className={classes.wrapper}>
          {!editMode ? (
            <span className={classes.text}>{props.data.name}</span>
          ) : (
            <Input
              type="text"
              view="outlined"
              color="default"
              onInput={e => setEditData(e.target.value)}
              value={editData}
              fill={true}
              error={false}
              dense={true}
              errorMessage={""}
              style={{ marginRight: 5 }}
            />
          )}
          {renderButton(props.data.type)}
        </div>
      }
      className={classes.item}
    >
      {formOpen && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 5,
            paddingBottom: 5
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              type="text"
              view="outlined"
              color="default"
              onInput={e => setName(e.target.value)}
              value={name}
              fill={true}
              error={false}
              dense={true}
              errorMessage={""}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <IconButton
              aria-label="save"
              size="small"
              onClick={async () => {
                const parent = props.ancestors[props.ancestors.length - 1];
                const isSuccess = await props.addSection({
                  name,
                  parent,
                  ancestors: props.ancestors,
                  type: props.type[0]
                });
                if (isSuccess) {
                  setFormOpen(false);
                  setName("");
                }
              }}
              disabled={name === ""}
              style={{ opacity: name !== "" ? 1 : 0.3 }}
            >
              <Icon
                icon="tick"
                iconSize={16}
                style={{ color: "var(--grey50)" }}
              />
            </IconButton>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => setFormOpen(false)}
            >
              <Icon
                icon="cross"
                iconSize={16}
                style={{ color: "var(--grey50)" }}
              />
            </IconButton>
          </div>
        </div>
      )}
      {Array.isArray(props.data.children)
        ? props.data.children.map(node => (
            <CustomTree
              key={node.sectionId}
              data={node}
              ancestors={[...props.ancestors, node.sectionId]}
              type={props.type.slice(1)}
              onClick={props.onClick}
              addSection={props.addSection}
              updateSection={props.updateSection}
              handleDelete={props.handleDelete}
            />
          ))
        : null}
    </TreeItem>
  );
}

const mapDispatchToProps = dispatch => ({
  addSection: data => dispatch(addSection(data)),
  updateSection: (sectionId, name) => dispatch(updateSection(sectionId, name))
});

export default connect(
  null,
  mapDispatchToProps
)(CustomTree);
