import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Classes } from '@blueprintjs/core';
import styles from './Style.module.css';
import { applySuggestion } from '../../redux/actions/annotation';
import { createLoadingSelector } from '../../redux/api/loading';

export default function InputSuggestion(props) {
  const dispatch = useDispatch();
  const suggestions = useSelector(
    ({ annotation }) => annotation.suggestions.person
  );
  const selectedVariable = useSelector(
    ({ annotation }) => annotation.selectedSection
  );
  const itemsArr = props.fieldName.split('.');
  const position = itemsArr[0].match(/[\d+]/);
  const search = (suggestion) => {
    const lowerSuggestion = suggestion.toLowerCase();
    const lowerText = props.text.toLowerCase();
    return props.text === '' || lowerSuggestion.includes(lowerText);
  };
  const filteredSuggestions = Object.keys(suggestions).filter(search);
  const isLoading = useSelector(
    createLoadingSelector(['GET_ANNOTATION_SUGGESTIONS'])
  );
  return (
    <Popover
      minimal
      targetTagName="div"
      targetClassName={styles.input_suggestion}
      className={styles.input_suggestion_wrapper}
    >
      <div style={{ width: '100%' }}>{props.children}</div>
      <div
        style={{ width: 250, maxHeight: 200 }}
        className={styles.item_options}
      >
        <ul>
          {!isLoading ? (
            filteredSuggestions.length > 0 ? (
              filteredSuggestions.map((suggestion, index) => (
                <li
                  key={`suggestion-${index}`}
                  onClick={() =>
                    dispatch(
                      applySuggestion(suggestion, selectedVariable, position)
                    )
                  }
                  className={Classes.POPOVER_DISMISS}
                >
                  {suggestion}
                </li>
              ))
            ) : (
              <li style={{ fontSize: 12 }} className={Classes.POPOVER_DISMISS}>
                No suggestion available
              </li>
            )
          ) : (
            <li style={{ fontSize: 12 }} className={Classes.POPOVER_DISMISS}>
              Loading...
            </li>
          )}
        </ul>
      </div>
    </Popover>
  );
}
