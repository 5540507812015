import React from "react";

import { Box, Typography, Avatar } from "@material-ui/core";
import FileIcon from "./icons/file";

export default (props) => {
    let {
        classes,
        members,
        group,
        setRows,
        addMemberCallback,
        onSuccessCallback,
    } = props;

    const eventAddAsMember = (item) => {
        const callback = addMemberCallback(item, group)

        if (typeof onSuccessCallback === "function") {
            callback.then(() =>
                onSuccessCallback({ item, group, members }, setRows)
            );
        }
    };

    
    return (
        <Box className={classes.resultWrapper}>
            {members.map((item) => {
                let classStyle = classes.resultDefault;

                if (group && group.registeredIds.indexOf(item.id) > -1) {
                    classStyle = `${classes.resultDefault} ${classes.resultActive}`;
                }

                const Thumbnail = () => {
                    if (item.thumb === "icon") return <FileIcon />;
                    return <Avatar
                            alt={item.name}
                            className={classes.resultIcon}
                            src={item.thumb} />
                };

                return (
                    <Box
                    display="flex"
                    key={`result-item-${item.id}`}
                    alignItems="center"
                    className={classStyle}
                    onClick={() => eventAddAsMember(item)}>
                        <Thumbnail />
                        <span>{item.name}</span>

                        </Box>
                    );
                })
            }
        </Box>
    );
};
