import React from "react";
import PropTypes from "prop-types";

function BasicWrapper(props) {
  return (
    <div
      style={{
        height: props.height || "auto",
        width: props.width || "auto",
        margin: props.margin || 0,
        padding: props.padding || 0,
        ...props.style
      }}
    >
      {props.children}
    </div>
  );
}

BasicWrapper.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

export default BasicWrapper;
