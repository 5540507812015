import React from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ReviewButtons from "./ReviewButtons";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import { setSelectedVariableField } from "../../redux/actions/annotation";
import styles from "./style.module.css";

function TreeForm({ root, annotationItemIndex, data, parents, focusPage, handleSetDirty, selectedFieldName }) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState([]);

  const handleExpand = parentId => {
    if (expanded.includes(parentId))
      setExpanded(expanded.filter(p => p !== parentId));
    else setExpanded(expanded.concat(parentId));
  };

  const handleFocus = (e, type, page) => {
    const { name } = e.target;
    if (page) focusPage(page);
    dispatch(setSelectedVariableField({ annotationItemIndex, keyMap: name, type }));
  }

  if (data.objAttr) {
    return data.objAttr.map(field => {
      if (!isDerivative(field.dtype)) {
        const { attr } = field.child;
        const rootName = `${compact(root.name)}[${annotationItemIndex}]`;
        const fieldName = rootName + "." + [...parents, compact(field.name)].join(".");

        if (field.array) {
          return (
            <div
              key={fieldName}
              style={{
                borderBottom: "1px solid var(--grey10)",
                marginBottom: 5
              }}
            >
              <div className={styles.nested_header_wrapper}>
                <h2
                  className={styles.nested_header}
                  style={{ marginBottom: 5 }}
                >
                  {field.name}
                </h2>
                <div className={styles.header_button_wrapper}>
                  <span className={styles.count_badge}>
                    {field.children.length}
                  </span>
                  <ReviewButtons status={null} />
                </div>
              </div>
              {field.children.map((child, index) => (
                <div
                  key={fieldName + "-" + index}
                  className={styles.input_wrapper}
                >
                  {generateInput(isEnum(child.attr) ? "enum" : field.dtype, {
                    fieldName: `${fieldName}[${index}]`,
                    defaultValue: child,
                    pageNumber: child.pageNumber,
                    handleFocus,
                    attr: isEnum(child.attr) ? child.attr : null,
                    placeholder: field.name,
                    isFocused: selectedFieldName === `${fieldName}[${index}]`
                  })}
                  <ReviewButtons status={child.status} />
                </div>
              ))}
            </div>
          );
        } else {
          if (isEnum(attr)) {
            return (
              <div key={fieldName} className={styles.input_wrapper}>
                {generateInput("enum", {
                  fieldName,
                  defaultValue: field.child || "",
                  pageNumber: field.pageNumber,
                  attr,
                  handleFocus,
                  placeholder: field.name,
                  isFocused: selectedFieldName === fieldName
                })}
                <ReviewButtons status={field.child.status} />
              </div>
            );
          }
          return (
            <div key={fieldName} className={styles.input_wrapper}>
              {generateInput(field.dtype, {
                fieldName,
                defaultValue: field.child || "",
                pageNumber: field.pageNumber,
                handleFocus,
                placeholder: field.name,
                isFocused: selectedFieldName === fieldName
              })}
              <ReviewButtons status={field.child.status} />
            </div>
          );
        }
      } else {
        const nestedParentKey = compact(field.name) + "-nested";

        return (
          <div
            key={nestedParentKey}
            className={`${styles.nested_parent_wrapper}`}
          >
            <div
              className={styles.nested_header_wrapper}
              onClick={() => handleExpand(nestedParentKey)}
            >
              <h2 className={styles.nested_header}>{field.name}</h2>
              <div className={styles.header_button_wrapper}>
                {field.array && (
                  <>
                    <span className={styles.count_badge}>
                      {field.children.length}
                    </span>
                  </>
                )}
                <ReviewButtons status={null} />
              </div>
            </div>
            {field.array ? (
              field.children.map((child, index) => (
                <div
                  key={nestedParentKey + "-tree-" + index}
                  style={{
                    borderBottom: "1px solid var(--grey10)",
                    marginBottom: 5
                  }}
                >
                  <div className={styles.nested_header_wrapper}>
                    <h2
                      className={styles.nested_header}
                      style={{ marginBottom: 5 }}
                    >
                      {field.name} {index + 1}
                    </h2>
                    <div className={styles.header_button_wrapper}>
                      <ReviewButtons status={null} />
                    </div>
                  </div>
                  <TreeForm
                    root={root}
                    annotationItemIndex={annotationItemIndex}
                    data={child}
                    parents={parents.concat(`${compact(field.name)}[${index}]`)}
                    focusPage={focusPage}
                    selectedFieldName={selectedFieldName}
                    handleSetDirty={handleSetDirty}
                  />
                </div>
              ))
            ) : (
              <TreeForm
                key={nestedParentKey + "-tree"}
                root={root}
                annotationItemIndex={annotationItemIndex}
                data={field.child}
                parents={parents.concat(compact(field.name))}
                focusPage={focusPage}
                selectedFieldName={selectedFieldName}
                handleSetDirty={handleSetDirty}
              />
            )}
          </div>
        );
      }
    });
  } else {
    return (
      <div key={compact(data.name)} className={styles.input_wrapper}>
        {generateInput(isEnum(data.attr) ? "enum" : root.dtype, {
          fieldName: `${compact(root.name)}[${annotationItemIndex}].${compact(data.name)}`,
          defaultValue: data,
          pageNumber: data.pageNumber,
          handleFocus,
          attr: isEnum(data.attr) ? data.attr : null,
          placeholder: data.name,
          isFocused: selectedFieldName === `${compact(root.name)}[${annotationItemIndex}].${compact(data.name)}`
        })}
        <ReviewButtons status={data.status} />
      </div>
    );
  }
}

function isDerivative(type) {
  return type.includes("derivative");
}

function isEnum(attr) {
  return Array.isArray(attr) && typeof attr[0] !== "object";
}

function compact(name) {
  return name.toLowerCase().replaceAll(" ", "-");
}

function getInputIcon(type) {
  const ICONS = {
    "text-basic": "text-highlight",
    "number-basic": "numerical",
    "date-basic": "calendar",
    "percentage-basic": "percentage",
    enum: "menu"
  };
  return (
    <Icon
      icon={ICONS[type] || "text-highlight"}
      iconSize={16}
      className={styles.input_icon}
    />
  );
}

function generateInput(type, data) {
  const { fieldName, pageNumber, defaultValue, handleFocus, placeholder, isFocused } = data;
  const icon = getInputIcon(type);
  const currentData = defaultValue
  const handleFocusWrapper = e => {
    const page = currentData ? (currentData.pageNumber || pageNumber) : pageNumber;
    handleFocus(e, type, page);
  }

  switch (type) {
    case "text-basic":
      return (
        <>
          {icon}
          <input
            type="text"
            name={fieldName}
            value={currentData ? currentData.rawData : ""}
            onFocus={handleFocusWrapper}
            placeholder={placeholder}
            className={`${styles.variable_text_input} ${isFocused ? styles.input_focused : ""}`}
            autoComplete="off"
            readOnly
            style={{ cursor: "pointer" }}
          />
        </>
      );
    case "date-basic":
      return (
        <>
          {icon}
          <DatePicker 
            value={currentData && currentData.rawData ? format(new Date(currentData.rawData), "M/dd/yyyy") : ""}
            selected={currentData && currentData.rawData ? new Date(currentData.rawData) : ""}
            onFocus={() => handleFocusWrapper({ target: { name: fieldName } })}
            className={`${styles.variable_text_input} ${isFocused ? styles.input_focused : ""}`}
            placeholder={placeholder}
            style={{ cursor: "pointer" }}
          />
        </>
      );
    case "number-basic":
      return (
        <>
          {icon}
          <input
            type="number"
            name={fieldName}
            value={currentData ? currentData.rawData : ""}
            onFocus={handleFocusWrapper}
            placeholder={placeholder}
            className={`${styles.variable_text_input} ${isFocused ? styles.input_focused : ""}`}
            autoComplete="off"
            readOnly
            style={{ cursor: "pointer" }}
          />
        </>
      );
    case "enum":
      return (
        <>
          {icon}
          <select
            name={fieldName}
            value={currentData ? currentData.rawData : ""}
            className={`${styles.select_input} ${isFocused ? styles.input_focused : ""}`}
            onFocus={handleFocusWrapper}
            readOnly
            style={{ cursor: "pointer" }}
          >
            <option value="">{placeholder}</option>
            {data.attr.map((item, index) => {
              const optionKey = fieldName + "-option-" + index;
              return (
                <option key={optionKey} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </>
      );
    default:
      return (
        <>
          {icon}
          <input
            type="text"
            name={fieldName}
            value={currentData ? currentData.rawData : ""}
            onFocus={handleFocusWrapper}
            placeholder={placeholder}
            className={`${styles.variable_text_input} ${isFocused ? styles.input_focused : ""}`}
            autoComplete="off"
            readOnly
            style={{ cursor: "pointer" }}
          />
        </>
      );
  }
}

export default TreeForm;
