import React from "react";
import { connect } from "react-redux";
import withAuthentication from "../../utils/withAuthentication";
import Layout from "../../components/Layout";
import Button from "../../components/@setproduct-ui/core/Button";
import FlexWrapper from "../../components/FlexWrapper";
import { signOut } from "../../redux/actions/auth";

function WaitingApprovalPage({ role, firebase, signOut }) {
  const handleLogout = () => {
    signOut();
  };

  const handleResendVerifyEmail = async () => {
    await firebase.auth().currentUser.sendEmailVerification();
  };

  return (
    <Layout>
      <FlexWrapper flexFlow="column" style={{ padding: 30, height: "100vh" }}>
        <h2 style={{ marginTop: 0 }}>
          Hello, {firebase.auth().currentUser.email}
        </h2>
        <h3 style={{ marginTop: 5 }}>
          Your account is still waiting to be approved by Admin to get an access
          as { role === 'annotator' ? "Annotator" : "Reviewer" }.
        </h3>
        {!firebase.auth().currentUser.emailVerified && (
          <>
            <p>Your email is unverified, please verify you email.</p>
            <Button
              type="default"
              view="filled"
              color="default"
              text="Resend email verification link"
              style={{ background: "#60bcbc", color: "#fff" }}
              onClick={handleResendVerifyEmail}
            />
          </>
        )}
        <Button
          type="default"
          view="filled"
          color="default"
          text="Log Out"
          style={{ marginTop: 10, background: "#65b168", color: "#fff" }}
          onClick={handleLogout}
        />
      </FlexWrapper>
    </Layout>
  );
}

const mapStateToProps = state => ({
  role: state.userProfile.role
})

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOut())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(WaitingApprovalPage));
