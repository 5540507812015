import { apiRequest } from "../utils/apiRequest";

export const getReviewCompanyList = (data) => async dispatch => {
  await apiRequest(
    dispatch,
    "GET_REVIEW_COMPANY_LIST",
    "get",
    "/dashboard/companies",
    data
  );
};

export const getTaskGroupList = (companyId, documentType) => async dispatch => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_TASK_GROUP_LIST",
    "get",
    "/dashboard/tasks",
    {
      companyId,
      documentType
    }
  );
  const { type } = actionDispatched;
  if (type === "GET_TASK_GROUP_LIST_SUCCESS") {
    const { payload } = actionDispatched;
    dispatch(setTaskGroupData(companyId, documentType, payload));
    return true;
  }
};

const setTaskGroupData = (companyId, documentType, data) => (
  dispatch,
  getState
) => {
  const { companies } = getState().reviewer;
  const updatedCompanies = companies.reduce((companiesWrapper, company) => {
    if (company.companyId === companyId) {
      if (company) {
        const documentTypes = company.documentTypes.reduce((acc, doc) => {
          if (doc.type === documentType) {
            acc = acc.concat({...doc, tasks: data});
          } else acc = acc.concat(doc);
          return acc;
        }, []);
        companiesWrapper = companiesWrapper.concat({
          ...company,
          documentTypes
        });
      }
    } else companiesWrapper = companiesWrapper.concat(company);
    return companiesWrapper;
  }, []);
  dispatch({
    type: "SET_TASK_GROUP_DATA",
    payload: updatedCompanies
  });
};

export const setTablePage = page => ({
  type: "SET_TABLE_PAGE",
  payload: page
});

export const setTableRowsPerPage = page => ({
  type: "SET_TABLE_ROWS_PER_PAGE",
  payload: page
});

export const setTableOrder = order => ({
  type: "SET_TABLE_ORDER",
  payload: order
});

export const setTableOrderBy = orderBy => ({
  type: "SET_TABLE_ORDER_BY",
  payload: orderBy
});

export const setTableFilter = filter => ({
  type: "SET_TABLE_FILTER",
  payload: filter
});
