import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';


export default (props) => {
    
    return (
        <SkeletonTheme color={props.color || "#EDF3F6"} highlightColor={props.highlightColor || "#D3DADD"}>
            <Skeleton 
                circle={Boolean(props.circle)} 
                width={props.width || 10} 
                height={props.height || 10} 
                className={props.className}
                count={props.count || 1}
                />
        </SkeletonTheme>
    )
}