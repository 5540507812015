const initialState = {
  companies: [],
  order: 'asc',
  orderBy: 'task_title',
  pageData: {
    currentPageNumber: 1,
    limit: 10,
    currentPageSize: 0,
    totalItems: 0,
    totalPages: 0,
  },
  filter: {
    taskName: '',
    type: { value: '', label: 'Type' },
    status: { value: '', label: 'Status' },
  },
};

const reviewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REVIEW_COMPANY_LIST_SUCCESS": {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        companies: list,
        pageData
      };
    }
    case "SET_TASK_GROUP_DATA":
      return {
        ...state,
        companies: action.payload
      };
    case "SET_TABLE_PAGE":
      return {
        ...state,
        pageData: {
          ...state.pageData,
          currentPageNumber: action.payload
        }
      };
    case "SET_TABLE_ROWS_PER_PAGE":
      return {
        ...state,
        pageData: {
          ...state.pageData,
          limit: action.payload,
          currentPageNumber: 1
        }
      };
    case "SET_TABLE_ORDER":
      return {
        ...state,
        order: action.payload
      };
    case "SET_TABLE_ORDER_BY":
      return {
        ...state,
        orderBy: action.payload
      };
    case "SET_TABLE_FILTER":
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
};

export default reviewerReducer;
