import React from 'react';
import { Dialog } from '@blueprintjs/core';
import Button from '../../components/@setproduct-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../redux/api/loading';
import { getBoundaries } from '../../redux/actions/documentPreview';
import { find } from 'lodash';
import styles from './style.module.css';
import { storeEditedTextBoundary } from '../../redux/actions/annotation';

export default function DialogEditBoundary({ isOpen, onClose, boundaryData }) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const documentId = useSelector((state) => state.annotation.documentId);
  const boundaries = useSelector((state) => state.documentPreview.boundingBox);
  const loadingBoundaries = useSelector(
    createLoadingSelector(['GET_BOUNDARIES'])
  );
  const { id, pageNumber, text, index } = boundaryData;
  const getOriginalText = (page, id) => {
    const boundary = find(boundaries[page - 1], ['nodeId', id]) || {};
    return boundary.text;
  };
  const originalText = getOriginalText(pageNumber, id);
  const loadedBoundaries = boundaries[pageNumber - 1];
  const loadBoundaries = React.useCallback(() => {
    dispatch(getBoundaries(documentId, pageNumber));
  }, [dispatch, documentId, pageNumber]);
  const handleSave = () => {
    dispatch(storeEditedTextBoundary(id, index, value));
    onClose();
  };

  React.useEffect(() => {
    if (isOpen) {
      if (!loadedBoundaries && !text) loadBoundaries();
      else setValue(!!text ? text : originalText);
    }
  }, [isOpen, loadedBoundaries, loadBoundaries, text, originalText]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={styles.edit_boundary_container}
    >
      <h2 className={styles.edit_boundary_header}>Boundary ID: {id}</h2>
      {!!text && (
        <span style={{ color: 'var(--grey50)', fontSize: 14, marginTop: 8 }}>
          Edited
        </span>
      )}
      <textarea
        className={styles.edit_boundary_textarea}
        disabled={loadingBoundaries}
        value={!loadingBoundaries ? value : 'Loading...'}
        onChange={(e) => setValue(e.target.value)}
      ></textarea>
      <div className={styles.edit_boundary_button_container}>
        <Button
          view="outlined"
          color="danger"
          text="Cancel"
          style={{ marginRight: 12 }}
          onClick={onClose}
        />
        <Button
          view="filled"
          color="primary"
          text="Save"
          disabled={loadingBoundaries}
          onClick={handleSave}
        />
      </div>
    </Dialog>
  );
}
