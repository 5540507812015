import React from 'react';

export default props => {
    let width = props.width || 15
    let height = props.heght || 20
    let viewBox = props.viewBox || "0 0 15 20"
    
    return (
        <div style={{width, height}} className={props.className}>
            <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
                <path fill={props.color}  fillRule="evenodd" clipRule="evenodd" d="M9 0H1C0.45 0 0 0.45 0 1V19C0 19.55 0.45 20 1 20H14C14.55 20 15 19.55 15 19V6L9 0ZM13 18H2V2H8V7H13V18Z"/>
            </svg>
        </div>
    )
}
