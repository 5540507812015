import { size } from 'lodash';
import { PDFDocument } from 'pdf-lib';

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {};

  for (let i = 0; i < files.length; i++) {
    const id = size(existingFiles) + i + 1;

    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
      },
    };
  }

  return fileToUpload;
};

export function convertFileSizeToReadableFormat(filesize) {
  const i = Math.floor(Math.log(filesize) / Math.log(1024));
  return (
    (filesize / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
}

export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

export async function getNumPages(file) {
  const arrayBuffer = await readFile(file);
  const pdf = await PDFDocument.load(arrayBuffer);
  return pdf.getPages();
}
