import { makeStyles } from '@material-ui/core/styles';

export const useTableStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    // height: "100%"
  },
  rowContainer: {
    cursor: 'pointer',
  },
  checkbox: {
    padding: '4px',
    borderWidth: '0px',
    width: '50px',
  },
  header: {
    backgroundColor: 'var(--grey0)',
    color: 'var(--grey50)',
    fontWeight: '600',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  pagination: {
    width: '100%',
    padding: theme.spacing(0.1),
    borderTop: '1px solid #ddd',
  },
  container: {
    display: 'flex',
    maxHeight: 'calc(100vh - 340px)',
    flexDirection: 'column',
  },
  overviewContainer: {
    display: 'flex',
    maxHeight: 'calc(100vh - 420px)',
    flexDirection: 'column',
  },
  table: {
    minWidth: 750,
    height: '100%',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  nameCell: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
  },
  firstCell: {
    verticalAlign: 'middle',
  },
  cell: {
    // backgroundColor: "#fff",
    color: 'var(--grey80)',
    fontFamily: 'var(--inter)',
    fontSize: '12px',
    fontWeight: '500',
    height: '54px',
    padding: '0px 16px 0px 0px',
    borderWidth: '0px',
  },
  pills: {
    padding: theme.spacing(0.5, 0.8),
    borderRadius: '3rem',
  },
  action: {
    borderRadius: '50%',
    backgroundColor: '#ddd !important',
    boxShadow: 'none !important',
    display: 'inline',
    margin: theme.spacing(0, 0.5),
  },
  emptyResult: {
    backgroundColor: '#fff',
    height: 'calc(50vh - 170px)',
    color: '#000',
    textAlign: 'center',
    padding: 0,
    border: 0,
  },
  clickable: {
    cursor: 'pointer',
  },
}));
