import React from 'react';
import { useHistory } from 'react-router';
import { ClickOutsideContainer } from '../../components/utils/ClickOutsideContainer';
import Menu from '../../components/@setproduct-ui/core/Menu';
import MenuItem from '../../components/@setproduct-ui/core/Menu/MenuItem';
import styles from './style.module.css';

export default function EnhancedMenu({
  row,
  onRunOCR,
  onDeleteDocument,
  onReplaceDocument,
  onClose,
}) {
  const history = useHistory();
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu view="smooth" type="dense" color="default" className={styles.menu}>
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Preview Document"
          disabled={row.ocr.status !== 4}
          onClick={() => {
            history.push(`/d/document-preview/${row.id}`);
            return onClose();
          }}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Run OCR"
          onClick={onRunOCR}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Replace Document"
          onClick={onReplaceDocument}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Delete"
          onClick={onDeleteDocument}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}
