import React from "react";
import { debounce } from "lodash";

import { FixedSizeList as List } from "react-window";
import { pdfjs, Document, Page } from "react-pdf";
import InfiniteLoader from "react-window-infinite-loader";
import Spinner from "../../components/Loading";
import styles from "./style.module.css";
import { colors } from "./colors.json";
const PAGE_SPAZING = 16;

// PDFjs options
const options = {};

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function getInitial(name) {
    return name.split(" ").reduce((acc, name, idx) => {
        if (idx < 2) acc += name[0].toUpperCase();
        return acc;
    }, "")
}

class LoadingPageRenderer extends React.PureComponent {
  render() {
    const { height, width } = this.props;
    return (
      <div
        style={{
          border: "1px solid var(--grey10)",
          height: height,
          width: width
        }}
      ></div>
    );
  }
}

const isPageContainSelected = (
  selectedPage,
  selectedBoundingBox,
  pageNumber,
  currentAnnotator
) => {
  let pageOfBoundaries;
  if (currentAnnotator) {
    pageOfBoundaries = selectedBoundingBox
      ? selectedBoundingBox[currentAnnotator].reduce((acc, node) => {
        if (acc.indexOf(node.pageNumber) === -1)
          acc = acc.concat(node.pageNumber);
        return acc;
      }, [])
      : [];
  } else 
    pageOfBoundaries = selectedBoundingBox
      ? selectedBoundingBox.reduce((acc, node) => {
        if (acc.indexOf(node.pageNumber) === -1)
          acc = acc.concat(node.pageNumber);
        return acc;
      }, [])
      : [];
  return pageOfBoundaries.indexOf(pageNumber) !== -1;
};

class PageRenderer extends React.PureComponent {
  render() {
    const { index, data } = this.props;
    const {
      cachedPageDimensions,
      setCurrentPage,
      containerWidth,
      currentPage,
      maxPageHeight,
      maxPageWidth,
      pageBoundingBox,
      selectedPage,
      selectedBoundingBox,
      displayedPage,
      displayedAnnotators,
      annotators,
      currentAnnotator,
      itemSize,
      thumbnailPageWidth
    } = data;

    const pageNumber = index + 1;
    const pageDimensions = cachedPageDimensions.get(pageNumber);
    const aspectRatio = cachedPageDimensions.get(pageNumber)[1] / cachedPageDimensions.get(pageNumber)[0];
    const width = thumbnailPageWidth;
    const height = thumbnailPageWidth * aspectRatio;
    // const scale = cachedPageDimensions.get(pageNumber)[0] / firstPageWidth;
    const scale = width / pageDimensions[0];
    let isSelected;
    if(pageBoundingBox.length > 0 && displayedPage) {
      isSelected = Object.keys(displayedPage).indexOf(pageBoundingBox[index].nodeId) !== -1;
    } else {
      isSelected = false;
    }

    const pageWrapperStyle = {
      ...this.props.style,
      cursor: "pointer",
      width: containerWidth,
      height: itemSize - 16,
      border: isSelected
        ? "2px solid var(--blue40)"
        : isPageContainSelected(selectedPage, selectedBoundingBox, index + 1, currentAnnotator)
        ? "2px solid var(--red30)"
        : "2px solid transparent"
    };
    const isActive = currentPage === pageNumber;

    return (
      <div
        className={`${styles.page_wrapper} ${isActive ? styles.active : ''}`}
        key={`page_${pageNumber}`}
        style={pageWrapperStyle}
        onClick={() => setCurrentPage(pageNumber)}
      >
        {isSelected && (
          <div className={styles.userBoxWrapperPage}>
            {displayedPage[pageBoundingBox[index].nodeId].annotators.map(
              (annotatorId, idx) => (
                <span
                  key={idx}
                  className={styles.userBox}
                  style={{
                    background: colors[displayedAnnotators.indexOf(annotatorId)]
                  }}
                >
                  {getInitial(annotators[annotatorId])}
                </span>
              )
            )}
          </div>
        )}
        <Page
          className={`${styles.page}`}
          pageNumber={pageNumber}
          loading={() => (
            <LoadingPageRenderer height={height} width={containerWidth} />
          )}
          scale={scale}
          renderAnnotationLayer={false}
        />
        <div className={styles.page_number_wrapper}>
          <span className={styles.page_number}>{index + 1}</span>
        </div>
      </div>
    );
  }
}

class DocumentViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      thumbnailPageWidth: 160,
      containerWidth: 192,
      containerHeight: undefined,
      numPages: undefined,
      currentPage: 1,
      cachedPageDimensions: null,
      maxPageHeight: null,
      maxPageWidth: null,
      documentLoaded: false,
      loaded: false,
      file: {
        url: this.props.file
      }
    };

    this.viewerContainerRef = React.createRef();
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this._mounted = true;
    this.calculateContainerBounds();
    window.addEventListener("resize", this.handleWindowResize, true);
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener("resize", this.handleWindowResize, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scale !== this.props.scale) {
      this.recomputeRowHeights();
    }
  }

  /**
   * Load all pages to cache all page dimensions.
   */
  cachePageDimensions(pdf) {
    const promises = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map(
      pageNumber => pdf.getPage(pageNumber)
    );

    // Assuming all pages may have different heights. Otherwise we can just
    // load the first page and use its height for determining all the row
    // heights.
    Promise.all(promises).then(values => {
      if (!this._mounted) {
        return null;
      }
      let widthArray = [];
      let heightArray = [];

      const pageDimensions = values.reduce((accPageDimensions, page) => {
        widthArray.push(page.view[2]);
        heightArray.push(page.view[3]);
        accPageDimensions.set(page.pageNumber, [
          page.view[2],
          page.view[3]
        ]);
        return accPageDimensions;
      }, new Map());
      this.setState({
        cachedPageDimensions: pageDimensions,
        maxPageHeight: Math.max.apply(Math, heightArray),
        maxPageWidth: Math.max.apply(Math, widthArray),
      });
    });
  }

  calculateContainerBounds = () => {
    if (this.viewerContainerRef == null) {
      return;
    }
    const rect = this.viewerContainerRef.current.getBoundingClientRect();
    this.setState({
      // containerWidth: rect.width,
      containerHeight: rect.height
    });
  };

  recomputeRowHeights = () => {
    this.listRef.current.resetAfterIndex(0);
  };

  onDocumentLoadSuccess = pdf => {
    this.setState({
      numPages: pdf.numPages
    });
    this.calculateContainerBounds();
    this.cachePageDimensions(pdf);
    this.setState({
      loaded: true
    });
  };

  handleWindowResize = debounce(() => {
    this.calculateContainerBounds();
  }, 300);

  updateCurrentVisiblePage = ({ visibleStopIndex }) => {
    this.setState({
      currentPage: visibleStopIndex + 1
    });
  };

  getItemSize = index => {
    const aspectRatio =
      this.state.cachedPageDimensions.get(index + 1)[1] /
      this.state.cachedPageDimensions.get(index + 1)[0];
    return 16 + (aspectRatio * (this.state.containerWidth - 32))
  };

  render() {
    const {
      numPages,
      cachedPageDimensions,
      containerHeight,
      containerWidth,
      maxPageHeight,
      maxPageWidth,
      thumbnailPageWidth,
      loaded
    } = this.state;


    const itemSize = 322;


    const { currentPage } = this.props;

    const itemData = {
      scale: this.props.scale,
      containerWidth: containerWidth,
      containerHeight: containerHeight,
      maxPageHeight: maxPageHeight,
      maxPageWidth: maxPageWidth,
      setCurrentPage: this.props.setCurrentPage,
      cachedPageDimensions,
      currentPage: currentPage,
      pageBoundingBox: this.props.pageBoundingBox,
      selectedPage: this.props.selectedPage,
      selectedBoundingBox: this.props.selectedBoundingBox,
      displayedPage: this.props.displayedPage,
      displayedAnnotators: this.props.displayedAnnotators,
      annotators: this.props.annotators,
      currentAnnotator: this.props.currentAnnotator,
      itemSize: itemSize,
      thumbnailPageWidth: thumbnailPageWidth
    };

    return (
      <div style={{ height: "100%" }} ref={this.viewerContainerRef}>
        <Document
          className="dv__document"
          style={{ height: "100%" }}
          file={this.state.file}
          loading={<Spinner />}
          onLoadSuccess={pdf => this.onDocumentLoadSuccess(pdf)}
          options={options}
          noData=""
          onItemClick={({ pageNumber }) => {
            return this.props.setCurrentPage(pageNumber);
          }}
          renderMode="img"
        >
          {loaded
            ? (cachedPageDimensions != null && maxPageWidth > 0) && (
                <List
                  height={containerHeight}
                  itemCount={numPages}
                  itemSize={itemSize}
                  itemData={itemData}
                  overscanCount={2}
                  onItemsRendered={this.updateCurrentVisiblePage}
                  ref={this.listRef}
                >
                  {PageRenderer}
                </List>
              )
            : null}
        </Document>
      </div>
    );
  }
}

export default DocumentViewer;
