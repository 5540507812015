import React, { useState } from "react"
import { connect } from "react-redux";
import { Icon } from "@blueprintjs/core";
import FlexWrapper from "../../components/FlexWrapper";
import Input from "../../components/Input";
import IconButton from "@material-ui/core/IconButton";
import { addSection } from "../../redux/actions/admin"

function ChapterForm(props) {
  const [chapterName, setChapterName] = useState("");
  const handleSaveChapter = async () => {
    const ancestor = [props.ancestor];
    const isSuccess = await props.addSection({
      name: chapterName,
      type: "CHAPTER",
      ancestors: ancestor,
      parent: ancestor[0]
    });
    if (isSuccess) {
      setChapterName("");
      props.hide()
    }
  };

  return (
      <FlexWrapper
        justifyContent="space-between"
        style={{
          padding: ".3em",
          borderRadius: "0.2em",
          marginBottom: 5,
          cursor: "pointer",
          transition: "100ms background ease-in"
        }}
      >
        <FlexWrapper>
          <Icon
            icon="folder-close"
            iconSize={16}
            style={{ color: "var(--grey50)" }}
          />
          <div style={{ marginLeft: 10 }}>
            <Input
              name="chapterName"
              type="text"
              view="outlined"
              color="default"
              onInput={e => setChapterName(e.target.value)}
              value={chapterName}
              fill={true}
              error={false}
              dense={true}
              errorMessage={""}
              style={{ width: "95%" }}
            />
          </div>
        </FlexWrapper>
        <IconButton
          size="small"
          onClick={handleSaveChapter}
          disabled={chapterName === ""}
          style={{ opacity: chapterName !== "" ? 1 : 0.5 }}
        >
          <Icon icon="tick" iconSize={16} />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setChapterName("");
            props.hide()
          }}
        >
          <Icon icon="cross" iconSize={16} />
        </IconButton>
      </FlexWrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  addSection: data => dispatch(addSection(data))
})

export default connect(null, mapDispatchToProps)(ChapterForm)
