import React from "react";

function BoundingBox(props) {
  const { matchedBoundary, isHighlighted } = props;
  const isSelected = !!matchedBoundary
  const isEdited = matchedBoundary?.text
  const getBorder = () => {
    if (isHighlighted) return "2px solid var(--green70)"
    if (isEdited) return "2px solid var(--orange70)"
    if (isSelected) return "2px solid var(--blue40)"
    return "2px solid var(--yellow40)"
  }

  return (
    <div
      style={{
        position: "absolute",
        top: props.topLeft.y,
        left: props.topLeft.x,
        height: props.height,
        width: props.width,
        cursor: "pointer",
        border: getBorder(),
        background: isSelected && "rgba(118, 169, 255, 0.2)",
        ...props.style
      }}
      onClick={props.onClick}
    ></div>
  );
}

export default BoundingBox;
