import React from 'react'
import routes from '../../routers/routes';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const generateRoutesComponents = (routes) => routes.map((route) =>
    <Route exact key={route.path} path={route.path} component={route.component} />
)

export default function PrivateRoutesWithoutSidebar(props) {
    const role = useSelector(state => state.userProfile.role);
    const locationPath = useSelector(state => state.router.location.pathname);
    const roleRoutes = routes.authenticated[role][1].routes;
    const routeSelector = roleRoutes.filter(item => item.path === locationPath);
    const title = routeSelector.length > 0 ? routeSelector[0].title : "Dashboard";
    const routesComponents = generateRoutesComponents(roleRoutes)

    return (
        <Switch location={props.location}>
            {routesComponents}
            <Route>
                <Redirect to="/m" />
            </Route>
        </Switch>
    )
}
