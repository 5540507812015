import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import { debounce } from 'lodash';
import Select from 'react-select';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import Button from '../../components/@setproduct-ui/core/Button';
import Chips from '../../components/@setproduct-ui/core/Chips';
import ProgressBar from '../../components/@setproduct-ui/core/ProgressBar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '../../components/@setproduct-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Icon } from '@blueprintjs/core';
import Wrapper from '../../components/BasicWrapper';
import FlexWrapper from '../../components/FlexWrapper';
import Input from '../../components/Input';
import Navbar from '../../components/@setproduct-ui/core/Navbar';
import NavbarGroup from '../../components/@setproduct-ui/core/Navbar/Group';
import NavbarHeading from '../../components/@setproduct-ui/core/Navbar/Heading';
import Brand from '../../components/Brand';
import NavbarUserMenu from '../../components/NavbarUserMenuDocument';
import logo from '../../assets/images/cesgs-logo-only.png';
import Heading from '../../components/Heading';
import Spinner, { Loading } from '../../components/Loading';
import Toolbar from './Toolbar';
import styles from './style.module.css';
import { getTaskList } from '../../redux/actions/admin';
import {
  getDocumentData,
  getCompanyList,
  updateMetadata,
  getBoundaries,
  zoomIn,
  zoomOut,
  resetPageScale,
  nextPage,
  prevPage,
  setNumPages,
  setCurrentPage,
  setPageLoaded,
  setPaperSize,
  setBoundingBox,
  changeBoundingBoxType,
  resetDocumentPreview,
  removeBoundaries,
  shiftBoundaries,
  toggleAnnotationDisable,
} from '../../redux/actions/documentPreview';
import { documentPreviewSelector } from '../../redux/reducers/documentPreview';
import { createLoadingSelector } from '../../redux/api/loading';
import FullLayout from '../../layouts/FullLayout';
import DocumentViewer from './DocumentViewer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdfOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const loadingSelectorDocument = createLoadingSelector([
  'GET_DOCUMENT_DATA',
  'UPDATE_METADATA',
]);

const useStyles = makeStyles({
  container: {
    maxHeight: '15vh',
    overflowY: 'auto',
  },
  cell: {
    color: 'var(--grey50)',
    fontSize: 12,
    fontFamily: 'var(--inter)',
  },
});

const PageLoading = ({ height, width }) => (
  <div className={styles.loadingBackdrop}>
    <Loading />
  </div>
);

function getReportType(type) {
  if (type) {
    if (type === 'annual_report') return 'Annual Report';
    if (type === 'sustainability_report') return 'Sustainability Report';
  }
  return '-';
}

function fixUrl(url) {
  return !url.includes('http') ? `https://${url}` : url;
}

function DocumentPreview({
  getDocumentData,
  getCompanyList,
  getTaskList,
  getBoundaries,
  file,
  boundingBox,
  numPages,
  currentPage,
  pageScale,
  paperSize,
  pageLoaded,
  setBoundingBox,
  boundingBoxType,
  resetDocumentPreview,
  loadingRemoveBoundaries,
  loadingShiftBoundaries,
  annotationDisabled,
  ...props
}) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [company, setCompany] = useState({});
  const [type, setType] = useState({
    value: 'annual_report',
    label: 'Annual Report',
  });
  const [year, setYear] = useState('');
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [progress, setProgress] = useState({ status: true, value: 0 });
  const [selectedBox, setSelectedBox] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogShiftBoundaries, setOpenDialogShiftBoundaries] =
    useState(false);
  const { document_id } = useParams();

  useEffect(() => {
    resetDocumentPreview();
    getDocumentData(document_id);
    getTaskList();
    getCompanyList();
  }, [
    document_id,
    getCompanyList,
    getDocumentData,
    getTaskList,
    resetDocumentPreview,
  ]);

  useEffect(() => {
    if (props.companies.length) {
      setCompany({
        value: props.companies[0]._id,
        label: props.companies[0].name,
      });
    }
  }, [props.companies]);

  useEffect(() => {
    if (props.ocrStatus === 4 && document_id && !boundingBox[currentPage - 1]) {
      const debounced = debounce(getBoundaries, 500);
      debounced(document_id, currentPage);
      return () => {
        debounced.cancel();
      };
    }
  }, [document_id, currentPage, props.ocrStatus, boundingBox, getBoundaries]);

  useEffect(() => {
    setType({
      value:
        props.metadata.type === '' || props.metadata.type === 'annual_report'
          ? 'annual_report'
          : 'sustainability_report',
      label:
        props.metadata.type === '' || props.metadata.type === 'annual_report'
          ? 'Annual Report'
          : 'Sustainability Report',
    });
    setYear(props.metadata.year || '');
    if (props.metadata.companyId && props.metadata.companyName) {
      const { companyId, companyName } = props.metadata;
      setCompany({ value: companyId, label: companyName });
    } else {
      if (props.companies.length) {
        setCompany({
          value: props.companies[0]._id,
          label: props.companies[0].name,
        });
      }
    }
  }, [props.metadata, props.companies]);

  const generateAnnotation = (pageData, page, boundingBoxType) => {
    const boxes = boundingBox[page - 1]
      .filter((box) => boundingBoxType.indexOf(box.type) !== -1)
      .map((box) => {
        const scaledBox = box.attributes.vertices.map((vertices) => {
          return {
            x: vertices.x * pageData.width * pageScale,
            y: vertices.y * pageData.height * pageScale,
          };
        });
        // calculate div size
        const width = scaledBox[1].x - scaledBox[0].x;
        const height = scaledBox[3].y - scaledBox[0].y;
        // position
        const topLeft = scaledBox[0];
        return (
          <div
            key={box.nodeId}
            onClick={() => handleSelectBox(box.nodeId)}
            style={{
              position: 'absolute',
              top: topLeft.y,
              left: topLeft.x,
              height: height,
              width: width,
              cursor: 'pointer',
              border:
                selectedBox.indexOf(box.nodeId) !== -1
                  ? '2px solid var(--red70)'
                  : '2px solid #7DB1FF',
            }}
          ></div>
        );
      });
    const Container = (
      <div
        id="annotation-layer"
        style={{
          position: 'absolute',
          display: 'inline-block',
          zIndex: 1,
          height: paperSize.height * pageScale,
          width: paperSize.width * pageScale,
        }}
      >
        <div
          style={{
            position: 'relative',
            height: paperSize.height * pageScale,
            width: paperSize.width * pageScale,
          }}
        >
          {boxes}
        </div>
      </div>
    );
    return Container;
  };

  const handleSelectBox = (nodeId) => {
    if (selectedBox.indexOf(nodeId) === -1) {
      setSelectedBox(selectedBox.concat(nodeId));
    } else setSelectedBox(selectedBox.filter((x) => x !== nodeId));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setDocumentLoaded(true);
    setProgress({ status: false, value: 0 });
    props.setNumPages(numPages);
  };

  const onPageLoadSuccess = (page) => {
    const pageOriginalRotation = page._pageInfo.rotate;
    const [horMargin, verMargin] = page._pageInfo.view;
    // count height & width with margin size
    const origHeight = page.originalHeight - verMargin;
    const origWidth = page.originalWidth - horMargin;
    // if rotation is not 0 or 180 then swap height and width
    const height = [0, 180].includes(pageOriginalRotation)
      ? origHeight
      : origWidth;
    const width = [0, 180].includes(pageOriginalRotation)
      ? origWidth
      : origHeight;
    props.setPageLoaded(true);
    props.setPaperSize({ height, width });
  };

  const validate = () => {
    return !(year !== '' && company && type);
  };

  const handleUpdateMetadata = async () => {
    const isSucess = await props.updateMetadata(document_id, {
      metadata: {
        type: type.value,
        companyId: company.value,
        year,
      },
    });
    if (isSucess) {
      setEditMode(false);
    }
  };

  const handleNextPage = () => {
    props.setPageLoaded(false);
    props.nextPage();
  };

  const handlePrevPage = () => {
    props.setPageLoaded(false);
    props.prevPage();
  };

  const handleDeleteBoundaries = async () => {
    const success = await props.removeBoundaries(selectedBox);
    if (success) {
      setSelectedBox([]);
      getBoundaries(document_id, currentPage);
      setOpenDialog(false);
    }
  };

  const handleShiftBoundaries = async () => {
    const success = await props.shiftBoundaries(
      document_id,
      currentPage,
      paperSize
    );
    if (success) {
      getBoundaries(document_id, currentPage);
      setOpenDialogShiftBoundaries(false);
    }
  };

  return (
    <FullLayout>
      <Wrapper height="100vh">
        <Navbar type="desktop" view="flat">
          <NavbarGroup>
            <NavbarHeading>
              <Brand
                logo={logo}
                filename={props.filename}
                status={getReportType(props.metadata.type)}
              />
            </NavbarHeading>
          </NavbarGroup>
          <NavbarGroup align="right">
            <NavbarHeading>
              <NavbarUserMenu />
            </NavbarHeading>
          </NavbarGroup>
        </Navbar>
        <FlexWrapper height="100%" alignItems="flex-start">
          <div
            style={{
              display: 'flex',
              flex: '0 1 240px',
              borderRight: '1px solid var(--grey20)',
              flexFlow: 'column nowrap',
            }}
          >
            <FlexWrapper
              justifyContent="space-between"
              style={{
                padding: 5,
                borderTop: '1px solid var(--grey10)',
                borderLeft: '1px solid var(--grey10)',
                borderBottom: '1px solid var(--grey10)',
              }}
            >
              <Heading text="Page Thumbnails" />
            </FlexWrapper>
            <FlexWrapper
              flexFlow="column"
              justifyContent="flex-start"
              alignItems="stretch"
              height="84vh"
              width="100%"
              style={{
                overflow: 'auto',
                borderRight: '1px solid var(--grey20)',
              }}
            >
              {!documentLoaded ? (
                <Spinner />
              ) : (
                file && (
                  <DocumentViewer
                    file={fixUrl(file)}
                    currentPage={currentPage}
                    setCurrentPage={props.setCurrentPage}
                  />
                )
              )}
            </FlexWrapper>
          </div>
          <FlexWrapper
            width="60%"
            flexFlow="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Toolbar
              data={{
                boundingBoxType,
                pageScale,
                currentPage,
                numPages,
                pageLoaded,
                selectedBoxCount: selectedBox.length,
                loadingRemoveBoundaries,
                loadingShiftBoundaries,
                annotationDisabled,
              }}
              ocrStatus={props.ocrStatus}
              zoomIn={props.zoomIn}
              zoomOut={props.zoomOut}
              resetPageScale={props.resetPageScale}
              setCurrentPage={props.setCurrentPage}
              prevPage={handlePrevPage}
              nextPage={handleNextPage}
              handlePageChange={(e) => {
                const { value } = e.target;
                if (/\d+/.test(value)) {
                  const targetPage = parseInt(value);
                  if (targetPage <= numPages && targetPage > 0) {
                    props.setCurrentPage(parseInt(value));
                  }
                }
              }}
              changeBoundingBoxType={props.changeBoundingBoxType}
              handleRemoveBoundaries={() => setOpenDialog(true)}
              handleShiftBoundaries={() => setOpenDialogShiftBoundaries(true)}
              toggleAnnotationDisable={() => props.toggleAnnotationDisable()}
            />
            <div className={styles.pdf_viewer}>
              {progress.status && !documentLoaded && (
                <div className={styles.progress}>
                  <ProgressBar
                    view="filled"
                    color="primary"
                    stripes={false}
                    value={progress.value}
                  />
                </div>
              )}
              {file && (
                <Document
                  className={styles.pdf_doc}
                  file={fixUrl(file)}
                  loading={<PageLoading />}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={({ loaded, total }) =>
                    setProgress({ status: true, value: loaded / total })
                  }
                  options={pdfOptions}
                  onItemClick={({ pageNumber }) => {
                    if (pageNumber) props.setCurrentPage(pageNumber);
                  }}
                  externalLinkTarget="_blank"
                >
                  <div
                    className={styles.pdf_page}
                    style={{
                      height: Math.floor(paperSize.height * pageScale),
                      width: Math.floor(paperSize.width * pageScale),
                    }}
                  >
                    {documentLoaded &&
                      pageLoaded &&
                      boundingBox[currentPage - 1] &&
                      !annotationDisabled &&
                      generateAnnotation(
                        paperSize,
                        currentPage,
                        boundingBoxType
                      )}
                    <Page
                      loading={<PageLoading />}
                      onLoadSuccess={onPageLoadSuccess}
                      scale={pageScale}
                      pageNumber={currentPage}
                      renderMode="canvas"
                      renderInteractiveForms={false}
                      renderTextLayer={false}
                    />
                  </div>
                </Document>
              )}
            </div>
          </FlexWrapper>
          <Wrapper
            width="25%"
            height="calc(100% - 3.5rem)"
            style={{
              overflowY: 'auto',
            }}
          >
            <FlexWrapper
              justifyContent="space-between"
              style={{
                padding: 5,
                borderTop: '1px solid var(--grey10)',
                borderLeft: '1px solid var(--grey10)',
                borderBottom: '1px solid var(--grey10)',
                position: 'sticky',
                top: 0,
                background: '#fff',
              }}
            >
              <Heading text="Document Details" />
            </FlexWrapper>
            <FlexWrapper
              flexFlow="column"
              height={267}
              style={{ borderBottom: '1px solid var(--grey10)' }}
            >
              <Icon icon="document" iconSize={96} />
            </FlexWrapper>
            <FlexWrapper
              width="100%"
              justifyContent="space-between"
              style={{
                borderBottom: '1px solid var(--grey10)',
                padding: 5,
                position: 'sticky',
                top: 50,
                background: '#fff',
              }}
            >
              <Heading text="Document Metadata" />
              <IconButton size="small" onClick={() => setEditMode(true)}>
                <Icon icon="edit" iconSize={16} />
              </IconButton>
            </FlexWrapper>
            <FlexWrapper
              flexFlow="column"
              justifyContent="flex-start"
              alignItems="stretch"
              width="100%"
              padding="0 10px 10px 10px"
              style={{
                overflowY: 'auto',
                maxHeight: '39vh',
              }}
            >
              {editMode ? (
                <>
                  <FlexWrapper
                    flexFlow="column"
                    width="100%"
                    alignItems="stretch"
                    margin="10px 0"
                  >
                    <label
                      htmlFor="type"
                      style={{ color: 'var(--grey50)', marginBottom: 5 }}
                    >
                      Type
                    </label>
                    <Select
                      name="type"
                      value={type}
                      onChange={(selected) => setType(selected)}
                      options={[
                        {
                          value: 'annual_report',
                          label: 'Annual Report',
                        },
                        {
                          value: 'sustainability_report',
                          label: 'Sustainability Report',
                        },
                      ]}
                    />
                  </FlexWrapper>
                  <FlexWrapper
                    flexFlow="column"
                    width="100%"
                    alignItems="stretch"
                    margin="0 0 10px 0"
                  >
                    <label
                      htmlFor="company"
                      style={{ color: 'var(--grey50)', marginBottom: 5 }}
                    >
                      Company
                    </label>
                    <Select
                      name="company"
                      value={company}
                      onChange={(selected) => setCompany(selected)}
                      placeholder=""
                      options={props.companies.map((company) => ({
                        value: company._id,
                        label: company.name,
                      }))}
                    />
                  </FlexWrapper>
                  <FlexWrapper
                    flexFlow="column"
                    width="100%"
                    alignItems="stretch"
                    margin="0 0 10px 0"
                  >
                    <label
                      htmlFor="type"
                      style={{ color: 'var(--grey50)', marginBottom: 5 }}
                    >
                      Year
                    </label>
                    <Input
                      name="year"
                      type="text"
                      view="outlined"
                      color="default"
                      onInput={(e) => setYear(e.target.value)}
                      value={year.toString()}
                      fill={true}
                      errorMessage={''}
                    />
                  </FlexWrapper>
                  <FlexWrapper width="100%" justifyContent="space-between">
                    <Button
                      text="Cancel"
                      view="flat"
                      color="default"
                      fill={false}
                      onClick={() => setEditMode(false)}
                      disabled={props.loading}
                    />
                    <Button
                      text="Update"
                      view="filled"
                      color="default"
                      fill={false}
                      onClick={handleUpdateMetadata}
                      loading={props.loading}
                      disabled={validate()}
                    />
                  </FlexWrapper>
                </>
              ) : (
                <div style={{ fontSize: 12, marginTop: 10 }}>
                  <FlexWrapper
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="stretch"
                  >
                    <label
                      htmlFor="type"
                      style={{ color: 'var(--grey50)', width: 95, height: 27 }}
                    >
                      Type
                    </label>
                    <span>{getReportType(props.metadata.type)}</span>
                  </FlexWrapper>
                  <FlexWrapper
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="stretch"
                  >
                    <label
                      htmlFor="company"
                      style={{ color: 'var(--grey50)', width: 95, height: 27 }}
                    >
                      Company
                    </label>
                    <span>{props.metadata.companyName || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="stretch"
                  >
                    <label
                      htmlFor="company"
                      style={{ color: 'var(--grey50)', width: 95, height: 27 }}
                    >
                      Uploaded At
                    </label>
                    <span>
                      {props.uploadedDate
                        ? format(new Date(props.uploadedDate), 'dd MMMM yyyy')
                        : '-'}
                    </span>
                  </FlexWrapper>
                  <FlexWrapper
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="stretch"
                  >
                    <label
                      htmlFor="company"
                      style={{ color: 'var(--grey50)', width: 95, height: 27 }}
                    >
                      Year
                    </label>
                    <span>{props.metadata.year || '-'}</span>
                  </FlexWrapper>
                </div>
              )}
            </FlexWrapper>
            {!editMode && (
              <>
                <FlexWrapper
                  width="100%"
                  justifyContent="space-between"
                  padding="5px"
                >
                  <Heading text="Tasks" />
                </FlexWrapper>
                {props.tasks.list.filter(
                  (task) => task.document.filename === props.filename
                ).length ? (
                  <TableContainer component="div" className={classes.container}>
                    <Table
                      className={styles.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        {props.tasks.list
                          .filter(
                            (task) => task.document.filename === props.filename
                          )
                          .sort((a, b) => a.id - b.id)
                          .map((task) => (
                            <TableRow key={task.id} className={styles.row}>
                              <TableCell className={classes.cell}>
                                #{task.id}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {task.annotator.profile.name}
                              </TableCell>
                              <TableCell>
                                <Chips
                                  type="dense"
                                  color="success"
                                  tag={task.status.toUpperCase()}
                                  withTick={false}
                                  round={true}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <span className={styles.info}>
                    No task related to this document
                  </span>
                )}
              </>
            )}
          </Wrapper>
        </FlexWrapper>
        <Dialog
          view="raised"
          title={`Delete Boundar${selectedBox.length === 1 ? 'y' : 'ies'}`}
          text={`Are you sure you want to delete the selected boundar${
            selectedBox.length === 1 ? 'y' : 'ies'
          }?`}
          backdropOpacity={40}
          rightButton={
            <>
              <Button
                view="flat"
                color="default"
                dense={false}
                onClick={() => setOpenDialog(false)}
                disabled={loadingRemoveBoundaries}
                text="Cancel"
                style={{ marginRight: 10 }}
              />
              <Button
                view="filled"
                color="danger"
                dense={false}
                onClick={handleDeleteBoundaries}
                loading={loadingRemoveBoundaries}
                text="Delete"
              />
            </>
          }
          isOpen={openDialog}
          onClose={() => setOpenDialog(false)}
        />
        <Dialog
          view="raised"
          title={`Shift Boundaries`}
          text={`Are you sure you want to shift all the boundaries on this page?`}
          backdropOpacity={40}
          rightButton={
            <>
              <Button
                view="flat"
                color="default"
                dense={false}
                onClick={() => setOpenDialogShiftBoundaries(false)}
                disabled={loadingShiftBoundaries}
                text="Cancel"
                style={{ marginRight: 10 }}
              />
              <Button
                view="filled"
                color="primary"
                dense={false}
                onClick={handleShiftBoundaries}
                loading={loadingShiftBoundaries}
                text="Proceed"
              />
            </>
          }
          isOpen={openDialogShiftBoundaries}
          onClose={() => setOpenDialogShiftBoundaries(false)}
        />
      </Wrapper>
    </FullLayout>
  );
}

const mapStateToProps = (state) => ({
  ...documentPreviewSelector(state),
  userProfile: state.userProfile,
  tasks: state.admin.tasks,
  location: state.router.location,
  loading: loadingSelectorDocument(state),
  loadingRemoveBoundaries: createLoadingSelector(['REMOVE_BOUNDARIES'])(state),
  loadingShiftBoundaries: createLoadingSelector(['SHIFT_BOUNDARIES'])(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDocumentData: (documentId) => dispatch(getDocumentData(documentId)),
  getCompanyList: () => dispatch(getCompanyList()),
  getTaskList: () => dispatch(getTaskList()),
  updateMetadata: (id, data) => dispatch(updateMetadata(id, data)),
  getBoundaries: (documentId, page) =>
    dispatch(getBoundaries(documentId, page)),
  zoomIn: () => dispatch(zoomIn()),
  zoomOut: () => dispatch(zoomOut()),
  resetPageScale: () => dispatch(resetPageScale()),
  setNumPages: (pageCount) => dispatch(setNumPages(pageCount)),
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  setPageLoaded: (status) => dispatch(setPageLoaded(status)),
  setPaperSize: (paperSize) => dispatch(setPaperSize(paperSize)),
  nextPage: () => dispatch(nextPage()),
  prevPage: () => dispatch(prevPage()),
  setBoundingBox: (data) => dispatch(setBoundingBox(data)),
  changeBoundingBoxType: (boundingBoxType) =>
    dispatch(changeBoundingBoxType(boundingBoxType)),
  resetDocumentPreview: () => dispatch(resetDocumentPreview()),
  removeBoundaries: (nodeIds) => dispatch(removeBoundaries(nodeIds)),
  shiftBoundaries: (documentId, pageNumber, pageSize) =>
    dispatch(shiftBoundaries(documentId, pageNumber, pageSize)),
  toggleAnnotationDisable: () => dispatch(toggleAnnotationDisable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreview);
