import React from "react";
import { useSelector } from "react-redux";
import sidebarItems from "./LeftSidebarData";
import { Link } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import annotateLogo from "../../assets/images/cesgs-logo-only.png";
import PATH_URL from "../../routers/path";
import styles from "./Sidebar.module.css";
import cx from "classnames";

const sidebarItem = (item, currentPath, index) => {
  const isActive = currentPath === item.link;
  return (
    <Link to={item.link} key={index}>
      <div
        className={cx(
          styles.sidebarItem,
          { [`${styles.active}`]: isActive }
        )}
      >
        <Icon
          icon={item.icon}
          iconSize={24}
          color="var(--blue100)"
          className={styles.sidebarItemIcon}
        />
        <span>
          {item.label}
        </span>
      </div>
    </Link>
  )
}

const Sidebar = () => {
  const currentPath = useSelector(state => state.router.location.pathname);
  const role = useSelector(state => state.userProfile.role);
  return (
    <div className={styles.sidebarContainer}>
      <Link to={PATH_URL.DASHBOARD} className={styles.sidebarHeaderContainer}>
        <div className={styles.sidebarLogo}>
          <img src={annotateLogo} alt="" />
        </div>
        <span
          className={styles.sidebarLogoTitle}
        >
          Annotation
        </span>
      </Link>
      <div className={styles.sidebarItems}>
        {sidebarItems[role].map((item, index) => sidebarItem(item, currentPath, index))}
      </div>
    </div>
  );
};

export default Sidebar;
