import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchInput from '../../components/FormAnnotation/SearchInput';
import Highlighter from 'react-highlight-words';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { debounce } from 'lodash';
import Spinner from '../../components/Loading';
import {
  resetSearchResults,
  searchDocument,
} from '../../redux/actions/documentSearch';
import styles from './style.module.css';
import { createLoadingSelector } from '../../redux/api/loading';

export default function TabSearch(props) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [containerHeight, setContainerHeight] = useState(0);
  const {
    results: searchResults,
    searchTerm,
    canNextPage,
  } = useSelector((state) => state.documentSearch);
  const isLoadingInit = useSelector(
    createLoadingSelector(['SEARCH_DOCUMENT_INIT'])
  );
  const isLoadingMore = useSelector(
    createLoadingSelector(['SEARCH_DOCUMENT_APPEND'])
  );
  const loadInitial = (searchTerm) => {
    dispatch(searchDocument(searchTerm));
  };
  const loadMore = () => {
    return dispatch(searchDocument(null, 'append'));
  };
  const itemCount = canNextPage
    ? searchResults.length + 1
    : searchResults.length;
  const loadMoreItems = isLoadingMore ? () => {} : loadMore;
  const isItemLoaded = (index) => !canNextPage || index < searchResults.length;
  const Item = ({ index, style }) => {
    if (isItemLoaded(index)) {
      const result = searchResults[index];
      return (
        <div style={style}>
          <div
            className={styles.resultList}
            onClick={() => props.handleClick(result.page)}
          >
            <Highlighter
              className={styles.matchText}
              searchWords={searchTerm.split(' ')}
              textToHighlight={result.text}
            />
            <span>{result.page}</span>
          </div>
        </div>
      );
    }
    return (
      <div style={style}>
        <div className={styles.resultList}>
          <span>Loading...</span>
        </div>
      </div>
    );
  };
  const getContainerHeight = () => {
    if (containerRef.current) {
      const { height } = containerRef.current.getBoundingClientRect();
      setContainerHeight(height);
    }
  };
  const handleWindowResize = debounce(() => {
    getContainerHeight();
  }, 300);

  useEffect(() => {
    getContainerHeight();
    window.addEventListener('resize', handleWindowResize, true);
    return () => {
      window.removeEventListener('resize', handleWindowResize, true);
    };
  }, [handleWindowResize]);

  useEffect(() => () => dispatch(resetSearchResults()), [dispatch]);

  return (
    <div
      role="tabpanel"
      hidden={props.tabIndex !== 2}
      id={`tabpanel-${3}`}
      aria-labelledby={`tab-${3}`}
    >
      <div style={{ padding: 10 }}>
        <SearchInput
          onSubmit={loadInitial}
          onReset={() => dispatch(resetSearchResults())}
        />
        <div ref={containerRef} className={styles.searchResultContainer}>
          {isLoadingInit && <Spinner />}
          {!isLoadingInit && searchResults.length > 0 && (
            <InfiniteLoader
              itemCount={itemCount}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  ref={ref}
                  height={containerHeight || 100}
                  onItemsRendered={onItemsRendered}
                  itemCount={itemCount}
                  itemSize={40}
                  width="100%"
                >
                  {Item}
                </List>
              )}
            </InfiniteLoader>
          )}
          {!isLoadingInit && searchResults.length === 0 && (
            <div className={styles.emptySearchResult}>
              No results were found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
