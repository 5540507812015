import { Position, Icon, Classes } from '@blueprintjs/core';
import { range } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedPageRange,
  setTakenBoundaries,
} from '../../redux/actions/annotation';
import { setCurrentPage } from '../../redux/actions/documentPreview';
import Button from '../@setproduct-ui/core/Button/Button';
import Popover from '../@setproduct-ui/core/Popover/Popover';
import styles from './Style.module.css';

export default function PageRangeSelector(props) {
  const dispatch = useDispatch();
  const [selectedRange, setSelectedRange] = useState({
    start: '',
    end: '',
  });
  const {
    sections,
    selectedPage,
    selectedSection,
    type: taskType,
    takenBoundaries,
  } = useSelector((state) => state.annotation);
  const { pageBoundingBox } = useSelector((state) => state.documentPreview);
  const isValidRange = () => {
    const { start, end } = selectedRange;
    if (start && end && start >= 1 && end <= pageBoundingBox.length)
      return start < end;
    return false;
  };

  const isPageSelected = (nodeId) => {
    if (selectedPage[selectedSection]) {
      return selectedPage[selectedSection].find((page) => page.id === nodeId);
    }
  };

  const handleSelectPageRange = () => {
    if (['CHAPTER', 'SUBCHAPTER'].includes(taskType)) {
      const annotationStatus = sections[selectedSection].annotation.status;
      if (selectedSection && annotationStatus !== 3) {
        const { start, end } = selectedRange;
        const added = range(start, end + 1).reduce((acc, pageNumber) => {
          const { nodeId } = pageBoundingBox[pageNumber - 1];
          if (!isPageSelected(nodeId)) {
            acc = acc.concat({ id: nodeId, pageNumber });
          }
          return acc;
        }, []);
        dispatch(setSelectedPageRange(selectedSection, added));
        if (taskType === 'SUBCHAPTER')
          dispatch(setTakenBoundaries(takenBoundaries.concat(added)));
        dispatch(setCurrentPage(start));
        props.focusPage(start);
        setSelectedRange({ start: '', end: '' });
      }
    }
  };

  return (
    <>
      <span
        style={{
          height: 24,
          width: 1,
          borderRight: '1px solid var(--grey20)',
          marginLeft: 10,
          marginRight: 24,
        }}
      ></span>
      <Popover
        minimal
        position={Position.BOTTOM_LEFT}
        popoverClassName={styles.page_range_popover}
      >
        <div className={styles.page_range_target}>
          <span>Page range</span>
          <Icon icon="caret-down" iconSize={16} style={{ marginLeft: 16 }} />
        </div>
        <div className={styles.page_range_popover_content}>
          <h4 style={{ margin: 0 }}>Page Range</h4>
          <div className={styles.page_range_container}>
            <input
              value={selectedRange.start}
              onChange={(e) => {
                const { value } = e.target;
                if (/\d+/.test(value)) {
                  const targetPage = parseInt(value);
                  setSelectedRange((prev) => ({
                    ...prev,
                    start: targetPage,
                  }));
                }
              }}
              type="text"
              className={styles.page_range_input}
            />
            <span className={styles.page_range_separator}></span>
            <input
              value={selectedRange.end}
              onChange={(e) => {
                const { value } = e.target;
                if (/\d+/.test(value)) {
                  const targetPage = parseInt(value);
                  setSelectedRange((prev) => ({
                    ...prev,
                    end: targetPage,
                  }));
                }
              }}
              type="text"
              className={styles.page_range_input}
            />
          </div>
          <Button
            view="filled"
            color="primary"
            text="Submit"
            className={Classes.POPOVER_DISMISS}
            style={{ width: '100%', marginTop: 24 }}
            disabled={!isValidRange()}
            onClick={handleSelectPageRange}
          />
        </div>
      </Popover>
    </>
  );
}
