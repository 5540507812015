import React from 'react'
import routes from '../../routers/routes';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthLayout } from '../../layouts';
import HelmetHOC from './HelmetHOC';
const notAuthenticatedRoutes = routes.notAuthenticated.user[0];

const routesComponents = notAuthenticatedRoutes.routes.map((route) =>
    <Route
        exact
        key={route.path}
        path={route.path}
        render={() => (
            <HelmetHOC title={route.title}>
                <route.component/>
            </HelmetHOC>

        )
        }
    />
)

export default function AuthRoutes(props) {
    return (
        <AuthLayout>
            <Switch location={props.location}>
                {routesComponents}
                <Route>
                    <Redirect to="/a/login" />
                </Route>
            </Switch>
        </AuthLayout>
    )
}
