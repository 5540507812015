import React from 'react';
import Avatar from '@material-ui/core/Avatar';

function getInitial(name) {
  return name.split(' ').reduce((acc, name, idx) => {
    if (idx < 2) acc += name.length > 0 ? name[0].toUpperCase() : '';
    return acc;
  }, '');
}

export default (props) => {
  return props.photoUrl ? (
    <Avatar
      src={props.photoUrl}
      title={props.name}
      style={{
        height: props.size || 30,
        width: props.size || 30,
        fontSize: props.textSize || '.9rem',
        ...props.style,
      }}
    />
  ) : (
    <Avatar
      title={props.name}
      style={{
        height: props.size || 30,
        width: props.size || 30,
        fontSize: props.textSize || '.9rem',
        background: props.color,
        ...props.style,
      }}
    >
      {getInitial(props.name)}
    </Avatar>
  );
};
