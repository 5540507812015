const initialState = {
  companies: "",
  companiesCreate: "",
  companiesCreateError: "",
  companiesBulkUpload: "",
  companiesBulkUploadError: "",
  companiesDelete: "",
  companiesDeleteError: "",
  companiesEdit: "",
  companiesEditError: "",
  selectedCompanies: []
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COMPANIES_ACCEPT_SUCCESS":
      return {
        ...state,
        companies: action.payload,
      };
    case "COMPANIES_CREATE_SUCCESS":
      return {
        ...state,
        companiesCreate: action.payload,
      };
    case "COMPANIES_CREATE_FAILURE":
      return {
        ...state,
        companiesCreateError: action.payload,
      };
    case "COMPANIES_EDIT_SUCCESS":
      return {
        ...state,
        companiesEdit: action.payload,
      };
    case "COMPANIES_EDIT_FAILURE":
      return {
        ...state,
        companiesEditError: action.payload,
      };
    case "COMPANIES_BULK_UPLOAD_SUCCESS":
      return {
        ...state,
        companiesBulkUpload: action.payload,
      };
    case "COMPANIES_BULK_UPLOAD_FAILURE":
      return {
        ...state,
        companiesBulkUploadError: action.payload,
      };
    case "COMPANIES_DELETE_SUCCESS":
      return {
        ...state,
        companiesDelete: action.payload,
      };
    case "COMPANIES_DELETE_FAILURE":
      return {
        ...state,
        companiesDeleteError: action.payload,
      };
    case "COMPANIES_RENDER_FIRST":
      return {
        ...state,
        companiesBulkUploadError: "",
        companiesCreateError: "",
      };
    case "COMPANIES_SET_SELECTED":
      return {
        ...state,
        selectedCompanies: action.payload
      }
    default:
      return state;
  }
};

export default companiesReducer;
