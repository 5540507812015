import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { capitalize } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import Checkbox from '../../components/@setproduct-ui/core/CheckBox';
import CustomDialog from '../../components/CustomDialog';
import Input from '../../components/Input';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import styles from './Style.module.css';
import {
  getTemplates,
  getDocumentList,
  getAvailableAnnotators,
  getAvailableReviewers,
  getChapterList,
  getDocumentGroupList,
  getAnnotatorTeamList,
  assignTask,
  getVariableListDeep,
  setChapterVariable,
  resetChapterVariable,
} from '../../redux/actions/admin';
import { createLoadingSelector } from '../../redux/api/loading';

const loadingSelectorMutation = createLoadingSelector(['ASSIGN_TASK']);
const FormSchema = yup.object().shape({
  taskPrefix: yup.string().required('Required'),
});

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '2.5rem',
  }),
};

function parseVariableType(type) {
  const typeArr = type.split('-');
  return typeArr
    .slice(0, typeArr.length - 1)
    .join(' ')
    .toUpperCase();
}

function FormCreateTask(props) {
  const [type, setType] = useState('CHAPTER');
  const [templateId, setTemplateId] = useState(null);
  const {
    isOpen,
    getTemplates,
    getDocumentList,
    getDocumentGroupList,
    getAvailableAnnotators,
    getAvailableReviewers,
    getChapterList,
    getAnnotatorTeamList,
  } = props;

  useEffect(() => {
    if (isOpen) {
      getTemplates();
      getDocumentList();
      getDocumentGroupList();
      getAvailableAnnotators();
      getAvailableReviewers();
      getAnnotatorTeamList();
    }
  }, [
    getAvailableAnnotators,
    getAvailableReviewers,
    getDocumentGroupList,
    getAnnotatorTeamList,
    getDocumentList,
    getTemplates,
    isOpen,
  ]);

  useEffect(() => {
    if (['SUBCHAPTER', 'VARIABLE'].includes(type) && templateId) {
      getChapterList(templateId);
    }
  }, [type, templateId, getChapterList]);

  const validate = (fields) => {
    if (type === 'SUBCHAPTER') {
      return !(
        fields.taskPrefix !== '' &&
        fields.template &&
        fields.documents.length &&
        fields.annotators.length &&
        fields.chapters.length &&
        fields.reviewer
      );
    }
    if (type === 'TABLE') {
      return !(
        fields.taskPrefix !== '' &&
        fields.documents.length &&
        fields.annotators.length &&
        fields.reviewer
      );
    }
    if (type === 'VARIABLE') {
      return !(
        fields.taskPrefix !== '' &&
        fields.template &&
        fields.documents.length &&
        fields.annotators.length &&
        fields.chapters.length &&
        fields.reviewer &&
        fields.variables.length
      );
    }
    return !(
      fields.taskPrefix !== '' &&
      fields.template &&
      fields.documents.length &&
      fields.annotators.length &&
      fields.reviewer
    );
  };

  const handleClose = () => {
    setType('CHAPTER');
    props.onClose();
  };

  const TemplateField = (setFieldValue, values) => {
    if (type === 'TABLE') return <></>;
    return (
      <>
        <label htmlFor="template" className={styles.formLabel}>
          Template
        </label>
        <Select
          name="template"
          value={values.template}
          placeholder={props.loadingTemplates ? 'Loading...' : ''}
          isDisabled={props.loadingTemplates}
          options={props.templates.map((template) => ({
            value: template.sectionId,
            label: template.name,
            data: { ...template },
          }))}
          onChange={(selected) => {
            setFieldValue('template', selected);
            setFieldValue('documents', []);
            setTemplateId(selected.value);
            if (values.template && values.template.value !== selected.value) {
              setFieldValue('chapters', []);
            }
          }}
          className={styles.select}
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'black',
            },
          })}
        />
        {['SUBCHAPTER', 'VARIABLE'].includes(values.type.value) && (
          <>
            <label htmlFor="chapters" className={styles.formLabel}>
              Chapters
            </label>
            <Select
              name="chapters"
              isMulti
              value={values.chapters}
              placeholder={props.loadingChapters ? 'Loading...' : ''}
              isDisabled={props.loadingChapters}
              options={props.chapters.map((chapter) => ({
                value: chapter.sectionId,
                label: chapter.name,
                data: { ...chapter },
              }))}
              onChange={(chapters, { action, ...actionData }) => {
                if (values.type.value === 'VARIABLE') {
                  if (action === 'select-option') {
                    const {
                      option: { value: chapterId },
                    } = actionData;
                    props.getVariableListDeep(chapterId);
                  } else if (action === 'remove-value') {
                    const {
                      removedValue: { value: chapterId },
                    } = actionData;
                    const filterChapter = (v) => v.chapterId !== chapterId;
                    const updatedVariables = props.chapterVariables.filter(
                      filterChapter
                    );
                    const selectedVariables = values.variables.filter(
                      filterChapter
                    );
                    props.setChapterVariable(updatedVariables);
                    setFieldValue('variables', selectedVariables);
                  } else if (action === 'clear') {
                    props.setChapterVariable([]);
                    setFieldValue('variables', []);
                  }
                }
                setFieldValue('chapters', chapters === null ? [] : chapters);
              }}
              className={styles.select}
              styles={customSelectStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'black',
                },
              })}
            />
          </>
        )}
      </>
    );
  };

  return (
    <CustomDialog
      view="raised"
      title="Create New Task"
      backdropOpacity={40}
      isOpen={props.isOpen}
      onClose={handleClose}
      style={{
        maxWidth: type === 'VARIABLE' ? 800 : '30.5rem',
        maxHeight: 750,
      }}
    >
      <Formik
        enableReinitialize
        validationSchema={FormSchema}
        validateOnSubmit
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          taskPrefix: '',
          type: { value: 'CHAPTER', label: 'Chapter' },
          template: null,
          chapters: [],
          documents: [],
          annotators:
            props.user.role === 'annotator'
              ? [
                  {
                    value: props.user.id,
                    label: props.user.fullName,
                    type: 'person',
                    data: {
                      id: props.user.id,
                      email: props.user.email,
                      block_status: props.user.status === 'inactive',
                      photoUrl: props.user.photoUrl,
                    },
                  },
                ]
              : [],
          reviewer:
            props.user.role === 'reviewer'
              ? { value: props.user.id, label: props.user.fullName }
              : null,
          variables: [],
        }}
        onSubmit={async (values, actions) => {
          let section = [];
          if (values.type.value === 'CHAPTER') {
            section = [
              {
                sectionId: values.template.value,
                name: values.template.data.name,
              },
            ];
          } else if (values.type.value === 'SUBCHAPTER') {
            section = values.chapters.map((chapter) => ({
              id: chapter.value,
              name: chapter.label,
            }));
          } else if (values.type.value === 'VARIABLE') {
            section = [values.variables.map((variable) => variable.id)];
          }
          const data = {
            name: values.taskPrefix,
            type: values.type.value,
            document: values.documents.map((doc) => ({
              id: doc.value,
              filename: doc.label,
              metadata: doc.metadata,
            })),
            section,
            annotator: values.annotators.map((annotator) => ({
              id: annotator.value,
              name: annotator.label,
            })),
            reviewer: values.reviewer.value,
          };
          const isSuccess = await props.assignTask(data, { refetchAll: false });
          if (isSuccess) {
            handleClose();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <FlexWrapper alignItems="stretch">
              <div
                style={{
                  width: type === 'VARIABLE' ? '60%' : '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                }}
              >
                <label htmlFor="taskPrefix" className={styles.formLabel}>
                  Task Prefix
                </label>
                <Input
                  name="taskPrefix"
                  type="text"
                  view="outlined"
                  color="default"
                  onBlur={handleBlur}
                  onInput={handleChange}
                  value={values.taskPrefix}
                  fill={true}
                  error={errors.hasOwnProperty('taskPrefix')}
                  errorMessage={errors.taskPrefix}
                  style={{ marginBottom: 10 }}
                />
                <label htmlFor="type" className={styles.formLabel}>
                  Type
                </label>
                <Select
                  name="type"
                  value={values.type}
                  options={[
                    {
                      value: 'CHAPTER',
                      label: 'Chapter',
                    },
                    {
                      value: 'SUBCHAPTER',
                      label: 'Subchapter',
                    },
                    {
                      value: 'VARIABLE',
                      label: 'Variable',
                    },
                    {
                      value: 'TABLE',
                      label: 'Table',
                    },
                  ]}
                  onChange={(selected) => {
                    setFieldValue('type', selected);
                    setType(selected.value);
                    if (selected.value === 'VARIABLE') {
                      setFieldValue('chapters', []);
                      setFieldValue('variables', []);
                      props.resetChapterVariable();
                    }
                  }}
                  className={styles.select}
                  styles={customSelectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'black',
                    },
                  })}
                />

                {TemplateField(setFieldValue, values)}

                <label htmlFor="document" className={styles.formLabel}>
                  Document
                </label>
                <Select
                  name="document"
                  isMulti
                  value={values.documents}
                  placeholder={props.loadingDocuments ? 'Loading...' : ''}
                  isDisabled={props.loadingDocuments}
                  options={[...props.documentGroups, ...props.documents]
                    .filter((list) => {
                      if (list.members) {
                        return list.members.length > 0;
                      }
                      return true;
                    })
                    .map((doc) => ({
                      value: doc.id,
                      label: doc.filename || doc.name,
                      metadata: doc.metadata,
                      type: doc.members ? 'group' : 'document',
                      data: { ...doc },
                    }))
                    .filter((doc) => {
                      if (values.template) {
                        if (doc.type === 'group') {
                          const { data } = doc;
                          if (data.extra)
                            return (
                              data.extra.type ===
                              values.template.data.modelReport
                            );
                          return (
                            data.members[0].metadata.type ===
                            values.template.data.modelReport
                          );
                        }
                        return (
                          doc.metadata.type ===
                            values.template.data.modelReport &&
                          doc.metadata.companyId
                        );
                      }
                      return true;
                    })}
                  onChange={(documents) => {
                    const data = documents
                      ? documents.reduce((acc, selected) => {
                          if (selected.type === 'group') {
                            const existing = acc.map((i) => i.value);
                            const members = selected.data.members
                              .filter(
                                (member) => existing.indexOf(member.id) === -1
                              )
                              .map((member) => ({
                                value: member.id,
                                label: member.filename,
                              }));
                            acc = [...acc, ...members];
                          } else acc = [...acc, selected];
                          return acc;
                        }, [])
                      : [];
                    setFieldValue('documents', data);
                  }}
                  className={styles.select}
                  styles={customSelectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'black',
                    },
                  })}
                />
                <label htmlFor="annotators" className={styles.formLabel}>
                  Assigned annotator
                </label>
                <Select
                  name="annotators"
                  isMulti={props.user.role !== 'annotator'}
                  value={values.annotators}
                  placeholder={props.loadingAnnotators ? 'Loading...' : ''}
                  isDisabled={
                    props.user.role === 'annotator' || props.loadingAnnotators
                  }
                  options={
                    props.user.role === 'reviewer'
                      ? [...props.annotatorTeams, ...props.annotators]
                          .filter((list) => {
                            if (list.members) return list.members.length > 0;
                            return true;
                          })
                          .map((annotator) => ({
                            value: annotator.id || annotator._id,
                            label: annotator.name,
                            type: annotator.members ? 'team' : 'person',
                            data: { ...annotator },
                          }))
                      : [
                          {
                            value: props.user.id,
                            label: props.user.fullName,
                            type: 'person',
                            data: {
                              id: props.user.id,
                              email: props.user.email,
                              block_status: props.user.status === 'inactive',
                              photoUrl: props.user.photoUrl,
                            },
                          },
                        ]
                  }
                  onChange={(annotators) => {
                    const data = annotators
                      ? annotators.reduce((acc, selected) => {
                          if (selected.type === 'team') {
                            const existing = acc.map((i) => i.value);
                            const members = selected.data.members
                              .filter(
                                (member) => existing.indexOf(member.id) === -1
                              )
                              .map((member) => ({
                                value: member.id,
                                label: member.name,
                              }));
                            acc = [...acc, ...members];
                          } else acc = [...acc, selected];
                          return acc;
                        }, [])
                      : [];
                    setFieldValue('annotators', data);
                  }}
                  className={styles.select}
                  styles={customSelectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'black',
                    },
                  })}
                />
                <label htmlFor="reviewer" className={styles.formLabel}>
                  Assigned reviewer
                </label>
                <Select
                  name="reviewer"
                  defaultValue={values.reviewer}
                  placeholder={props.loadingReviewers ? 'Loading...' : ''}
                  isDisabled={
                    props.user.role === 'reviewer' || props.loadingReviewers
                  }
                  options={
                    props.user.role !== 'reviewer'
                      ? props.reviewers.map((reviewer) => ({
                          value: reviewer.id,
                          label: reviewer.name,
                        }))
                      : [{ value: props.user.id, label: props.user.fullName }]
                  }
                  onChange={(selected) => setFieldValue('reviewer', selected)}
                  className={styles.select}
                  styles={customSelectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'black',
                    },
                  })}
                />
              </div>
              {type === 'VARIABLE' && (
                <div style={{ width: '40%', maxHeight: 550, marginLeft: 15 }}>
                  <span className={styles.formLabel}>Variables</span>
                  <div
                    style={{
                      height: '93%',
                      overflow: 'auto',
                    }}
                  >
                    {!props.loadingVariables ? (
                      props.chapterVariables.length > 0 ? (
                        <>
                          <Checkbox
                            checked={
                              values.variables.length ===
                              props.chapterVariables.length
                            }
                            label="Select All"
                            onChange={() => {
                              const allSelected =
                                values.variables.length ===
                                props.chapterVariables.length;
                              if (allSelected) {
                                setFieldValue('variables', []);
                              } else {
                                const selectedVariables = props.chapterVariables.map(
                                  (v) => ({
                                    id: v.id,
                                    name: v.name,
                                    chapterId: v.chapterId,
                                    dtype: v.dtype,
                                    isArray: v.isArray,
                                  })
                                );
                                setFieldValue('variables', selectedVariables);
                              }
                            }}
                            style={{ fontWeight: 'bold' }}
                          />
                          {props.chapterVariables.map((variable, index) => {
                            const checked =
                              values.variables.filter(
                                (v) => v.id === variable.id
                              ).length > 0;
                            return (
                              <Checkbox
                                key={variable.id}
                                checked={checked}
                                label={variable.name}
                                helperText={`${
                                  variable.isArray ? 'Array of ' : ''
                                }${capitalize(
                                  parseVariableType(variable.dtype)
                                )}`}
                                onChange={() => {
                                  if (checked) {
                                    const updated = values.variables.filter(
                                      (v) => v.id !== variable.id
                                    );
                                    setFieldValue('variables', updated);
                                  } else {
                                    const newVariable = {
                                      id: variable.id,
                                      name: variable.name,
                                      chapterId: variable.chapterId,
                                      dtype: variable.dtype,
                                      isArray: variable.isArray,
                                    };
                                    setFieldValue(
                                      'variables',
                                      values.variables.concat(newVariable)
                                    );
                                  }
                                }}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <span className={styles.noVariable}>No variables</span>
                      )
                    ) : (
                      Array(5)
                        .fill(true)
                        .map((_, index) => (
                          <FlexWrapper
                            key={`loading_${index}`}
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ marginLeft: 10, marginTop: 10 }}
                          >
                            <Skeleton
                              height={20}
                              width={20}
                              style={{ marginRight: 10, display: 'block' }}
                            />
                            <FlexWrapper flexFlow="column" alignItems="stretch">
                              <Skeleton
                                height={14}
                                width={120}
                                style={{ display: 'block', marginBottom: 5 }}
                              />
                              <Skeleton
                                height={10}
                                width={100}
                                style={{ display: 'block' }}
                              />
                            </FlexWrapper>
                          </FlexWrapper>
                        ))
                    )}
                  </div>
                </div>
              )}
            </FlexWrapper>
            <FlexWrapper justifyContent="flex-end">
              <Button
                view="flat"
                color="default"
                text="Cancel"
                disabled={isSubmitting || props.loadingMutation}
                onClick={handleClose}
              />
              <Button
                view="filled"
                color="primary"
                text="Create"
                style={{ marginLeft: 20 }}
                loading={isSubmitting || props.loadingMutation}
                disabled={validate(values)}
                onClick={handleSubmit}
              />
            </FlexWrapper>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
}

const mapStateToProps = (state) => ({
  loadingTemplates: createLoadingSelector(['GET_TEMPLATES'])(state),
  loadingDocuments: createLoadingSelector([
    'GET_DOCUMENT_LIST',
    'GET_DOCUMENT_GROUP_LIST',
  ])(state),
  loadingAnnotators: createLoadingSelector([
    'GET_AVAILABLE_ANNOTATORS',
    'GET_ANNOTATOR_TEAM_LIST',
  ])(state),
  loadingReviewers: createLoadingSelector(['GET_AVAILABLE_REVIEWERS'])(state),
  loadingChapters: createLoadingSelector(['GET_CHAPTER_LIST'])(state),
  loadingVariables: createLoadingSelector(['GET_VARIABLE_LIST_DEEP'])(state),
  loadingMutation: loadingSelectorMutation(state),
  templates: state.admin.templates,
  documents: state.admin.documents,
  chapters: state.admin.chapters,
  annotators: state.admin.availableAnnotators,
  reviewers: state.admin.availableReviewers,
  documentGroups: state.admin.documentGroups,
  annotatorTeams: state.admin.annotatorTeams,
  chapterVariables: state.admin.chapterVariables,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(getTemplates()),
  getDocumentList: () => dispatch(getDocumentList()),
  getAvailableAnnotators: () => dispatch(getAvailableAnnotators()),
  getAvailableReviewers: () => dispatch(getAvailableReviewers()),
  getChapterList: (templateId) => dispatch(getChapterList(templateId)),
  getDocumentGroupList: () => dispatch(getDocumentGroupList()),
  getAnnotatorTeamList: () => dispatch(getAnnotatorTeamList()),
  assignTask: (data, config) => dispatch(assignTask(data, config)),
  getVariableListDeep: (chapterId) => dispatch(getVariableListDeep(chapterId)),
  setChapterVariable: (variables) => dispatch(setChapterVariable(variables)),
  resetChapterVariable: () => dispatch(resetChapterVariable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateTask);
