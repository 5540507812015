import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import {
  debounce,
  min,
  max,
  uniq,
  difference,
  find,
  // filter,
  cloneDeep,
} from 'lodash';
import { differenceInMonths, formatDistanceToNow } from 'date-fns';
import { useParams } from 'react-router-dom';
import { SelectableGroup } from 'react-selectable-fast';
import { useForm, FormProvider } from 'react-hook-form';
import { Prompt } from 'react-router';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Wrapper from '../../components/BasicWrapper';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import Navbar from '../../components/@setproduct-ui/core/Navbar';
import NavbarGroup from '../../components/@setproduct-ui/core/Navbar/Group';
import NavbarHeading from '../../components/@setproduct-ui/core/Navbar/Heading';
import ProgressBar from '../../components/@setproduct-ui/core/ProgressBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Brand from '../../components/Brand';
import NavbarUserMenu from '../../components/NavbarUserMenuDocument';
import logo from '../../assets/images/cesgs-logo-only.png';
import Heading from '../../components/Heading';
import Spinner, { Loading } from '../../components/Loading';
import Toolbar from './Toolbar';
import TabAnnotation from './TabAnnotation';
import TabNote from './TabNote';
import TabSearch from './TabSearch';
import BoundingBox from './BoundingBox';
import SelectableBoundingBox from './SelectableBoundingBox';
import FullLayout from '../../layouts/FullLayout';
import DocumentViewer from './DocumentViewer';
import styles from './style.module.css';
import {
  getDocumentData,
  getBoundaries,
  getPageBoundaries,
  zoomIn,
  zoomOut,
  resetPageScale,
  nextPage,
  prevPage,
  setNumPages,
  setCurrentPage,
  setPageLoaded,
  setPaperSize,
  changeBoundingBoxType,
  resetDocumentPreview,
  toggleAnnotationDisable,
} from '../../redux/actions/documentPreview';
import { documentPreviewSelector } from '../../redux/reducers/documentPreview';
import {
  getTaskData,
  getSections,
  setSelectedBoundingBox,
  addSelectedBoundingBox,
  removeSelectedBoundingBox,
  setSelectedPage,
  getTaskNote,
  resetTaskData,
  setSelectedVariableField,
  setAnnotatedVariableField,
  addAnnotatedVariableField,
  saveAnnotationVariable,
  submitAnnotationVariable,
  getAnnotationTable,
  addAnnotationTable,
  updateAnnotationTable,
  deleteAnnotationTable,
  setTakenBoundaries,
  runParseAnnotatedVariableField,
  reorderAnnotationData,
  getAnnotationSuggestions,
  reorderAnnotationField,
  setSelectedPageRange,
  removeSelectedPageRange,
  resetEditSelectedBoundary,
} from '../../redux/actions/annotation';
import { annotationSelector } from '../../redux/reducers/annotation';
import { createLoadingSelector } from '../../redux/api/loading';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DialogEditBoundary from './DialogEditBoundary';
import { clearTextSelection } from '../../utils/annotation';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { differenceInYears } from 'date-fns';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdfOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const PageLoading = ({ height, width }) => (
  <div className={styles.loadingBackdrop}>
    <Loading />
  </div>
);

const getPageBlocks = (arr) =>
  arr.reduce((acc, boundary) => {
    if (boundary.type === 'BLOCK') acc = acc.concat(boundary.nodeId);
    return acc;
  }, []);

function fixUrl(url) {
  return !url.includes('http') ? `https://${url}` : url;
}

function isAccepted(status) {
  return status === 3;
}

function compact(name) {
  return name.toLowerCase().replaceAll(' ', '-');
}

function Annotation({
  getDocumentData,
  getTaskData,
  getBoundaries,
  getPageBoundaries,
  getSections,
  getTableAnnotation,
  addTableAnnotation,
  updateTableAnnotation,
  getTaskNote,
  file,
  boundingBox,
  pageBoundingBox,
  numPages,
  currentPage,
  pageScale,
  paperSize,
  pageLoaded,
  boundingBoxType,
  selectedSection,
  documentId,
  sectionId,
  resetDocumentPreview,
  resetTaskData,
  runParseAnnotatedVariableField,
  reorderAnnotationData,
  getAnnotationSuggestions,
  reorderAnnotationField,
  annotationDisabled,
  ...props
}) {
  const selectableGroupRef = useRef(null);
  const thumbsWrapperRef = useRef();
  const pdfViewWrapperRef = useRef();
  const classes = useStyles();
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [progress, setProgress] = useState({ status: true, value: 0 });
  const { task_id } = useParams();
  const [dirty, setDirty] = useState([]);
  const [validationSnackbar, setValidationSnackbar] = useState(false);
  const [deselectionSnackbar, setDeselectionSnackbar] = useState(false);

  const dispatch = useDispatch();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const [dataAnnotationTable, setDataAnnotationTable] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(0);

  const handleEdit = (id) => {
    setEditId(id);
    setIsEdit(true);
  };

  const handleAdd = () => {
    setDataAnnotationTable((prev) => [
      {
        title: 'Tabel Baru',
        coordinates: [
          { x: 0, y: 0 },
          { x: 0, y: 0 },
        ],
        pageIndex: currentPage,
      },
      ...prev,
    ]);
  };
  const handleDelete = (id) => {
    if (id === undefined) {
      setDataAnnotationTable(dataAnnotationTable.slice(1));
    } else dispatch(deleteAnnotationTable(documentId, id));
  };

  const handleChange = (id, val) => {
    const updated = dataAnnotationTable.map((data) => {
      if (data._id === id) {
        return {
          ...data,
          title: val,
        };
      }
      return data;
    });
    setDataAnnotationTable(updated);
  };

  const handleEnableCanvas = () => {
    setEnableCanvas(true);
  };

  const handleCancel = (id, prevTitle) => {
    const updated = dataAnnotationTable.map((data) => {
      if (data._id === id) {
        return {
          ...data,
          title: prevTitle,
        };
      }
      return data;
    });
    setDataAnnotationTable(updated);
    setIsEdit(false);
  };

  // for drawing area
  const [tableData, setTableData] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [btn, setBtn] = useState(false);
  const [posBtnX, setPosBtnX] = useState(0);
  const [posBtnY, setPosBtnY] = useState(0);
  const [enableCanvas, setEnableCanvas] = useState(false);

  const formHooks = useForm({
    shouldUnregister: false,
  });
  const { setValue, watch, reset } = formHooks;

  const scalePage = useSelector((state) => state.documentPreview.pageScale);
  const annotationTable = useSelector(
    (state) => state.annotation.annotationTable
  );
  const documentSearch = useSelector((state) => state.documentSearch);

  useEffect(() => {
    //for selecting

    const canvas = document.getElementById('canvas');
    if (canvas != null && canvas !== undefined && tableData) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const [firstCoord, secondCoord] = tableData.coordinates;
      ctx.fillStyle = 'rgba(196,218,255,0.5)';
      ctx.fillRect(
        firstCoord.x,
        firstCoord.y,
        secondCoord.x - firstCoord.x,
        secondCoord.y - firstCoord.y
      );
      setPosBtnX(Math.max(firstCoord.x, secondCoord.x));
      setPosBtnY(Math.max(firstCoord.y, secondCoord.y));
    }

    //for showing data selected area
    const canvasData = document.getElementById('canvasData');
    if (canvasData != null) {
      const ctx = canvasData.getContext('2d');
      ctx.clearRect(0, 0, canvasData.width, canvasData.height);
      dataAnnotationTable.forEach(({ coordinates, pageIndex, title }) => {
        const [firstCoord, secondCoord] = coordinates;
        if (pageIndex === currentPage) {
          ctx.strokeStyle = 'rgba(79,145,255,1)';
          ctx.strokeRect(
            firstCoord.x * Math.floor(paperSize.width * pageScale),
            firstCoord.y * Math.floor(paperSize.height * pageScale),
            (secondCoord.x - firstCoord.x) *
              Math.floor(paperSize.width * pageScale),
            (secondCoord.y - firstCoord.y) *
              Math.floor(paperSize.height * pageScale)
          );
          if (boundingBoxType[0] === 'FIELD') {
            ctx.fillStyle = '#2979FF';
            ctx.fillRect(
              (secondCoord.x - firstCoord.x) *
                Math.floor(paperSize.width * pageScale) +
                firstCoord.x * Math.floor(paperSize.width * pageScale) -
                title.length * 5 * scalePage,
              firstCoord.y * Math.floor(paperSize.height * pageScale) -
                15 * scalePage,
              title.length * 5 * scalePage,
              15 * scalePage
            );
            ctx.fillStyle = 'white';
            ctx.font = `${10 * scalePage}px Arial`;
            ctx.fillText(
              title,
              (secondCoord.x - firstCoord.x) *
                Math.floor(paperSize.width * pageScale) +
                firstCoord.x * Math.floor(paperSize.width * pageScale) -
                title.length * 5 * scalePage,
              firstCoord.y * Math.floor(paperSize.height * pageScale) -
                4 * scalePage
            );
          }
        }
      });
    }
  }, [
    tableData,
    annotationTable,
    currentPage,
    pageScale,
    documentLoaded,
    dataAnnotationTable,
    boundingBoxType,
    paperSize.width,
    paperSize.height,
    scalePage,
  ]);

  const prevScale = usePrevious(scalePage);
  useEffect(() => {
    setTableData((tableData) => {
      if (tableData) {
        const [firstCoord, secondCoord] = tableData.coordinates;
        const updatedData = {
          ...tableData,
          coordinates: [
            {
              x: (firstCoord.x / prevScale) * scalePage,
              y: (firstCoord.y / prevScale) * scalePage,
            },
            {
              x: (secondCoord.x / prevScale) * scalePage,
              y: (secondCoord.y / prevScale) * scalePage,
            },
          ],
        };
        return updatedData;
      }
      return null;
    });
  }, [scalePage, prevScale]);

  //convert database to data
  useEffect(() => {
    if (annotationTable !== undefined) {
      const data = annotationTable.map(({ refId, dataTables, status, _id }) => {
        return {
          coordinates: dataTables.coordinates,
          pageIndex: dataTables.pageNumber,
          status,
          title: refId,
          _id,
        };
      });
      // setFixDatas(data);
      setDataAnnotationTable([...data].reverse());
    }
  }, [annotationTable, boundingBoxType]);

  useEffect(() => {
    getTaskData(task_id);
    getTaskNote(task_id);
    getAnnotationSuggestions(task_id);
  }, [getTaskData, task_id, getTaskNote, getAnnotationSuggestions]);

  useEffect(() => {
    if (documentId) getDocumentData(documentId);
    if (sectionId) getSections(sectionId);
  }, [sectionId, getDocumentData, getSections, documentId]);

  useEffect(() => {
    if (documentId && props.ocrStatus === 4) {
      getPageBoundaries(documentId);
    }
  }, [props.ocrStatus, getPageBoundaries, documentId]);

  useEffect(() => {
    if (documentId && props.ocrStatus === 4 && !boundingBox[currentPage - 1]) {
      const debounced = debounce(getBoundaries, 500);
      debounced(documentId, currentPage);
      return () => {
        debounced.cancel();
      };
    }
  }, [currentPage, props.ocrStatus, boundingBox, getBoundaries, documentId]);

  useEffect(() => {
    return () => {
      resetDocumentPreview();
      resetTaskData();
    };
  }, [resetDocumentPreview, resetTaskData]);

  const handleMouseDown = (event) => {
    setTableData(null);
    setDrawing(true);
    setBtn(false);
    const pos = event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;
    const x = clientX - pos.left;
    const y = clientY - pos.top;
    const tableData = {
      coordinates: [
        { x, y },
        { x, y },
      ],
      pageIndex: currentPage,
    };
    setTableData(tableData);
  };

  const handleMouseMove = (event) => {
    const pos = event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;
    if (!drawing) return;
    const startCoord = tableData.coordinates[0];
    const x = clientX - pos.left;
    const y = clientY - pos.top;
    const updateTableData = {
      coordinates: [
        { x: startCoord.x, y: startCoord.y },
        { x, y },
      ],
      pageIndex: currentPage,
    };
    setTableData(updateTableData);
  };

  const handleMouseUp = (event) => {
    setDrawing(false);
    setBtn(true);
  };

  const handleSaveTableAnnotate = () => {
    var coor = [];
    setBtn(false);
    const [firstCoord, secondCoord] = tableData.coordinates;
    const normalizeX1 = firstCoord.x / Math.floor(paperSize.width * pageScale);
    const normalizeX2 = secondCoord.x / Math.floor(paperSize.width * pageScale);
    const normalizeY1 = firstCoord.y / Math.floor(paperSize.height * pageScale);
    const normalizeY2 =
      secondCoord.y / Math.floor(paperSize.height * pageScale);
    const topLeft = {
      x: Math.min(normalizeX1, normalizeX2),
      y: Math.min(normalizeY1, normalizeY2),
    };
    const bottomRight = {
      x: Math.max(normalizeX1, normalizeX2),
      y: Math.max(normalizeY1, normalizeY2),
    };
    coor.push(topLeft, bottomRight);
    const dataTable = {
      dataTables: { pageNumber: currentPage, coordinates: coor },
    };

    const existing = dataAnnotationTable.find(
      (data) => data._id === editId && data.status
    );
    if (existing) {
      const form = {
        refId: existing.title,
        status: 2,
        data: dataTable,
      };
      updateTableAnnotation(form, documentId, editId);
    } else {
      const form = {
        tableId: dataAnnotationTable[0].title,
        data: dataTable,
      };
      addTableAnnotation(form, documentId);
    }

    setEnableCanvas(false);
    setDataAnnotationTable([]);
    setTableData(null);
  };

  useEffect(() => {
    function preventReload(e) {
      if (props.type === 'VARIABLE' && dirty.length > 0) {
        e.returnValue = '';
        return '';
      }
      return null;
    }
    window.addEventListener('beforeunload', preventReload);
    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, [props.type, dirty]);

  useEffect(() => {
    const dirtyVariables = Object.keys(formHooks.formState.dirtyFields);
    if (
      formHooks.formState.isDirty &&
      difference(dirtyVariables, dirty).length > 0
    ) {
      const updatedDirty = uniq(dirty.concat(dirtyVariables));
      setDirty(updatedDirty);
    }
  }, [formHooks.formState, dirty]);

  useEffect(() => {
    const currentVariable = props.sections[selectedSection];
    if (currentVariable && currentVariable.staledAnnotatedField) {
      const rawDataFields = runParseAnnotatedVariableField(
        currentVariable,
        currentVariable.staledAnnotatedFieldIndex
      );
      // update form state field
      rawDataFields.forEach((rawDataField) => {
        const { keyMap, rawData, fieldType } = rawDataField;
        const isTypeDate = rawData !== '' && fieldType === 'date-basic';
        setValue(keyMap, isTypeDate ? new Date(rawData) : rawData);
      });
    }
  }, [
    props.sections,
    selectedSection,
    runParseAnnotatedVariableField,
    setValue,
  ]);

  useEffect(() => {
    const {
      variableId,
      variableName,
      from,
      to,
      fieldName,
      annotationItemIndex,
    } = props.movedAnnotationItem;
    if (variableId) {
      // move annotation field position
      if (annotationItemIndex !== null && fieldName) {
        const name = compact(variableName);
        const prevFormData = watch();
        const variableFormData = cloneDeep(props.storedFormData[name]);
        const formData = variableFormData[annotationItemIndex][fieldName];
        const rawDataFields = reorderAnnotationField(
          variableId,
          from,
          to,
          formData,
          annotationItemIndex,
          fieldName
        );
        // reset form data using shifted form data
        variableFormData[annotationItemIndex][fieldName] = rawDataFields;
        reset({
          ...prevFormData,
          [name]: variableFormData,
        });
      } else {
        // move annotation item position
        const name = compact(variableName);
        const prevFormData = watch();
        const formData = cloneDeep(props.storedFormData[name]);
        const rawDataFields = reorderAnnotationData(
          variableId,
          from,
          to,
          formData
        );
        // reset form data using shifted form data
        reset({ ...prevFormData, [name]: rawDataFields });
      }
    }
  }, [
    props.movedAnnotationItem,
    props.storedFormData,
    reorderAnnotationData,
    reorderAnnotationField,
    setValue,
    watch,
    reset,
  ]);

  const generateAnnotation = (pageData, page, boundingBoxType) => {
    const { type: taskType } = props;
    let boxes = boundingBox[page - 1]
      .filter((box) => boundingBoxType.indexOf(box.type) !== -1)
      .map((box) => {
        const scaledBox = box.attributes.vertices.map((vertices) => {
          return {
            x: vertices.x * pageData.width * pageScale,
            y: vertices.y * pageData.height * pageScale,
          };
        });
        // calculate div size
        const width = scaledBox[1].x - scaledBox[0].x;
        const height = scaledBox[3].y - scaledBox[0].y;
        // position
        const topLeft = scaledBox[0];

        if (taskType === 'VARIABLE') {
          return (
            <SelectableBoundingBox
              key={box.nodeId}
              nodeId={box.nodeId}
              coordinates={box.attributes.vertices}
              text={box.text}
              topLeft={topLeft}
              height={height}
              width={width}
              type="boundary"
              onClick={() => handleSelectBox(box.nodeId)}
            />
          );
        } else {
          const currentPageId = pageBoundingBox[currentPage - 1].nodeId;
          if (
            taskType === 'SUBCHAPTER' &&
            (find(props.takenBoundaries, { id: currentPageId }) ||
              find(props.takenBoundaries, { id: box.nodeId })) &&
            !isSelected(box.nodeId)
          ) {
            return (
              <BoundingBox
                key={box.nodeId}
                nodeId={box.nodeId}
                topLeft={topLeft}
                height={height}
                width={width}
                onClick={() => {}}
                style={{
                  background: 'rgba(238, 68, 68, 0.2)',
                  border: '2px solid var(--red40)',
                }}
              />
            );
          }
          return (
            <BoundingBox
              key={box.nodeId}
              nodeId={box.nodeId}
              topLeft={topLeft}
              height={height}
              width={width}
              matchedBoundary={isSelected(box.nodeId)}
              isHighlighted={props.highlightedBoundary === box.nodeId}
              onClick={(e) => handleSelectBox(box.nodeId, e.shiftKey)}
            />
          );
        }
      });

    if (
      boundingBoxType.indexOf('FIELD') !== -1 &&
      taskType === 'VARIABLE' &&
      props.annotatedField[selectedSection]
    ) {
      boxes = boxes.concat(
        props.annotatedField[selectedSection]
          .filter((field) => field.pageNumber === currentPage)
          .map((field) => {
            const scaledBox = field.coordinates.map((vertices) => {
              return {
                x: vertices.x * pageData.width * pageScale,
                y: vertices.y * pageData.height * pageScale,
              };
            });
            // calculate div size
            const width = scaledBox[1].x - scaledBox[0].x;
            const height = scaledBox[3].y - scaledBox[0].y;
            // position
            const topLeft = scaledBox[0];
            return (
              <SelectableBoundingBox
                key={field.keyMap}
                nodeId={field.keyMap}
                coordinates={field.coordinates}
                isArray={props.sections[selectedSection].isArray}
                label={field.keyMap}
                topLeft={topLeft}
                height={height}
                width={width}
                type="annotation"
                highlighted={
                  props.selectedField.keyMap &&
                  field.keyMap === props.selectedField.keyMap
                }
              />
            );
          })
      );
    }

    const Container = (
      <div
        id="annotation-layer"
        style={{
          position: 'absolute',
          display: 'inline-block',
          zIndex: 2,
          height: paperSize.height * pageScale,
          width: paperSize.width * pageScale,
          cursor: props.selectedField.keyMap !== null ? 'crosshair' : 'default',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: paperSize.height * pageScale,
            width: paperSize.width * pageScale,
          }}
        >
          {boxes}
        </div>
      </div>
    );
    if (taskType === 'VARIABLE') {
      return (
        <SelectableGroup
          ref={selectableGroupRef}
          tolerance={0}
          globalMouse={false}
          onSelectionFinish={handleSelect}
          selectboxClassName={styles.selectbox}
          disabled={props.selectedField.annotationItemIndex === null}
          allowShiftClick={true}
        >
          {Container}
        </SelectableGroup>
      );
    }
    return Container;
  };

  const displaySearchHighlightLayer = (pageData, page) => {
    const { searchTerm, resultsGrouped } = documentSearch;
    const currentPageResults = resultsGrouped[page];
    const searchTermArr = searchTerm.split(' ').map((w) => w.toLowerCase());
    let boxes = boundingBox[page - 1]
      .filter((box) => box.type === 'WORD')
      .filter((box) => {
        // get only paragraph ancestor
        const paragraphAncestor = box.ancestors.find((a) =>
          a.includes('paragraph')
        );
        // check if it's match with search result
        const matchAncestor =
          paragraphAncestor && currentPageResults
            ? currentPageResults.includes(paragraphAncestor)
            : false;
        if (matchAncestor) {
          // find which word boundary should be highlighted
          let isMatch = false;
          let i = 0;
          let searchTermLen = searchTermArr.length;
          while (i < searchTermLen && !isMatch) {
            isMatch = box.text.toLowerCase().includes(searchTermArr[i]);
            i++;
          }
          return isMatch;
        }
        return false;
      })
      .map((box) => {
        const scaledBox = box.attributes.vertices.map((vertices) => {
          return {
            x: vertices.x * pageData.width * pageScale,
            y: vertices.y * pageData.height * pageScale,
          };
        });
        // calculate div size
        const width = scaledBox[1].x - scaledBox[0].x;
        const height = scaledBox[3].y - scaledBox[0].y;
        // position
        const topLeft = scaledBox[0];
        return (
          <BoundingBox
            key={box.nodeId}
            nodeId={box.nodeId}
            topLeft={topLeft}
            height={height}
            width={width}
            onClick={() => {}}
            style={{
              background: 'rgba(232, 255, 114, 0.5)',
              border: 'none',
            }}
          />
        );
      });
    return (
      <div
        id="search-layer"
        style={{
          position: 'absolute',
          display: 'inline-block',
          zIndex: 1,
          height: paperSize.height * pageScale,
          width: paperSize.width * pageScale,
        }}
      >
        <div
          style={{
            position: 'relative',
            height: paperSize.height * pageScale,
            width: paperSize.width * pageScale,
          }}
        >
          {boxes}
        </div>
      </div>
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setDocumentLoaded(true);
    setProgress({ status: false, value: 0 });
    props.setNumPages(numPages);
  };

  const onPageLoadSuccess = (page) => {
    const pageOriginalRotation = page._pageInfo.rotate;
    const [horMargin, verMargin] = page._pageInfo.view;
    // count height & width with margin size
    const origHeight = page.originalHeight - verMargin;
    const origWidth = page.originalWidth - horMargin;
    // if rotation is not 0 or 180 then swap height and width
    const height = [0, 180].includes(pageOriginalRotation)
      ? origHeight
      : origWidth;
    const width = [0, 180].includes(pageOriginalRotation)
      ? origWidth
      : origHeight;
    props.setPageLoaded(true);
    props.setPaperSize({ height, width });
  };

  const isSelected = (nodeId) => {
    const { selectedPage, selectedBoundingBox, type: taskType } = props;
    if (taskType !== 'VARIABLE') {
      if (pageBoundingBox[currentPage - 1]) {
        if (
          selectedPage[selectedSection].find(
            (page) => page.id === pageBoundingBox[currentPage - 1].nodeId
          )
        )
          return true;
      }
      if (selectedBoundingBox[selectedSection]) {
        return selectedBoundingBox[selectedSection].find(
          (node) => node.id === nodeId
        );
      }
    }
  };

  const isPageSelected = (nodeId) => {
    if (props.selectedPage[selectedSection]) {
      return props.selectedPage[selectedSection].find(
        (page) => page.id === nodeId
      );
    }
  };

  const handleSelectBox = (nodeId, shiftKeyPressed = false) => {
    const { type: taskType } = props;
    if (taskType !== 'VARIABLE' && selectedSection) {
      const annotationStatus =
        props.sections[selectedSection].annotation.status;
      if (!isAccepted(annotationStatus)) {
        if (isSelected(nodeId) && !shiftKeyPressed) {
          // Prevent user from deselecting multiple boundaries
          const sameBoundaries = props.selectedBoundingBox[
            selectedSection
          ].reduce((acc, boundary) => {
            if (boundary.id === nodeId) acc += 1;
            return acc;
          }, 0);
          if (sameBoundaries > 1) {
            setDeselectionSnackbar(true);
            return;
          }
          // UNSELECT
          const pageNodeId = pageBoundingBox[currentPage - 1].nodeId;
          if (
            props.selectedPage[selectedSection].find(
              (page) => page.id === pageNodeId
            )
          ) {
            handleRemoveSelectedPage(pageNodeId);
            const pageBlocks = boundingBox[currentPage - 1].reduce(
              (acc, boundary) => {
                if (boundary.type === 'BLOCK' && boundary.nodeId !== nodeId)
                  acc = acc.concat({
                    id: boundary.nodeId,
                    pageNumber: currentPage,
                  });
                return acc;
              },
              []
            );
            props.setSelectedBoundingBox(
              selectedSection,
              props.selectedBoundingBox[selectedSection].concat(pageBlocks)
            );
            // if (taskType === 'SUBCHAPTER') {
            //   const updatedTakenBoundaries = filter(
            //     props.takenBoundaries,
            //     (boundary) => boundary.id !== pageNodeId
            //   ).concat(pageBlocks);
            //   props.setTakenBoundaries(updatedTakenBoundaries);
            // }
          } else {
            props.removeSelectedBoundingBox(nodeId);
            // if (taskType === 'SUBCHAPTER')
            //   props.setTakenBoundaries(
            //     filter(
            //       props.takenBoundaries,
            //       (boundary) => boundary.id !== nodeId
            //     )
            //   );
          }
        } else {
          // SELECT
          if (shiftKeyPressed) clearTextSelection();
          const pageBlocks = getPageBlocks(boundingBox[currentPage - 1]);
          const currentlySelected = props.selectedBoundingBox[selectedSection];
          const filtered = currentlySelected.reduce(
            (acc, node) => {
              if (pageBlocks.indexOf(node.id) === -1)
                acc = {
                  ...acc,
                  unrelated: acc.unrelated.concat({
                    id: node.id,
                    pageNumber: node.pageNumber,
                  }),
                };
              else
                acc = {
                  ...acc,
                  related: acc.related.concat({
                    id: node.id,
                    pageNumber: node.pageNumber,
                  }),
                };
              return acc;
            },
            { related: [], unrelated: [] }
          );
          if (filtered.related.length + 1 === pageBlocks.length) {
            const currentPageId = pageBoundingBox[currentPage - 1].nodeId;
            const updateSelected = [
              ...props.selectedPage[selectedSection],
              { id: currentPageId, pageNumber: currentPage },
            ];
            props.setSelectedPage(selectedSection, updateSelected);
            props.setSelectedBoundingBox(selectedSection, filtered.unrelated);

            if (taskType === 'SUBCHAPTER') {
              //   const currentlySelectedIds = currentlySelected.map(
              //     (obj) => obj.id
              //   );
              //   const newTakenBoundaries = filter(
              //     props.takenBoundaries,
              //     (boundary) => !currentlySelectedIds.includes(boundary.id)
              //   ).concat(
              //     filtered.unrelated.concat({
              //       id: currentPageId,
              //       pageNumber: currentPage,
              //     })
              //   );
              //   props.setTakenBoundaries(newTakenBoundaries);
            }
          } else {
            props.addSelectedBoundingBox(nodeId, currentPage);
            // if (taskType === 'SUBCHAPTER')
            //   props.setTakenBoundaries(
            //     props.takenBoundaries.concat({
            //       id: nodeId,
            //       pageNumber: currentPage,
            //     })
            //   );
          }
        }
      }
    }
  };

  const handleSelectPage = (nodeId, pageNumber) => {
    const { type: taskType } = props;
    if (['CHAPTER', 'SUBCHAPTER'].includes(taskType)) {
      const annotationStatus =
        props.sections[selectedSection].annotation.status;
      if (selectedSection && !isAccepted(annotationStatus)) {
        if (!isPageSelected(nodeId)) {
          // let newTakenBoundaries = props.takenBoundaries;
          const currentlySelected = props.selectedBoundingBox[selectedSection];
          if (boundingBox[pageNumber - 1] && currentlySelected.length) {
            const pageBlocks = getPageBlocks(boundingBox[pageNumber - 1]);
            const unrelated = currentlySelected.filter(
              (node) => pageBlocks.indexOf(node.id) === -1
            );
            props.setSelectedBoundingBox(selectedSection, unrelated);
            // if (taskType === 'SUBCHAPTER') {
            //   const currentlySelectedIds = currentlySelected.map(
            //     (obj) => obj.id
            //   );
            //   newTakenBoundaries = filter(
            //     newTakenBoundaries,
            //     (boundary) => !currentlySelectedIds.includes(boundary.id)
            //   );
            //   newTakenBoundaries = newTakenBoundaries.concat(unrelated);
            // }
          }
          const updateSelected = [
            ...props.selectedPage[selectedSection],
            { id: nodeId, pageNumber },
          ];
          props.setSelectedPage(selectedSection, updateSelected);
          // if (taskType === 'SUBCHAPTER')
          //   props.setTakenBoundaries(
          //     newTakenBoundaries.concat({ id: nodeId, pageNumber })
          //   );
        } else {
          handleRemoveSelectedPage(nodeId);
        }
      }
    }
  };

  const handleSelect = (selectedNodes) => {
    const { selectedField, sections } = props;
    const currentSection = sections[selectedSection];
    // disable selection if reviewed(accepted)
    if ([3, 'accepted'].includes(currentSection.annotation.status)) return;
    if (selectedNodes.length) {
      const deletedNode = selectedNodes.find(
        (node) => node.props.nodeId === selectedField.keyMap
      );
      if (deletedNode) {
        const updatedFields = props.annotatedField[selectedSection].filter(
          (annotated) => {
            return annotated.keyMap !== deletedNode.props.nodeId;
          }
        );
        formHooks.setValue(selectedField.keyMap, '');
        props.setAnnotatedVariableField(selectedSection, updatedFields);
      } else {
        const result = selectedNodes
          .sort((a, b) => a.props.coordinates[0].x - b.props.coordinates[0].x)
          .sort((a, b) => a.props.coordinates[0].y - b.props.coordinates[0].y)
          .reduce(
            (acc, selected) => {
              const {
                props: { text, coordinates },
              } = selected;
              const xs = coordinates.map(({ x }) => x);
              const ys = coordinates.map(({ y }) => y);
              acc.coords.x = acc.coords.x.concat(xs);
              acc.coords.y = acc.coords.y.concat(ys);
              acc.text = acc.text.concat(text);
              return acc;
            },
            { coords: { x: [], y: [] }, text: [] }
          );
        const { coords, text } = result;
        const calculated = [
          { x: min(coords.x), y: min(coords.y) },
          { x: max(coords.x), y: min(coords.y) },
          { x: max(coords.x), y: max(coords.y) },
          { x: min(coords.x), y: max(coords.y) },
        ];
        const { keyMap, type } = selectedField;
        const existingRaw = formHooks.getValues(keyMap) || '';
        if (
          ['number-basic', 'percentage-basic', 'ratio-basic'].includes(type)
        ) {
          let rawData = [existingRaw]
            .concat(text.join('').replace(/\D/g, ''))
            .join(' ')
            .trim('');
          formHooks.setValue(keyMap, rawData);
        } else if (
          [
            'text-basic',
            'telephone-basic',
            'email-basic',
            'website-basic',
          ].includes(type)
        ) {
          const rawData = [existingRaw]
            .concat(text)
            .join(' ')
            .replace(/(<([^>]+)>)/gi, '')
            .trim();
          formHooks.setValue(keyMap, rawData);
        }
        const newField = {
          keyMap,
          coordinates: calculated,
          pageNumber: currentPage,
        };
        const existing = props.annotatedField[selectedSection].find(
          (field) => field.keyMap === keyMap
        );
        if (existing) {
          const existingCoords = existing.coordinates;
          const newCoords = newField.coordinates;
          const combinedCoordinates = [
            {
              x: Math.min(existingCoords[0].x, newCoords[0].x),
              y: Math.min(existingCoords[0].y, newCoords[0].y),
            },
            {
              x: Math.max(existingCoords[1].x, newCoords[1].x),
              y: Math.min(existingCoords[1].y, newCoords[1].y),
            },
            {
              x: Math.max(existingCoords[2].x, newCoords[2].x),
              y: Math.max(existingCoords[2].y, newCoords[2].y),
            },
            {
              x: Math.min(existingCoords[3].x, newCoords[3].x),
              y: Math.max(existingCoords[3].y, newCoords[3].y),
            },
          ];
          newField.coordinates = combinedCoordinates;
          // replace existing field data
          const updated = props.annotatedField[selectedSection].reduce(
            (acc, field) => {
              if (field.keyMap === keyMap) acc = acc.concat(newField);
              else acc = acc.concat(field);
              return acc;
            },
            []
          );
          props.setAnnotatedVariableField(selectedSection, updated);
        } else {
          props.addAnnotatedVariableField(selectedSection, newField);
        }
      }
      const compactName = compact(props.sections[selectedSection].name);
      if (!dirty.includes(compactName)) {
        setDirty(dirty.concat(compactName));
      }
      selectableGroupRef.current.clearSelection();
    }
  };

  const handleResetSelected = () => {
    props.setSelectedBoundingBox(selectedSection, []);
    props.setSelectedPage(selectedSection, []);
  };

  const handleRemoveSelectedPage = (nodeId) => {
    const annotationStatus = props.sections[selectedSection].annotation.status;
    if (!isAccepted(annotationStatus)) {
      props.setSelectedPage(
        selectedSection,
        props.selectedPage[selectedSection].filter(
          (selected) => selected.id !== nodeId
        )
      );
      // if (props.type === 'SUBCHAPTER')
      //   props.setTakenBoundaries(
      //     filter(props.takenBoundaries, (boundary) => boundary.id !== nodeId)
      //   );
    }
  };

  const handleNextPage = () => {
    props.setPageLoaded(false);
    props.nextPage();
  };

  const handlePrevPage = () => {
    props.setPageLoaded(false);
    props.prevPage();
  };

  const focusPage = (pageNumber) => {
    if (thumbsWrapperRef.current) {
      const listRef = thumbsWrapperRef.current.listRef;
      if (listRef && listRef.current) {
        listRef.current.scrollToItem(pageNumber - 1, 'center');
        const pdfWrapper = pdfViewWrapperRef.current;
        if (currentPage !== pageNumber) pdfWrapper.scrollTo(0, 0);
        props.setCurrentPage(pageNumber);
      }
    }
  };

  const handleAutofill = (fieldname) => {
    const regex =
      /(position|working-experience)\[\d+\]\.tenure-in-(months|years)/;
    if (regex.test(fieldname)) {
      const parentField = fieldname.split('.').slice(0, 2).join('.');
      const startingDateField = `${parentField}.starting-date`;
      const endingDateField = `${parentField}.ending-date`;
      const startingDate = formHooks.getValues(startingDateField);
      const endingDate = formHooks.getValues(endingDateField);
      if (typeof startingDate === 'object' && typeof endingDate === 'object') {
        const diffMonths = differenceInMonths(endingDate, startingDate);
        const diffYears = differenceInYears(endingDate, startingDate);
        const tenureInMonthsField = `${parentField}.tenure-in-months`;
        const tenureInYearsField = `${parentField}.tenure-in-years`;
        formHooks.setValue(tenureInMonthsField, diffMonths);
        formHooks.setValue(tenureInYearsField, diffYears);
      }
    }
  };

  const handleSubmitBtn = (e) => {
    props.submitAnnotationVariable();
  };

  const handleSaveBtn = async () => {
    const isValid = await formHooks.trigger();
    if (!isValid) {
      setValidationSnackbar(true);
      return;
    }
    const data = formHooks.watch();
    const responses = await props.saveAnnotationVariable(data, dirty);
    formHooks.reset({ ...data });
    setDirty(dirty.filter((x, index) => !responses[index]));
  };

  const handleSetDirty = (variableName) => {
    if (!dirty.includes(variableName)) {
      setDirty(dirty.concat(variableName));
    }
  };

  // const handleRegion = (event) => {
  // 	if (event.region) {
  // 		alert('clicked');
  // 	}
  // };

  return (
    <FullLayout>
      {props.type === 'VARIABLE' && (
        <Prompt
          when={dirty.length > 0}
          message="Changes you made may not be saved. Continue?"
        />
      )}
      <Wrapper height="100vh">
        <Navbar type="desktop" view="flat">
          <NavbarGroup>
            <NavbarHeading>
              <Brand
                logo={logo}
                filename={props.filename}
                status={`Last updated ${
                  props.lastUpdateDate &&
                  formatDistanceToNow(new Date(props.lastUpdateDate))
                } ago - Task #${props.taskId}`}
              />
            </NavbarHeading>
          </NavbarGroup>
          <NavbarGroup align="right">
            {props.type === 'VARIABLE' && (
              <div style={{ marginRight: 12 }}>
                <Button
                  view="filled"
                  color="primary"
                  text="Submit"
                  loading={props.loadingSubmitAnnotationVariable}
                  disabled={
                    dirty.length > 0 ||
                    props.isErrorVariableAnnotation ||
                    props.taskStatus === 'need revision'
                  }
                  style={{ marginRight: 12, fontWeight: 500 }}
                  onClick={handleSubmitBtn}
                />
                <Button
                  view="filled"
                  color="primary"
                  text="Save"
                  loading={props.loadingSaveAnnotationVariable}
                  disabled={props.isErrorVariableAnnotation}
                  style={{ fontWeight: 500 }}
                  onClick={handleSaveBtn}
                />
              </div>
            )}
            <NavbarHeading>
              <NavbarUserMenu />
            </NavbarHeading>
          </NavbarGroup>
        </Navbar>
        <FlexWrapper
          height="calc(100vh - 64px)"
          width="100%"
          alignItems="flex-start"
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flex: '0 1 240px',
              borderRight: '1px solid var(--grey20)',
              flexFlow: 'column nowrap',
            }}
          >
            <FlexWrapper
              justifyContent="space-between"
              style={{
                padding: 5,
                borderTop: '1px solid var(--grey10)',
                borderLeft: '1px solid var(--grey10)',
                borderBottom: '1px solid var(--grey10)',
              }}
            >
              <Heading text="Page Thumbnails" />
            </FlexWrapper>
            <div ref={thumbsWrapperRef} className={styles.thumbWrapper}>
              {!documentLoaded ? (
                <Spinner />
              ) : (
                file && (
                  <DocumentViewer
                    ref={thumbsWrapperRef}
                    file={fixUrl(file)}
                    currentPage={currentPage}
                    setCurrentPage={props.setCurrentPage}
                    handleSelectPage={handleSelectPage}
                    handleRemoveSelectedPage={handleRemoveSelectedPage}
                    pageBoundingBox={pageBoundingBox}
                    selectedPage={props.selectedPage[selectedSection]}
                    selectedBoundingBox={
                      props.type === 'VARIABLE'
                        ? props.annotatedField[selectedSection]
                        : props.selectedBoundingBox[selectedSection]
                    }
                    takenBoundaries={props.takenBoundaries}
                  />
                )
              )}
            </div>
          </div>
          <FlexWrapper
            height="100%"
            width="calc(100% - 25% - 240px)"
            flexFlow="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Toolbar
              data={{
                boundingBoxType,
                pageScale,
                currentPage,
                numPages,
                pageLoaded,
                annotationDisabled,
              }}
              ocrStatus={props.ocrStatus}
              zoomIn={props.zoomIn}
              zoomOut={props.zoomOut}
              resetPageScale={props.resetPageScale}
              setCurrentPage={props.setCurrentPage}
              prevPage={handlePrevPage}
              nextPage={handleNextPage}
              handlePageChange={(e) => {
                const { value } = e.target;
                if (/\d+/.test(value)) {
                  const targetPage = parseInt(value);
                  if (targetPage <= numPages && targetPage > 0) {
                    props.setCurrentPage(parseInt(value));
                    focusPage(parseInt(value));
                  }
                }
              }}
              changeBoundingBoxType={props.changeBoundingBoxType}
              focusPage={focusPage}
              toggleAnnotationDisable={() => props.toggleAnnotationDisable()}
            />
            <div ref={pdfViewWrapperRef} className={styles.pdf_viewer}>
              {progress.status && !documentLoaded && (
                <div className={styles.progress}>
                  <ProgressBar
                    view="filled"
                    color="primary"
                    stripes={false}
                    value={progress.value}
                  />
                </div>
              )}
              {file && (
                <Document
                  className="pdf-doc"
                  file={fixUrl(file)}
                  loading={<PageLoading />}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={({ loaded, total }) =>
                    setProgress({ status: true, value: loaded / total })
                  }
                  onItemClick={({ pageNumber }) => {
                    if (pageNumber) props.setCurrentPage(pageNumber);
                  }}
                  options={pdfOptions}
                >
                  <div
                    className={styles.pdf_page}
                    style={{
                      height: Math.floor(paperSize.height * pageScale),
                      width: Math.floor(paperSize.width * pageScale),
                    }}
                  >
                    {props.type === 'TABLE' && enableCanvas ? (
                      <canvas
                        // onClick={() => setBtn(false)}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        id="canvas"
                        width={Math.floor(paperSize.width * pageScale)}
                        height={Math.floor(paperSize.height * pageScale)}
                        style={{
                          backgroundColor: 'transparent',
                          zIndex: 3,
                          position: 'absolute',
                          height: Math.floor(paperSize.height * pageScale),
                          width: Math.floor(paperSize.width * pageScale),
                        }}
                      />
                    ) : null}
                    {props.type === 'TABLE' && (
                      <canvas
                        id="canvasData"
                        width={Math.floor(paperSize.width * pageScale)}
                        height={Math.floor(paperSize.height * pageScale)}
                        style={{
                          backgroundColor: 'transparent',
                          zIndex: 2,
                          position: 'absolute',
                          height: Math.floor(paperSize.height * pageScale),
                          width: Math.floor(paperSize.width * pageScale),
                        }}
                      />
                    )}
                    {!props.loadingTakenBoundaries &&
                      !props.isErrorSectionAnnotation &&
                      documentLoaded &&
                      pageLoaded &&
                      !annotationDisabled &&
                      boundingBox[currentPage - 1] &&
                      generateAnnotation(
                        paperSize,
                        currentPage,
                        boundingBoxType
                      )}
                    {documentLoaded &&
                      pageLoaded &&
                      !annotationDisabled &&
                      boundingBox[currentPage - 1] &&
                      displaySearchHighlightLayer(paperSize, currentPage)}
                    <Page
                      className="pdf-page"
                      loading={<PageLoading />}
                      onLoadSuccess={onPageLoadSuccess}
                      scale={pageScale}
                      pageNumber={currentPage}
                      renderMode="canvas"
                      renderInteractiveForms={false}
                      renderTextLayer={false}
                    >
                      {tableData && !drawing && btn ? (
                        <button
                          onClick={handleSaveTableAnnotate}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: posBtnY,
                            left: posBtnX - 80 * scalePage,
                            zIndex: 5,
                            width: `${80 * scalePage}px`,
                            background: '#2979FF',
                            color: 'white',
                            border: 'none',
                            fontSize: `${10 * scalePage}px`,
                            borderRadius: '2px',
                          }}
                        >
                          + Select Area
                        </button>
                      ) : null}
                    </Page>
                  </div>
                </Document>
              )}
            </div>
          </FlexWrapper>
          <Wrapper width="25%">
            <FlexWrapper
              justifyContent="space-between"
              style={{
                padding: 5,
                borderTop: '1px solid var(--grey10)',
                borderLeft: '1px solid var(--grey10)',
                borderBottom: '1px solid var(--grey10)',
              }}
            >
              <Heading text={`Task #${props.taskId}`} />
            </FlexWrapper>
            <ThemeProvider theme={theme}>
              <Tabs
                value={tabIndex}
                variant="fullWidth"
                onChange={(e, idx) => setTabIndex(idx)}
                classes={{ flexContainer: classes.flexContainer }}
                TabIndicatorProps={{ style: { background: 'var(--blue70)' } }}
              >
                <Tab
                  label="Annotation"
                  id="tab-1"
                  aria-controls="tabpanel-1"
                  disableRipple={true}
                  classes={{ root: classes.button, wrapper: classes.wrapper }}
                />
                <Tab
                  label="Notes"
                  id="tab-2"
                  aria-controls="tabpanel-2"
                  disableRipple={true}
                  classes={{ root: classes.button, wrapper: classes.wrapper }}
                />
                <Tab
                  label="Search"
                  id="tab-3"
                  aria-controls="tabpanel-3"
                  disableRipple={true}
                  classes={{ root: classes.button, wrapper: classes.wrapper }}
                />
              </Tabs>

              <FormProvider {...formHooks}>
                <TabAnnotation
                  dataAnnotationTable={dataAnnotationTable}
                  handleAdd={handleAdd}
                  handleChange={handleChange}
                  enableCanvas={handleEnableCanvas}
                  handleEdit={handleEdit}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  editId={editId}
                  setEditId={setEditId}
                  handleCancel={handleCancel}
                  handleDelete={handleDelete}
                  tabIndex={tabIndex}
                  handleClickBoundary={focusPage}
                  handleAutofill={handleAutofill}
                  handleRemoveSelectedBox={(nodeId) => {
                    props.removeSelectedBoundingBox(nodeId);
                    // if (props.type === 'SUBCHAPTER')
                    //   props.setTakenBoundaries(
                    //     filter(
                    //       props.takenBoundaries,
                    //       (boundary) => boundary.id !== nodeId
                    //     )
                    //   );
                  }}
                  handleRemoveSelectedPage={(nodeId) =>
                    handleRemoveSelectedPage(nodeId)
                  }
                  handleResetSelected={handleResetSelected}
                  dirty={dirty}
                  handleSetDirty={handleSetDirty}
                />
              </FormProvider>
              <TabNote tabIndex={tabIndex} />
              <TabSearch tabIndex={tabIndex} handleClick={focusPage} />
            </ThemeProvider>
          </Wrapper>
        </FlexWrapper>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={validationSnackbar}
          onClose={() => setValidationSnackbar(false)}
        >
          <Alert onClose={() => setValidationSnackbar(false)} severity="error">
            Invalid form input, please kindly check your input
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={deselectionSnackbar}
          onClose={() => setDeselectionSnackbar(false)}
        >
          <Alert onClose={() => setDeselectionSnackbar(false)} severity="error">
            There are multiple selected boundaries related to this bounding box.
            <br />
            You can only remove it one-by-one through the sidebar.
          </Alert>
        </Snackbar>
      </Wrapper>
      <DialogEditBoundary
        isOpen={props.editSelectedBoundary.id !== null}
        onClose={() => {
          dispatch(resetEditSelectedBoundary());
        }}
        boundaryData={props.editSelectedBoundary}
      />
    </FullLayout>
  );
}

const mapStateToProps = (state) => ({
  ...documentPreviewSelector(state),
  ...annotationSelector(state),
  isErrorVariableAnnotation: state.annotation.isErrorVariableAnnotation,
  userProfile: state.userProfile,
  location: state.router.location,
  loadingTakenBoundaries: createLoadingSelector(['GET_TAKEN_BOUNDARIES'])(
    state
  ),
  loadingSaveAnnotationVariable: createLoadingSelector([
    'UPDATE_ANNOTATION_VARIABLE',
  ])(state),
  loadingSubmitAnnotationVariable: createLoadingSelector([
    'UPDATE_TASK_STATUS',
  ])(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDocumentData: (documentId) => dispatch(getDocumentData(documentId)),
  getTaskData: (taskId) => dispatch(getTaskData(taskId)),
  getBoundaries: (documentId, page) =>
    dispatch(getBoundaries(documentId, page)),
  getPageBoundaries: (documentId) => dispatch(getPageBoundaries(documentId)),
  getSections: (sectionId) => dispatch(getSections(sectionId)),
  getTableAnnotation: (documentId) => dispatch(getAnnotationTable(documentId)),
  addTableAnnotation: (form, documentId) =>
    dispatch(addAnnotationTable(form, documentId)),
  updateTableAnnotation: (form, documentId, annotationId) =>
    dispatch(updateAnnotationTable(form, documentId, annotationId)),
  zoomIn: () => dispatch(zoomIn()),
  zoomOut: () => dispatch(zoomOut()),
  resetPageScale: () => dispatch(resetPageScale()),
  setNumPages: (pageCount) => dispatch(setNumPages(pageCount)),
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  setPageLoaded: (status) => dispatch(setPageLoaded(status)),
  setPaperSize: (paperSize) => dispatch(setPaperSize(paperSize)),
  nextPage: () => dispatch(nextPage()),
  prevPage: () => dispatch(prevPage()),
  changeBoundingBoxType: (boundingBoxType) =>
    dispatch(changeBoundingBoxType(boundingBoxType)),
  setSelectedBoundingBox: (sectionId, nodeIds) =>
    dispatch(setSelectedBoundingBox(sectionId, nodeIds)),
  addSelectedBoundingBox: (nodeId, pageNumber) =>
    dispatch(addSelectedBoundingBox(nodeId, pageNumber)),
  removeSelectedBoundingBox: (nodeId) =>
    dispatch(removeSelectedBoundingBox(nodeId)),
  setSelectedPage: (sectionId, nodeIds) =>
    dispatch(setSelectedPage(sectionId, nodeIds)),
  getTaskNote: (taskId) => dispatch(getTaskNote(taskId)),
  resetDocumentPreview: () => dispatch(resetDocumentPreview()),
  resetTaskData: () => dispatch(resetTaskData()),
  setSelectedVariableField: (data) => dispatch(setSelectedVariableField(data)),
  setAnnotatedVariableField: (variableId, fields) =>
    dispatch(setAnnotatedVariableField(variableId, fields)),
  addAnnotatedVariableField: (variableId, data) =>
    dispatch(addAnnotatedVariableField(variableId, data)),
  saveAnnotationVariable: (formData, dirty) =>
    dispatch(saveAnnotationVariable(formData, dirty)),
  submitAnnotationVariable: () => dispatch(submitAnnotationVariable()),
  setTakenBoundaries: (boundaries) => dispatch(setTakenBoundaries(boundaries)),
  runParseAnnotatedVariableField: (variable, annotationItemIndex) =>
    dispatch(runParseAnnotatedVariableField(variable, annotationItemIndex)),
  reorderAnnotationData: (variableId, from, to, formData) =>
    dispatch(reorderAnnotationData(variableId, from, to, formData)),
  getAnnotationSuggestions: (taskId) =>
    dispatch(getAnnotationSuggestions(taskId)),
  reorderAnnotationField: (
    variableId,
    from,
    to,
    formData,
    annotationItemIndex,
    fieldName
  ) =>
    dispatch(
      reorderAnnotationField(
        variableId,
        from,
        to,
        formData,
        annotationItemIndex,
        fieldName
      )
    ),
  setSelectedPageRange: (sectionId, selectedPages) =>
    dispatch(setSelectedPageRange(sectionId, selectedPages)),
  removeSelectedPageRange: (sectionId, selectedPageIds) =>
    dispatch(removeSelectedPageRange(sectionId, selectedPageIds)),
  toggleAnnotationDisable: () => dispatch(toggleAnnotationDisable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Annotation);

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    height: 35,
    borderBottom: '1px solid var(--grey30)',
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 14,
    height: 35,
  },
  button: {
    minWidth: 100,
    fontWeight: 'normal',
  },
  deselectButton: {
    position: 'absolute',
    top: -8,
    right: -8,
    background: '#fff',
    boxShadow: '0px 2px 4px rgb(27, 78, 163, 0.2)',
    '&:hover': {
      background: '#fff',
    },
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        height: 35,
        minHeight: 35,
      },
      fixed: {
        height: 35,
      },
    },
    MuiTab: {
      root: {
        minHeight: 35,
        padding: 0,
      },
    },
    MuiButtonBase: {
      root: {
        padding: 0,
      },
    },
  },
});
