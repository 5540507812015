export default {
    gray0: '#F7F7FA',
    gray20: "#D2D2D6",
    gray30: "#B4B4BB",
    gray50: "#787885",
    gray80: "#3A3A44",
    gray90: "#292A31",

    blue40: '#76A9FF',
    blue60: "#2979FF",

    white100: "#ffffff",
      /***NEW PALLETE SCHEME***/
    black: "#000000",
    white: "#ffffff",
    /*PRIMARY COLORS*/
    /*Grey*/
    grey0: "#f7f7fa",
    grey5: "#ededf0",
    grey10: "#e1e1e3",
    grey20: "#d2d2d6",
    grey30: "#b4b4bb",
    grey40: "#9696a0",
    grey50: "#787885",
    grey60: "#5a5b6a",
    grey70: "#4a4b57",
    grey80: "#3a3a44",
    grey90: "#292a31",
    grey100: "#19191d",
    /*Blue*/

    blue0: "#f5f8ff",
    blue5: "#ebf2ff",
    blue10: "#d8e6ff",
    blue20: "#c4daff",
    blue30: "#9dc2ff",
    blue40: "#76a9ff",
    blue50: "#4f91ff",
    blue60: "#2979ff",
    blue70: "#2264d1",
    blue80: "#1b4ea3",
    blue90: "#133774",
    blue100: "#0c2146",
    /*Green*/
    green0: "#f5faf5",
    green5: "#ecf7ed",
    green10: "#dceddd",
    green20: "#cbe5cc",
    green30: "#a9d3ab",
    green40: "#87c289",
    green50: "#65b168",
    green60: "#43a047",
    green70: "#37833b",
    green80: "#2b662e",
    green90: "#1f4921",
    green100: "#132c14",
    /*Yellow*/
    yellow0: "#fff8eb",
    yellow5: "#ffefd1",
    yellow10: "#ffe5b3",
    yellow20: "#ffd98f",
    yellow30: "#f5ce84",
    yellow40: "#ebbf67",
    yellow50: "#e5ae40",
    yellow60: "#d6981b",
    yellow70: "#b88217",
    yellow80: "#8f6512",
    yellow90: "#66480d",
    yellow100: "#463209",
    /*Red*/
    red0: "#fef2f1",
    red5: "#fee8e7",
    red10: "#fddcda",
    red20: "#fccbc8",
    red30: "#faa9a3",
    red40: "#f8877f",
    red50: "#f6655a",
    red60: "#f44336",
    red70: "#c8372d",
    red80: "#9c2b23",
    red90: "#6f1f19",
    red100: "#43130f",
    /*SECONDARY COLORS*/
    /*Indigo*/
    indigo0: "#f7f8fc",
    indigo5: "#eff0fa",
    indigo10: "#e1e4f3",
    indigo20: "#d2d6ed",
    indigo30: "#b4bbe2",
    indigo40: "#97a0d6",
    indigo50: "#7985cb",
    indigo60: "#5c6bc0",
    indigo70: "#4c589e",
    indigo80: "#3b457b",
    indigo90: "#2a3158",
    indigo100: "#1a1e35",
    /*Teal*/
    teal0: "#f0fafa",
    teal5: "#e5f5f5",
    teal10: "#d1ebec",
    teal20: "#bbe2e2",
    teal30: "#8dcfcf",
    teal40: "#60bcbc",
    teal50: "#33a9a9",
    teal60: "#069697",
    teal70: "#057b7c",
    teal80: "#046061",
    teal90: "#034545",
    teal100: "#02292a",
    /*Orange*/
    orange0: "#fff6ed",
    orange5: "#ffefde",
    orange10: "#fee7cd",
    orange20: "#fddcb9",
    orange30: "#fdc68b",
    orange40: "#fcaf5c",
    orange50: "#fb982e",
    orange60: "#fb8200",
    orange70: "#ce6b00",
    orange80: "#a05300",
    orange90: "#733c00",
    orange100: "#452400",
    /*Pink*/
    pink0: "#fff7fa",
    pink5: "#fdedf2",
    pink10: "#fbdce6",
    pink20: "#f9cada",
    pink30: "#f6a8c2",
    pink40: "#f285aa",
    pink50: "#ef6292",
    pink60: "#ec407a",
    pink70: "#c23564",
    pink80: "#97294e",
    pink90: "#6c1e38",
    pink100: "#411222",
} 