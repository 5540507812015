import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import Checkbox from '../../components/@setproduct-ui/core/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from '@blueprintjs/core';
import styles from './style.module.css';
import { setDisplayedAnnotators } from '../../redux/actions/annotation';
import { CircularProgress } from '@material-ui/core';
import { Done, Clear as Cross } from '@material-ui/icons';
import { createLoadingSelector } from '../../redux/api/loading';

const useStyles = makeStyles({
  list: {
    padding: 0,
  },
});

function Toolbar(props) {
  const [userListOpen, setUserListOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  let inputRef = useRef();
  let menuPosRef = useRef();
  const classes = useStyles();
  const isLoadedBoundingBox = () => {
    const { currentPage, pageBoundingBox, boundingBox } = props;
    const pageIndex = currentPage - 1;
    return pageBoundingBox[pageIndex] && boundingBox[pageIndex];
  };

  const displayMenu = () => {
    if (menuPosRef.current) {
      const { offsetTop, offsetLeft } = menuPosRef.current;
      setMenuPosition({ top: offsetTop, left: offsetLeft });
      setUserListOpen(true);
    }
  };

  return (
    <FlexWrapper
      justifyContent="space-between"
      style={{
        height: 47,
        borderTop: '1px solid var(--grey10)',
        borderBottom: '1px solid var(--grey10)',
        fontFamily: 'var(--inter)',
      }}
    >
      <FlexWrapper justifyContent="flex-start" padding="9px">
        {['CHAPTER', 'SUBCHAPTER'].includes(props.taskType) ? (
          <>
            <FlexWrapper flexFlow="column">
              <Button
                view="outlined"
                text="Other Annotators"
                color="default"
                dense={true}
                rightIcon="caret-down"
                onClick={() => displayMenu(true)}
              />
              <div ref={menuPosRef}></div>
            </FlexWrapper>
            <Menu
              keepMounted
              anchorReference="anchorPosition"
              anchorPosition={{
                top: menuPosition.top + 10,
                left: menuPosition.left - 82,
              }}
              PaperProps={{
                style: {
                  maxHeight: 300,
                  width: 'auto',
                },
              }}
              open={Boolean(userListOpen)}
              onClose={() => setUserListOpen(false)}
              classes={classes}
            >
              {Object.keys(props.annotators).length > 1 ? (
                Object.keys(props.annotators)
                  .filter(
                    (annotatorId) => parseInt(annotatorId) !== props.annotatorId
                  )
                  .map((annotatorId) => (
                    <MenuItem key={annotatorId}>
                      <Checkbox
                        type="dense"
                        color="primary"
                        checked={
                          props.displayedAnnotators.indexOf(
                            parseInt(annotatorId)
                          ) !== -1
                        }
                        label={props.annotators[annotatorId]}
                        onChange={() => {
                          if (
                            props.displayedAnnotators.indexOf(
                              parseInt(annotatorId)
                            ) === -1
                          )
                            props.setDisplayedAnnotators([
                              ...props.displayedAnnotators,
                              parseInt(annotatorId),
                            ]);
                          else
                            props.setDisplayedAnnotators(
                              props.displayedAnnotators.filter(
                                (displayed) =>
                                  displayed !== parseInt(annotatorId)
                              )
                            );
                        }}
                      />
                    </MenuItem>
                  ))
              ) : (
                <MenuItem>
                  <span style={{ color: 'var(--grey40)', fontStyle: 'italic' }}>
                    No related annotator
                  </span>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <>
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf('FIELD') !== -1}
              label="Show labels"
              onChange={() => props.changeBoundingBoxType('FIELD')}
            />
          </>
        )}
        {props.loadingBoundary && (
          <span
            style={{ color: 'var(--grey60)', fontSize: 12, marginLeft: 10 }}
          >
            <CircularProgress size={20} />
          </span>
        )}
        {!props.loadingBoundary && isLoadedBoundingBox() && (
          <Done
            style={{ fontSize: 20, color: 'var(--green50)', marginLeft: 10 }}
          />
        )}
        {!props.loadingBoundary && !isLoadedBoundingBox() && (
          <Cross
            style={{ fontSize: 20, color: 'var(--red50)', marginLeft: 10 }}
          />
        )}
      </FlexWrapper>
      <FlexWrapper className={styles.pageTools}>
        <IconButton
          size="small"
          onClick={props.prevPage}
          disabled={props.data.currentPage === 1}
        >
          <Icon icon="chevron-left" iconSize={20} />
        </IconButton>
        <span style={{ margin: '0 5px' }}>
          <input
            ref={(el) => (inputRef = el)}
            type="text"
            value={props.data.currentPage}
            onChange={props.handlePageChange}
            onClick={({ target: { value } }) => {
              inputRef.setSelectionRange(0, value.length);
            }}
            className={styles.input}
          />{' '}
          / {props.data.numPages}
        </span>
        <IconButton
          size="small"
          onClick={props.nextPage}
          disabled={props.data.currentPage === props.data.numPages}
        >
          <Icon icon="chevron-right" iconSize={20} />
        </IconButton>
      </FlexWrapper>
      <FlexWrapper padding="9px">
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-in"
          onClick={props.zoomIn}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-out"
          disabled={props.data.pageScale === 0.25}
          onClick={props.zoomOut}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-to-fit"
          onClick={props.resetPageScale}
        />
      </FlexWrapper>
    </FlexWrapper>
  );
}

const mapStateToProps = (state) => ({
  annotatorId: state.annotation.annotator.id,
  annotators: state.annotation.annotators,
  displayedAnnotators: state.annotation.displayedAnnotators,
  boundingBox: state.documentPreview.boundingBox,
  pageBoundingBox: state.documentPreview.pageBoundingBox,
  currentPage: state.documentPreview.currentPage,
  loadingBoundary: createLoadingSelector(['GET_BOUNDARIES'])(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDisplayedAnnotators: (annotators) =>
    dispatch(setDisplayedAnnotators(annotators)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
