import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import SearchInput from '../../components/SearchInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import styles from '../../components/AdvancedTable/table.module.css';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import { getComparator, stableSort } from '../../components/AdvancedTable';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import {
  changePageDataOnTaskProgress,
  changeTaskFilter,
  getTaskAnnotators,
  getTaskProgress,
} from '../../redux/actions/admin';
import { createLoadingSelector } from '../../redux/api/loading';
import Switch from '../../components/@setproduct-ui/core/Switch';
import { round } from 'lodash';

const loadingSelectorQuery = createLoadingSelector(['GET_TASK_PROGRESS']);

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'ANNOTATOR',
  },
  {
    id: 'dtype',
    numeric: false,
    disablePadding: false,
    label: 'TYPE',
  },
  {
    id: 'totalTasks',
    type: 'number',
    disablePadding: false,
    label: 'ASSIGNED',
  },
  {
    id: 'totalTodo',
    type: 'number',
    disablePadding: false,
    label: 'TODO',
  },
  {
    id: 'totalOngoing',
    type: 'number',
    disablePadding: false,
    label: 'ONGOING',
  },
  {
    id: 'totalUnderReview',
    type: 'number',
    disablePadding: false,
    label: 'UNDER REVIEW',
  },
  {
    id: 'totalAccepted',
    type: 'number',
    disablePadding: false,
    label: 'ACCEPTED',
  },
  {
    id: 'totalRejected',
    type: 'number',
    disablePadding: false,
    label: 'REJECTED',
  },
];

function TaskProgress() {
  const dispatch = useDispatch();
  const classes = useTableStyle();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [orderType, setOrderType] = useState('string');
  const [isPercentage, setIsPercentage] = useState(true);
  const { pageData, list } = useSelector(({ admin }) => admin.taskProgress);
  const { filter } = useSelector(({ admin }) => admin.tasks);
  const annotators = useSelector((state) => state.admin.tasks.annotators);
  const loading = useSelector(loadingSelectorQuery);
  const loadingAnnotators = useSelector(
    createLoadingSelector(['GET_TASK_ANNOTATORS'])
  );
  const toPercent = (base, total) => {
    return round(base / total, 2) * 100;
  };
  const mapToPercentage = (rows) => {
    return rows.map((row) => ({
      ...row,
      totalTodo: toPercent(row.totalTodo, row.totalTasks),
      totalOngoing: toPercent(row.totalOngoing, row.totalTasks),
      totalUnderReview: toPercent(row.totalUnderReview, row.totalTasks),
      totalAccepted: toPercent(row.totalAccepted, row.totalTasks),
      totalRejected: toPercent(row.totalRejected, row.totalTasks),
    }));
  };

  useEffect(() => {
    dispatch(getTaskAnnotators());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getTaskProgress({
        taskPrefix: filter.q,
        taskType: filter.type,
        annotatorId: filter.annotator.value || null,
        limit: pageData.limit,
        page: pageData.currentPageNumber,
      })
    );
  }, [dispatch, filter, pageData.limit, pageData.currentPageNumber]);

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setOrderType(type);
  };

  const handleChangePage = (event, newPage) => {
    // material-ui using zero-based page
    dispatch(changePageDataOnTaskProgress({ currentPageNumber: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      changePageDataOnTaskProgress({
        limit: parseInt(event.target.value, 10),
        currentPageNumber: 1,
      })
    );
  };

  const getSelectedType = (type) => {
    const uppercased = type.toUpperCase();
    if (type) return { value: uppercased, label: uppercased };
    return { value: '', label: 'Type' };
  };

  return (
    <div className={pageLayoutStyle.root}>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbarScrollable}>
          <h1 className={styles.tableTitle}>Task Progress</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              defaultValue={filter.q}
              onApplySearch={(value) => {
                dispatch(changeTaskFilter({ q: value }));
              }}
              placeholder="Task Prefix"
              style={{ width: 250, marginRight: 10 }}
            />
            <Select
              name="annotator"
              value={filter.annotator}
              defaultValue={{ value: '', label: 'Annotator' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ annotator: selected }))
              }
              options={[
                { value: '', label: 'Annotator' },
                ...annotators.map((annotator) => {
                  return { value: annotator.id, label: annotator.name };
                }),
              ]}
              isLoading={loadingAnnotators}
              styles={tableSelectStyle}
              menuPortalTarget={document.body}
            />
            <Select
              name="type"
              value={getSelectedType(filter.type)}
              isSearchable={false}
              defaultValue={{ value: '', label: 'Type' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ type: selected.value }))
              }
              options={[
                { value: '', label: 'Type' },
                {
                  value: 'CHAPTER',
                  label: 'CHAPTER',
                },
                {
                  value: 'SUBCHAPTER',
                  label: 'SUBCHAPTER',
                },
                {
                  value: 'VARIABLE',
                  label: 'VARIABLE',
                },
              ]}
              styles={tableSelectStyle}
              menuPortalTarget={document.body}
            />
            <Switch
              type="dense"
              color="primary"
              leftPosition={true}
              label="Percentage"
              checked={isPercentage}
              onChange={() => setIsPercentage((prev) => !prev)}
            />
          </FlexWrapper>
        </div>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <AdvancedTableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              onSort={handleRequestSort}
              rowCount={list.length}
              columns={columns}
            />
            <TableBody>
              {loading ? (
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={50} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={50} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={50} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={50} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={50} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={50} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : list.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} className={classes.emptyResult}>
                    No results found
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(
                  isPercentage ? mapToPercentage(list) : list,
                  getComparator(order, orderBy, orderType)
                ).map((row, index) => {
                  return (
                    <TableRow key={`row-${index}`} hover tabIndex={-1}>
                      <TableCell
                        className={classes.cell}
                        style={{ paddingLeft: 15 }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dtype}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.totalTasks}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isPercentage ? `${row.totalTodo}%` : row.totalTodo}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isPercentage
                          ? `${row.totalOngoing}%`
                          : row.totalOngoing}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isPercentage
                          ? `${row.totalUnderReview}%`
                          : row.totalUnderReview}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isPercentage
                          ? `${row.totalAccepted}%`
                          : row.totalAccepted}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {isPercentage
                          ? `${row.totalRejected}%`
                          : row.totalRejected}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 30, 75, 100]}
            component="div"
            count={pageData.totalItems}
            rowsPerPage={pageData.limit}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            // material-ui using zero-based page
            page={pageData.currentPageNumber - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}

export default TaskProgress;
