const initialState = {
  groups: [],
  members: [],

  group: null,
  member: null,
  removedMember: null,
  groupCreate: {},
};

export const actionType = {
  InitData: `ANNO_GROUP_TEAM::INIT_DATA_LOADED`,

  ReplaceGroupRows: `ANNO_GROUP_TEAM::REPLACE_GROUP_ROW_SUCCESS_1`,
  ReplaceGroupList: `ANNO_GROUP_TEAM::REPLACE_GROUP_ROW`,

  CreateGroup: `ANNO_GROUP_TEAM::CREATE_GROUP`,
  LoadGroup: `ANNO_GROUP_TEAM::LOAD_GROUP`,
  LoadListAnnotator: `ANNO_GROUP_TEAM::LOAD_LIST_ANNOTATOR`,
  LoadListGroupAnnotator: `ANNO_GROUP_TEAM::LOAD_LIST_GROUP_ANNOTATOR`,

  SelectMemberAndGroup: `ANNO_GROUP_TEAM::SELECT_MEMBER_AND_GROUP`,
  SelectRemovedMemberAndGroup: `ANNO_GROUP_TEAM::SELECT_REMOVED_MEMBER_AND_GROUP`,
  UpdateGroup: `ANNO_GROUP_TEAM::UPDATE_GROUP`,
  DeleteGroup: `ANNO_GROUP_TEAM::DELETE_GROUP`,

  AddMemberToGroup: `ANNO_GROUP_TEAM::ADD_MEMBER_TO_GROUP`,
  RemoveMemberFromGroup: `ANNO_GROUP_TEAM::REMOVE_MEMBER_FROM_GROUP`,

  ClearSelectedObject: `ANNO_GROUP_TEAM::CLEAR_SELECTED_OBJECT`,
};

export default (state = initialState, action) => {
  let data = null;

  switch (action.type) {
    case actionType.InitData:
      return {
        ...state,
        members: action.members,
        groups: action.groups,
      };

    case actionType.UpdateGroup + "_SUCCESS":
      data = state.groups.map((item) =>
        item.id === state.group.id ? state.group : item
      );
      return {
        ...state,
        groups: data,
      };

    case actionType.SelectMemberAndGroup:
      return {
        ...state,
        group: action.group,
        member: action.member,
      };

    case actionType.SelectRemovedMemberAndGroup:
      return {
        ...state,
        group: action.group,
        removedMember: action.member,
      };

    case actionType.ReplaceGroupList:
      return {
        ...state,
        groups: action.payload,
      };

    case actionType.AddMemberToGroup + "_SUCCESS":
      state.group.registeredIds.push(state.member.id);
      state.group.members.push(state.member);
      state.group.totalMember += 1;

      data = state.groups.map((item) =>
        item.id === state.group.id ? state.group : item
      );
      return {
        ...state,
        groups: data,
      };

    case actionType.ReplaceGroupRows:
      return {
        ...state,
        groups: action.payload,
      };

    case actionType.LoadMemberSuccess + "_SUCCESS":
      return {
        ...state,
        members: action.payload,
      };

    case actionType.RemoveMemberFromGroup + "_SUCCESS":
      state.group.registeredIds = state.group.registeredIds.filter((id) => {
        if (id !== state.removedMember.id) return id;
      });

      state.group.members = state.group.members.filter((member) => {
        if (member.id !== state.removedMember.id) return member;
      });

      state.group.totalMember -= 1;
      state.group.totalMember =
        state.group.totalMember < 0 ? 0 : state.group.totalMember;

      data = state.groups.map((item) =>
        item.id === state.group.id ? state.group : item
      );
      return {
        ...state,
        groups: data,
      };

    case actionType.ClearSelectedObject:
      return {
        ...state,
        group: null,
        member: null,
        removedMember: null,
      };

    default:
      return state;
  }
};
