import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as yup from "yup";
import { push } from "connected-react-router";
import withAuthentication from "../../utils/withAuthentication";
import Checkbox from "../../components/@setproduct-ui/core/CheckBox";
import FlexWrapper from "../../components/FlexWrapper";
import Button from "../../components/@setproduct-ui/core/Button";
import Input from "../../components/Input";
import FormMessage from "../../components/FormMessage";
import HorizontalDivider from "../../components/HorizontalDivider";
import useViewport from "../../hooks/useViewport";
import { signIn, signInGoogle } from "../../redux/actions/auth";
import { createLoadingSelector } from "../../redux/api/loading";
import { createErrorMessageSelector } from "../../redux/api/error";
import googleLogo from "../../assets/images/google-logo.svg";
import PATH_URL from "../../routers/path";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  password: yup
    .string()
    .min(6, "Password length must be at least 6 characters")
    .max(100, "Maximum 100 characters")
    .required("Required")
});

const loadingSelector = createLoadingSelector(["LOGIN"]);
const errorMessageSelector = createErrorMessageSelector(["LOGIN"]);

function LoginPage(props) {
  const [remember, setRemember] = useState(false);
  const { screenHeight, screenWidth } = useViewport();

  return (
    <div
      style={{
        minWidth: screenWidth > 900 ? "500px" : 0,
        width: screenWidth > 900 ? "40%" : "100%",
        height: screenHeight < 585 ? "100%" : "auto",
        padding: screenHeight < 585 ? "30px 0" : "0",
        overflow: "auto"
      }}
    >
      <Formik
        enableReinitialize
        validationSchema={LoginSchema}
        validateOnSubmit
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          email: "",
          password: ""
        }}
        onSubmit={async (values, actions) => {
          const { email, password } = values;
          await props.signIn(email, password, remember);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              padding: "0 70px"
            }}
          >
            <h1 style={{ marginTop: 0 }}>Welcome back</h1>
            {props.error !== "" && (
              <div style={{ marginBottom: 10 }}>
                <FormMessage type="error">{props.error}</FormMessage>
              </div>
            )}
            <Input
              name="email"
              type="text"
              view="outlined"
              color="default"
              onBlur={handleBlur}
              onInput={handleChange}
              value={values.email}
              fill={true}
              placeholder="Email"
              error={errors.hasOwnProperty("email")}
              errorMessage={errors.email}
              style={{ marginBottom: 20 }}
            />
            <Input
              name="password"
              type="password"
              view="outlined"
              color="default"
              value={values.password}
              onInput={handleChange}
              fill={true}
              placeholder="Password"
              error={errors.hasOwnProperty("password")}
              errorMessage={errors.password}
            />
            <FlexWrapper
              flexFlow={screenWidth <= 460 ? "column" : "row"}
              justifyContent="space-between"
              margin="20px 0"
            >
              <Checkbox
                type="def"
                color="primary"
                checked={remember}
                label="Remember me"
                helperText=""
                rtl={false}
                right={false}
                onChange={() => setRemember(!remember)}
              />
              <Link
                to={PATH_URL.FORGOT_PASSWORD}
                style={{
                  display: "block",
                  color: "var(--blue90)",
                  fontWeight: "bold"
                }}
              >
                Forgot password?
              </Link>
            </FlexWrapper>
            <Button
              type="default"
              view="filled"
              color="primary"
              text="Sign In"
              fill={true}
              style={{ marginTop: 0 }}
              loading={isSubmitting || props.loading}
              onClick={handleSubmit}
            />
            <HorizontalDivider text="Or" />
            <Button
              type="default"
              view="outlined"
              color="default"
              icon={
                <img
                  src={googleLogo}
                  style={{ height: 30, width: 30 }}
                  alt="google-logo"
                />
              }
              text="Sign In With Google"
              fill={true}
              style={{ marginTop: 10 }}
              disabled={isSubmitting || props.loading}
              onClick={() => props.signInGoogle()}
            />
            <button type="submit" style={{ display: "none" }}>
              Submit
            </button>
            <p style={{ marginTop: 30, color: "var(--gray50)" }}>
              Don't have an account?{" "}
              <Link
                to={PATH_URL.REGISTER}
                style={{ color: "var(--blue90)", fontWeight: "bold" }}
              >
                Click here
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  error: errorMessageSelector(state)
});

const mapDispatchToProps = dispatch => ({
  signIn: (email, password, isRemembered) =>
    dispatch(signIn(email, password, isRemembered)),
  signInGoogle: () => dispatch(signInGoogle()),
  push: path => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(LoginPage));
