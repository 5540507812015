import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import FlexWrapper from "../../components/FlexWrapper";
import Input from "../../components/Input";
import Checkbox from "../../components/@setproduct-ui/core/CheckBox";
import Button from "../../components/@setproduct-ui/core/Button";
import Divider from "../../components/@setproduct-ui/core/Divider";
import { addSection } from "../../redux/actions/admin";
import CustomDialog from "../../components/CustomDialog";
import Select from "react-select";
import { Form, Formik } from "formik";
import { getDataTypeList, addVariable } from "../../redux/actions/admin";
import { createLoadingSelector } from "../../redux/api/loading";
import styles from "./style.module.css";

const customSelectStyles = {
  control: provided => ({
    ...provided,
    height: 40
  })
};

function CreateVariableForm(props) {
  const { getDataTypeList } = props;
  const dataTypes = useSelector(state => state.admin.dataTypes);
  const loadingDataType = useSelector(state => createLoadingSelector(["GET_DATATYPE_LIST"])(state));
  const loadingCreate = useSelector(state => createLoadingSelector(["ADD_VARIABLE"])(state));

  useEffect(() => {
    getDataTypeList();
  }, [getDataTypeList])

  const validate = fields => {
    return fields.variableName === "" || fields.category === "" || fields.type.value === "";
  }

  return (
    <CustomDialog
      view="raised"
      title="Create New Variable"
      backdropOpacity={40}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          variableName: "",
          // category: { value: "", label: "" },
          category: "",
          description: "",
          isArray: false,
          type: { value: "", label: "" }
        }}
        onSubmit={async (values, actions) => {
          const { sectionId, name } = props.sectionData;
          const { variableName, category, description, isArray, type } = values;
          const data = {
            name: variableName,
            // category: category.value,
            category,
            description,
            isArray,
            dtype: type.value,
            sectionProbs: {
              id: sectionId,
              name
            }
          }
          const isSuccess = await props.addVariable(data);
          if (isSuccess) {
            props.onClose()
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center"
            }}
          >
            <label htmlFor="variableName" className={styles.formLabel}>
              Variable Name
            </label>
            <Input
              name="variableName"
              type="text"
              view="outlined"
              color="default"
              onBlur={handleBlur}
              onInput={handleChange}
              value={values.variableName}
              fill={true}
              error={errors.hasOwnProperty("variableName")}
              errorMessage={errors.variableName}
              style={{ marginBottom: 10 }}
            />
            <div style={{ marginBottom: 10 }}>
              <label htmlFor="category" className={styles.formLabel}>
                ESG Category
              </label>
              {/**
              <Select
                name="category"
                value={values.category}
                options={[
                  {
                    value: "governance",
                    label: "Governance"
                  }
                  // TODO: Get esg category data from backend
                ]}
                onChange={selected => {
                  setFieldValue("category", selected);
                }}
                styles={customSelectStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black"
                  }
                })}
              />
              **/}
              <Input
                name="category"
                type="text"
                view="outlined"
                color="default"
                onBlur={handleBlur}
                onInput={handleChange}
                value={values.category}
                fill={true}
                error={errors.hasOwnProperty("category")}
                errorMessage={errors.category}
              />
            </div>
            <label htmlFor="description" className={styles.formLabel}>
              Description (optional)
            </label>
            <Input
              name="description"
              type="text"
              view="outlined"
              color="default"
              onBlur={handleBlur}
              onInput={handleChange}
              value={values.description}
              fill={true}
              error={errors.hasOwnProperty("description")}
              errorMessage={errors.description}
              style={{ marginBottom: 10 }}
            />
            <Divider size={1} colorStep={20} />
            <Checkbox
              type="dense"
              checked={values.isArray}
              label="Is array?"
              onChange={() => setFieldValue("isArray", !values.isArray)}
              style={{ marginBottom: 10, marginLeft: -5 }}
            />
            <label htmlFor="type" className={styles.formLabel}>
              Type
            </label>
            <div style={{ marginBottom: 25 }}>
              <Select
                name="type"
                value={values.type}
                placeholder={loadingDataType ? "Loading..." : ""}
                isDisabled={loadingDataType}
                options={dataTypes.map(item => ({
                  value: item.dataTypeId,
                  label: item.name
                }))}
                onChange={selected => {
                  setFieldValue("type", selected);
                }}
                styles={customSelectStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black"
                  }
                })}
              />
            </div>
            <FlexWrapper justifyContent="flex-end">
              <Button
                view="filled"
                color="warning"
                text="Save Changes"
                style={{ marginRight: 8 }}
                loading={isSubmitting || loadingCreate}
                disabled={validate(values)}
                onClick={handleSubmit}
              />
              <Button
                view="outlined"
                color="default"
                text="Discard Changes"
                disabled={isSubmitting || loadingCreate}
                onClick={props.onClose}
              />
            </FlexWrapper>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  addSection: data => dispatch(addSection(data)),
  getDataTypeList: () => dispatch(getDataTypeList()),
  addVariable: data => dispatch(addVariable(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(CreateVariableForm);
