import React from "react";
import { Box, TableCell, TableRow } from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LoadingTable = (props) => {
  let { number } = props;
  number = number || 1;

  const color = "#eee";
  const highlightColor = "#eee";

  let rows = [];
  for (let i = 0; i < number; i++) {
    rows.push(
      <TableRow key={`skeleton-item-${i + 1}`}>
        <TableCell>
          <Skeleton width={20} height={20} circle={true} />
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <SkeletonTheme color="#eee" highlightColor="#eee">
                <Skeleton width={300} height={20} />
              </SkeletonTheme>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <SkeletonTheme color={color} highlightColor={highlightColor}>
                <Skeleton width={200} height={20} />
              </SkeletonTheme>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          {" "}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <SkeletonTheme color={color} highlightColor={highlightColor}>
                <Skeleton width={100} height={20} />
              </SkeletonTheme>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <SkeletonTheme color={color} highlightColor={highlightColor}>
                <Skeleton width={100} height={20} />
              </SkeletonTheme>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <SkeletonTheme color={color} highlightColor={highlightColor}>
                <Skeleton width={100} height={20} />
              </SkeletonTheme>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <SkeletonTheme color={color} highlightColor={highlightColor}>
                <Skeleton width={100} height={20} />
              </SkeletonTheme>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  return rows;
};
