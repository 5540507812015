import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upperFirst } from "lodash";
import styled from "styled-components";
import Typography from "../@setproduct-ui/core/Typography/Typography";
import { Icon } from "@blueprintjs/core";
import DynamicAvatar from "../../components/DynamicAvatar";
import Menu from "../@setproduct-ui/core/Menu/Menu";
import MenuItem from "../@setproduct-ui/core/Menu/MenuItem/Item";
import { signOut } from "../../redux/actions/auth";
import { ClickOutsideContainer } from "../utils/ClickOutsideContainer";

export default function NavbarUserMenuDocument() {
  const name = useSelector(state => state.userProfile.profile.name);
  const photo = useSelector(state => state.firebase.auth.photoURL);
  const role = useSelector(state => state.userProfile.role);

  const dispatch = useDispatch();

  const photoURL = photo ? photo : "";
  const userName = name ? name : "";
  const userRole = role ? role : "";

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <NavbarUserMenuStyle onClick={() => setShowMenu(!showMenu)}>
        <div className="title">
          <Typography type="body_bold" small style={{ color: "var(--grey80)" }}>
            {userName}
          </Typography>
          <Typography type="caption" style={{ color: "var(--grey50)" }}>
            {upperFirst(userRole)}
          </Typography>
        </div>
        <DynamicAvatar
          photoUrl={photoURL}
          name={userName}
          color="var(--green50)"
          style={{ marginRight: 5 }}
        />
        <Icon icon="caret-down" iconSize={16} style={{ color: "#000000" }} />
      </NavbarUserMenuStyle>
      {showMenu && (
        <ClickOutsideContainer onClose={() => setShowMenu(false)}>
          <Menu
            type="dense"
            view="raised"
            color="default"
            style={{ position: "absolute", paddingBottom: "8px" }}
          >
            <MenuItem
              type="dense"
              view="raised"
              color="default"
              text="Logout"
              onClick={() => dispatch(signOut())}
            />
          </Menu>
        </ClickOutsideContainer>
      )}
    </div>
  );
}

const NavbarUserMenuStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .title {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    align-items: flex-end;
  }

  .photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-right: 6px;
  }
`;
