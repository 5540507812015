import React from "react";
import { connect } from "react-redux";
import styles from "./style.module.css";
import { colors } from "./colors.json";

function getInitial(name) {
    return name.split(" ").reduce((acc, name, idx) => {
        if (idx < 2) acc += name[0].toUpperCase();
        return acc;
    }, "")
}

function BoundingBox(props) {
  return (
    <div
      style={{
        position: "absolute",
        top: props.topLeft.y,
        left: props.topLeft.x,
        height: props.height,
        width: props.width,
        cursor: "pointer",
        border: props.isSelected
          ? "2px solid var(--blue40)"
          : "2px solid var(--yellow40)",
        ...props.style
      }}
    >
      <div className={styles.userBoxWrapperBounding}>
        {props.annotators.map((annotatorId, index) => (
          <span
            key={index}
            className={styles.userBox}
            style={{
              background: colors[props.displayedAnnotators.indexOf(annotatorId)]
            }}
          >
            {getInitial(props.annotatorList[annotatorId])}
          </span>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  annotatorList: state.annotation.annotators,
  displayedAnnotators: state.annotation.displayedAnnotators
});

export default connect(
  mapStateToProps,
  null
)(BoundingBox);
