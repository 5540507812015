import React from 'react';
import Dialog from '../../components/CustomDialog';
import Button from '../../components/@setproduct-ui/core/Button';
import { useStyles } from './use-styles';

export default function DialogDelete({ isOpen, loading, onCancel, onSubmit }) {
  const classes = useStyles();
  return (
    <Dialog
      type="default"
      view={'raised'}
      onClose={onCancel}
      isOpen={isOpen}
      color="default"
      title="Delete Document"
    >
      <div>
        <p>Are you sure want to delete this document?</p>
        <div className={classes.submitWrapperForm}>
          <Button
            type="default"
            view="flat"
            dense={true}
            onClick={onCancel}
            style={{ padding: 20 }}
            color="default"
          >
            Cancel
          </Button>
          <Button
            type="default"
            onClick={onSubmit}
            view="filled"
            loading={loading}
            dense={true}
            style={{ padding: 20 }}
            color="danger"
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
