import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  TablePagination,
  Paper,
} from '@material-ui/core';
import { useTableStyle } from '../../../components/AdvancedTable/tableStyle';
import AdvancedTableHeader from '../../../components/AdvancedTable/AdvancedTableHeader';
import { getComparator, stableSort } from '../../../components/AdvancedTable';
import Skeleton from 'react-loading-skeleton';
import AdvancedTableAction from '../../../components/AdvancedTable/AdvancedTableAction';
import styles from '../style.module.css';

const ROWS_PER_PAGE = 15;

export default function CustomTable({
  columns,
  search,
  buttonFilter,
  filterBy,
  searchBy,
  loading,
  data,
  selectedData,
  selectData,
}) {
  const classes = useTableStyle();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      dispatch(selectData(data.filter(applySearch)));
    } else {
      dispatch(selectData([]));
    }
  };
  const handleClick = (event, data) => {
    const selected = selectedData.find((selected) => selected.id === data.id);
    if (!selected) {
      dispatch(selectData([...selectedData, data]));
    } else {
      const newSelected = selectedData.filter(
        (selected) => selected.id !== data.id
      );
      dispatch(selectData(newSelected));
    }
  };
  const isSelected = (id) => {
    const selected = selectedData.find((selected) => selected.id === id);
    return selected ? true : false;
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const applySearch = (data) => {
    let isMatch = true;
    if (search) {
      const regex = RegExp(`${search}`, 'i');
      isMatch = isMatch && searchBy(regex, data);
    }
    if (Object.keys(buttonFilter).length > 0) {
      isMatch =
        isMatch &&
        Object.keys(buttonFilter).reduce(
          (match, v, i) =>
            match &&
            (!buttonFilter[v].value ||
              (filterBy[i] && filterBy[i](data)) === buttonFilter[v].value),
          true
        );
    }
    return isMatch;
  };
  useEffect(() => {
    setPage(0);
  }, [buttonFilter, search]);
  return (
    <>
      <TableContainer className={styles.customContainerTable}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
          stickyHeader
        >
          <AdvancedTableHeader
            classes={classes}
            selectionCounter={selectedData.length}
            order={order}
            orderBy={orderBy}
            onSelect={handleSelectAllClick}
            onSort={handleRequestSort}
            rowCount={data.filter(applySearch).length}
            columns={columns}
          />
          <TableBody>
            {loading ? (
              Array(3)
                .fill(1)
                .map((_, index) => (
                  <TableRow key={index} role="checkbox">
                    <TableCell className={classes.checkbox}>
                      <Skeleton
                        height={20}
                        width={20}
                        style={{ marginLeft: 10 }}
                      />
                    </TableCell>
                    {new Array(columns.length).fill(0).map((_, i) => (
                      <TableCell
                        key={`indexsk-${i}`}
                        component="th"
                        scope="row"
                        padding="default"
                        className={classes.cell}
                      >
                        <Skeleton width="90%" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : data.filter(applySearch).length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} className={classes.emptyResult}>
                  No results found
                </TableCell>
              </TableRow>
            ) : (
              stableSort(
                data.filter(applySearch),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={row.id || `row-data-${index}`}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell className={classes.checkbox}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row)}
                        />
                      </TableCell>
                      {columns.map((column, index) => (
                        <TableCell
                          key={`column-${index}`}
                          component="th"
                          scope="row"
                          padding="default"
                          className={classes.cell}
                        >
                          {column.renderRow(row)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper className={classes.pagination} elevation={0}>
        {selectedData.length > 0 && (
          <AdvancedTableAction
            selectionCounter={selectedData.length}
            actions={[
              {
                icon: 'trash',
                onClick: () => console.log('delete selected task'),
              },
            ]}
          />
        )}
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.filter(applySearch).length}
          rowsPerPage={ROWS_PER_PAGE}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} of ${count} rows`
          }
          labelRowsPerPage={'Show'}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
