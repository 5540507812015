import React from "react";
import styles from "../../components/AdvancedTable/table.module.css";

import {
  Box,
  TableBody,
  Table,
  TableContainer,
  Card,
  CardContent,
  CardHeader,
  OutlinedInput,
  TablePagination,
  Paper,
  Grid,
} from "@material-ui/core";
import Button from "../../components/@setproduct-ui/core/Button";

import AdvancedTableHeader from "../../components/AdvancedTable/AdvancedTableHeader";
import { LoadingRowTable } from "./components/Loading";

import { useTableStyle } from "../../components/AdvancedTable/tableStyle";

import RowItem from "./components/Row";
import FormDialog from "./components/FormDialog";
import MetricCard from "../../components/MetricCard";
import MetricContainer from "../../components/MetricContainer";
import PATH_URL from "../../routers/path";
import FlexWrapper from "../../components/FlexWrapper";
import SearchInput from "../../components/@setproduct-ui/core/Inputs/SearchInput";

function ViewTable(props) {
  const { tableState, tableAction, groups, classes } = props;

  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "GROUPS",
    },
    { id: "members", numeric: false, disablePadding: false, label: "" },
    { id: "actions", numeric: false, disablePadding: false, label: "" },
  ];
  console.log(groups);
  const TableRows = () => {
    const offset = tableState.page * tableState.rowsPerPage;
    const limit = offset + tableState.rowsPerPage;
    return tableAction
      .sorting(
        groups,
        tableAction.getComparator(tableState.order, tableState.orderBy)
      )
      .slice(offset, limit)
      .map((group, index) => (
        <RowItem key={`row-item-${group.id}`} group={group} {...props} />
      ));
  };

  return (
    <TableContainer className={styles.container}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={"medium"}
        aria-label="enhanced table"
        stickyHeader
      >
        <AdvancedTableHeader
          classes={classes}
          order={tableState.order}
          orderBy={tableState.orderBy}
          onSort={(array, comparator) => tableAction.sorting(array, comparator)}
          rowCount={groups.length}
          columns={columns}
          selectionCounter={0}
          onSelect={() => console.log("selected")}
        />
        <TableBody>
          {props.loadingPage ? <LoadingRowTable /> : <TableRows />}
        </TableBody>
      </Table>
      <Paper className={classes.pagination} elevation={0}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 30]}
          component="div"
          count={groups.length}
          rowsPerPage={tableState.rowsPerPage}
          labelDisplayedRows={() => "rows"}
          labelRowsPerPage={"Show"}
          page={tableState.page}
          onChangePage={(e, page) => tableAction.changePage(e, page)}
          onChangeRowsPerPage={(e) => tableAction.changeRowsPerPage(e)}
        />
      </Paper>
    </TableContainer>
  );

  // return (
  //   <>
  //   <Card className={styles.cardTable}>
  //     <Box display="flex" justifyContent="space-between" alignItems="center">
  //       <CardHeader
  //         title="Document Groups"
  //         classes={{
  //           title: styles.cardHeaderTitleTable,
  //         }}
  //         className={styles.cardHeaderTable}
  //       ></CardHeader>

  //       <Box style={{ paddingRight: 20 }}>
  //         <OutlinedInput
  //           ref={elm}
  //           placeholder="Search"
  //           endAdornment={
  //             <SearchIcon
  //               className={styles.searchBoxIcon}
  //               onClick={eventOnSearch}
  //             />
  //           }
  //           style={{ marginRight: 20 }}
  //           onKeyUp={eventOnKeyUp}
  //           classes={{
  //             input: styles.searchBoxInput,
  //             notchedOutline: styles.searchBoxOutline,
  //           }}
  //         />

  //         <Button
  //           className={styles.mainButton}
  //           onClick={props.eventFormOpen}
  //           disableElevation
  //         >
  //           Add New Group
  //         </Button>
  //       </Box>
  //     </Box>
  //     <CardContent className={styles.cardContentTable}>
  //       <TableContainer className={styles.tableContainer}>
  //         <Table
  //           className={classes.table}
  //           aria-labelledby="tableTitle"
  //           size={"medium"}
  //           aria-label="enhanced table"
  //         >
  //           <AdvancedTableHeader
  //             classes={classes}
  //             onSelect={props.eventSelectAll}
  //             selectionCounter={0}
  //             order={tableState.order}
  //             orderBy={tableState.orderBy}
  //             onSort={(array, comparator) =>
  //               tableAction.sorting(array, comparator)
  //             }
  //             rowCount={groups.length}
  //             columns={columns}
  //             selectionCounter={0}
  //             style={{ display: "block" }}
  //           />

  //           <TableBody className={styles.tbody}>
  //             {generateRow(props)}
  //           </TableBody>
  //         </Table>

  //         <AdvancedTablePagination
  //           classes={paginationStyle}
  //           rowCounter={groups.length}
  //           page={tableState.page}
  //           rowsPerPageOptions={[10, 25, 30]}
  //           rowsPerPage={tableState.rowsPerPage}
  //           onChangePage={(e, newPage) => tableAction.changePage(e, newPage)}
  //           onChangeRowsPerPage={(e) => tableAction.changeRowsPerPage(e)}
  //         />
  //       </TableContainer>
  //     </CardContent>
  //   </Card>
  //  </>
  // );
}

function View(props) {
  const { groups, action, tableState, tableAction, members } = props;

  const classes = useTableStyle();

  const [formOpen, setFormOpen] = React.useState(null);
  const [selectedList, setSelectedList] = React.useState([]);
  const [currentList, setCurrentList] = React.useState(groups);

  const ref = { value: 0 };

  const eventFormOpen = (e) => {
    action.clearSelectedObject();
    setFormOpen(e.target);
  };

  const eventOnCloseForm = () => {
    setFormOpen(null);
  };

  const eventOnSaveForm = async (group) => {
    if (props.loadingCreate) return false;

    if (group.id) return action.updateGroup(group);
    return action.createDocumentGroup(group);
  };

  React.useEffect(() => {
    setCurrentList(props.groups);
  }, [props.groups]);

  const addMember = async (member, group) => {
    return action.addMemberToGroup(member, group);
  };

  const removeMember = async (member, group) => {
    return action.removeMemberFromGroup(member, group);
  };

  const eventOnSearch = (target) => {
    let items = props.documentGroup.groups;
    tableAction.changePage(null, 0);
    if (target.value) {
      items = props.documentGroup.groups.filter((item) => {
        const name = item.name.toLowerCase();
        if (name.indexOf(target.value) > -1) return item;
      });
    }
    setCurrentList(items);
  };

  return (
    <div className={classes.root}>
      <Button
        type="default"
        view="filled"
        color="primary"
        dense={false}
        text="Back"
        icon="arrow-left"
        onClick={() => props.history.push(PATH_URL.USERS)}
      />
      <MetricContainer>
        <MetricCard
          title="DOCUMENTS"
          number={32}
          description="All Documents uploaded"
          iconBackgroundColor="var(--green50)"
          loading={props.loadingPage}
          icon="document"
        />
        <MetricCard
          title="GROUPS"
          number={groups.length}
          description="Number of groups"
          iconBackgroundColor="var(--green50)"
          loading={props.loadingPage}
          icon="folder-close"
        />
        <MetricCard
          title="AVG.DOCS"
          number={groups.length}
          description="Avg docs per groups"
          iconBackgroundColor="var(--green50)"
          loading={props.loadingPage}
          icon="percentage"
        />
        <MetricCard loading={props.loadingPage} />
        <MetricCard loading={props.loadingPage} />
      </MetricContainer>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <h1 className={styles.tableTitle}>Document Groups</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              view="outlined"
              color="primary"
              onInput={(e) => eventOnSearch(e.target)}
              placeholder="Search"
              className={styles.searchInput}
            />
            <Button
              view="filled"
              color="primary"
              text="Add New Group"
              loading={props.loadingPage || props.loadingCreate}
              disabled={false}
              onClick={eventFormOpen}
              className={styles.mainButton}
              style={{ background: "var(--blue70)", boxShadow: "none" }}
            />
          </FlexWrapper>
        </div>
        <ViewTable
          {...props}
          groups={currentList}
          classes={classes}
          eventFormOpen={eventFormOpen}
          setCurrentList={setCurrentList}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          counterRef={ref}
        />

        <FormDialog
          formOpen={Boolean(formOpen)}
          onSave={eventOnSaveForm}
          onClose={eventOnCloseForm}
          members={props.documents}
          group={props.documentGroup.group}
          addMemberCallback={addMember}
          removeMemberCallback={removeMember}
          loadingCreate={props.loadingCreate || props.loadingUpdate}
        />
      </div>
    </div>
  );
}

export default View;
