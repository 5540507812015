import { actionType } from "../reducers/table";

export function TableAction(propDispatch) {
  const requestSort = (event, property) => {
    return propDispatch((dispatch) => {
      dispatch({
        type: actionType.SetOrderAndOrderBy,
        property,
      });
    });
  };

  const sorting = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const changePage = (event, newPage) => {
    return propDispatch((dispatch) => {
      dispatch({
        type: actionType.SetPage,
        payload: newPage,
      });
    });
  };

  const changeRowsPerPage = (event) => {
    return propDispatch((dispatch) => {
      dispatch({
        type: actionType.SetRowsPerPageAndPage,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    });
  };

  return Object.freeze({
    requestSort,
    sorting,
    getComparator,
    changePage,
    changeRowsPerPage,
  });
}
