import React from "react";
import { Box, TableCell, TableRow } from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LoadingTable = (props) => {
  let { number } = props;
  number = number || 1;
  let rows = [];
  for (let i = 0; i < number; i++) {
    rows.push(
      <TableRow key={`skeleton-item-${i + 1}`}>
        <TableCell>
          <Skeleton width={20} height={20} circle={true} />
        </TableCell>
        <TableCell>
              <SkeletonTheme color="#eee" highlightColor="#eee">
                <Skeleton width={300} height={20} />
              </SkeletonTheme>
        </TableCell>
        <TableCell>
          <SkeletonTheme color="#eee" highlightColor="#eee">
            <Skeleton width={200} height={20} />
          </SkeletonTheme>
        </TableCell>
        <TableCell>
          <SkeletonTheme color="#eee" highlightColor="#eee">
            <Skeleton width={100} height={20} />
          </SkeletonTheme>
        </TableCell>
        <TableCell>
          <SkeletonTheme color="#eee" highlightColor="#eee">
            <Skeleton width={100} height={20} />
          </SkeletonTheme>
        </TableCell>
        <TableCell>
          <SkeletonTheme color="#eee" highlightColor="#eee">
            <Skeleton width={100} height={20} />
          </SkeletonTheme>
        </TableCell>
        <TableCell>
          <SkeletonTheme color="#eee" highlightColor="#eee">
            <Skeleton width={100} height={20} />
          </SkeletonTheme>
        </TableCell>
      </TableRow>
    );
  }

  return rows;
};
