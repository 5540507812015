import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { replace } from "connected-react-router";
import SplashScreen from "../../Pages/SplashScreen";
import connectedAuthWrapper from "redux-auth-wrapper/connectedAuthWrapper";
import { compose } from "redux";
import PATH_URL from "../../routers/path";

const locationHelper = locationHelperBuilder({});

const UserIsAuthenticatedDefaults = {
  authenticatingSelector: ({ firebase: { auth, isInitializing }, loading }) => !auth.isLoaded || isInitializing === true || loading["LOGIN"] || loading["REGISTER"] || loading["LOGOUT"],
  authenticatedSelector: ({ firebase: { auth }, loading }) => auth.isLoaded && !auth.isEmpty && !loading["LOGIN"] && !loading["REGISTER"] && !loading["LOGOUT"],
  wrapperDisplayName: 'UserIsAuthenticated'
}

export const UserIsAuthenticated = connectedAuthWrapper(UserIsAuthenticatedDefaults);

export const UserIsAuthenticatedRedir = connectedRouterRedirect({
  ...UserIsAuthenticatedDefaults,
  AuthenticatingComponent: SplashScreen,
  redirectPath:"/a/login"
})

const UserIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatingSelector: ({ firebase: { auth } }) => !auth.isLoaded,
  authenticatedSelector: ({ firebase: { auth } }) => auth.isLoaded && auth.isEmpty,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const UserIsNotAuthenticated = connectedAuthWrapper(UserIsNotAuthenticatedDefaults)

export const UserIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...UserIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/m/',
  allowRedirectBack: false,
})

export const VerifiedEmailUserRedir = connectedRouterRedirect({
  wrapperDisplayName: "UnverifiedEmailUser",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.UNVERIFIED_EMAIL;
  },
  authenticatedSelector: ({ firebase: { auth } }) => auth.emailVerified,
  redirectAction: replace
});

export const UnverifiedEmailUserRedir = connectedRouterRedirect({
  wrapperDisplayName: "UnverifiedEmailUser",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.DASHBOARD;
  },
  authenticatedSelector: ({ firebase: { auth } }) => !auth.emailVerified,
  redirectAction: replace
});

export const UserIsAdminRedir = connectedRouterRedirect({
  wrapperDisplayName: "UserIsAdmin",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    const { block_status } = state.userProfile;
    const { emailVerified } = state.firebase.auth;
    if (!emailVerified) return PATH_URL.UNVERIFIED_EMAIL;
    if (block_status) return PATH_URL.WAITING_APPROVAL;
    return locationHelper.getRedirectQueryParam(ownProps) || "/";
  },
  authenticatedSelector: ({ firebase: { auth }, userProfile }) =>
    auth.emailVerified && userProfile.role === "admin",
  redirectAction: replace
});

export const UserApprovedRedir = connectedRouterRedirect({
  wrapperDisplayName: "UserApproved",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.WAITING_APPROVAL;
  },
  authenticatedSelector: ({ firebase: { auth }, userProfile }) => !userProfile.block_status,
  redirectAction: replace
});

export const UserWaitingApprovalRedir = connectedRouterRedirect({
  wrapperDisplayName: "UserWaitingApproval",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.DASHBOARD;
  },
  authenticatedSelector: ({ firebase: { auth }, userProfile }) => userProfile.block_status,
  redirectAction: replace
});

export const UserIsAnnotatorRedir = connectedRouterRedirect({
  wrapperDisplayName: "UserIsAnnotator",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    const { block_status } = state.userProfile;
    const { emailVerified } = state.firebase.auth;
    if (!emailVerified) return PATH_URL.UNVERIFIED_EMAIL;
    if (block_status) return PATH_URL.WAITING_APPROVAL;
    return locationHelper.getRedirectQueryParam(ownProps) || "/";
  },
  authenticatedSelector: ({ firebase: { auth }, userProfile }) =>
    auth.emailVerified &&
    userProfile.role === "annotator" &&
    !userProfile.block_status,
  redirectAction: replace
});

export const UserIsReviewerRedir = connectedRouterRedirect({
  wrapperDisplayName: "UserIsReviewer",
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    const { block_status } = state.userProfile;
    const { emailVerified } = state.firebase.auth;
    if (!emailVerified) return PATH_URL.UNVERIFIED_EMAIL;
    if (block_status) return PATH_URL.WAITING_APPROVAL;
    return locationHelper.getRedirectQueryParam(ownProps) || "/";
  },
  authenticatedSelector: ({ firebase: { auth }, userProfile }) =>
    auth.emailVerified &&
    userProfile.role === "reviewer" &&
    !userProfile.block_status,
  redirectAction: replace
});

export const UnverifiedEmailUser = compose(
  UserIsAuthenticatedRedir,
  UnverifiedEmailUserRedir
);

export const UserIsApproved = compose(
  UserIsAuthenticatedRedir,
  VerifiedEmailUserRedir,
  UserApprovedRedir
);

export const UserIsAdmin = compose(
  UserIsAuthenticatedRedir,
  UserIsAdminRedir
);

export const UserIsAnnotator = compose(
  UserIsAuthenticatedRedir,
  UserIsAnnotatorRedir
);

export const UserIsReviewer = compose(
  UserIsAuthenticatedRedir,
  UserIsReviewerRedir
);

export const UserWaitingApproval = compose(
  UserIsAuthenticatedRedir,
  UserWaitingApprovalRedir
);
