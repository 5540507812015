import React from 'react';
import { Icon } from '@blueprintjs/core';
import { Controller } from 'react-hook-form';
import TextInput from '../../components/FormAnnotation/TextInput';
import InputSuggestion from '../../components/FormAnnotation/InputSuggestion';
import SelectInput from '../../components/FormAnnotation/SelectInput';
import DateInput from '../../components/FormAnnotation/DateInput';
import { isFieldPersonName, getIconName } from '../../utils/annotation';
import { useFormContext } from 'react-hook-form';
import styles from './Style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormulatedVariableField } from '../../redux/actions/annotation';

export default function Input({ type, data }) {
  const dispatch = useDispatch();
  const { register, getValues, setValue, control } = useFormContext();
  const {
    fieldName,
    defaultValue,
    handleFocus,
    handleRemoveBoundary,
    placeholder,
    isFocused,
    isReadOnly,
    isRejected,
    dataType,
  } = data;
  const currentData = getValues(fieldName) || defaultValue;
  const selectedVariable = useSelector(
    ({ annotation }) => annotation.selectedSection
  );
  const formulatedField = useSelector(
    ({ annotation }) => annotation.formulatedField
  );
  const handleFocusWrapper = (e) => handleFocus(e, type);
  const handleStoreFormula = (fieldName, formula) => {
    dispatch(
      storeFormulatedVariableField(selectedVariable, fieldName, formula)
    );
  };
  const handleGetFormula = (fieldName) =>
    formulatedField[selectedVariable][fieldName];
  const renderTextInput = () =>
    isFieldPersonName(dataType, fieldName) ? (
      <Controller
        control={control}
        name={fieldName}
        defaultValue={currentData || ''}
        render={({ onChange, onBlur, value }) => (
          <InputSuggestion fieldName={fieldName} text={value}>
            <TextInput
              type="text"
              ref={register}
              fieldName={fieldName}
              currentData={currentData}
              handleFocusWrapper={handleFocusWrapper}
              onChange={onChange}
              isReadOnly={isReadOnly}
              placeholder={placeholder}
              isFocused={isFocused}
              isRejected={isRejected}
            />
          </InputSuggestion>
        )}
      />
    ) : (
      <TextInput
        type="text"
        ref={register}
        fieldName={fieldName}
        currentData={currentData}
        handleFocusWrapper={handleFocusWrapper}
        isReadOnly={isReadOnly}
        placeholder={placeholder}
        isFocused={isFocused}
        isRejected={isRejected}
      />
    );

  switch (type) {
    case 'text-basic':
      return (
        <>
          <Icon
            icon={getIconName(type)}
            iconSize={16}
            className={styles.input_icon}
          />
          {renderTextInput()}
        </>
      );
    case 'date-basic':
      return (
        <>
          <Icon
            icon={getIconName(type)}
            iconSize={16}
            className={styles.input_icon}
          />
          <DateInput
            control={control}
            fieldName={fieldName}
            currentData={currentData}
            handleFocusWrapper={handleFocusWrapper}
            isReadOnly={isReadOnly}
            placeholder={placeholder}
            isRejected={isRejected}
            isFocused={isFocused}
            setValue={setValue}
            handleStoreFormula={handleStoreFormula}
          />
        </>
      );
    case 'number-basic':
      return (
        <>
          <Icon
            icon={getIconName(type)}
            iconSize={16}
            className={styles.input_icon}
          />
          <TextInput
            type="text"
            ref={register({
              // to validate valid number
              validate: (value) =>
                value === '' ||
                /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value),
            })}
            setValue={setValue}
            fieldName={fieldName}
            currentData={currentData}
            handleFocusWrapper={handleFocusWrapper}
            handleStoreFormula={handleStoreFormula}
            handleGetFormula={handleGetFormula}
            isReadOnly={isReadOnly}
            placeholder={placeholder}
            isFocused={isFocused}
            isRejected={isRejected}
            formulaEnabled
          />
        </>
      );
    case 'enum':
      return (
        <>
          <Icon
            icon={getIconName(type)}
            iconSize={16}
            className={styles.input_icon}
          />
          <SelectInput
            ref={register}
            fieldName={fieldName}
            currentData={currentData}
            handleFocusWrapper={handleFocus}
            handleRemoveBoundary={handleRemoveBoundary}
            isReadOnly={isReadOnly}
            isRejected={isRejected}
            options={data.attr}
          />
        </>
      );
    default:
      return (
        <>
          <Icon
            icon={getIconName(type)}
            iconSize={16}
            className={styles.input_icon}
          />
          <TextInput
            type="text"
            ref={register}
            fieldName={fieldName}
            currentData={currentData}
            handleFocusWrapper={handleFocusWrapper}
            isReadOnly={isReadOnly}
            placeholder={placeholder}
            isFocused={isFocused}
            isRejected={isRejected}
          />
        </>
      );
  }
}
