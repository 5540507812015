import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { Done, DoneAll, Clear as Cross } from "@material-ui/icons";
import FlexWrapper from "../../components/FlexWrapper";
import Heading from "../../components/Heading";
import {
  setSelectedSection,
} from "../../redux/actions/annotation";
import { createLoadingSelector } from "../../redux/api/loading";

function AnnotationSection(props) {
  const dispatch = useDispatch();
  const sections = useSelector(state => state.annotation.sections);
  const type = useSelector(state => state.annotation.type);
  const annotatorId = useSelector(state => state.annotation.annotator.id);
  const selectedSection = useSelector(
    state => state.annotation.selectedSection
  );
  const selectedBoundingBox = useSelector(
    state => state.annotation.selectedBoundingBox
  );
  const selectedPage = useSelector(state => state.annotation.selectedPage);
  const selectedChapter = useSelector(
    state => state.annotation.selectedChapter
  );
  const chapterList = useSelector(state => state.annotation.chapterList);
  const loadingChapterList = useSelector(state =>
    createLoadingSelector(["GET_CHAPTER_DETAIL", "GET_CHAPTER_LIST_OPTION"])(
      state
    )
  );

  return (
    <>
      <Heading text={type} style={{ marginLeft: 10 }} />
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        padding="10px"
        style={{ fontSize: 12 }}
      >
        <div
          style={{
            maxHeight: "25vh",
            overflowY: "auto"
          }}
        >
          {Object.keys(sections).map(sectionId => (
            <FlexWrapper
              key={sectionId}
              justifyContent="space-between"
              padding="5px"
              margin="0 0 2px 0"
              style={{
                cursor: "pointer",
                background:
                  selectedSection === sectionId ? "var(--grey10)" : "",
                borderRadius: 3
              }}
              onClick={() => dispatch(setSelectedSection(sectionId))}
            >
              <FlexWrapper>
                <Icon
                  icon="folder-close"
                  iconSize={16}
                  style={{
                    color: "var(--grey50)",
                    marginRight: 10
                  }}
                />
                <h4 style={{ margin: 0 }}>{sections[sectionId].name}</h4>
              </FlexWrapper>
              {sections[sectionId].annotation.id && (
                <FlexWrapper justifyContent="space-between">
                  {sections[sectionId].annotation.status === 3 && (
                    <DoneAll
                      style={{
                        fontSize: 18,
                        color: "var(--green50)"
                      }}
                    />
                  )}
                  {sections[sectionId].annotation.status === 4 && (
                    <Cross
                      style={{
                        fontSize: 18,
                        color: "var(--red50)"
                      }}
                    />
                  )}
                  {sections[sectionId].annotation.status === 2 && (
                    <Done style={{ fontSize: 18, color: "var(--green50)" }} />
                  )}
                </FlexWrapper>
              )}
            </FlexWrapper>
          ))}
        </div>
      </FlexWrapper>
      {selectedPage[selectedSection] && selectedBoundingBox[selectedSection] && (
        <>
          <Heading
            text="Selected Boundaries"
            style={{ marginTop: 10, marginLeft: 10 }}
          />
          <FlexWrapper
            flexFlow="column"
            justifyContent="flex-start"
            alignItems="stretch"
            padding="10px"
            style={{ fontSize: 12 }}
          >
            {selectedPage[selectedSection][annotatorId].length ||
            selectedBoundingBox[selectedSection][annotatorId].length ? (
              <>
                <div
                  style={{
                    maxHeight: "25vh",
                    overflowY: "auto"
                  }}
                >
                  {selectedPage[selectedSection][annotatorId].map(
                    (page, index) => (
                      <FlexWrapper
                        key={index}
                        justifyContent="space-between"
                        padding="0 5px"
                        margin="0 0 10px 0"
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <h4
                          style={{
                            margin: 0,
                            color: "var(--grey50)"
                          }}
                          onClick={() =>
                            props.handleClickBoundary(page.pageNumber)
                          }
                        >
                          {page.id}
                        </h4>
                      </FlexWrapper>
                    )
                  )}
                  {selectedBoundingBox[selectedSection][annotatorId].map(
                    (node, index) => (
                      <FlexWrapper
                        key={index}
                        justifyContent="space-between"
                        padding="0 5px"
                        margin="0 0 10px 0"
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <h4
                          style={{ margin: 0, color: "var(--grey50)" }}
                          onClick={() =>
                            props.handleClickBoundary(node.pageNumber)
                          }
                        >
                          {node.id}
                        </h4>
                      </FlexWrapper>
                    )
                  )}
                </div>
              </>
            ) : (
              <span style={{ marginLeft: 5, color: "var(--grey50)" }}>
                No selected boundaries
              </span>
            )}
            {type === "SUBCHAPTER" &&
            (selectedPage[selectedSection][annotatorId].length ||
              selectedBoundingBox[selectedSection][annotatorId].length) ? (
              <>
                <label
                  htmlFor="chapterLocation"
                  style={{
                    marginTop: 20,
                    display: "block",
                    fontFamily: "var(--inter)",
                    fontSize: 12,
                    color: "#656565",
                    fontWeight: 500,
                    textTransform: "uppercase"
                  }}
                >
                  Chapter Location
                </label>
                <div style={{ margin: "10px 0" }}>
                  {loadingChapterList ? (
                    "Loading..."
                  ) : (
                    <h5
                      style={{
                        border: "1px solid var(--grey20)",
                        borderRadius: 5,
                        padding: 10,
                        fontSize: 12,
                        fontFamily: "var(--inter)",
                        color: "var(--grey60)"
                      }}
                    >
                      {chapterList.length
                        ? chapterList.find(
                            chapter =>
                              chapter.value === selectedChapter[selectedSection]
                          ).label
                        : ""}
                    </h5>
                  )}
                </div>
              </>
            ) : null}
          </FlexWrapper>
        </>
      )}
    </>
  );
}

export default AnnotationSection;
