import { apiRequest } from "../utils/apiRequest";

import { actionType } from "../reducers/annotatorTeam";
import {
  AnnotatorGroupEntity,
  AnnotatorTeamEntity,
} from "../../Pages/AnnotatorsTeam/entities";

const AnnotatorFaker = {
  group: (number) => {
    const items = [];

    for (let i = 0; i < number; i++) {
      let group = new AnnotatorGroupEntity({
        _id: i + 1,
        name: `Group Entity ${i + 1}`,
        thumb: "icon",
      });

      let teams = AnnotatorFaker.team(3);

      group.members = teams;
      group.totalMember = teams.length;
      group.registeredIds = [1, 2, 3];

      items.push(group);
    }
  },

  team: (number) => {
    const items = [];
    for (let i = 0; i < number; i++) {
      let name = Math.random().toString(36).substring(7);

      let team = new AnnotatorTeamEntity({
        id: i + 1,
        name,
        email: `${name}@local.host`,
        thumb: "https://placeimg.com/80/80/people",
      });

      items.push(team);
    }
    return items;
  },
};

/**
 * Initialin action
 *
 * I pass `dispatch` as argument, so by this approach
 * I don't need to repeat pass-in the `dispatch` function in all function parameters
 * futhermore, our `view` going to be more clean. No need to much lines just for import function
 * as long as the variable described what's the behaviour should be
 *
 * @param {function} propDispatch
 */
export function Action(propDispatch) {
  // Loading Frist
  const initData = async () => {
    return propDispatch(async (dispatch) => {
      const results = await Promise.all([
        apiRequest(
          dispatch,
          actionType.LoadListAnnotator,
          "get",
          "tasks/person/annotator"
        ),

        apiRequest(
          dispatch,
          actionType.LoadListGroupAnnotator,
          "get",
          "groups/annotator"
        ),
      ]);

      const members = __extractTeam(results[0]);
      const groups = __extractGroup(results[1]);

      dispatch({
        type: actionType.InitData,
        members,
        groups,
      });

      return true;
    });
  };

  // I using double underscorse to flag that the
  // function would never call from the outside of context of Action function
  const __extractGroup = (result) => {
    let payload = [];

    if (result.type === `${actionType.LoadListGroupAnnotator}_SUCCESS`) {
      payload = result.payload.map((item) => {
        let group = new AnnotatorGroupEntity(item);

        let members = group.members.map(
          (member) => new AnnotatorTeamEntity(member)
        );

        group.members = members;
        group.totalMember = members.length;
        group.registeredIds = members.map((item) => item.id);

        return group;
      });
    }

    return payload;
  };

  const __extractTeam = (result) => {
    let payload = [];

    if (result.type === `${actionType.LoadListAnnotator}_SUCCESS`) {
      payload = result.payload.list.map(
        (item) => new AnnotatorTeamEntity(item)
      );
    }
    return payload;
  };

  /**
   * Create new Group with default name `Group Team`
   */
  const createGroup = async () => {
    return propDispatch(async (dispatch) => {
      let name = "Group Team";
      let result = await apiRequest(
        dispatch,
        actionType.CreateGroup,
        "post",
        "groups/annotator",
        { name, members: [] }
      );

      if (result.type !== `${actionType.CreateGroup}_SUCCESS`) {
        return false;
      }

      result = await apiRequest(
        dispatch,
        actionType.LoadListGroupAnnotator,
        "get",
        "groups/annotator"
      );

      let groups = __extractGroup(result);
      dispatch({
        type: actionType.ReplaceGroupList,
        payload: groups,
      });
    });
  };

  const deleteGroup = async (group) => {
    return propDispatch(async (dispatch) => {
      let result = await apiRequest(
        dispatch,
        actionType.DeleteGroup,
        "delete",
        `groups/${group.id}`
      );

      result = await apiRequest(
        dispatch,
        actionType.LoadListGroupAnnotator,
        "get",
        "groups/annotator"
      );

      let groups = __extractGroup(result);
      dispatch({
        type: actionType.ReplaceGroupList,
        payload: groups,
      });
    });
  };

  /**
   * Rename Group
   * @param {AnnotatorGroupEntity} group
   */

  const renameGroup = async (group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectMemberAndGroup,
        group,
      });

      return apiRequest(
        dispatch,
        actionType.UpdateGroup,
        "put",
        `groups/${group.id}`,
        { name: group.name }
      );
    });
  };

  /**
   * Add member to selected group
   *
   * @param {AnnotatorTeamEntity} member
   * @param {AnnotatorGroupEntity} group
   */
  const addMemberToGroup = async (member, group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectMemberAndGroup,
        member,
        group,
      });

      let url = `/groups/member/add/${group.id}`;
      return apiRequest(
        dispatch,
        actionType.AddMemberToGroup,
        "post",
        url,
        member.asData()
      );
    });
  };

  /**
   * remove member from selected group
   *
   * @param {AnnotatorTeamEntity} member
   * @param {AnnotatorGroupEntity} group
   */
  const removeMemberFromGroup = async (member, group) => {
    return propDispatch(async (dispatch) => {
      dispatch({
        type: actionType.SelectRemovedMemberAndGroup,
        group,
        member,
      });

      let url = `/groups/member/delete/${group.id}`;
      let result = await apiRequest(
        dispatch,
        actionType.RemoveMemberFromGroup,
        "post",
        url,
        member.asData()
      );

      dispatch({ type: actionType.ClearSelectedObject });
      return result;
    });
  };

  return Object.freeze({
    initData,
    createGroup,
    renameGroup,
    deleteGroup,
    addMemberToGroup,
    removeMemberFromGroup,
  });
}
