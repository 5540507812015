import React, { useState } from "react";
import FlexWrapper from "../../components/FlexWrapper";
import { Typography } from "@material-ui/core";
import { Icon } from "@blueprintjs/core";
import { Skeleton } from "@material-ui/lab";
import { useDispatch  } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

const useStyles = makeStyles({
  root: {
    height: 116,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function AnnotationTable(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  return (
    <div style={{ overflowY: "scroll", height: "75vh" }}>
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          fontSize: 12,
          padding: "10px",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <FlexWrapper>
          <Icon
            icon="join-table"
            iconSize={16}
            style={{
              color: "var(--grey50)",
              marginRight: 10,
            }}
          />
          Tables
        </FlexWrapper>
        <FlexWrapper>
          <Skeleton
            variant="circle"
            animation={false}
            width={30}
            height={30}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "3px",
            }}
          >
            {props.dataAnnotationTable.length}
          </Skeleton>
        </FlexWrapper>
      </FlexWrapper>
      <React.Fragment>
        <TreeView
          className={classes.root}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {props.dataAnnotationTable.map((item, id) => (
            <TreeItem
              nodeId={id + "1"}
              label={
                <FlexWrapper
                  flexFlow="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{
                    fontSize: 12,
                    padding: "10px",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  {" "}
               
                  <Typography style={{ width: "100px", fontSize: "12px" }}>
                  <Icon
                    icon="th"
                    iconSize={16}
                    style={{
                      color: "var(--grey50)",
                      marginRight: 10,
                    }}
                  />   {item.title}
                  </Typography>
                </FlexWrapper>
              }
            >
              <TreeItem
                nodeId={id + "2"}
                label={
                  <div>
                    <span>
                      selected ({item.coordinates[0].x?.toFixed(2)},{item.coordinates[0].y?.toFixed(2)}),(
                      {item.coordinates[1].x?.toFixed(2)},{item.coordinates[1].y?.toFixed(2)})
                    </span>
                  </div>
                }
              />
            </TreeItem>
          ))}
        </TreeView>
      </React.Fragment>
    </div>
  );
}

export default AnnotationTable;
