import { pick } from 'lodash';

const initialState = {
  documents: {
    summary: {
      all_docs: 0,
      new_docs: 0,
      queue: 0,
      in_progress: 0,
      ready: 0,
    },
    filter: {
      q: '',
      type: '',
      status: '',
    },
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
    },
    list: [],
  },
  upload: {
    files: {},
    status: {},
    progress: {},
    error: {},
  },
  documentsError: '',
  ocrDocument: '',
  ocrDocumentMultiple: '',
  selectedDocuments: [],
};

export const actionType = {
  LIST_DOCUMENT_SUCCESS: 'LIST_DOCUMENT_SUCCESS',
  GET_DOCUMENT_SUMMARY_SUCCESS: 'GET_DOCUMENT_SUMMARY_SUCCESS',
  CHANGE_DOCUMENT_FILTER: 'CHANGE_DOCUMENT_FILTER',
  CHANGE_PAGE_DATA_ON_DOCUMENT: 'CHANGE_PAGE_DATA_ON_DOCUMENT',
  SELECT_DOCUMENT: 'SELECT_DOCUMENT',
  LIST_DOCUMENT_FAILURE: 'LIST_DOCUMENT_FAILURE',
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LIST_DOCUMENT_SUCCESS: {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          pageData,
          list,
        },
      };
    }
    case actionType.GET_DOCUMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          summary: action.payload,
        },
      };
    case actionType.LIST_DOCUMENT_FAILURE:
      return {
        ...state,
        documentsError: action.payload,
      };
    case actionType.CHANGE_DOCUMENT_FILTER:
      return {
        ...state,
        documents: {
          ...state.documents,
          filter: { ...state.documents.filter, ...action.payload },
          pageData: { ...state.documents.pageData, currentPageNumber: 1 }, // reset page
        },
      };
    case actionType.CHANGE_PAGE_DATA_ON_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          pageData: { ...state.documents.pageData, ...action.payload },
        },
      };
    case actionType.SELECT_DOCUMENT:
      return {
        ...state,
        selectedDocuments: action.payload,
      };
    case 'STORE_FILE_UPLOAD': {
      const { name, file } = action.payload;
      return {
        ...state,
        upload: {
          ...state.upload,
          files: {
            ...state.upload.files,
            [name]: file,
          },
          status: {
            ...state.upload.status,
            [name]: 'init',
          },
        },
      };
    }
    case 'CHANGE_UPLOAD_PROGRESS': {
      const { name, progress } = action.payload;
      return {
        ...state,
        upload: {
          ...state.upload,
          progress: {
            ...state.upload.progress,
            [name]: progress,
          },
          status: {
            ...state.upload.status,
            [name]: 'uploading',
          },
        },
      };
    }
    case 'SET_UPLOAD_COMPLETE': {
      const name = action.payload;
      return {
        ...state,
        upload: {
          ...state.upload,
          status: {
            ...state.upload.status,
            [name]: 'complete',
          },
        },
      };
    }
    case 'REMOVE_UPLOAD': {
      const names = action.payload;
      const picked = Object.keys(state.upload.files).filter(
        (name) => !names.includes(name)
      );
      return {
        ...state,
        upload: {
          files: pick(state.upload.files, picked),
          progress: pick(state.upload.progress, picked),
          status: pick(state.upload.status, picked),
          error: pick(state.upload.error, picked),
        },
      };
    }
    case 'SET_UPLOAD_ERROR': {
      const { name, error } = action.payload;
      return {
        ...state,
        upload: {
          ...state.upload,
          error: {
            ...state.upload.error,
            [name]: error,
          },
          status: {
            ...state.upload.status,
            [name]: 'error',
          },
        },
      };
    }
    default:
      return state;
  }
};

export default documentsReducer;
