import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import FlexWrapper from "../../components/FlexWrapper";
import Button from "../../components/@setproduct-ui/core/Button";
import withAuthentication from "../../utils/withAuthentication";
import useViewport from "../../hooks/useViewport"
import { verifyEmail } from "../../redux/actions/auth";
import { createLoadingSelector } from "../../redux/api/loading";
import { createErrorMessageSelector } from "../../redux/api/error";
import AuthLayout from "../../layouts/AuthLayout";

const loadingSelector = createLoadingSelector(["VERIFY_EMAIL"]);
const errorMessageSelector = createErrorMessageSelector(["VERIFY_EMAIL"]);

function VerifyEmail({
  firebase,
  urlParams,
  errorMessage,
  loading,
  verifyEmail,
  push
}) {
  const { oobCode: actionCode } = urlParams;
  const { screenWidth } = useViewport()

  useEffect(() => {
    verifyEmail(actionCode);
  }, [actionCode, verifyEmail]);

  return (
    <AuthLayout>

      <div
        style={{
          minWidth: screenWidth > 900 ? "500px" : 0,
          width: screenWidth > 900 ? "40%" : "100%",
        }}
      >
        <FlexWrapper flexFlow="column" padding="0 70px" width="100%">
          {loading ? (
            <h2>Please wait...</h2>
          ) : errorMessage ? (
            <>
              <h1>Error occured</h1>
              <p>{errorMessage}</p>
            </>
          ) : (
            <>
              <h1 style={{ marginTop: 0 }}>Email Verification</h1>
              <h3 style={{ color: "var(--gray70)" }}>
                Your email is succesfully verified.
              </h3>
              <Button
                type="default"
                view="outlined"
                color="default"
                text="Back to Home"
                fill={true}
                style={{ marginTop: 20 }}
                onClick={() => push("/")}
              />
            </>
          )}
        </FlexWrapper>
      </div>
    </AuthLayout>
  );
}

const mapStateToProps = state => ({
  urlParams: state.router.location.query,
  loading: loadingSelector(state),
  errorMessage: errorMessageSelector(state)
});

const mapDispatchToProps = dispatch => ({
  verifyEmail: actionCode => dispatch(verifyEmail(actionCode)),
  push: path => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(VerifyEmail));
