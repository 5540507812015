import React from 'react';
import {makeStyles} from '@material-ui/core';

export default makeStyles({
    
    radius: {
        borderRadius: "24px !important"
    },

    removeBackdrop: {
        "& .MuiBackdrop-root": {
            background: "none",
        }
    },

    spaces: {
        padding: "30px",
    },

    formGroup: {
        display: 'flex',
        justifyContent: "flex-start",
        flexDirection: "column",
        marginBottom: 16,
    },

    formLabel: {
        marginBottom: 7,
        fontSize: 14,
    },
    formInput: {
        padding: 10,
    },
    searchSelect: {
        "& fieldset": {
            border: 0,
        }
    },

    fromTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    formTitle: {
        fontSize: 24, 
        fontWeight: "bold", 
        marginBottom: 16
    },

    btnWrapper: {
        marginTop: 32
    },
    btnCancel: {
        fontSize: 14,
        marginRight: 10,
        background: "none!important",
        boxShadow: "none!important",
        color: "var(--grey50)!important",
    },
    btnSave: {
        background: "var(--blue70)!important",
        fontSize: 14,
        color: "#ffffff"
    }


})