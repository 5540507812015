import { apiRequest } from '../utils/apiRequest';

const DEFAULT_LIMIT = 50;

export const storeSearchTerm = (searchTerm) => ({
  type: 'STORE_SEARCH_TERM',
  payload: searchTerm,
});

export const resetSearchResults = () => ({
  type: 'RESET_SEARCH_RESULTS',
});

export const searchDocument =
  (searchTerm, mode = 'init') =>
  async (dispatch, getState) => {
    const { page, searchTerm: previousSearchTerm } = getState().documentSearch;
    const { documentId } = getState().annotation;
    const actions = `SEARCH_DOCUMENT_${mode === 'init' ? 'INIT' : 'APPEND'}`;
    const searchKeyword = mode === 'init' ? searchTerm : previousSearchTerm;
    if (mode === 'init') dispatch(storeSearchTerm(searchTerm));
    await apiRequest(dispatch, actions, 'get', '/search', {
      q: searchKeyword,
      documentId,
      page: mode === 'init' ? 1 : page + 1,
      limit: DEFAULT_LIMIT,
    });
  };
