const companiesSuccess = authData => {
    return {
      type: "COMPANIES_ACCEPT_SUCCESS",
      payload: authData
    };
  };
  
  const companiesError = () => {
    return {
      type : "COMPANIES_ACCEPT_FAILURE"
    }
  }

  const companiesCreateSuccess = authData => {
    return {
      type: "COMPANIES_CREATE_SUCCESS",
      payload: authData
    };
  };
  
  const companiesCreateError = authData => {
    return {
      type : "COMPANIES_CREATE_FAILURE",
      payload: authData
    }
  }

  const dispatchType = {
    companiesSuccess,
    companiesError,
    companiesCreateSuccess,
    companiesCreateError
  };
  
  export default dispatchType;
