import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledHeading = styled.h5`
  font-family: var(--inter);
  font-size: 12px;
  margin: 10px 5px;
  font-weight: 500;
  color: #656565;
  text-transform: uppercase;
`;

function Heading(props) {
  return <StyledHeading style={props.style}>{props.text}</StyledHeading>;
}

Heading.propTypes = {
  text: PropTypes.string.isRequired
};

export default Heading;
