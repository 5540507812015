import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import SearchInput from '../../components/SearchInput';
import Chips from '../../components/Chips';
import DynamicAvatar from '../../components/DynamicAvatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import MetricCard from '../../components/MetricCard';
import MetricContainer from '../../components/MetricContainer';
import PATH_URL from '../../routers/path';
import styles from '../../components/AdvancedTable/table.module.css';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import { getComparator, stableSort } from '../../components/AdvancedTable';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import {
  getTaskList,
  getTaskSummary,
  changeTaskFilter,
  changePageDataOnTask,
} from '../../redux/actions/admin';

import { createLoadingSelector } from '../../redux/api/loading';

const loadingSelectorQuery = createLoadingSelector(['GET_TASK_LIST']);

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'TASK NAME',
  },
  {
    id: 'annotator.profile.name',
    numeric: false,
    disablePadding: false,
    label: 'REVIEWER',
  },
  { id: 'dtype', numeric: false, disablePadding: false, label: 'TYPE' },
  {
    id: 'document.filename',
    numeric: false,
    disablePadding: false,
    label: 'DOCUMENT',
  },
  {
    id: 'section.name',
    numeric: false,
    disablePadding: false,
    label: 'SECTION',
  },
  {
    id: 'createdAt',
    numeric: false,
    type: 'date',
    disablePadding: false,
    label: 'CREATED AT',
  },
  {
    id: 'updatedAt',
    numeric: false,
    type: 'date',
    disablePadding: false,
    label: 'LAST UPDATE',
  },
  { id: 'progress', numeric: false, disablePadding: false, label: 'PROGRESS' },
  { id: 'status', numeric: false, disablePadding: false, label: 'STATUS' },
];
const CHIPS_COLORS = {
  'to do': 'default',
  ongoing: 'warning',
  'under review': 'primary',
  rejected: 'danger',
  accepted: 'success',
  'need revision': 'danger-alt',
};
function AnnotatorDashboard(props) {
  const dispatch = useDispatch();
  const classes = useTableStyle();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('task_title');
  const [orderType, setOrderType] = useState('string');
  const {
    filter,
    summary,
    pageData,
    list: tasks,
  } = useSelector(({ admin }) => admin.tasks);
  const loading = useSelector(loadingSelectorQuery);
  const loadingSummary = useSelector(
    createLoadingSelector(['GET_TASK_SUMMARY'])
  );

  useEffect(() => {
    dispatch(getTaskSummary());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getTaskList({
        ...filter,
        annotator: filter.annotator.value,
        reviewer: filter.reviewer.value,
        limit: pageData.limit,
        page: pageData.currentPageNumber,
      })
    );
  }, [dispatch, filter, pageData.limit, pageData.currentPageNumber]);

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setOrderType(type);
  };

  const handleChangePage = (event, newPage) => {
    // material-ui using zero-based page
    dispatch(changePageDataOnTask({ currentPageNumber: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      changePageDataOnTask({
        limit: parseInt(event.target.value, 10),
        currentPageNumber: 1,
      })
    );
  };

  const getSelectedType = (type) => {
    const uppercased = type.toUpperCase();
    if (type) return { value: uppercased, label: uppercased };
    return { value: '', label: 'Type' };
  };

  const getSelectedStatus = (status) => {
    if (status) return { value: status, label: status.toUpperCase() };
    return { value: '', label: 'Status' };
  };

  const getSectionName = (dtype, section) => {
    if (dtype === 'VARIABLE') return '-';
    if (dtype === 'SUBCHAPTER') return section.name;
    if (section.groupName) return `${section.name} | ${section.groupName}`;
    return section.name;
  };

  return (
    <div className={pageLayoutStyle.root}>
      <MetricContainer>
        <MetricCard
          title="ALL TASKS"
          number={summary.all_tasks}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
        <MetricCard
          title="TO DO"
          number={summary.todo}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="trending-down"
          loading={loadingSummary}
        />
        <MetricCard
          title="IN PROGRESS"
          number={summary.in_progress}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="trending-down"
          loading={loadingSummary}
        />
        <MetricCard
          title="ON REVIEW"
          number={summary.on_review}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
        <MetricCard
          title="REJECTED"
          number={summary.rejected}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
        <MetricCard
          title="DONE"
          number={summary.done}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
      </MetricContainer>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <h1 className={styles.tableTitle}>All Tasks</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              defaultValue={filter.q}
              onApplySearch={(value) => {
                dispatch(changeTaskFilter({ q: value }));
              }}
              placeholder="Search"
              style={{ width: 250, marginRight: 10 }}
            />
            <Select
              name="type"
              value={getSelectedType(filter.type)}
              isSearchable={false}
              defaultValue={{ value: '', label: 'Type' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ type: selected.value }))
              }
              options={[
                { value: '', label: 'Type' },
                {
                  value: 'CHAPTER',
                  label: 'CHAPTER',
                },
                {
                  value: 'SUBCHAPTER',
                  label: 'SUBCHAPTER',
                },
                {
                  value: 'VARIABLE',
                  label: 'VARIABLE',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Select
              name="status"
              value={getSelectedStatus(filter.status)}
              isSearchable={false}
              defaultValue={{ value: '', label: 'Status' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ status: selected.value }))
              }
              options={[
                { value: '', label: 'Status' },
                {
                  value: 'todo',
                  label: 'TODO',
                },
                {
                  value: 'ongoing',
                  label: 'ONGOING',
                },
                {
                  value: 'under review',
                  label: 'UNDER REVIEW',
                },
                {
                  value: 'rejected',
                  label: 'REJECTED',
                },
                {
                  value: 'accepted',
                  label: 'ACCEPTED',
                },
                {
                  value: 'need revision',
                  label: 'NEED REVISION',
                },
              ]}
              styles={tableSelectStyle}
            />
          </FlexWrapper>
        </div>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <AdvancedTableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              onSort={handleRequestSort}
              rowCount={tasks.length}
              columns={columns}
              selectionCounter={0}
            />
            <TableBody>
              {loading ? (
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index} role="checkbox">
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        className={classes.cell}
                        style={{ paddingLeft: 15 }}
                      >
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Skeleton
                            circle={true}
                            height={30}
                            width={30}
                            style={{ marginRight: 10 }}
                          />
                          <Skeleton width={80} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : tasks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} className={classes.emptyResult}>
                    No results found
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(tasks, getComparator(order, orderBy, orderType)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow key={row.id} hover tabIndex={-1}>
                        <TableCell
                          component="th"
                          id={labelId}
                          className={classes.cell}
                          style={{ paddingLeft: 15 }}
                        >
                          <Link
                            to={`${PATH_URL.ANNOTATION.replace(
                              ':task_id',
                              row.id
                            )}`}
                          >
                            {row.name}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {row.reviewer ? (
                              <>
                                <DynamicAvatar
                                  photoUrl={row.reviewer.photoUrl}
                                  name={row.reviewer.profile.name}
                                  color="var(--green50)"
                                  style={{ marginRight: 5 }}
                                />
                                <span className={classes.nameCell}>
                                  {row.reviewer.profile.name}
                                </span>
                              </>
                            ) : (
                              <span
                                style={{
                                  color: 'var(--red60)',
                                  fontWeight: 600,
                                }}
                              >
                                Missing Reviewer
                              </span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.dtype}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.document.filename}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {getSectionName(row.dtype, row.section)}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.createdAt &&
                            format(new Date(row.createdAt), 'LLL d, yyyy')}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.updatedAt &&
                            format(new Date(row.updatedAt), 'LLL d, yyyy')}
                        </TableCell>
                        <TableCell className={classes.cell}>-</TableCell>
                        <TableCell className={classes.cell}>
                          <Chips
                            color={
                              CHIPS_COLORS[row.status.toLowerCase()] ||
                              'default'
                            }
                            text={row.status.toUpperCase()}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 30, 75, 100]}
            component="div"
            count={pageData.totalItems}
            rowsPerPage={pageData.limit}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            // material-ui using zero-based page
            page={pageData.currentPageNumber - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}

export default AnnotatorDashboard;
