const TYPES = {
  annual_report: { id: "annual_report", label: "Annual Report" },
  sustainability_report: {
    id: "sustainability_report",
    label: "Sustainability Report",
  },
  document: { id: "document", label: "Document" },
};

export const DOCUMENT_TYPES = Object.values(TYPES);

export class GroupDocumentEntity {
  constructor({ _id, name, thumb, type }) {
    this.id = _id;
    this.name = name;
    this.totalMember = 0;
    this.thumb = thumb || "";

    type = type || DOCUMENT_TYPES[2].id;
    this.groupType = type.toLowerCase();

    this.groupTypeLabel = TYPES[this.groupType].label;
    this.registeredIds = [];
    this.members = [];
  }
}

export class DocumentEntity {
  constructor({
    id,
    url,
    filename,
    filesize,
    numPages,
    uploadedDate,
    ocr,
    metadata,
    thumb,
  }) {
    this.id = id;
    this.url = url;
    this.name = filename;
    this.size = filesize;
    this.documentType =
      metadata && metadata.type
        ? metadata.type.toLowerCase()
        : DOCUMENT_TYPES[2].id;
    this.pages = numPages;
    this.uploadedAt = uploadedDate;
    this.metadata = metadata;
    this.ocr = ocr;
    this.thumb = thumb || "icon";
  }

  asData() {
    return {
      id: this.id,
      url: this.url,
      filename: this.name,
      filesize: this.size,
      numPages: this.pages,
      uploadedDate: this.uploadedAt,
      metadata: this.metadata,
      ocr: this.ocr,
    };
  }
}
