import React from 'react';
import { parse, resolve } from '@estilles/expression-parser';
import styles from './Style.module.css';

const TextInput = React.forwardRef((props, ref) => {
  const {
    type,
    fieldName,
    currentData,
    handleFocusWrapper,
    isReadOnly,
    handleRemoveBoundary,
    placeholder,
    onChange,
    isRejected,
    isFocused,
    setValue,
    formulaEnabled,
    handleStoreFormula,
    handleGetFormula,
  } = props;
  const calculate = (value) => {
    const operationStr = value.slice(1);
    try {
      const result = resolve(parse(operationStr));
      handleStoreFormula(fieldName, value);
      setValue(fieldName, result);
    } catch (error) {
      // handle error
    }
  };
  return (
    <input
      type={type}
      ref={ref}
      name={fieldName}
      defaultValue={currentData}
      onFocus={(event) => {
        handleFocusWrapper(event);
        if (!formulaEnabled) return;
        const formula = handleGetFormula(fieldName);
        if (formula) {
          setValue(fieldName, formula);
        }
      }}
      onBlur={(event) => {
        if (!formulaEnabled) return;
        const { value } = event.target;
        if (value && value[0] === '=') {
          calculate(value);
        } else if (value === '') {
          handleStoreFormula(fieldName, '');
        }
      }}
      onClick={isReadOnly ? () => {} : handleRemoveBoundary}
      placeholder={placeholder}
      className={`${styles.variable_text_input} ${
        isFocused ? styles.input_focused : ''
      }`}
      onChange={onChange}
      onKeyDown={(event) => {
        // press enter
        if (!formulaEnabled) return;
        const { value } = event.target;
        if (event.keyCode === 13 && value && value[0] === '=') {
          calculate(value);
          event.preventDefault();
        }
      }}
      autoComplete="off"
      readOnly={isReadOnly}
      style={{
        background: isRejected ? 'var(--red20)' : 'none',
      }}
    />
  );
});

export default TextInput;
