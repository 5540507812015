import React from 'react';
import routes from '../../routers/routes';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useSelector } from 'react-redux';
import PATH_URL from '../../routers/path';

const generateRoutesComponents = (routes) =>
  routes.map((route) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

export default function PrivateRoutesWithSidebar(props) {
  const role = useSelector((state) => state.userProfile.role);
  const locationPath = useSelector((state) => state.router.location.pathname);
  const roleRoutes = routes.authenticated[role][0].routes;

  const routeSelector = roleRoutes.filter((item) =>
    matchPath(locationPath, {
      path: item.path,
      exact: true,
    })
  );
  const title = routeSelector.length > 0 ? routeSelector[0].title : 'Dashboard';
  const routesComponents = generateRoutesComponents(roleRoutes);
  let location = useLocation();
  return (
    <DashboardLayout title={title}>
      <Switch>
        {routesComponents}
        <Route>
          <Redirect to={roleRoutes[0].path} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
}
