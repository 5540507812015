import firebase from 'firebase/app';
import 'firebase/auth';

import React from "react";
import { render } from "react-dom";

import { AppContainer } from "react-hot-loader";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { PersistGate } from 'redux-persist/integration/react';

import "normalize.css/normalize.css";
import 'antd/dist/antd.css';
import "@blueprintjs/core/lib/css/blueprint.css";
// import "./components/@setproduct-ui/styles/setproduct.css";
import "./style/index.css";

import App from "./App";
import configureStore from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { persistStore } from 'redux-persist'
import { setupAxios } from "./utils/api";

// import './style/App.scss';

const { REACT_APP_FIREBASE_CONFIG } = process.env;

const rrfConfig = {
  // userProfile: "users"
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const fbConfig = JSON.parse(REACT_APP_FIREBASE_CONFIG);

// Initialize firebase instance
firebase.initializeApp(fbConfig);

const store = configureStore();
const persistor = persistStore(store)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance // <- needed if using firestore
};

setupAxios();

// Wrap the rendering in a function:
function IndexApp() {
  return (
    <AppContainer>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
              <App />
            </ReactReduxFirebaseProvider>
        </Provider>
      </PersistGate>
    </AppContainer>
  );
}

render(<IndexApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
