import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Button, Paper, TablePagination } from '@material-ui/core';
import CustomSelect from './CustomSelect';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Skeleton from 'react-loading-skeleton';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import {
  getTemplateList,
  getVariableList,
  getCompanyTicker,
  getDocumentsYear,
  getAnnotationsOverview,
  getVariableStructure,
  setAnnotationOverviewFilter,
  getAnnotationsOverviewStats,
} from '../../redux/actions/admin';
import { createLoadingSelector } from '../../redux/api/loading';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import styles from '../../components/AdvancedTable/table.module.css';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import FlexWrapper from '../../components/FlexWrapper';
import SearchInput from '../../components/SearchInput';
import TableRowCustom from './TableRowCustom';
import { get, upperFirst, findIndex } from 'lodash';
import toCapitalCase from 'to-capital-case';

const columns = [
  {
    id: 'button',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'ticker',
    numeric: false,
    disablePadding: false,
    label: 'TICKER',
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: false,
    label: 'COMPANY NAME',
  },
  {
    id: 'year',
    numeric: true,
    disablePadding: false,
    label: 'YEAR',
  },
  { id: 'anotator', numeric: false, disablePadding: false, label: 'ANOTATOR' },
  { id: 'reviewer', numeric: false, disablePadding: false, label: 'REVIEWER' },
];

const OverviewVariable = () => {
  const dispatch = useDispatch();
  const [limitPerpage, setLimitPerPage] = useState(10);
  const [keySearch, setKeysearch] = useState('');
  const classes = useStyles();
  const classesTab = useTableStyle();
  const alreadySelectedTemplate = useRef(false);
  const onGetDataOverview = useSelector(
    createLoadingSelector(['GET_ANNOTATION_OVERVIEW'])
  );
  const onGetDataSelectOptions = useSelector(
    createLoadingSelector([
      'GET_TEMPLATE_LIST',
      'GET_VARIABLE_LIST',
      'GET_COMPANY_TICKER',
      'GET_DOCUMENTS_YEAR',
    ])
  );
  const templates = useSelector((state) => state.admin.templates);
  const variables = useSelector((state) => state.admin.variables);
  const variableStructures = useSelector(
    (state) => state.admin.variableStructures
  );
  const companyTicker = useSelector((state) => state.admin.companyTicker);
  const documentsYear = useSelector((state) => state.admin.documentsYear);
  const variableOverview = useSelector((state) => state.admin.variableOverview);
  const [selectedFilter, setSelectedFilter] = useState({
    template: { value: '', label: '' },
    section: { value: '', label: '' },
    variable: { value: '', label: '' },
    field1: { value: '', label: '' },
    field2: { value: '', label: '' },
    ticker: { value: '*', label: 'All companies' },
    year: documentsYear[0] ? [documentsYear[0], documentsYear[0]] : [0, 0],
  });
  const {
    template: selectedTemplate,
    section: selectedSection,
    variable: selectedVariable,
    field1: selectedField1,
    field2: selectedField2,
    ticker: selectedTicker,
    year: selectedYear,
  } = selectedFilter;
  const selectFilter = (field, selected) =>
    setSelectedFilter((prev) => ({ ...prev, [field]: selected }));
  const setSelectedTemplate = (selected) => selectFilter('template', selected);
  const setSelectedSection = (selected) => selectFilter('section', selected);
  const setSelectedVariable = (selected) => selectFilter('variable', selected);
  const setSelectedField1 = (selected) => selectFilter('field1', selected);
  const setSelectedField2 = (selected) => selectFilter('field2', selected);
  const setSelectedTicker = (selected) => selectFilter('ticker', selected);
  const setSelectedYear = (selected) => selectFilter('year', selected);
  const optionsTemplates = useMemo(
    () => templates.map((v) => ({ value: v.sectionId, label: v.name })),
    [templates]
  );
  const optionsSection = useMemo(() => {
    if (selectedTemplate.value) {
      const template = templates.filter(
        (v) => v.sectionId === selectedTemplate.value
      )[0];
      if (template && template.children?.length > 0) {
        return template.children.map((v) => ({
          value: v.sectionId,
          label: v.name,
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [selectedTemplate, templates]);
  const optionVariable = useMemo(() => {
    const selectedSection = selectedFilter.section;
    if (
      variables[selectedSection.value] &&
      variables[selectedSection.value].length > 0
    ) {
      return variables[selectedSection.value].map((v) => ({
        label: v.name,
        value: v._id,
      }));
    } else {
      return [];
    }
  }, [variables, selectedFilter.section]);
  const optionsField1 = useMemo(() => {
    if (optionVariable.length > 0 && selectedVariable.value) {
      const variableName = toCamelCase(selectedVariable.label);
      const structure = variableStructures[variableName];
      const firstKey = Object.keys(structure)[0];
      if (structure[firstKey] === null) {
        return [{ label: upperFirst(firstKey), value: firstKey }];
      }
      return Object.keys(structure[firstKey]).map((field) => ({
        label: toCapitalCase(field),
        value: field,
      }));
    }
    return [];
  }, [optionVariable, selectedVariable, variableStructures]);
  const optionsField2 = useMemo(() => {
    if (optionsField1.length > 0 && selectedField1.value) {
      const variableName = toCamelCase(selectedVariable.label);
      const firstKey = Object.keys(variableStructures[variableName])[0];
      const fieldName = selectedField1.value;
      const structure = get(
        variableStructures,
        `${variableName}.${firstKey}.${fieldName}`,
        {}
      );
      if (structure) {
        return Object.keys(structure).map((field) => ({
          label: toCapitalCase(field),
          value: field,
        }));
      }
      return [];
    }
    return [];
  }, [optionsField1, selectedVariable, selectedField1, variableStructures]);
  const optionsYear = useMemo(() => {
    if (documentsYear.length > 0) {
      return documentsYear.map((year) => ({
        label: year,
        value: year,
      }));
    }
    return [];
  }, [documentsYear]);
  const optionsTicker = useMemo(() => {
    if (companyTicker.length > 0) {
      return [
        {
          value: '*',
          label: 'All Companies',
        },
        ...companyTicker.map((v) => ({
          value: v,
          label: v,
        })),
      ];
    }
    return [
      {
        value: '*',
        label: 'All Companies',
      },
    ];
  }, [companyTicker]);
  const getOverview = useCallback(
    (page = 1, limit, searchTerm) => {
      dispatch(
        getAnnotationsOverview({
          page,
          limit,
          q: searchTerm,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      getAnnotationsOverview({ page: 1, limit: limitPerpage, q: keySearch })
    );
  }, [dispatch, keySearch, limitPerpage]);

  useEffect(() => {
    dispatch(getAnnotationsOverviewStats(keySearch));
  }, [dispatch, keySearch]);

  useEffect(() => {
    if (!alreadySelectedTemplate.current && optionsTemplates.length > 0) {
      setSelectedFilter((prev) => ({ ...prev, template: optionsTemplates[0] }));
      alreadySelectedTemplate.current = true;
    }
  }, [optionsTemplates]);

  useEffect(() => {
    let section;
    if (optionsSection.length > 0) {
      section = optionsSection[0];
    } else {
      section = { value: '', label: '' };
    }
    setSelectedFilter((prev) => ({ ...prev, section }));
  }, [optionsSection]);

  useEffect(() => {
    if (selectedSection.value) {
      dispatch(getVariableList(selectedSection.value));
    }
  }, [dispatch, selectedSection]);

  useEffect(() => {
    let variable;
    if (optionVariable.length > 0) {
      variable = optionVariable[0];
    } else {
      variable = { value: '', label: '' };
    }
    setSelectedFilter((prev) => ({ ...prev, variable }));
    dispatch(setAnnotationOverviewFilter({ refId: variable.value }));
  }, [optionVariable, dispatch]);

  useEffect(() => {
    let field1;
    if (optionsField1.length > 0) {
      field1 = optionsField1[0];
    } else {
      field1 = {
        value: '',
        label: '',
      };
    }
    setSelectedFilter((prev) => ({ ...prev, field1 }));
    dispatch(
      setAnnotationOverviewFilter({ field1: findIndex(optionsField1, field1) })
    );
  }, [optionsField1, dispatch]);

  useEffect(() => {
    let field2;
    if (optionsField2.length > 0) {
      field2 = optionsField2[0];
    } else {
      field2 = {
        value: '',
        label: '',
      };
    }
    setSelectedFilter((prev) => ({ ...prev, field2 }));
    dispatch(
      setAnnotationOverviewFilter({ field2: findIndex(optionsField2, field2) })
    );
  }, [optionsField2, dispatch]);

  useEffect(() => {
    if (documentsYear.length > 0) {
      setSelectedFilter((prev) => ({
        ...prev,
        year: [documentsYear[0], documentsYear[0]],
      }));
      dispatch(
        setAnnotationOverviewFilter({
          yearRange: [documentsYear[0], documentsYear[0]],
        })
      );
    }
  }, [documentsYear, dispatch]);

  useEffect(() => {
    dispatch(getTemplateList());
    dispatch(getCompanyTicker());
    dispatch(getDocumentsYear());
    dispatch(getVariableStructure());
  }, [dispatch]);

  return (
    <div className={pageLayoutStyle.root}>
      <Grid container className={classes.gridSection}>
        <Grid item xs={4}>
          <p className={classes.label}>Select Template</p>
          <CustomSelect
            name="template"
            value={selectedTemplate}
            options={optionsTemplates}
            onChange={(selected) => setSelectedTemplate(selected)}
            isLoading={onGetDataSelectOptions}
            disabled={!(optionsTemplates.length > 0)}
          />
        </Grid>
        <Grid item xs={4}>
          <p className={classes.label}>Select Year Range</p>
          <Grid container>
            <Grid item xs={6}>
              <CustomSelect
                name="start-year"
                value={{
                  value: selectedYear[0] || '',
                  label: selectedYear[0] || '',
                }}
                options={optionsYear.filter((v) => v.value <= selectedYear[1])}
                onChange={(selected) => {
                  const yearRange = [selected.value, selectedYear[1]];
                  setSelectedYear(yearRange);
                  dispatch(setAnnotationOverviewFilter({ yearRange }));
                }}
                disabled={!(optionsYear.length > 0)}
                isLoading={onGetDataSelectOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                name="end-year"
                value={{
                  value: selectedYear[1] || '',
                  label: selectedYear[1] || '',
                }}
                options={optionsYear.filter((v) => v.value >= selectedYear[0])}
                onChange={(selected) => {
                  const yearRange = [selectedYear[0], selected.value];
                  setSelectedYear(yearRange);
                  dispatch(setAnnotationOverviewFilter({ yearRange }));
                }}
                disabled={!(optionsYear.length > 0)}
                isLoading={onGetDataSelectOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <p className={classes.label}>Select Ticker</p>
          <CustomSelect
            name="ticker"
            value={selectedTicker}
            options={optionsTicker}
            onChange={(selected) => {
              setSelectedTicker(selected);
              dispatch(setAnnotationOverviewFilter({ ticker: selected.value }));
            }}
            disabled={!(optionsTicker.length > 0)}
            isLoading={onGetDataSelectOptions}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridSection}>
        <Grid item xs={3}>
          <p className={classes.label}>Select Section</p>
          <CustomSelect
            name="section"
            value={selectedSection}
            options={optionsSection}
            onChange={(selected) => setSelectedSection(selected)}
            isLoading={onGetDataSelectOptions}
            disabled={!(optionsSection.length > 0)}
          />
        </Grid>
        <Grid item xs={3}>
          <p className={classes.label}>Select Variable</p>
          <CustomSelect
            name="variable"
            value={selectedVariable}
            options={optionVariable}
            onChange={(selected) => {
              setSelectedVariable(selected);
              dispatch(setAnnotationOverviewFilter({ refId: selected.value }));
            }}
            isLoading={onGetDataSelectOptions}
            disabled={!(optionVariable.length > 0)}
          />
        </Grid>
        <Grid item xs={3}>
          <p className={classes.label}>Select Variable Detail 1</p>
          <CustomSelect
            name="variable-detail-1"
            value={selectedField1}
            options={optionsField1}
            onChange={(selected) => {
              setSelectedField1(selected);
              dispatch(
                setAnnotationOverviewFilter({
                  field1: findIndex(optionsField1, selected),
                })
              );
            }}
            isLoading={onGetDataSelectOptions}
            disabled={!(optionsField1.length > 0)}
          />
        </Grid>
        <Grid item xs={3}>
          <p className={classes.label}>Select Variable Detail 2</p>
          <CustomSelect
            name="variable-detail-2"
            value={selectedField2}
            options={optionsField2}
            onChange={(selected) => {
              setSelectedField2(selected);
              dispatch(
                setAnnotationOverviewFilter({
                  field2: findIndex(optionsField2, selected),
                })
              );
            }}
            disabled={!(optionsField2.length > 0)}
            isLoading={onGetDataSelectOptions}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonSubmit}
          disabled={onGetDataOverview}
          onClick={() => {
            getOverview();
            dispatch(getAnnotationsOverviewStats());
          }}
        >
          Submit
        </Button>
      </Box>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <FlexWrapper
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <p className={classes.textVariableInfo}>
              {`${variableOverview.stats.filled} of ${variableOverview.stats.total} documents filled`}
            </p>
            <SearchInput
              defaultValue={keySearch}
              onApplySearch={(value) => {
                setKeysearch(value);
                dispatch(setAnnotationOverviewFilter({ q: value }));
              }}
              placeholder="Search"
            />
          </FlexWrapper>
        </div>
        <TableContainer className={classesTab.overviewContainer}>
          <Table
            className={classesTab.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <AdvancedTableHeader
              classes={classesTab}
              columns={columns}
              selectionCounter={0}
              onSort={() => {}}
              order="asc"
              orderBy=""
              rowCount={5}
            />

            <TableBody>
              {onGetDataOverview &&
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index} role="checkbox">
                      <TableCell className={classes.checkbox}>
                        <Skeleton
                          height={20}
                          width={20}
                          style={{ marginLeft: 10 }}
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                    </TableRow>
                  ))}
              {!onGetDataOverview && variableOverview.list.length > 0 ? (
                variableOverview.list.map((v, i) => (
                  <TableRowCustom rowData={v} key={i} />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: 'center', color: 'var(--grey40)' }}
                  >
                    Empty result
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 3000]}
            component="div"
            count={variableOverview.totalItems}
            rowsPerPage={variableOverview.limit}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            page={variableOverview.currentPageNumber - 1}
            onChangePage={(e, page) => getOverview(page + 1)}
            onChangeRowsPerPage={(e) => {
              setLimitPerPage(e.target.value);
              getOverview(1, e.target.value);
            }}
          />
        </Paper>
      </div>
    </div>
  );
};
export default OverviewVariable;

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#fff',
    margin: '5px 0',
  },
  gridSection: {
    marginBottom: '10px',
  },
  textVariableInfo: {
    fontSize: '14px',
  },
  buttonSubmit: {
    fontWeight: 500,
    fontSize: '14px',
    textTransform: 'none',
    backgroundColor: '#4F91FF',
    padding: '8px 20px',
    marginBottom: '20px',
  },
  tableContainer: {
    maxHeight: 5000,
  },
}));

function toCamelCase(text) {
  let arrayText = text.toLowerCase().split(' ');
  arrayText = arrayText.map((itemText) => {
    if (arrayText[0] !== itemText) {
      itemText = itemText.split('');
      itemText[0] = itemText[0].toUpperCase();
      return itemText.join('');
    }
    return itemText;
  });
  return arrayText.join('');
}
