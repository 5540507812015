import React from "react";
import styled, { keyframes } from "styled-components";

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const ParentLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 100%;
  padding: 36px;
`;

export const Loading = styled.div`
  box-sizing: content-box; 
  width: 30px;
  height: 30px;
  border: 5px solid var(--blue70);
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spinning} 0.8s linear infinite;
`;

function Spinner() {
  return (
    <ParentLoading>
      <Loading />
    </ParentLoading>
  );
}

export default Spinner;
