import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import CustomDialog from '../../components/CustomDialog';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import styles from './Style.module.css';
import {
  getAvailableAnnotators,
  getAvailableReviewers,
  transferTasks,
} from '../../redux/actions/admin';
import { createLoadingSelector } from '../../redux/api/loading';

const loadingSelector = createLoadingSelector(['TRANSFER_TASKS']);

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '2.5rem',
  }),
};

function FormTransfer(props) {
  const {
    isOpen,
    getAvailableAnnotators,
    getAvailableReviewers,
    selectedTasks,
  } = props;
  useEffect(() => {
    if (isOpen) {
      getAvailableAnnotators();
      getAvailableReviewers();
    }
  }, [isOpen, getAvailableAnnotators, getAvailableReviewers]);

  return (
    <CustomDialog
      view="raised"
      title="Transfer Tasks"
      backdropOpacity={40}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {selectedTasks.length > 0 && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            annotator: {
              value: selectedTasks[0].annotator.id,
              label: selectedTasks[0].annotator.name,
            },
            reviewer: {
              value: selectedTasks[0].reviewer.id,
              label: selectedTasks[0].reviewer.name,
            },
          }}
          onSubmit={async (values, actions) => {
            const data = {
              ids: selectedTasks.map((task) => task.id),
              annotatorId: values.annotator.value,
              reviewerId: values.reviewer.value,
            };
            const isSuccess = await props.transferTasks(data, {
              refetchAll: false,
            });
            if (isSuccess) {
              props.onClose();
              props.onReset();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form
              style={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
              }}
            >
              <label htmlFor="annotators" className={styles.formLabel}>
                Tasks
              </label>
              <div
                style={{ overflowY: 'auto', maxHeight: 150, marginBottom: 16 }}
              >
                {selectedTasks.map((task, index) => (
                  <p
                    key={`task-item-${index}`}
                    style={{ fontSize: 14, marginLeft: 4 }}
                  >
                    {index + 1}. {task.document} - {task.type}
                  </p>
                ))}
              </div>
              <label htmlFor="annotators" className={styles.formLabel}>
                Assigned annotator
              </label>
              <Select
                name="annotator"
                value={values.annotator}
                placeholder=""
                options={props.annotators.map((annotator) => ({
                  value: annotator.id,
                  label: annotator.name,
                }))}
                onChange={(annotator) => setFieldValue('annotator', annotator)}
                className={styles.select}
                styles={customSelectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'black',
                  },
                })}
              />
              <label htmlFor="reviewer" className={styles.formLabel}>
                Assigned reviewer
              </label>
              <Select
                name="reviewer"
                defaultValue={values.reviewer}
                placeholder=""
                options={props.reviewers.map((reviewer) => ({
                  value: reviewer.id,
                  label: reviewer.name,
                }))}
                onChange={(selected) => setFieldValue('reviewer', selected)}
                className={styles.select}
                styles={customSelectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'black',
                  },
                })}
              />
              <FlexWrapper justifyContent="flex-end">
                <Button
                  view="flat"
                  color="default"
                  text="Cancel"
                  loading={isSubmitting || props.loading}
                  onClick={props.onClose}
                />
                <Button
                  view="filled"
                  color="primary"
                  text="Save"
                  style={{ marginLeft: 20 }}
                  loading={isSubmitting || props.loading}
                  onClick={handleSubmit}
                />
              </FlexWrapper>
            </Form>
          )}
        </Formik>
      )}
    </CustomDialog>
  );
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  annotators: state.admin.availableAnnotators,
  reviewers: state.admin.availableReviewers,
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableAnnotators: () => dispatch(getAvailableAnnotators()),
  getAvailableReviewers: () => dispatch(getAvailableReviewers()),
  transferTasks: (data, config) => dispatch(transferTasks(data, config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormTransfer);
