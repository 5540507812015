export const tableSelectStyle = {
    container: provided => ({
        ...provided,
        width: 150,
        marginRight: 15,
        zIndex: 9
    }),
    control: provided => ({
        ...provided,
        background: "var(--blue70)",
        borderColor: "var(--blue70)",
        height: 42
    }),
    singleValue: provided => ({
        ...provided,
        fontFamily: "var(--inter)",
        fontSize: 12,
        color: "#fff"
    }),
    indicatorSeparator: provided => ({
        ...provided,
        visibility: "hidden"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        color: "#fff"
    })
};