import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
`;

function FlexWrapper(props) {
  return (
    <Flex
      style={{
        height: props.height || "auto",
        width: props.width || "auto",
        flexFlow: props.flexFlow || "row",
        alignItems: props.alignItems || "center",
        justifyContent: props.justifyContent || "center",
        margin: props.margin || 0,
        padding: props.padding || 0,
        ...props.style
      }}
      onClick={props.onClick}
      className={props.className}
    >
      {props.children}
    </Flex>
  );
}

FlexWrapper.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexFlow: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

export default FlexWrapper;
