import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Wrapper from '../../components/BasicWrapper';
import { createLoadingSelector } from '../../redux/api/loading';
import styles from './style.module.css';
import { Icon } from '@blueprintjs/core';
import Accordion, {
  AccordionSummary,
  AccordionDetails,
} from '../../components/FormAnnotation/Accordion';
import Report from '../../components/FormAnnotation/Report';
import Note from '../../components/FormAnnotation/Note';

const loadingSelector = createLoadingSelector(['GET_TASK_NOTE']);

function TabNote(props) {
  const [note, setNote] = useState({
    id: null,
    text: '',
  });
  const [reports, setReports] = useState([]);
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const { note: reviewersNote, reports: reportList } = props.notes.reduce(
      (acc, note) => {
        if (note.type === 'REVIEW') {
          acc = {
            ...acc,
            note,
          };
        } else {
          acc = {
            ...acc,
            reports: acc.reports.concat(note),
          };
        }
        return acc;
      },
      { note: {}, reports: [] }
    );
    if (reviewersNote._id) {
      const { _id, text } = reviewersNote;
      setNote({ id: _id, text });
    }
    setReports(reportList);
  }, [props.notes]);
  return (
    <div
      role="tabpanel"
      hidden={props.tabIndex !== 1}
      id={`tabpanel-${1}`}
      aria-labelledby={`tab-${1}`}
    >
      {props.tabIndex === 1 && (
        <Wrapper width="100%" height="100%">
          <Accordion
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className={styles.accordion_title_container}>
                <span>Reviewer's Note</span>
                <Icon
                  icon={expanded === 'panel1' ? 'chevron-down' : 'chevron-up'}
                  iconSize={20}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                <Wrapper height="55vh" width="100%">
                  {props.loading ? (
                    'Loading...'
                  ) : (
                    <Note value={note.text} readOnly />
                  )}
                </Wrapper>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <div className={styles.accordion_title_container}>
                <span>Reports</span>
                <Icon
                  icon={expanded === 'panel2' ? 'chevron-down' : 'chevron-up'}
                  iconSize={20}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Report data={reports} />
            </AccordionDetails>
          </Accordion>
        </Wrapper>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  notes: state.annotation.notes,
});

export default connect(mapStateToProps, null)(TabNote);
