import React from "react";
import PropTypes from "prop-types";
import styles from "../../components/AdvancedTable/table.module.css";


import {
  Grid,
  Box,
  TableRow,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TablePagination,
  Paper,
  Typography,
  CardContent,
} from "@material-ui/core";

import Button from "../../components/@setproduct-ui/core/Button";
import AdvancedTableHeader from "../../components/AdvancedTable/AdvancedTableHeader";
import AdvancedTableSeparator from "../../components/AdvancedTable/AdvancedTableSeparator";
import AdvancedTablePagination from "../../components/AdvancedTable/AdvancedTablePagination";
import {Loading as PageLoading} from '../../components/Loading';

import RowItem from "./components/Row";
import MetricCard from "../../components/MetricCard";
import MetricContainer from "../../components/MetricContainer";
import { useTableStyle } from "../../components/AdvancedTable/tableStyle";
import FlexWrapper from "../../components/FlexWrapper";
import PATH_URL from "../../routers/path";
import SearchInput from "../../components/@setproduct-ui/core/Inputs/SearchInput";

function View(props) {
  const { pageLoading, action, tableAction, tableState } = props;

  const groups = props.annotatorTeam.groups;
  const [currentList, setCurrentList] = React.useState([]);

  React.useEffect(() => {
    setCurrentList(props.annotatorTeam.groups);
  }, [props.annotatorTeam.groups]);

  const classes = useTableStyle();
  const columns = [
    {
      id: "team",
      numeric: false,
      disablePadding: false,
      label: "TEAM"
    },
    { id: "members", numeric: false, disablePadding: false, label: "" },
    { id: "actions", numeric: false, disablePadding: false, label: "" }
  ];

  const TableRows = () => {
    const offset = tableState.page * tableState.rowsPerPage;
    const limit = offset + tableState.rowsPerPage;
    return tableAction
      .sorting(
        currentList,
        tableAction.getComparator(tableState.order, tableState.orderBy)
      )
      .slice(offset, limit)
      .map((group, index) => <RowItem key={`row-item-${group.id}`} group={group} {...props} />);
  };

   const eventOnSearch = (target) => {
    let items = props.annotatorTeam.groups;
    tableAction.changePage(null, 0);
    if (target.value) {
      items = props.annotatorTeam.groups.filter((item) => {
        const name = item.name.toLowerCase();
        if (name.indexOf(target.value) > -1) return item;
      });
    }
    setCurrentList(items);
  };

  


  const ViewTable = () => {
    return (
      
      <TableContainer className={styles.container}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
          stickyHeader
        >
          <AdvancedTableHeader
            classes={classes}
            order={tableState.order}
            orderBy={tableState.orderBy}
            onSort={(array, comparator) =>
              tableAction.sorting(array, comparator)
            }
            rowCount={groups.length}
            columns={columns}
            selectionCounter={0}
            onSelect={() => console.log('selected')}
          />
          <TableBody>
            <TableRows />
          </TableBody>
        </Table>
        <Paper className={classes.pagination} elevation={0}>
           <TablePagination
            rowsPerPageOptions={[10, 25, 30]}
            component="div"
            count={groups.length}
            rowsPerPage={tableState.rowsPerPage}
            labelDisplayedRows={() => "rows"}
            labelRowsPerPage={"Show"}
            page={tableState.page}
            onChangePage={(e, page) => tableAction.changePage(e, page)}
            onChangeRowsPerPage={e => tableAction.changeRowsPerPage(e)}
          />
        </Paper>
      </TableContainer>
      
    );
  };

  const eventBtnCreate = () => {
    action.createGroup()
  };

  return (
    <div className={classes.root}>
      <Button
        type='default'
        view='filled'
        color='primary'
        dense={false}
        text='Back'
        icon='arrow-left'
        onClick={() => props.history.push(PATH_URL.USERS)}
      />
      <MetricContainer>
        <MetricCard
          title="ANNOTATORS"
          number={0}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="person"
        />
        <MetricCard
          title="ACTIVE"
          number={0}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="confirm"
        />
        <MetricCard
          title="BLOCKED"
          number={0}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="cross"
        />
        <MetricCard
          title="TEAMS"
          number={0}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="people"
        />
        <MetricCard
          title="AVG. MEMBERS"
          number={0}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="pie-chart"
        />
      </MetricContainer>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <h1 className={styles.tableTitle}>All Users</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              view="outlined"
              color="primary"
              onInput={e => eventOnSearch(e.target)}
              placeholder="Search"
              className={styles.searchInput}
            />
            <Button
              view="filled"
              color="primary"
              text="Add New Team"
              loading={props.loadingPage || props.loadingCreate}
              disabled={false}
              onClick={eventBtnCreate}
              className={styles.mainButton}
              style={{ background: "var(--blue70)", boxShadow: "none" }}
            />
          </FlexWrapper>
        </div>
        <ViewTable />
      </div>
    </div>
  );
}

export default View;
