export function assignCallback(name, callback) {
  if (!window.__listenerCallbacksCollection) {
    window.__listenerCallbacksCollection = {};
  }
  window.__listenerCallbacksCollection[name] = callback;
}

export function bodyCallbackListener(name, target, callback) {
  assignCallback(name, callback);

  if (!window.__bodyListeners) window.__bodyListeners = {};
  if (!window.__bodyListeners[name]) {
    window.__bodyListeners[name] = true;

    document.body.addEventListener("click", (e) => {
      let parent = e.target;
      let found = false;

      while (parent && parent !== document.body) {
        parent = parent.parentNode;

        if (parent && parent.classList.contains(target)) {
          found = true;
        }
      }

      if (
        !found &&
        typeof window.__listenerCallbacksCollection[name] === "function"
      ) {
        window.__listenerCallbacksCollection[name]();
      }
    });
  }
}
