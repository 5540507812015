import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import { Typography, Box, Tabs, Tab } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import styles from './style.module.css';
import CardInformation from './components/CardInformation';
import TabVariable from './components/TabVariable';
import TabChapter from './components/TabChapter';
import TabSubChapter from './components/TabSubChapter';
import { getDetailDocument } from '../../redux/actions/documentDetail';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { createLoadingSelector } from '../../redux/api/loading';

function DocumentDetail(props) {
  const [activePanel, setActivePanel] = useState('1');
  const paramsPage = useParams();
  const dispatch = useDispatch();
  const detailDocument = useSelector((state) => state.documentDetail.detail);
  const loading = useSelector(createLoadingSelector(['GET_DETAIL_DOCUMENT']));
  const handleChange = (e, activeValue) => {
    setActivePanel(activeValue);
  };
  useEffect(() => {
    dispatch(getDetailDocument(paramsPage.id));
  }, [paramsPage.id, dispatch]);
  return (
    <div className={pageLayoutStyle.root}>
      <div className={styles.dashboardCards}>
        <div className={styles.headerDocument}>
          <Typography variant="h6" component="h1" className={styles.title}>
            {detailDocument.filename}
          </Typography>
          <Box display="flex">
            <CardInformation
              title={capitalize(
                `${
                  (detailDocument.metadata &&
                    detailDocument.metadata.type.split('_').join(' ')) ||
                  ''
                } (${
                  (detailDocument.metadata && detailDocument.metadata.year) ||
                  ''
                })`
              )}
              icon="document"
              loading={loading}
              style={{
                textTransform: 'capitalize',
              }}
            />
            <CardInformation
              title={
                detailDocument.metadata && detailDocument.metadata.companyName
              }
              icon="office"
              loading={loading}
            />
            <CardInformation
              title={
                (detailDocument.uploadedDate &&
                  format(
                    new Date(detailDocument.uploadedDate),
                    'd LLLL yyyy'
                  )) ||
                ''
              }
              icon="upload"
              loading={loading}
            />
          </Box>
        </div>
      </div>
      <div className={styles.taskContainer}>
        <div className={styles.taskContent}>
          <TabContext value={activePanel}>
            <Box width="100%">
              <Tabs
                value={activePanel}
                onChange={handleChange}
                indicatorColor="primary"
                classes={{
                  root: styles.tabsCustom,
                  indicator: styles.tabsIndicator,
                }}
              >
                <Tab
                  label="Chapter"
                  className={styles.customButton}
                  value="1"
                />
                <Tab
                  label="Sub Chapter"
                  className={styles.customButton}
                  value="2"
                />
                <Tab
                  label="Variable"
                  className={styles.customButton}
                  value="3"
                />
              </Tabs>
              <TabPanel value="1" className={styles.customPanel}>
                <TabChapter />
              </TabPanel>
              <TabPanel value="2" className={styles.customPanel}>
                <TabSubChapter />
              </TabPanel>
              <TabPanel value="3" className={styles.customPanel}>
                <TabVariable />
              </TabPanel>
            </Box>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default DocumentDetail;
