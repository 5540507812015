import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import styles from './Style.module.css';

export default function SearchInput(props) {
  const [value, setValue] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon
          icon="search"
          iconSize={16}
          style={{ position: 'absolute', left: 10, color: 'var(--grey30)' }}
        />
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search"
          className={styles.searchInput}
        />
        {value.length > 0 && (
          <Icon
            icon="cross"
            iconSize={16}
            onClick={() => {
              setValue('');
              props.onReset();
            }}
            className={styles.resetSearchButton}
          />
        )}
      </div>
    </form>
  );
}
