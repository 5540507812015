import React from 'react';
import CustomDialog from '../../components/CustomDialog';

export default function DialogDetailOCR({ isOpen, onClose, data }) {
  return (
    <CustomDialog
      view="raised"
      title="Detail Bulk OCR"
      backdropOpacity={40}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div style={{ marginBottom: 20 }}>
        <h5>Successfully submitted</h5>
        <div
          style={{
            marginTop: 8,
            maxHeight: 200,
            overflow: 'auto',
            fontSize: 12,
          }}
        >
          {data.success.length > 0 ? (
            data.success.map((documentName, index) => (
              <div
                key={documentName}
                style={{ display: 'flex', padding: '4px 0' }}
              >
                <span style={{ display: 'block', marginRight: 4 }}>
                  {index + 1}.
                </span>
                <span style={{ display: 'block' }}>{documentName}</span>
              </div>
            ))
          ) : (
            <span>No document is successfully submitted</span>
          )}
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        <h5>Failed to submit</h5>
        <div
          style={{
            marginTop: 8,
            maxHeight: 200,
            overflow: 'auto',
            fontSize: 12,
          }}
        >
          {data.fail.length > 0 ? (
            data.fail.map((document, index) => (
              <div key={document.id} style={{ marginBottom: 8 }}>
                <div style={{ display: 'flex', padding: '4px 0' }}>
                  <span style={{ display: 'block', marginRight: 4 }}>
                    {index + 1}.
                  </span>
                  <span style={{ display: 'block' }}>{document.id}</span>
                </div>
                <span style={{ color: 'var(--red70)', fontWeight: 500 }}>
                  Error: {document.message}
                </span>
              </div>
            ))
          ) : (
            <span>No document is failed to submit</span>
          )}
        </div>
      </div>
    </CustomDialog>
  );
}
