import React from "react";
import { connect } from "react-redux";
import withAuthentication from "../../utils/withAuthentication";
import Layout from "../../components/Layout";
import Button from "../../components/@setproduct-ui/core/Button";
import FlexWrapper from "../../components/FlexWrapper";
import { signOut } from "../../redux/actions/auth";

function UnverifiedEmailPage({ firebase, signOut }) {
  const handleLogout = () => {
    signOut();
  };

  const handleResendVerifyEmail = async () => {
    await firebase.auth().currentUser.sendEmailVerification();
  };

  return (
    <Layout>
      <FlexWrapper flexFlow="column" style={{ padding: 30, height: "100vh" }}>
        <h2 style={{ marginTop: 0 }}>
          Hello, {firebase.auth().currentUser.email}
        </h2>
        <p>Your email is unverified, please verify your email to continue.</p>
        <Button
          type="default"
          view="filled"
          color="default"
          text="Resend email verification link"
          style={{ background: "#60bcbc", color: "#fff" }}
          onClick={handleResendVerifyEmail}
        />
        <Button
          type="default"
          view="filled"
          color="default"
          text="Log Out"
          style={{ marginTop: 10, background: "#65b168", color: "#fff" }}
          onClick={handleLogout}
        />
      </FlexWrapper>
    </Layout>
  );
}

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOut())
});

export default connect(
  null,
  mapDispatchToProps
)(withAuthentication(UnverifiedEmailPage));
