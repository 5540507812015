import React from 'react';
import styles from './MetricCard.module.css';
import { Icon } from "@blueprintjs/core";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function MetricCard(props) {
    const { title, number, description, icon, iconBackgroundColor, loading } = props;
    return (
            <div className={styles.metricCard}>
                <div className={styles.metricCardContent}>
                    <SkeletonTheme color="#EDF3F6" highlightColor="#d3dadd">
                        <p className={styles.number}>{loading ? <Skeleton width={57} /> : number}</p>
                        <p className={styles.title}>{loading ? <Skeleton width={80} /> : title }</p>
                        <div className={styles.description} style={{ color: iconBackgroundColor}}>
                        {loading ? <Skeleton width={133} /> : (
                            <div>
                                <Icon icon={icon} iconSize={12} className={styles.logo} style={{ color: iconBackgroundColor }} />
                                <p>{description}</p>
                            </div>
                        )}
                        </div>
                    </SkeletonTheme>
                </div>
            </div>
    )
}
