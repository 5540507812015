import styled from "styled-components";

const HorizontalDivider = styled.hr`
  border: none;
  border-top: 1px dashed #d2d2d6;
  color: #b4b4bb;
  font-family: var(--inter);
  text-align: center;
  margin: 30px 0;

  &::after {
    background: #fff;
    font-weight: bold;
    content: "${props => props.text || ""}";
    padding: 0 4.5px;
    position: relative;
    top: -10px;
  }
`;

export default HorizontalDivider;
