import React from "react";

export default (props) => {
  const size = props.size || 30;
  const viewBox = props.viewBox || "0 0 30 30";
  const color = props.colors || '#B4B4BB'

  return (
    <span {...props}>
      <svg width={size} height={size} viewBox={viewBox} fill="none">
        <circle cx="15" cy="15" r="14" stroke={color} strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.7075 21.4325C21.28 20.4425 19.195 19.79 17.815 19.175C16.4275 18.56 16.6225 18.1925 16.57 17.6825C16.5625 17.615 16.5625 17.54 16.555 17.465C17.0275 17.045 17.4175 16.4675 17.6725 15.8C17.6725 15.8 17.6875 15.7625 17.6875 15.755C17.74 15.6125 17.785 15.4625 17.83 15.305C18.145 15.2375 18.3325 14.8925 18.4 14.57C18.475 14.4425 18.6175 14.12 18.5875 13.76C18.5425 13.295 18.355 13.0775 18.145 12.9875C18.145 12.9575 18.145 12.935 18.145 12.905C18.145 12.3125 18.0925 11.4575 17.98 10.895C17.9575 10.7375 17.92 10.58 17.875 10.43C17.6725 9.74 17.245 9.125 16.675 8.69C16.0825 8.24 15.25 8 14.5075 8C13.765 8 12.9325 8.24 12.34 8.69C11.77 9.125 11.3425 9.74 11.14 10.43C11.095 10.58 11.0575 10.7375 11.035 10.895C10.93 11.4575 10.87 12.3125 10.87 12.905C10.87 12.9275 10.87 12.95 10.87 12.98C10.6525 13.0625 10.4575 13.28 10.4125 13.76C10.3825 14.12 10.525 14.4425 10.6 14.57C10.675 14.9 10.8625 15.2525 11.1925 15.305C11.23 15.4625 11.2825 15.6125 11.335 15.755C11.335 15.7625 11.3425 15.7775 11.3425 15.785L11.35 15.8C11.605 16.4825 12.0025 17.0675 12.49 17.4875C12.49 17.555 12.4825 17.6225 12.475 17.6825C12.4225 18.1925 12.5725 18.56 11.185 19.175C9.79749 19.79 7.71999 20.4425 7.29249 21.4325C6.86499 22.4225 7.03749 23 7.03749 23H21.9625C21.9625 23 22.135 22.4225 21.7075 21.4325Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M21 6.5H19.5V5C19.5 4.725 19.275 4.5 19 4.5C18.725 4.5 18.5 4.725 18.5 5V6.5H17C16.725 6.5 16.5 6.725 16.5 7C16.5 7.275 16.725 7.5 17 7.5H18.5V9C18.5 9.275 18.725 9.5 19 9.5C19.275 9.5 19.5 9.275 19.5 9V7.5H21C21.275 7.5 21.5 7.275 21.5 7C21.5 6.725 21.275 6.5 21 6.5Z" fill={color}/>
      </svg>
    </span>
  );
};
