import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SearchInput from "../../components/SearchInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FlexWrapper from "../../components/FlexWrapper";
import AdvancedTableHeader from "../../components/AdvancedTable/AdvancedTableHeader";
import MetricCard from "../../components/MetricCard";
import MetricContainer from "../../components/MetricContainer";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import DynamicAvatar from "../../components/DynamicAvatar"
import PATH_URL from "../../routers/path";
import styles from "../../components/AdvancedTable/table.module.css";
import pageLayoutStyle from "../../layouts/DashboardLayout/Dashboard.module.css";
import { getComparator, stableSort } from "../../components/AdvancedTable";
import { useTableStyle } from "../../components/AdvancedTable/tableStyle";
import { getTaskSummary } from "../../redux/actions/admin";
import {
  getReviewCompanyList,
  getTaskGroupList,
  setTablePage,
  setTableRowsPerPage
} from "../../redux/actions/reviewer";

import { createLoadingSelector } from "../../redux/api/loading";

const loadingSelectorSummary = createLoadingSelector(["GET_TASK_SUMMARY"]);
const loadingSelectorQuery = createLoadingSelector(["GET_REVIEW_COMPANY_LIST"]);

const columns = [
  {
    id: "collapse",
    width: "3%",
    label: ""
  },
  {
    id: "name",
    width: "15%",
    label: "COMPANY NAME"
  },
  {
    id: "type",
    width: "15%",
    label: "TYPE"
  },
  {
    id: "task",
    width: "13%",
    label: "TASK"
  },
  {
    id: "assignee",
    width: "26%",
    label: "ASSIGNEE"
  },
  {
    id: "progress",
    width: "12%",
    label: "PROGRESS"
  },
  {
    id: "similarity",
    width: "12%",
    label: "SIMILARITY"
  }
];

function displayPercentage(num) {
  if (num !== null && num !== undefined) {
    return `${num % 1 === 0 ? num : num.toFixed(2)}%`;
  }
  return "0%";
}

function ReviewDashboard(props) {
  const classes = useTableStyle();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("task_title");
  const [collapseData, setCollapseData] = useState({
    level1: null,
    level2: null
  });
  const [search, setSearch] = useState({
    companyName: ""
  });
  const { getTaskSummary, getReviewCompanyList, getTaskGroupList, pageData } = props;

  useEffect(() => {
    getTaskSummary();
  }, [getTaskSummary]);

  useEffect(() => {
    getReviewCompanyList({
      q: search.companyName,
      limit: pageData.limit,
      page: pageData.currentPageNumber,
    });
  }, [
    getReviewCompanyList,
    search.companyName,
    pageData.limit,
    pageData.currentPageNumber,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.setTablePage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    props.setTableRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleCollapseL1 = companyId => {
    setCollapseData({
      ...collapseData,
      level1: collapseData.level1 === companyId ? null : companyId
    });
  };

  const handleCollapseL2 = async (companyId, documentType, isFetch) => {
    console.log(companyId);
    const isSuccess = isFetch
      ? await getTaskGroupList(companyId, documentType)
      : true;
    const collapseId = `${companyId}-${documentType}`;
    if (isSuccess)
      setCollapseData({
        ...collapseData,
        level2: collapseData.level2 === collapseId ? null : collapseId
      });
  };

  function renderAvatar(member) {
    const { annotator, taskId } = member;
    return (
      <Link key={taskId} to={PATH_URL.REVIEW.replace(":task_id", taskId)}>
        <DynamicAvatar
          photoUrl={annotator.photoUrl}
          name={annotator.profile.name}
          color="var(--green50)"
          style={{ marginRight: 4 }}
        />
      </Link>
    );
  }

  return (
    <div className={pageLayoutStyle.root}>
      <MetricContainer>
        <MetricCard
          title="ALL TASKS"
          number={props.summary.all_tasks}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={props.loadingSummary}
        />
        <MetricCard
          title="TO DO"
          number={props.summary.todo}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="trending-down"
          loading={props.loadingSummary}
        />
        <MetricCard
          title="IN PROGRESS"
          number={props.summary.in_progress}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="trending-down"
          loading={props.loadingSummary}
        />
        <MetricCard
          title="ON REVIEW"
          number={props.summary.on_review}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={props.loadingSummary}
        />
        <MetricCard
          title="REJECTED"
          number={props.summary.rejected}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={props.loadingSummary}
        />
        <MetricCard
          title="DONE"
          number={props.summary.done}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={props.loadingSummary}
        />
      </MetricContainer>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <h1 className={styles.tableTitle}>All Tasks</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              defaultValue={search.companyName}
              onApplySearch={(value) => {
                setSearch({ ...search, companyName: value })
              }}
              placeholder="Search"
              style={{ width: 250, marginRight: 10 }}
            />
          </FlexWrapper>
        </div>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <AdvancedTableHeader
              classes={classes}
              selectionCounter={0}
              order={order}
              orderBy={orderBy}
              onSort={handleRequestSort}
              rowCount={props.companies.length}
              columns={columns}
            />
            <TableBody>
              {props.loading ? (
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index} role="checkbox">
                      <TableCell padding="checkbox" className={classes.checkbox}>
                        <Skeleton height={20} width={20} style={{ marginLeft: 10 }} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : props.companies.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} className={classes.emptyResult}>
                    No results found
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(props.companies, getComparator(order, orderBy))
                  .map((row, index) => {
                    console.log(row);
                    return (
                      <Fragment key={row.companyId}>
                        <TableRow
                          onClick={() => handleCollapseL1(row.companyId)}
                          style={{ cursor: "pointer" }}
                          key={row.companyId}
                        >
                          <TableCell>
                            {collapseData.level1 === row.companyId ? (
                              <KeyboardArrowDownIcon
                                style={{ color: "var(--blue40)" }}
                              />
                            ) : (
                              <KeyboardArrowRightIcon
                                style={{ color: "var(--blue40)" }}
                              />
                            )}
                          </TableCell>
                          <TableCell>{row.companyName}</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ padding: 0, borderBottom: "none" }}
                            colSpan={8}
                          >
                            <Collapse
                              in={collapseData.level1 === row.companyId}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table>
                                <TableBody>
                                  {row.documentTypes.map(docType => (
                                    <Fragment key={docType.type}>
                                      <TableRow
                                        key={docType.type}
                                        onClick={() =>
                                          handleCollapseL2(
                                            row.companyId,
                                            docType.type,
                                            docType.tasks.length === 0
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <TableCell
                                          style={{ width: "8%" }}
                                        ></TableCell>
                                        <TableCell
                                          style={{ width: "13%" }}
                                        ></TableCell>
                                        <TableCell
                                          style={{
                                            width: "15%",
                                            paddingLeft: 0
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center"
                                            }}
                                          >
                                            {collapseData.level2 ===
                                            `${row.companyId}-${docType.type}` ? (
                                              <KeyboardArrowDownIcon
                                                style={{
                                                  color: "var(--blue40)"
                                                }}
                                              />
                                            ) : (
                                              <KeyboardArrowRightIcon
                                                style={{
                                                  color: "var(--blue40)"
                                                }}
                                              />
                                            )}
                                            <span>
                                              {docType.type.toLowerCase() === "annual_report"
                                                ? "AR"
                                                : "SR"}
                                            </span>
                                          </div>
                                        </TableCell>
                                        <TableCell style={{ width: "13%" }}>
                                          -
                                        </TableCell>
                                        <TableCell style={{ width: "26.5%" }}>
                                          -
                                        </TableCell>
                                        <TableCell style={{ width: "12.5%" }}>
                                          -
                                        </TableCell>
                                        <TableCell>-</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            padding: 0,
                                            borderBottom: "none"
                                          }}
                                          colSpan={8}
                                        >
                                          <Collapse
                                            in={
                                              collapseData.level2 ===
                                              `${row.companyId}-${docType.type}`
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <Table>
                                              <TableBody>
                                                {docType.tasks.map(
                                                  (doc, i) => (
                                                    <TableRow key={i}>
                                                      <TableCell
                                                        style={{ width: "8%" }}
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{ width: "13%" }}
                                                      ></TableCell>
                                                      <TableCell
                                                        style={{
                                                          width: "14%",
                                                          verticalAlign: doc.tasks.length > 1 ? "top" : "center",
                                                          paddingLeft: 5
                                                        }}
                                                      >
                                                        {doc.filename}
                                                      </TableCell>
                                                      <TableCell style={{ padding: 0 }}>
                                                        <Table>
                                                          <TableBody>
                                                            {doc.tasks.map((task, index) => (
                                                              <TableRow key={`${task.dtype}-${doc.documentId}-${["VARIABLE", "TABLE"].includes(task.dtype) ? index : task.section.name}`}>
                                                                <TableCell
                                                                  style={{ width: "21%" }}
                                                                >
                                                                  {["CHAPTER", "VARIABLE","TABLE"].includes(task.dtype)
                                                                    ? task.dtype
                                                                    : `${task.dtype} - ${task.section.name}`}
                                                                </TableCell>
                                                                <TableCell
                                                                  style={{
                                                                    width: "41%"
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      display: "flex"
                                                                    }}
                                                                  >
                                                                    {task.members.map(
                                                                      member =>
                                                                      renderAvatar(
                                                                        member
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </TableCell>
                                                                <TableCell
                                                                  style={{
                                                                    width: "20%"
                                                                  }}
                                                                >
                                                                  {displayPercentage(
                                                                    task.progress
                                                                  )}
                                                                </TableCell>
                                                                <TableCell>
                                                                  {displayPercentage(
                                                                    task.similarity
                                                                  )}
                                                                </TableCell>
                                                              </TableRow>
                                                            ))}
                                                          </TableBody>
                                                        </Table>
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                              </TableBody>
                                            </Table>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 30]}
            component="div"
            count={props.pageData.totalItems}
            rowsPerPage={props.pageData.limit}
            labelDisplayedRows={() => "rows"}
            labelRowsPerPage={"Show"}
            page={props.pageData.currentPageNumber - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  loadingSummary: loadingSelectorSummary(state),
  loading: loadingSelectorQuery(state),
  summary: state.admin.tasks.summary,
  companies: state.reviewer.companies,
  pageData: state.reviewer.pageData,
});

const mapDispatchToProps = dispatch => ({
  getTaskSummary: () => dispatch(getTaskSummary()),
  getReviewCompanyList: (data) => dispatch(getReviewCompanyList(data)),
  setTablePage: page => dispatch(setTablePage(page)),
  setTableRowsPerPage: total => dispatch(setTableRowsPerPage(total)),
  getTaskGroupList: (companyId, documentType) =>
    dispatch(getTaskGroupList(companyId, documentType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewDashboard);
