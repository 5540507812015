import React, { Component } from "react";
import { Helmet } from "react-helmet";
import withAuthentication from "../utils/withAuthentication";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      isOpenSidebar: false
    };
  }

  checkMobile = () => {
    // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
    const currentWidth = window.innerWidth;
    if (currentWidth > 640) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
    }
  };

  componentDidMount() {
    // window.addEventListener('scroll', () => {
    //     document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    // });
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  }

  handleOpenSidebar = () => {
    this.setState({
      isOpenSidebar: true
    });
  };

  handleCloseSidebar = () => {
    this.setState({
      isOpenSidebar: false
    });
  };

  render() {
    return (
      <div className="h-screen" style={{ backgroundColor: "#F5F6FA" }}>
        <Helmet>
          <title>ESG Annotation</title>
        </Helmet>
        <div className={`pt-16 ${!this.state.isMobile ? "pl-64" : ""}`}>
          <div
            className={`${
              this.state.isMobile ? "px-3 py-4" : "px-6 pt-6 pb-24"
            }`}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withAuthentication(Layout);
