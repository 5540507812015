import React, { useRef } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Done, Clear as Cross } from "@material-ui/icons";
import FlexWrapper from "../../components/FlexWrapper";
import Button from "../../components/@setproduct-ui/core/Button";
import Checkbox from "../../components/@setproduct-ui/core/CheckBox";
import IconButton from "@material-ui/core/IconButton";
import { Icon, Popover, Position, PopoverInteractionKind } from "@blueprintjs/core";
import styles from "./style.module.css";
import { createLoadingSelector } from "../../redux/api/loading";
import PageRangeSelector from "../../components/FormAnnotation/PageRangeSelector";

function Toolbar(props) {
  let inputRef = useRef();

  const taskType = useSelector(state => state.annotation.type);
  const loadingBoundary = useSelector(state =>
    createLoadingSelector(["GET_BOUNDARIES"])(state)
  );
  const { currentPage, pageBoundingBox, boundingBox } = useSelector(state => state.documentPreview);

  const parseOcrStatus = status => {
    switch (status) {
      case 1:
        return "Unprocessed";
      case 2:
        return "Queued";
      default:
        return "In Progress";
    }
  };

  const isLoadedBoundingBox = () => {
    const pageIndex = currentPage - 1;
    return pageBoundingBox[pageIndex] && boundingBox[pageIndex];
  }

  const renderToolbox = () => {
    if (props.ocrStatus === 4) {
      if (taskType === "CHAPTER") {
        return loadingBoundary ? (
          <span style={{ color: "var(--grey60)", fontSize: 12 }}>
            <CircularProgress size={20} />
          </span>
        ) : (
          <>
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf("BLOCK") !== -1}
              label="B"
              onChange={() => props.changeBoundingBoxType("BLOCK")}
            />
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf("PARAGRAPH") !== -1}
              label="P"
              onChange={() => props.changeBoundingBoxType("PARAGRAPH")}
            />
          </>
        );
      }
      if (taskType === "SUBCHAPTER") {
        return (
          <>
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf("BLOCK") !== -1}
              label="B"
              onChange={() => props.changeBoundingBoxType("BLOCK")}
            />
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf("PARAGRAPH") !== -1}
              label="P"
              onChange={() => props.changeBoundingBoxType("PARAGRAPH")}
            />
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf("WORD") !== -1}
              label="W"
              onChange={() => props.changeBoundingBoxType("WORD")}
            />
            {loadingBoundary ? (
              <span
                style={{ color: "var(--grey60)", fontSize: 12, marginLeft: 10 }}
              >
                <CircularProgress size={20} />
              </span>
            ) : (
              <div style={{ height: 20, width: 20, marginLeft: 10 }}></div>
            )}
          </>
        );
      }
      return (
        <>
          <Checkbox
            type="dense"
            color="primary"
            checked={props.data.boundingBoxType.indexOf("FIELD") !== -1}
            label="Show labels"
            onChange={() => props.changeBoundingBoxType("FIELD")}
          />
          {loadingBoundary && (
            <span
              style={{ color: "var(--grey60)", fontSize: 12, marginLeft: 10 }}
            >
              <CircularProgress size={20} />
            </span>
          )}
          {!loadingBoundary && isLoadedBoundingBox() && (
            <Done style={{ fontSize: 20, color: 'var(--green50)', marginLeft: 10 }} />
          )}
          {!loadingBoundary && !isLoadedBoundingBox() && (
            <Cross style={{ fontSize: 20, color: 'var(--red50)', marginLeft: 10 }} />
          )}
        </>
      );
    }
    return (
      <span style={{ color: "var(--grey60)", fontSize: 12 }}>
        OCR Status: <b>{parseOcrStatus(props.ocrStatus)}</b>
      </span>
    );
  };

  return (
    <FlexWrapper
      justifyContent="space-between"
      style={{
        height: 47,
        borderTop: "1px solid var(--grey10)",
        borderBottom: "1px solid var(--grey10)",
        fontFamily: "var(--inter)"
      }}
    >
      <FlexWrapper justifyContent="flex-start" padding="9px">
        {renderToolbox()}
        <Checkbox
          type="dense"
          color="primary"
          checked={props.data.annotationDisabled}
          label="Disable Annotation"
          onChange={() => props.toggleAnnotationDisable()}
        />
      </FlexWrapper>
      <FlexWrapper className={styles.pageTools}>
        <IconButton
          size="small"
          onClick={props.prevPage}
          disabled={props.data.currentPage === 1 || !props.data.pageLoaded}
        >
          <Icon icon="chevron-left" iconSize={20} />
        </IconButton>
        <span style={{ margin: "0 5px" }}>
          <input
            ref={el => (inputRef = el)}
            type="text"
            value={props.data.currentPage}
            onChange={props.handlePageChange}
            onClick={({ target: { value } }) => {
              inputRef.setSelectionRange(0, value.length);
            }}
            className={styles.input}
          />{" "}
          / {props.data.numPages}
        </span>
        <IconButton
          size="small"
          onClick={props.nextPage}
          disabled={
            props.data.currentPage === props.data.numPages ||
            !props.data.pageLoaded
          }
        >
          <Icon icon="chevron-right" iconSize={20} />
        </IconButton>
        {['CHAPTER', 'SUBCHAPTER'].includes(taskType) &&
          <PageRangeSelector focusPage={props.focusPage}/>}
      </FlexWrapper>
      <FlexWrapper padding="9px">
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-in"
          onClick={props.zoomIn}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-out"
          disabled={props.data.pageScale === 0.25}
          onClick={props.zoomOut}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-to-fit"
          onClick={props.resetPageScale}
        />
        {taskType === "SUBCHAPTER" && (
          <Popover
            minimal={true}
            popoverClassName={styles.popover}
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <Button
              type="icon"
              view="flat"
              color="default"
              dense={true}
              icon="info-sign"
            />
            <div className={styles.infoContainer}>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 8 }}>
                  <span className={styles.dotInfo}></span>
                </div>
                <p style={{ fontSize: 12 }}>
                  You are allowed to annotate content in the blue and yellow
                  boundary box
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 8 }}>
                  <span className={styles.dotError}></span>
                </div>
                <p style={{ fontSize: 12, margin: 0 }}>
                  You are not to allowed annotate content in the red boundary
                  box
                </p>
              </div>
            </div>
          </Popover>
        )}
      </FlexWrapper>
    </FlexWrapper>
  );
}

export default Toolbar;
