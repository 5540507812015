import React from 'react';
import { ClickOutsideContainer } from '../../components/utils/ClickOutsideContainer';
import Menu from '../../components/@setproduct-ui/core/Menu';
import MenuItem from '../../components/@setproduct-ui/core/Menu/MenuItem';
import styles from './Style.module.css';

export default function ActionMenu(props) {
  const { data, setMenu, setDialog, onClose } = props;
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu view="smooth" type="dense" color="default" className={styles.menu}>
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Set as admin"
          disabled={
            data.status !== 'active' ||
            !data.emailVerified ||
            data.role === 'admin'
          }
          onClick={async () => {
            const isSuccess = await props.changeRole(data.id);
            if (isSuccess) {
              setMenu(null);
            }
          }}
        />
        {data.status === 'inactive' ? (
          <MenuItem
            type="dense"
            view="smooth"
            color="default"
            text="Enable account"
            disabled={data.role === 'admin' || !data.emailVerified}
            onClick={() => {
              props.accept(data.id);
              onClose();
            }}
          />
        ) : (
          <MenuItem
            type="dense"
            view="smooth"
            color="default"
            text="Disable account"
            disabled={data.role === 'admin' || !data.emailVerified}
            onClick={() => {
              setDialog({
                open: true,
                id: data.id,
                operation: 'block',
              });
              return onClose();
            }}
          />
        )}
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Delete user"
          onClick={() => {
            setDialog({
              open: true,
              id: data.id,
              uid: data.uid,
              operation: 'delete',
            });
            return onClose();
          }}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}
