import { Layout } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../components/@setproduct-ui/core/Breadcrumbs/Breadcrumbs";
import NavbarGroup from "../../components/@setproduct-ui/core/Navbar/Group";
import Navbar from "../../components/@setproduct-ui/core/Navbar/Navbar";
import NavbarUserMenu from "../../components/NavbarUserMenu";
import Sidebar from "../../components/Sidebar";

const { Content, Sider, Header } = Layout;

// const parseLocationToCrumbsItem = (path) => {
//   const splittedPath = path.split('/');
//   return splittedPath;
// }

export default function DashboardLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Layout style={{ height: "100vh", overflow: "hidden" }}>
          <Sider className="sidebar">
            <Sidebar />
          </Sider>
          <Layout>
            <Header className="navbar">
            <Navbar type='desktop' view='flat' color='default' className="navbar-content" style={{ background: "1C2D59", padding: "0px"}}>
                <NavbarGroup align='left'>
                  <span className="navbar-header">
                    {props.title}
                  </span>
                </NavbarGroup>
                <NavbarGroup align='right'><NavbarUserMenu /></NavbarGroup>
              </Navbar>
            </Header>
            <Content className="content">
              {props.children}
            </Content>
          </Layout>
        </Layout>
    </React.Fragment>
  );
}
