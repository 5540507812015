import { apiRequest } from "../utils/apiRequest";
import { goBack } from "connected-react-router";

export const getCompanyList = () => async dispatch => {
  await apiRequest(
    dispatch,
    "GET_COMPANY_LIST",
    "get",
    "/annotation/companies"
  );
};

export const getCompany = companyId => async dispatch => {
  const { type, payload } = await apiRequest(
    dispatch,
    "GET_COMPANY",
    "get",
    `/annotation/company/${companyId}`
  );
  if (type === "GET_COMPANY_SUCCESS") {
    return payload;
  }
  return null;
};

export const updateMetadata = (id, data) => async dispatch => {
  const actionDispatched = await apiRequest(
    dispatch,
    "UPDATE_METADATA",
    "patch",
    `/annotation/document/${id}/update-metadata`,
    data
  );
  if (actionDispatched.type === "UPDATE_METADATA_SUCCESS") {
    dispatch(getDocumentData(id));
    return true;
  }
};

export const getBoundaries = (documentId, page) => async (
  dispatch,
  getState
) => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_BOUNDARIES",
    "get",
    `/annotation/document/${documentId}/boundaries`,
    { page_number: page }
  );
  if (actionDispatched.type === "GET_BOUNDARIES_SUCCESS") {
    const { boundingBox } = getState().documentPreview;
    const { pageNumber, boundaries } = actionDispatched.payload;
    const copy = boundingBox.slice();
    copy[pageNumber - 1] = boundaries;
    dispatch(setBoundingBox(copy));
  }
};

export const getPageBoundaries = (documentId, page) => async (
  dispatch,
  getState
) => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_PAGE_BOUNDARIES",
    "get",
    `/annotation/document/${documentId}/boundaries`,
    { type: "page" }
  );
  if (actionDispatched.type === "GET_PAGE_BOUNDARIES_SUCCESS") {
    const { boundaries } = actionDispatched.payload;
    dispatch(setPageBoundingBox(boundaries));
  }
};

export const getDocumentData = documentId => async dispatch => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_DOCUMENT_DATA",
    "get",
    `/annotation/document/${documentId}`
  );
  if (actionDispatched.type === "GET_DOCUMENT_DATA_SUCCESS") {
    const {
      url,
      filename,
      ocrStatus,
      uploadedDate,
      metadata
    } = actionDispatched.payload;
    if (filename) {
      if (metadata.companyId) {
        const result = await dispatch(getCompany(metadata.companyId));
        if (result) dispatch(setCompanyName(result.name));
      }
      dispatch(setFile(url));
      dispatch(setFilename(filename));
      dispatch(setOcrStatus(ocrStatus));
      dispatch(setUploadedDate(uploadedDate));
      dispatch(setMetadata(metadata));
    } else {
      dispatch(goBack());
    }
  }
};

export const resetDocumentPreview = () => ({ type: "RESET_DOCUMENT_PREVIEW" });

export const resetBoundaries = () => ({ type: "RESET_BOUNDARIES" });

export const setFilename = filename => ({
  type: "SET_FILENAME",
  payload: filename
});

export const setOcrStatus = status => ({
  type: "SET_OCR_STATUS",
  payload: status
});

export const setUploadedDate = date => ({
  type: "SET_UPLOADED_DATE",
  payload: date
});

export const setMetadata = metadata => ({
  type: "SET_METADATA",
  payload: metadata
});

export const setCompanyName = name => ({
  type: "SET_COMPANY_NAME",
  payload: name
});

export const setFile = file => ({
  type: "SET_FILE",
  payload: file
});

export const setBoundingBox = data => ({
  type: "SET_BOUNDING_BOX",
  payload: data
});

export const setPageBoundingBox = data => ({
  type: "SET_PAGE_BOUNDING_BOX",
  payload: data
});

export const changeBoundingBoxType = boundingBoxType => ({
  type: "CHANGE_BOUNDING_BOX_TYPE",
  payload: boundingBoxType
});

export const setBoundingBoxType = boundingBoxType => ({
  type: "SET_BOUNDING_BOX_TYPE",
  payload: boundingBoxType
});

export const setPageLoaded = status => ({
  type: "SET_PAGE_LOADED",
  payload: status
});

export const resetPageScale = () => ({
  type: "RESET_PAGE_SCALE"
});

export const zoomIn = () => ({
  type: "ZOOM_IN"
});

export const zoomOut = () => ({
  type: "ZOOM_OUT"
});

export const setNumPages = pageCount => ({
  type: "SET_NUM_PAGES",
  payload: pageCount
});

export const setPaperSize = paperSize => ({
  type: "SET_PAPER_SIZE",
  payload: paperSize
});

export const setCurrentPage = page => ({
  type: "SET_CURRENT_PAGE",
  payload: page
});

export const nextPage = () => ({
  type: "NEXT_PAGE"
});

export const prevPage = () => ({
  type: "PREV_PAGE"
});

export const toggleAnnotationDisable = () => ({
  type: "TOGGLE_ANNOTATION_DISABLE"
});

export const removeBoundaries = nodeIds => async dispatch => {
  const actionDispatched = await apiRequest(
    dispatch,
    "REMOVE_BOUNDARIES",
    "post",
    `/boundaries`,
    {
      ids: nodeIds
    }
  );
  return actionDispatched.type === "REMOVE_BOUNDARIES_SUCCESS";
}

export const shiftBoundaries = (documentId, pageNumber, pageSize) => async dispatch => {
  const actionDispatched = await apiRequest(
    dispatch,
    "SHIFT_BOUNDARIES",
    "post",
    `/annotation/document/${documentId}/fix`,
    {
      pageNumber,
      pageSize
    }
  );
  return actionDispatched.type === "SHIFT_BOUNDARIES_SUCCESS";
}