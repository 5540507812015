import React, { useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Icon } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmRevisedReport,
  storeReportScrollPosition,
} from '../../redux/actions/annotation';
import { format } from 'date-fns';
import { createLoadingSelector } from '../../redux/api/loading';
import styles from './Style.module.css';

export default function Report({ data, authorized = false }) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const loadingConfirmRevise = useSelector(
    createLoadingSelector(['CONFIRM_REVISED_REPORT'])
  );
  const reportScrollPosition = useSelector(
    ({ annotation }) => annotation.reportScrollPosition
  );

  useEffect(() => {
    const reportContainer = containerRef.current;
    if (reportContainer) {
      reportContainer.scrollTop = reportScrollPosition;
    }
  }, [reportScrollPosition]);

  useEffect(() => {
    const reportContainer = containerRef.current;
    return () => {
      if (reportContainer) {
        dispatch(storeReportScrollPosition(reportContainer.scrollTop));
      }
    };
  }, [dispatch]);

  return (
    <div ref={containerRef} className={styles.report_container}>
      {data.map((report) => {
        const isDone = report.type === 'REPORT-DONE';
        return (
          <div key={report._id} className={styles.report_item}>
            <div className={styles.report_header_container}>
              <span>Report</span>
              {loadingConfirmRevise ? (
                <div>
                  <CircularProgress size={16} color="primary" />
                </div>
              ) : (
                <button
                  onClick={() => {
                    dispatch(confirmRevisedReport(report._id));
                  }}
                  style={{
                    backgroundColor: isDone ? 'transparent' : 'var(--grey5)',
                    cursor: authorized ? 'pointer' : 'not-allowed',
                  }}
                  disabled={isDone || !authorized}
                >
                  <Icon
                    icon={isDone ? 'tick' : 'small-tick'}
                    iconSize={16}
                    style={{
                      color: isDone ? 'var(--green60)' : 'var(--grey70)',
                      marginBottom: 0,
                    }}
                  />
                </button>
              )}
            </div>
            <span>
              {format(new Date(report.createdAt), 'dd MMM yyyy, HH:mm')}
            </span>
            {report.text?.split('\n').map((line, index) => (
              <p key={`line-${index}`} style={{ marginBottom: 4 }}>
                {line}
              </p>
            ))}
          </div>
        );
      })}
    </div>
  );
}
