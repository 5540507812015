import React from "react";

import Button from "../../../../components/@setproduct-ui/core/Button";
import ConfirmDialog from "../../../../components/@setproduct-ui/core/Dialog";

function ButtonGroup(props) {
  const { deleteHandler, closeHandler } = props;

  const [loading, setLoading] = React.useState(false);

  const eventDelete = () => {
    setLoading(true);
    deleteHandler();
  };

  return (
    <>
      {!loading && (
        <Button
          view="flat"
          color="default"
          dense={false}
          onClick={closeHandler}
          text="Cancel"
          style={{ marginRight: 10 }}
        />
      )}
      <Button
        view="filled"
        color="danger"
        dense={false}
        loading={loading}
        onClick={eventDelete}
        text="Delete"
      />
    </>
  );
}

export default (props) => {
  let { isConfirmOpen, closeHandler, text, title } = props;
  text = text || "Are you sure you want to delete this ?";
  title = title || "Delete Section";

  return (
    <ConfirmDialog
      view="smooth"
      color="default"
      title={title}
      text={text}
      backdropOpacity={40}
      rightButton={<ButtonGroup {...props} />}
      isOpen={isConfirmOpen}
      onClose={closeHandler}
    />
  );
};
