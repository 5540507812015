import React from "react";
import styled from "styled-components";

const parseColor = type => {
  switch (type) {
    case "error":
      return "var(--red60)";
    case "success":
      return "var(--green60)";
    default:
      return "var(--blue60)";
  }
};

const StyledFormMessage = styled.p`
  padding: 5px 8px;
  background: ${props => parseColor(props.type)};
  color: var(--grey0);
  font-size: 14px;
  border-radius: 2px;
  margin-bottom: 0;
  text-align: left;
`;

function FormMessage(props) {
  return (
    <StyledFormMessage type={props.type}>{props.children}</StyledFormMessage>
  );
}

export default FormMessage;
