import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _, { capitalize } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Classes, Icon, Popover, Position } from '@blueprintjs/core';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Button from '../../components/@setproduct-ui/core/Button';
import CustomDialog from '../../components/CustomDialog';
import FlexWrapper from '../../components/FlexWrapper';
import Heading from '../../components/Heading';
import Spinner from '../../components/Loading';
import TreeForm from './TreeForm';
import {
  setExpandedAnnotationFields,
  setExpandedAnnotationItems,
  setSelectedSection,
  storeFormData,
} from '../../redux/actions/annotation';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  getVariableDetail,
  addVariableAnnotationItem,
  removeVariableAnnotationItem,
  resetSelectedVariableField,
  setAnnotatedVariableField,
  setSectionListScrollPosition,
  setVariableFormScrollPosition,
  moveAnnotationItemPosition,
} from '../../redux/actions/annotation';
import styles from './style.module.css';

function parseVariableType(type) {
  const typeArr = type.split('-');
  return capitalize(typeArr.slice(0, typeArr.length - 1).join(' '));
}

function AnnotationVariable(props) {
  const { watch, reset } = useFormContext();
  const dispatch = useDispatch();
  const sectionListRef = React.useRef();
  const loadingVariableDetail = useSelector((state) =>
    createLoadingSelector([
      'GET_TASK_VARIABLE_DETAIL',
      'GET_VARIABLE_DETAIL',
      'GET_ANNOTATION_VARIABLE',
    ])(state)
  );
  const sections = useSelector((state) => state.annotation.sections);
  const selectedSection = useSelector(
    (state) => state.annotation.selectedSection
  );
  const annotatedField = useSelector(
    (state) => state.annotation.annotatedField
  );
  const annotationVariableError = useSelector(
    (state) => state.annotation.isErrorVariableAnnotation
  );
  const sectionListScrollPosition = useSelector(
    (state) => state.annotation.sectionListScrollPosition
  );
  const variableFormScrollPosition = useSelector(
    (state) => state.annotation.variableFormScrollPosition
  );
  const storedExpandedItems = useSelector(
    (state) => state.annotation.expandedAnnotationItems
  );
  const [movingItem, setMovingItem] = React.useState({
    variableId: null,
    type: '',
    from: null,
    to: null,
  });
  const [expandedItems, setExpandedItems] = React.useState(storedExpandedItems);

  const handleExpand = (index) => {
    setExpandedItems((prev) => {
      const newExpandedItems = prev.slice();
      newExpandedItems[index] =
        newExpandedItems[index] !== undefined ? !newExpandedItems[index] : true;
      return newExpandedItems;
    });
  };

  const handleCollapseAll = () => {
    const currentVariable = sections[selectedSection];
    if (currentVariable.annotationItems)
      setExpandedItems((prev) => {
        const totalItems = currentVariable.annotationItems.length;
        if (prev.length < totalItems) {
          return Array(totalItems).fill(false);
        }
        return prev.map(() => false);
      });
  };

  const handleExpandAll = () => {
    const currentVariable = sections[selectedSection];
    if (currentVariable.annotationItems)
      setExpandedItems((prev) => {
        const totalItems = currentVariable.annotationItems.length;
        if (prev.length < totalItems) {
          return Array(totalItems).fill(true);
        }
        return prev.map(() => true);
      });
  };

  const handleCloseDialog = () => {
    setMovingItem({ variableId: null, type: '', from: null, to: null });
  };

  const handleAddVariableAnnotationItem = () => {
    const fieldName = `${compact(sections[selectedSection].name)}`;
    dispatch(addVariableAnnotationItem());
    props.handleSetDirty(fieldName);
  };

  const handleRemoveVariableAnnotationItem = (index) => {
    const fieldName = `${compact(sections[selectedSection].name)}`;
    const regex = RegExp(`${_.escapeRegExp(fieldName)}\\[\\d+\\]`);
    const splitResult = annotatedField[selectedSection].reduce(
      (acc, annotation) => {
        if (regex.test(annotation.keyMap))
          acc = { ...acc, matched: acc.matched.concat(annotation) };
        else acc = { ...acc, unmatched: acc.unmatched.concat(annotation) };
        return acc;
      },
      { matched: [], unmatched: [] }
    );
    const matched = splitResult.matched
      .map((annotation) => {
        const keyMapArr = annotation.keyMap.split('.');
        const index = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
        return { ...annotation, index };
      })
      .sort((a, b) => a.index - b.index)
      .reduce(
        (acc, annotation) => {
          if (annotation.index < index)
            acc = { ...acc, pre: acc.pre.concat(annotation) };
          else if (annotation.index > index)
            acc = { ...acc, post: acc.post.concat(annotation) };
          return acc;
        },
        { pre: [], post: [] }
      );
    // remove `index` key from pre result
    const matchPre = matched.pre.map(({ keyMap, coordinates, pageNumber }) => ({
      keyMap,
      coordinates,
      pageNumber,
    }));
    const matchPost = [];
    let iter = -1;
    let prev = null;
    matched.post.forEach((annotation, idx) => {
      if (prev !== annotation.index) {
        iter++;
        prev = annotation.index;
      }
      const keyMapArr = annotation.keyMap.split('.');
      // shifting index
      keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${index + iter}]`);
      // remove `index` key
      matchPost.push({
        keyMap: keyMapArr.join('.'),
        coordinates: annotation.coordinates,
        pageNumber: annotation.pageNumber,
      });
    });
    const formData = watch();
    const currentVariableData = formData[fieldName];
    const newData = currentVariableData.filter((_, idx) => idx !== index);
    reset({ ...formData, [fieldName]: newData });
    const result = splitResult.unmatched.concat(matchPre, matchPost);
    props.handleSetDirty(fieldName);
    dispatch(removeVariableAnnotationItem(index));
    dispatch(setAnnotatedVariableField(selectedSection, result));
  };

  const renderTreeForm = () => {
    const currentVariable = sections[selectedSection];
    if (
      currentVariable &&
      currentVariable.structure &&
      currentVariable.annotationItems
    ) {
      if (currentVariable.isArray) {
        return currentVariable.annotationItems.map((annotation, index) => (
          <Draggable
            key={`annotation-item-${index}`}
            draggableId={`annotation-item-${index}`}
            index={index}
            isDragDisabled={['acepted', 3].includes(
              currentVariable.annotation.status
            )}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                key={currentVariable.name + '-' + index}
                className={styles.border_bottom}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  id={`${currentVariable.dtype}-${index + 1}`}
                  className={styles.nested_header_wrapper}
                  style={{ marginTop: 0, marginBottom: 10, cursor: 'move' }}
                  onClick={() => handleExpand(index)}
                >
                  <h2
                    className={styles.nested_header}
                    style={{ fontWeight: 700 }}
                  >
                    <Icon
                      icon={
                        expandedItems[index] ? 'chevron-down' : 'chevron-right'
                      }
                      iconSize={16}
                      style={{ color: 'var(--grey70)', marginRight: 4 }}
                    />
                    {parseVariableType(currentVariable.dtype)} {index + 1}
                  </h2>
                  {currentVariable.annotationItems.length > 1 &&
                    ![3, 'accepted'].includes(
                      currentVariable.annotation.status
                    ) && (
                      <div className={styles.header_button_wrapper}>
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleRemoveVariableAnnotationItem(index)
                          }
                        >
                          <Icon
                            icon="minus"
                            iconSize={16}
                            style={{ color: 'var(--grey70)' }}
                          />
                        </IconButton>
                      </div>
                    )}
                </div>
                <Collapse
                  key={`annotation-item-collapse-${index}`}
                  in={expandedItems[index]}
                  timeout="auto"
                >
                  <TreeForm
                    root={currentVariable}
                    annotationItemIndex={index}
                    data={annotation}
                    parents={[]}
                    focusPage={props.handleClickBoundary}
                    autofill={props.handleAutofill}
                    handleSetDirty={props.handleSetDirty}
                  />
                </Collapse>
              </div>
            )}
          </Draggable>
        ));
      }
      return (
        <TreeForm
          key={currentVariable.name}
          root={currentVariable}
          annotationItemIndex={0}
          data={currentVariable.annotationItems[0]}
          parents={[]}
          focusPage={props.handleClickBoundary}
          autofill={props.handleAutofill}
          handleSetDirty={props.handleSetDirty}
        />
      );
    }
  };

  const setDirtyEmptyAnnotation = (variableName) => {
    props.handleSetDirty(compact(variableName));
  };

  const changeFormScrollPosition = (scrollPosition) => {
    const variableForm = document.getElementById('variable-form-container');
    if (variableForm) {
      variableForm.scrollTop = scrollPosition;
    }
  };

  React.useEffect(() => {
    if (sectionListRef.current) {
      const sectionList = sectionListRef.current;
      return () => {
        const scrollPosition = sectionList.scrollTop;
        dispatch(setSectionListScrollPosition(scrollPosition));
      };
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (sectionListRef.current) {
      const sectionList = sectionListRef.current;
      sectionList.scrollTop = sectionListScrollPosition;
    }
  }, [sectionListScrollPosition]);

  React.useEffect(() => {
    return () => {
      const variableForm = document.getElementById('variable-form-container');
      if (variableForm) {
        const scrollPosition = variableForm.scrollTop;
        dispatch(setVariableFormScrollPosition(scrollPosition));
      }
    };
  }, [dispatch]);

  React.useEffect(() => {
    const variableForm = document.getElementById('variable-form-container');
    if (variableForm) {
      variableForm.scrollTop = variableFormScrollPosition;
    }
  }, [variableFormScrollPosition]);

  React.useEffect(() => {
    dispatch(setExpandedAnnotationFields([]));
    return () => {
      setExpandedItems([]);
    };
  }, [dispatch, selectedSection]);

  React.useEffect(() => {
    return () => {
      dispatch(setExpandedAnnotationItems(expandedItems));
    };
  }, [dispatch, expandedItems]);

  return (
    <>
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ fontSize: 12 }}
      >
        <div
          ref={sectionListRef}
          style={{
            maxHeight: '25vh',
            overflowY: 'auto',
            borderBottom: '1px solid var(--grey10)',
          }}
        >
          {Object.keys(sections).map((sectionId) => (
            <FlexWrapper
              key={sectionId}
              justifyContent="space-between"
              padding="5px 8px"
              style={{
                cursor: 'pointer',
                background: selectedSection === sectionId ? 'var(--grey0)' : '',
                fontStyle: props.dirty.includes(
                  compact(sections[sectionId].name)
                )
                  ? 'italic'
                  : 'normal',
              }}
              onClick={async () => {
                if (!loadingVariableDetail && !annotationVariableError) {
                  dispatch(setSelectedSection(sectionId));
                  dispatch(resetSelectedVariableField());
                  changeFormScrollPosition(0);
                  const current = sections[sectionId];
                  if (!current.structure) {
                    dispatch(
                      getVariableDetail(
                        sectionId,
                        current.dtype,
                        setDirtyEmptyAnnotation
                      )
                    );
                  }
                }
              }}
            >
              <div>
                <span className={styles.variable_name}>
                  {sections[sectionId].name}
                </span>
                <span className={styles.variable_type}>
                  {`${sections[sectionId].isArray ? 'Array of ' : ''}` +
                    parseVariableType(sections[sectionId].dtype)}
                </span>
              </div>
              <div>
                {[3, 'accepted'].includes(
                  sections[sectionId].annotation.status
                ) && (
                  <Icon
                    icon="tick"
                    iconSize={12}
                    style={{
                      color: 'var(--white)',
                      background: 'var(--green70)',
                      padding: 2,
                      borderRadius: '50%',
                    }}
                  />
                )}
                {[4, 'rejected'].includes(
                  sections[sectionId].annotation.status
                ) && (
                  <Icon
                    icon="cross"
                    iconSize={12}
                    style={{
                      color: 'var(--white)',
                      background: 'var(--red70)',
                      padding: 2,
                      borderRadius: '50%',
                    }}
                  />
                )}
              </div>
            </FlexWrapper>
          ))}
        </div>
      </FlexWrapper>
      {selectedSection ? (
        loadingVariableDetail ? (
          <FlexWrapper
            flexFlow="column"
            justifyContent="flex-start"
            alignItems="stretch"
            padding="10px"
          >
            <Spinner />
          </FlexWrapper>
        ) : !annotationVariableError ? (
          <>
            <div className={styles.nested_header_wrapper} style={{ margin: 0 }}>
              <Heading
                text={parseVariableType(sections[selectedSection].dtype)}
                style={{ marginTop: 10, marginLeft: 10 }}
              />
              {sections[selectedSection].isArray && (
                <div
                  className={styles.header_button_wrapper}
                  style={{ marginRight: 10 }}
                >
                  <IconButton
                    size="small"
                    onClick={handleExpandAll}
                    style={{ marginRight: 4 }}
                  >
                    <Icon
                      icon="expand-all"
                      iconSize={14}
                      style={{ color: 'var(--grey60)' }}
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleCollapseAll}
                    style={{ marginRight: 4 }}
                  >
                    <Icon
                      icon="collapse-all"
                      iconSize={14}
                      style={{ color: 'var(--grey60)' }}
                    />
                  </IconButton>
                  {sections[selectedSection].annotationItems && (
                    <Popover
                      position={Position.BOTTOM_RIGHT}
                      popoverClassName={Classes.POPOVER_DISMISS}
                    >
                      <span className={styles.count_badge}>
                        {sections[selectedSection].annotationItems.length}
                      </span>
                      <div className={styles.item_options}>
                        <ul>
                          {Array(
                            sections[selectedSection].annotationItems.length
                          )
                            .fill(null)
                            .map((item, index) => (
                              <li
                                key={`annotation-item-${index}`}
                                onClick={() => {
                                  const el = document.querySelector(
                                    `#${sections[selectedSection].dtype}-${
                                      index + 1
                                    }`
                                  );
                                  el.scrollIntoView({ behavior: 'smooth' });
                                }}
                              >
                                {parseVariableType(
                                  sections[selectedSection].dtype
                                )}{' '}
                                {index + 1}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </Popover>
                  )}
                  {![3, 'accepted'].includes(
                    sections[selectedSection].annotation.status
                  ) && (
                    <IconButton
                      size="small"
                      onClick={handleAddVariableAnnotationItem}
                    >
                      <Icon
                        icon="plus"
                        iconSize={16}
                        style={{ color: 'var(--grey70)' }}
                      />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
            <DragDropContext
              onDragStart={() => {
                const variableName = compact(sections[selectedSection].name);
                const formData = watch(variableName);
                dispatch(storeFormData({ [variableName]: formData }));
              }}
              onDragEnd={(result) => {
                const { destination, source } = result;
                if (!destination) return;
                if (source.index === destination.index) return;
                setMovingItem({
                  variableId: selectedSection,
                  type: parseVariableType(sections[selectedSection].dtype),
                  from: source.index,
                  to: destination.index,
                });
              }}
            >
              <Droppable droppableId="annotation-item-list">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    id="variable-form-container"
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      padding: 10,
                      fontSize: 12,
                      maxHeight: '50vh',
                      overflowY: 'auto',
                      borderTop: '1px solid var(--grey20)',
                    }}
                    {...provided.droppableProps}
                  >
                    <form>
                      {renderTreeForm()}
                      {provided.placeholder}
                    </form>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <FlexWrapper
            flexFlow="column"
            justifyContent="flex-start"
            alignItems="stretch"
            padding="10px"
            style={{
              fontSize: 12,
              maxHeight: '50vh',
            }}
          >
            <p style={{ marginBottom: 10 }}>An error occurred</p>
            <Button
              view="filled"
              text="Reload annotation data"
              loading={loadingVariableDetail}
              disabled={false}
              style={{ marginRight: 12, fontWeight: 500 }}
              onClick={async () => {
                dispatch(resetSelectedVariableField());
                const current = sections[selectedSection];
                dispatch(
                  getVariableDetail(
                    current.id,
                    current.dtype,
                    setDirtyEmptyAnnotation
                  )
                );
              }}
            />
          </FlexWrapper>
        )
      ) : (
        <FlexWrapper
          flexFlow="column"
          justifyContent="flex-start"
          alignItems="stretch"
          padding="10px"
          style={{ fontSize: 12 }}
        >
          <span>No selected variable</span>
        </FlexWrapper>
      )}
      <CustomDialog
        view="raised"
        title="Change Position"
        backdropOpacity={40}
        isOpen={movingItem.from !== null}
        onClose={handleCloseDialog}
      >
        <div>
          <p>
            Change the position of{' '}
            <b>
              {movingItem.type} {movingItem.from + 1}
            </b>{' '}
            on variable <b>{sections[movingItem.variableId]?.name}</b>?
          </p>
          <FlexWrapper justifyContent="flex-end">
            <Button
              view="flat"
              color="default"
              text="Cancel"
              onClick={handleCloseDialog}
            />
            <Button
              view="filled"
              color="primary"
              text="Change"
              style={{ marginLeft: 20 }}
              onClick={() => {
                const { variableId, from, to } = movingItem;
                dispatch(moveAnnotationItemPosition(variableId, from, to));
                props.handleSetDirty(compact(sections[selectedSection].name));
                handleCloseDialog();
              }}
            />
          </FlexWrapper>
        </div>
      </CustomDialog>
    </>
  );
}

function compact(name) {
  return name.toLowerCase().replaceAll(' ', '-');
}

export default AnnotationVariable;
