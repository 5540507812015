import React from 'react';
import { TableRow, TableCell, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';

export function descendingComparator(a, b, orderBy, type) {
  let valueA = get(a, orderBy, 0);
  let valueB = get(b, orderBy, 0);
  if (type === 'date') {
    valueA = valueA ? new Date(valueA).getTime() : valueA;
    valueB = valueB ? new Date(valueB).getTime() : valueB;
  }
  if (type === 'number') {
    valueA = parseFloat(valueA);
    valueB = parseFloat(valueB);
  }
  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
}

export function getComparator(order, orderBy, type) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, type)
    : (a, b) => -descendingComparator(a, b, orderBy, type);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
// Table Utils
export const TableRowNoResult = (props) => {
  return (
    <TableRow>
      <TableCell colSpan={props.colSpan || 0}>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 450 }}
        >
          No Result Found
        </Box>
      </TableCell>
    </TableRow>
  );
};

TableRowNoResult.propTypes = {
  colSpan: PropTypes.number,
};
