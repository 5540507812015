import { auth } from "firebase";
import { push } from "connected-react-router";
import qs from "qs";
import { apiRequest } from "../utils/apiRequest";
import { getUserProfile } from "./userProfile";
import storage from "redux-persist/lib/storage";
import PATH_URL from "../../routers/path"

export const signIn = (email, password, isRemembered) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch({ type: "LOGIN_REQUEST" });
  try {
    let persistenceSetting = isRemembered
      ? auth.Auth.Persistence.LOCAL
      : auth.Auth.Persistence.SESSION;
    await getFirebase()
      .auth()
      .setPersistence(persistenceSetting);
    const response = await getFirebase().login({ email, password });
    if (response.user) {
      const result = await dispatch(getUserProfile());
      if (result.type === "GET_USER_PROFILE_SUCCESS") {
        dispatch({ type: "LOGIN_SUCCESS" });
      } else {
        throw new Error(result.payload);
      }
    }
  } catch (error) {
    dispatch(signOut());
    dispatch({ type: "LOGIN_FAILURE", payload: error });
    return false;
  }
};

export const signInGoogle = () => async (dispatch, getState, getFirebase) => {
  dispatch({ type: "LOGIN_REQUEST" });
  try {
    const response = await getFirebase().login({
      provider: "google",
      type: "popup"
    });
    if (response.user) {
      const { type, payload } = await dispatch(getUserProfile());
      if (type === "GET_USER_PROFILE_SUCCESS") {
        dispatch({ type: "LOGIN_SUCCESS" });
      } else {
        const { statusCode } = payload;
        if (statusCode === 401) {
          throw new Error("User not found, please register first.");
        } else {
          throw new Error(payload);
        }
      }
    }
  } catch (error) {
    dispatch(signOut());
    dispatch({ type: "LOGIN_FAILURE", payload: error });
    return false;
  }
};

export const registerGoogle = () => async (dispatch, getState, getFirebase) => {
  dispatch({ type: "REGISTER_REQUEST" });
  try {
    const response = await getFirebase().login({
      provider: "google",
      type: "popup"
    });
    if (response.user) {
      const { uid, email, displayName: name, photoURL } = response.user;
      const data = {
        uid,
        photoUrl: photoURL,
        email,
        name
      };
      const userProfileAction = await dispatch(getUserProfile());
      if (userProfileAction.type === "GET_USER_PROFILE_SUCCESS") {
        dispatch({ type: "REGISTER_SUCCESS" });
      } else {
        const { type, payload } = await dispatch(storeUserData(data));
        if (type === "STORE_USER_DATA_SUCCESS") {
          dispatch({ type: "REGISTER_SUCCESS" });
        } else {
          const { statusCode } = payload;
          if (statusCode === 401) {
            throw new Error("User not found, please register first.");
          } else {
            throw new Error(payload);
          }
        }
      }
    }
  } catch (error) {
    dispatch(signOut());
    dispatch({ type: "REGISTER_FAILURE", payload: error });
    return false;
  }
};

export const signOut = () => async (dispatch, getState, getFirebase) => {
dispatch({ type: "LOGOUT_REQUEST" });
try {
    await getFirebase().logout();
    await dispatch({ type: "RESET_USER_PROFILE" });
    await storage.removeItem("persist:root");
    // remove all data from redux persist
    await dispatch({ type: "LOGOUT_SUCCESS" });
  } catch (error) {
    await dispatch({ type: "LOGOUT_FAILURE", payload: error });
  }
};

export const register = (userData, isRemembered) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch({ type: "REGISTER_REQUEST" });
  const { name, email, role, password, retypePassword } = userData;
  if (password === retypePassword) {
    try {
      let persistenceSetting = isRemembered
        ? auth.Auth.Persistence.LOCAL
        : auth.Auth.Persistence.SESSION;
      await getFirebase()
        .auth()
        .setPersistence(persistenceSetting);
      const response = await getFirebase().createUser({ email, password });
      if (response.user) {
        const uid = response.user.uid;
        const data = {
          uid,
          email,
          name,
          role: role.value
        };
        await response.user.sendEmailVerification();
        const { type, payload } = await dispatch(storeUserData(data));
        if (type === "STORE_USER_DATA_SUCCESS") {
          dispatch({ type: "REGISTER_SUCCESS" });
        } else {
          const { statusCode } = payload;
          if (statusCode === 401) {
            throw new Error("User not found, please register first.");
          } else {
            throw new Error(payload);
          }
        }
      }
    } catch (error) {
      dispatch(signOut());
      dispatch({ type: "REGISTER_FAILURE", payload: error });
      return false;
    }
  } else {
    dispatch({
      type: "REGISTER_FAILURE",
      payload: { message: "Password mismatch." }
    });
    return false;
  }
};

export const forgotPassword = email => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch({ type: "FORGOT_PASSWORD_REQUEST" });
  try {
    const response = await getFirebase().resetPassword(email);
    dispatch({ type: "FORGOT_PASSWORD_SUCCESS", payload: response });
    return true;
  } catch (error) {
    dispatch({ type: "FORGOT_PASSWORD_FAILURE", payload: error });
    return false;
  }
};

export const verifyEmail = actionCode => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch({ type: "VERIFY_EMAIL_REQUEST" });
  try {
    await getFirebase()
      .auth()
      .applyActionCode(actionCode);
    dispatch({ type: "VERIFY_EMAIL_SUCCESS" });
  } catch (error) {
    dispatch({ type: "VERIFY_EMAIL_FAILURE", payload: error });
  }
};

export const resetPassword = (
  actionCode,
  newPassword,
  retypePassword
) => async (dispatch, getState, getFirebase) => {
  dispatch({ type: "RESET_PASSWORD_REQUEST" });
  if (newPassword === retypePassword) {
    try {
      await getFirebase()
        .auth()
        .confirmPasswordReset(actionCode, newPassword);
      dispatch({ type: "RESET_PASSWORD_SUCCESS" });
      setTimeout(() => {
        dispatch(push(PATH_URL.LOGIN));
      }, 2000);
      return true;
    } catch (error) {
      dispatch({ type: "RESET_PASSWORD_FAILURE", payload: error });
      return false;
    }
  } else
    dispatch({
      type: "RESET_PASSWORD_FAILURE",
      payload: { message: "Password mismatch." }
    });
};

const storeUserData = data => async dispatch => {
  return await apiRequest(
    dispatch,
    "STORE_USER_DATA",
    "post",
    "/auth/register",
    qs.stringify(data),
    "application/x-www-form-urlencoded"
  );
};
