import React from 'react';
import Dialog from '../../components/CustomDialog';
import Button from '../../components/@setproduct-ui/core/Button';
import { useStyles } from './use-styles';
import { currencyFormatter } from '../../utils/currencyFormatter';

export default function DialogOCR({
  isOpen,
  onClose,
  onSubmit,
  mode,
  selectedRow,
  selectedDocuments,
  estimation,
  loadingEstimate,
  loading,
}) {
  const classes = useStyles();
  const [storeInDb, setStoreInDb] = React.useState(true);
  const document =
    mode === 'single'
      ? selectedRow.filename
      : `${selectedDocuments.length} files`;
  return (
    <Dialog
      type="default"
      view={'raised'}
      onClose={onClose}
      isOpen={isOpen}
      color="default"
      title="Run OCR"
    >
      <div>
        <p>
          Are you sure want to run OCR on <strong>{document}</strong>? <br />
          {loadingEstimate ? (
            'Please wait, estimating OCR cost...'
          ) : (
            <>
              This operation will cost you{' '}
              <strong>{currencyFormatter.format(estimation)}</strong>
            </>
          )}
        </p>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
          <input
            type="checkbox"
            name="storeInDb"
            checked={storeInDb}
            onChange={() => setStoreInDb((prev) => !prev)}
          />
          <span>Store in DB</span>
        </div>
        <div className={classes.submitWrapperForm}>
          <Button
            type="default"
            view="flat"
            dense={true}
            onClick={onClose}
            style={{ padding: 20, marginRight: 8 }}
            color="default"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="default"
            onClick={() => onSubmit(storeInDb)}
            view="filled"
            dense={true}
            style={{ padding: 20 }}
            color="success"
            loading={loading}
          >
            Run OCR
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
