import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Wrapper from '../../components/BasicWrapper';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import { addTaskNote, updateTaskNote } from '../../redux/actions/annotation';
import { createLoadingSelector } from '../../redux/api/loading';
import styles from './style.module.css';
import { Icon } from '@blueprintjs/core';
import Accordion, {
  AccordionSummary,
  AccordionDetails,
} from '../../components/FormAnnotation/Accordion';
import Report from '../../components/FormAnnotation/Report';
import Note from '../../components/FormAnnotation/Note';

const loadingSelector = createLoadingSelector([
  'ADD_TASK_NOTE',
  'UPDATE_TASK_NOTE',
]);

function TabNote(props) {
  const [note, setNote] = useState({
    id: null,
    text: '',
  });
  const [noteValue, setNoteValue] = useState('');
  const [reports, setReports] = useState([]);
  const [snack, setSnack] = useState({ open: false, type: 'success' });
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const { note: reviewersNote, reports: reportList } = props.notes.reduce(
      (acc, note) => {
        if (note.type === 'REVIEW') {
          acc = {
            ...acc,
            note,
          };
        } else {
          acc = {
            ...acc,
            reports: acc.reports.concat(note),
          };
        }
        return acc;
      },
      { note: {}, reports: [] }
    );
    if (reviewersNote._id) {
      const { _id, text } = reviewersNote;
      setNote({ id: _id, text });
      setNoteValue(text);
    }
    setReports(reportList);
  }, [props.notes]);

  return (
    <div
      role="tabpanel"
      hidden={props.tabIndex !== 1}
      id={`tabpanel-${1}`}
      aria-labelledby={`tab-${1}`}
    >
      {props.tabIndex === 1 && (
        <Wrapper width="100%" height="100%">
          <Accordion
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className={styles.accordion_title_container}>
                <span>Reviewer's Note</span>
                <Icon
                  icon={expanded === 'panel1' ? 'chevron-down' : 'chevron-up'}
                  iconSize={20}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                <Wrapper height="55vh" width="100%">
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snack.open}
                    onClose={() => setSnack({ ...snack, open: false })}
                  >
                    <Alert
                      onClose={() => setSnack({ ...snack, open: false })}
                      severity={snack.type}
                    >
                      {snack.type === 'success'
                        ? 'Note sucessfully updated.'
                        : 'An error occured when saving note.'}
                    </Alert>
                  </Snackbar>
                  {props.loading ? (
                    'Loading...'
                  ) : (
                    <Note
                      value={noteValue}
                      onChange={(e) => {
                        setNoteValue(e.target.value);
                      }}
                    />
                  )}
                </Wrapper>
                <FlexWrapper margin="10px 0 0 0">
                  <Button
                    view="flat"
                    text="Discard"
                    color="danger"
                    fill={true}
                    dense={true}
                    loading={false}
                    disabled={false}
                    onClick={() => setNoteValue(note.text || '')}
                    style={{
                      fontSize: 'var(--font_size_body_small)',
                      marginRight: 5,
                    }}
                  />
                  <Button
                    view="filled"
                    text="Update"
                    color="primary"
                    fill={true}
                    dense={true}
                    loading={props.loading}
                    onClick={async () => {
                      let success;
                      if (!note.id) {
                        success = await props.addTaskNote(
                          props.taskId,
                          noteValue
                        );
                      } else {
                        success = await props.updateTaskNote(
                          note.id,
                          noteValue
                        );
                      }
                      if (success) setSnack({ open: true, type: 'success' });
                      else setSnack({ open: true, type: 'error' });
                    }}
                    style={{ fontSize: 'var(--font_size_body_small)' }}
                  />
                </FlexWrapper>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <div className={styles.accordion_title_container}>
                <span>Reports</span>
                <Icon
                  icon={expanded === 'panel2' ? 'chevron-down' : 'chevron-up'}
                  iconSize={20}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Report data={reports} authorized />
            </AccordionDetails>
          </Accordion>
        </Wrapper>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  taskId: state.annotation.taskId,
  notes: state.annotation.notes,
});

const mapDispatchToProps = (dispatch) => ({
  addTaskNote: (taskId, text) => dispatch(addTaskNote(taskId, text)),
  updateTaskNote: (commentId, text) =>
    dispatch(updateTaskNote(commentId, text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabNote);
