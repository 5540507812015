import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { tableSelectStyle } from "../../components/Select/tableSelectStyle";
import * as yup from "yup";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Icon } from "@blueprintjs/core";
import Button from "../../components/@setproduct-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AdvancedTableHeader from "../../components/AdvancedTable/AdvancedTableHeader";
import AdvancedTableAction from "../../components/AdvancedTable/AdvancedTableAction";
import _ from "lodash";
import { connect } from "react-redux";
import {
  actionCreateCompanies,
  actionEditCompanies,
  actionGetCompanies,
  actionCompaniesDelete,
  actionBulkCompaniesUpload,
  actionRenderFirst,
  actionSetSelectedCompanies
} from "../../redux/actions/companies";
import Menu from "../../components/@setproduct-ui/core/Menu";
import styles from "./style.module.css";
import MenuItem from "../../components/@setproduct-ui/core/Menu/MenuItem";
import Dialog from "../../components/@setproduct-ui/core/Dialog/Dialog";
import DialogUpload from "../../components/@setproduct-ui/core/Dialog/Dialog";
import DialogDelete from "../../components/@setproduct-ui/core/Dialog/Dialog";
import DialogEdit from "../../components/@setproduct-ui/core/Dialog/Dialog";
import Input from "../../components/Input";
import { Form, Formik } from "formik";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTableStyle } from "../../components/AdvancedTable/tableStyle";
import Select from "react-select";
import MetricCard from "../../components/MetricCard";
import MetricContainer from "../../components/MetricContainer";
import { LoadingTable } from "./Loading/view";
import { createLoadingSelector } from "../../redux/api/loading";
import pageLayoutStyle from "../../layouts/DashboardLayout/Dashboard.module.css";
import schemaFilter from "./schemaFilter";
import stylesTables from "../../components/AdvancedTable/table.module.css";
import FlexWrapper from "../../components/FlexWrapper";
import SearchInput from "../../components/@setproduct-ui/core/Inputs/SearchInput/SearchInput";
import { Paper, TablePagination } from "@material-ui/core";
import { ClickOutsideContainer } from "../../components/utils/ClickOutsideContainer";

const allowedFiles = [".csv", "application/vnd.ms-excel", "text/csv"];
const getUserCompaniesListLoading = createLoadingSelector(["COMPANIES_ACCEPT"]);
const deleteLoadingProgress = createLoadingSelector(["COMPANIES_DELETE"]);
const createLoadingProgress = createLoadingSelector(["COMPANIES_CREATE"]);
const editLoadingProgress = createLoadingSelector(["COMPANIES_EDIT"]);

function EnhancedMenu(props) {
  const {
    row,
    setFormData,
    setMenu,
    handleChangeShowModalEdit,
    handleChangeShowModalDelete,
    onClose,
  } = props;
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu view="smooth" type="dense" color="default" className={styles.menu}>
        <MenuItem
          type="def"
          view="smooth"
          color="default"
          text="Edit Company"
          onClick={() => {
            setMenu(null);
            handleChangeShowModalEdit();
            setFormData(row);
          }}
        />
        <MenuItem
          type="def"
          view="smooth"
          color="default"
          text="Delete Company"
          onClick={() => {
            handleChangeShowModalDelete(row);
            setFormData(row);
          }}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}

function Companies(props) {
  const classes = useStyles();
  const classesTab = useTableStyle();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("task_title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const [textErrorUploadType, setTextErrorUploadType] = useState(false);
  const [menu, setMenu] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalUpload, setIsShowModalUpload] = useState(false);
  const [isShowModalEdit, setIsShowModalEdit] = useState(false);
  const [isShowModalDialogDelete, setIsShowModalDialogDelete] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectFounded, setSelectFounded] = useState({
    type: { value: "FOUNDED IN", label: "FOUNDED IN" },
  });
  const [selectJasica, setSelectJasica] = useState({
    type: { value: "JASICA", label: "JASICA" },
  });
  const [parameter, setParameter] = useState(schemaFilter);
  const isInitialRender = useRef(true);
  const {
    companies: {
      companiesCreate,
      companiesCreateError,
      companies,
      companiesDelete,
      companiesBulkUpload,
      companiesBulkUploadError,
      companiesEdit,
      companiesEditError,
    },
    actionGetCompanies,
    actionEditCompanies,
    loadingCompanies,
    loadingDelete,
    loadingCreate,
    loadingEdit,
    actionCompaniesDelete,
    actionCreateCompanies,
    actionBulkCompaniesUpload,
    actionRenderFirst,
  } = props;
  const schema = yup.object().shape({
    name: yup.string().required("Required"),
    ticker: yup.string().required("Required"),
    JASICA: yup.string().required("Required"),
    ISIN: yup.string().required("Required"),
    foundedIn: yup.number().required("Required"),
  });

  useEffect(() => {
    actionGetCompanies();
    actionRenderFirst();
    setMenu(null); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isInitialRender.current
      ? (isInitialRender.current = false)
      : actionGetCompanies(parameter); // eslint-disable-next-line
  }, [parameter]);

  useEffect(() => {
    if (companiesCreate.type === "COMPANIES_CREATE_SUCCESS") {
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesCreate]);

  useEffect(() => {
    if (companiesDelete.type === "COMPANIES_DELETE_SUCCESS") {
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesDelete]);

  useEffect(() => {
    if (companiesBulkUpload.type === "COMPANIES_BULK_UPLOAD_SUCCESS") {
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesBulkUpload]);

  useEffect(() => {
    if (companiesCreate.type === "COMPANIES_CREATE_SUCCESS") {
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesCreate]);

  useEffect(() => {
    if (companiesDelete.type === "COMPANIES_DELETE_SUCCESS") {
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesDelete]);

  useEffect(() => {
    if (companiesEdit.type === "COMPANIES_EDIT_SUCCESS") {
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesEdit]);

  useEffect(() => {
    if (companiesBulkUpload.type === "COMPANIES_BULK_UPLOAD_SUCCESS") {
      setIsShowModalUpload(false)
      actionGetCompanies();
      setMenu(null);
    } // eslint-disable-next-line
  }, [companiesBulkUpload]);

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function handleChangeShowModalUpload() {
    setIsShowModalUpload(!isShowModalUpload);
    actionRenderFirst();
  }

  function handleChangeShowModal() {
    setIsShowModal(!isShowModal);
  }

  function handleChangeShowModalDelete() {
    setIsShowModalDialogDelete(!isShowModalDialogDelete);
  }

  function handleChangeShowModalEdit() {
    setIsShowModalEdit(!isShowModalEdit);
  }

  function createData(id, ticker, name, SIC, JASICA, ISIN, foundedIn) {
    return {
      id,
      ticker,
      name,
      SIC,
      JASICA,
      ISIN,
      foundedIn,
    };
  }

  const handleChangeSearch = (e) => {
    setFilter(e.target.value);
  };

  const lowercasedFilter = filter.toLowerCase();
  const filteredData =
    companies.length > 0 &&
    companies.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });

  const rows =
    companies.length > 0 &&
    filteredData.map((row, index) => {
      return createData(
        row._id,
        row.ticker,
        row.name,
        row.SIC,
        row.JASICA,
        row.ISIN,
        row.foundedIn
      );
    });

  const columns = [
    {
      id: "ticker",
      numeric: false,
      disablePadding: false,
      label: "TICKER",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "COMPANY NAME",
    },
    {
      id: "SIC",
      numeric: false,
      disablePadding: false,
      label: "SIC",
    },
    {
      id: "JASICA",
      numeric: false,
      disablePadding: false,
      label: "JASICA",
    },
    {
      id: "ISIN",
      numeric: false,
      disablePadding: false,
      label: "ISIN",
    },
    {
      id: "foundedIn",
      numeric: false,
      disablePadding: false,
      label: "FOUNDED IN",
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      props.actionSetSelectedCompanies(rows)
    } else {
      props.actionSetSelectedCompanies([]);
    }
  };

  const handleClick = (event, company) => {
    const selected = props.selectedCompanies.find(selected => selected.id === company.id);
    if (!selected) {
      props.actionSetSelectedCompanies([...props.selectedCompanies, company]);
    } else {
      const newSelected = props.selectedCompanies.filter(
        selected => selected.id !== company.id
      );
      props.actionSetSelectedCompanies(newSelected);
    }
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSubmit(e) {
    actionCreateCompanies(e, setIsShowModal);
  }

  function handleSubmitEdit(e) {
    actionEditCompanies(e, formData.id, setIsShowModalEdit);
  }

  const text = (
    <Formik
      initialValues={{
        name: "",
        ticker: "",
        JASICA: "",
        ISIN: "",
        foundedIn: "",
      }}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form>
          <div className={classes.input}>
            {" "}
            <Input
              name="name"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.name}
              label="Company Name"
              error={errors.hasOwnProperty("name")}
              errorMessage={errors.name}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="ticker"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.ticker}
              label="Ticker"
              error={errors.hasOwnProperty("ticker")}
              errorMessage={errors.ticker}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="JASICA"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.JASICA}
              label="JASICA"
              error={errors.hasOwnProperty("JASICA")}
              errorMessage={errors.JASICA}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="ISIN"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.ISIN}
              label="ISIN"
              error={errors.hasOwnProperty("ISIN")}
              errorMessage={errors.ISIN}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="foundedIn"
              onInput={handleChange}
              view="outlined"
              type="number"
              clearButton={false}
              value={values.foundedIn}
              label="Founded In"
              error={errors.hasOwnProperty("foundedIn")}
              errorMessage={errors.foundedIn}
              fill={true}
            />
          </div>{" "}
          {companiesCreateError.type === "COMPANIES_CREATE_FAILURE" && (
            <Alert severity="error" style={{ marginTop: 15 }}>
              <AlertTitle>Error</AlertTitle>
              <strong>{companiesCreateError.payload.message}</strong>
            </Alert>
          )}
          <div className={classes.submitWrapperForm}>
            <Button
              type="default"
              view="flat"
              dense={true}
              onClick={handleChangeShowModal}
              style={{ padding: 20 }}
              color="default"
            >
              Cancel
            </Button>
            <Button
              type="default"
              htmlType="submit"
              onClick={handleSubmit}
              loading={loadingCreate}
              view="filled"
              dense={true}
              style={{ padding: 20 }}
              color="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const textEdit = (
    <Formik
      initialValues={{
        name: formData.name,
        ticker: formData.ticker,
        JASICA: formData.JASICA,
        ISIN: formData.ISIN,
        foundedIn: formData.foundedIn,
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => {
        handleSubmitEdit(values, resetForm);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form>
          <div className={classes.input}>
            {" "}
            <Input
              name="name"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.name}
              label="Company Name"
              error={errors.hasOwnProperty("name")}
              errorMessage={errors.name}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="ticker"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.ticker}
              label="Ticker"
              error={errors.hasOwnProperty("ticker")}
              errorMessage={errors.ticker}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="JASICA"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.JASICA}
              label="JASICA"
              error={errors.hasOwnProperty("JASICA")}
              errorMessage={errors.JASICA}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="ISIN"
              onInput={handleChange}
              view="outlined"
              clearButton={false}
              value={values.ISIN}
              label="ISIN"
              error={errors.hasOwnProperty("ISIN")}
              errorMessage={errors.ISIN}
              fill={true}
            />
          </div>
          <div className={classes.input}>
            {" "}
            <Input
              name="foundedIn"
              onInput={handleChange}
              view="outlined"
              type="number"
              clearButton={false}
              value={values.foundedIn}
              label="Founded In"
              error={errors.hasOwnProperty("foundedIn")}
              errorMessage={errors.foundedIn}
              fill={true}
            />
          </div>{" "}
          {companiesEditError.type === "COMPANIES_EDIT_FAILURE" && (
            <Alert severity="error" style={{ marginTop: 15 }}>
              <AlertTitle>Error</AlertTitle>
              <strong>{companiesEditError.payload.message}</strong>
            </Alert>
          )}
          <div className={classes.submitWrapperForm}>
            <Button
              type="default"
              view="flat"
              dense={true}
              onClick={handleChangeShowModal}
              style={{ padding: 20 }}
              color="default"
            >
              Cancel
            </Button>
            <Button
              type="default"
              htmlType="submit"
              onClick={handleSubmit}
              loading={loadingEdit}
              view="filled"
              dense={true}
              style={{ padding: 20 }}
              color="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const onDropRejected = (e) => {
    setTextErrorUploadType(true);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    for (const file of acceptedFiles) formData.append("file", file);
    actionBulkCompaniesUpload(formData);
    setTextErrorUploadType(false); // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    accept: allowedFiles,
    noClick: true,
    onDropRejected: onDropRejected,
  });

  const textUpload = (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <div className={classes.wrapperUpload}>
        <img
          src={require("../../assets/images/cloud-upload.png")}
          style={{ width: "15%", margin: "auto" }}
          alt=""
        />
        <p style={{ textAlign: "center" }}>
          Drag & Drop your .csv file here OR
        </p>
        <Button
          type="default"
          view="filled"
          dense={true}
          onClick={open}
          style={{ padding: 20 }}
          color="primary"
        >
          BROWSE FILE
        </Button>
      </div>
      {companiesBulkUploadError.type === "COMPANIES_BULK_UPLOAD_FAILURE" && (
        <Alert severity="error" style={{ marginTop: 15 }}>
          <AlertTitle>Error</AlertTitle>
          <strong>{companiesBulkUploadError.payload.message}</strong>
        </Alert>
      )}
    </div>
  );

  const textDelete = (
    <div>
      <p>Are you sure want to delete this company?</p>
      <div className={classes.submitWrapperForm}>
        <Button
          type="default"
          view="flat"
          dense={true}
          onClick={handleChangeShowModalDelete}
          style={{ padding: 20 }}
          color="default"
        >
          Cancel
        </Button>
        <Button
          type="default"
          onClick={() =>
            actionCompaniesDelete(formData.id, setIsShowModalDialogDelete)
          }
          loading={loadingDelete}
          view="filled"
          dense={true}
          style={{ padding: 20 }}
          color="danger"
        >
          Delete
        </Button>
      </div>
    </div>
  );

  const isSelected = id => {
    const selected = props.selectedCompanies.find(selected => selected.id === id);
    return selected ? true : false;
  };

  const handleChangeJasica = (e) => {
    setSelectJasica({ ...selectJasica, type: e });
    setParameter({
      ...parameter,
      jasica: e.value === "JASICA" ? undefined : e.value,
    });
  };

  const handleChangeFoundedIn = (e) => {
    setSelectFounded({ ...selectFounded, type: e });
    setParameter({
      ...parameter,
      founded_in: e.value === "FOUNDED IN" ? undefined : e.value,
    });
  };
 
  const options =
    companies.length > 0 &&
    _.uniqBy(companies, 'JASICA').map((data, index) => {
      return {
        value: data.JASICA,
        label: data.JASICA,
      };
    });
    console.log();

  const optionsJesica = [{ value: "JASICA", label: "JASICA" }];

  const optionsFoundedIn =
    companies.length > 0 &&
    _.uniqBy(companies, 'foundedIn').map((data, index) => {
      return {
        value: data.foundedIn,
        label: data.foundedIn,
      };
    });

  const optionsFounded = [{ value: "FOUNDED IN", label: "FOUNDED IN" }];

  return (
    <div className={pageLayoutStyle.root}>
      <MetricContainer>
        <MetricCard
          title="ALL COMPANIES"
          number={companies.length}
          description="All Companies indexed"
          iconBackgroundColor="var(--green50)"
          icon="document"
          loading={loadingCompanies}
        />
        <MetricCard loading={loadingCompanies} />
        <MetricCard loading={loadingCompanies} />
        <MetricCard loading={loadingCompanies} />
        <MetricCard loading={loadingCompanies} />
      </MetricContainer>
      <div className={stylesTables.tableContainer}>
        <div className={stylesTables.tableToolbar}>
          <h1 className={stylesTables.tableTitle}>All Companies</h1>
          <FlexWrapper
            justifyContent="space-between"
            alignItems="center"
          >
            <SearchInput
              view="outlined"
              color="primary"
              value={filter}
              onInput={(e) => handleChangeSearch(e)}
              placeholder="Search"
              className={stylesTables.searchInput}
            />
            <Select
              name="type"
              value={selectJasica.type}
              defaultValue={{ value: "JASICA", label: "JASICA" }}
              onChange={(selected) => handleChangeJasica(selected)}
              options={optionsJesica.concat(options)}
              styles={tableSelectStyle}
            />
            <Select
              name="type"
              value={selectFounded.type}
              defaultValue={{ value: "FOUNDED IN", label: "FOUNDED IN" }}
              onChange={(selected) => handleChangeFoundedIn(selected)}
              options={optionsFounded.concat(optionsFoundedIn)}
              styles={tableSelectStyle}
            />
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Button
                onClick={() => handleChangeShowModalUpload()}
                className={stylesTables.mainButton}
                color="primary"
                view="filled"
                style={{
                  background: "var(--blue70)",
                  height: 42,
                  boxShadow: "none",
                }}
              >
                Import CSV
              </Button>
            </div>
            <Button
              color="primary"
              text={"Add New Company"}
              loading={false}
              disabled={false}
              onClick={() => handleChangeShowModal()}
              className={stylesTables.mainButton}
              style={{
                background: "var(--blue70)",
                height: 42,
                boxShadow: "none",
                marginLeft: 10,
              }}
            />
          </FlexWrapper>{" "}
        </div>
        {textErrorUploadType && (
          <Alert severity="error" style={{ marginTop: 15, marginBottom: 15 }}>
            <AlertTitle>Error</AlertTitle>
            <strong>{"Upload Hanya File CSV"}</strong>
          </Alert>
        )}
        <TableContainer className={classesTab.container}>
          <Table
            className={classesTab.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
            stickyHeader
          >
            <AdvancedTableHeader
              classes={classesTab}
              selectionCounter={props.selectedCompanies.length}
              order={order}
              orderBy={orderBy}
              onSelect={handleSelectAllClick}
              onSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
            />
            {/* <AdvancedTableSeparator /> */}
            <TableBody>
              {loadingCompanies ? (
                <LoadingTable number="5" />
              ) : rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell className={classesTab.checkbox}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell className={classesTab.cell}>
                          {row.ticker}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {row.name}
                        </TableCell>
                        <TableCell className={classesTab.cell}>
                          {row.SIC}
                        </TableCell>
                        <TableCell className={classesTab.cell}>
                          {row.JASICA}
                        </TableCell>
                        <TableCell className={classesTab.cell}>
                          {row.ISIN}
                        </TableCell>
                        <TableCell className={classesTab.cell}>
                          {row.foundedIn}
                        </TableCell>
                        <TableCell
                          className={classesTab.cell}
                          style={{ position: "relative" }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (menu === index) setMenu(null);
                              else setMenu(index);
                            }}
                          >
                            <Icon icon="more" iconSize={16} />
                          </IconButton>
                          {menu === index && (
                            <EnhancedMenu
                              row={row}
                              setMenu={setMenu}
                              setFormData={setFormData}
                              handleChangeShowModalEdit={
                                handleChangeShowModalEdit
                              }
                              handleChangeShowModalDelete={
                                handleChangeShowModalDelete
                              }
                              onClose={() => setMenu(null)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  {" "}
                  <TableCell
                    className={classesTab.emptyResult}
                    colSpan={12}
                    align="center"
                  >
                    <div>No results found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classesTab.pagination} elevation={0}>
          {props.selectedCompanies.length > 0 && (
            <AdvancedTableAction
              selectionCounter={props.selectedCompanies.length}
              actions={[
                {
                  icon: "trash",
                  onClick: () => console.log("delete selected companies")
                }
              ]}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 30]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={() => "rows"}
            labelRowsPerPage={"Show"}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Dialog
        type="default"
        view="raised"
        text={text}
        onClose={handleChangeShowModal}
        isOpen={isShowModal}
        color="default"
        title="Add New Company"
      />
      <DialogEdit
        type="default"
        view="raised"
        text={textEdit}
        onClose={handleChangeShowModalEdit}
        isOpen={isShowModalEdit}
        color="default"
        title="Edit Company"
      />
      <DialogUpload
        type="default"
        view={"raised"}
        text={textUpload}
        onClose={handleChangeShowModalUpload}
        isOpen={isShowModalUpload}
        color="default"
        title="Upload Files"
      />
      <DialogDelete
        type="default"
        view={"raised"}
        text={textDelete}
        onClose={handleChangeShowModalDelete}
        isOpen={isShowModalDialogDelete}
        color="default"
        title="Delete Company"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: state.admin.users,
  loadingCompanies: getUserCompaniesListLoading(state),
  loadingCreate: createLoadingProgress(state),
  loadingDelete: deleteLoadingProgress(state),
  loadingEdit: editLoadingProgress(state),
  selectedUsers: state.admin.selectedUsers,
  companies: state.companies,
  selectedCompanies: state.companies.selectedCompanies
});

const mapDispatchToProps = (dispatch) => ({
  actionGetCompanies: (parameter) => dispatch(actionGetCompanies(parameter)),
  actionCreateCompanies: (params, setIsShowModal) =>
    dispatch(actionCreateCompanies(params, setIsShowModal)),
  actionEditCompanies: (params, paramsId, setIsShowModalEdit) =>
    dispatch(actionEditCompanies(params, paramsId, setIsShowModalEdit)),
  actionCompaniesDelete: (paramsId, setIsShowModalDialogDelete) =>
    dispatch(actionCompaniesDelete(paramsId, setIsShowModalDialogDelete)),
  actionBulkCompaniesUpload: (params) =>
    dispatch(actionBulkCompaniesUpload(params)),
  actionRenderFirst: () => dispatch(actionRenderFirst()),
  actionSetSelectedCompanies: companies => dispatch(actionSetSelectedCompanies(companies))
});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  wrapperUpload: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  emptyResult: {
    backgroundColor: "#fff",
    height: "30vh",
    color: "#000",
    textAlign: "center",
    padding: 0,
    border: 0,
  },
  filter: {
    marginBottom: "20px",
  },
  submitWrapperForm: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "45px",
  },
  input: {
    marginTop: "20px",
  },
  table: {
    minWidth: 750,
    overflowX: "auto",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: "inline-block",
    verticalAlign: "middle",
  },
  nameCell: {
    marginLeft: theme.spacing(1),
    display: "inline-block",
  },
  firstCell: {
    backgroundColor: "#fff",
    verticalAlign: "middle",
  },
  cell: {
    textAlign: "left",
    backgroundColor: "#fff",
  },
  pills: {
    display: "inline",
    textAlign: "left",
    padding: theme.spacing(0.5, 0.8),
    borderRadius: "3rem",
  },
  action: {
    borderRadius: "50%",
    backgroundColor: "#ddd !important",
    boxShadow: "none !important",
    display: "inline",
    margin: theme.spacing(0, 0.5),
  },
}));
