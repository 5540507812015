import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@blueprintjs/core";
import TreeView from "@material-ui/lab/TreeView";
import CustomTree from "./CustomTree";

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  item: {
    paddingLeft: 0
  }
});

function RecursiveTreeView(props) {
  const classes = useStyles();

  const handleToggle = (e, nodeIds) => {
    props.handleToggle(nodeIds)
  };

  const handleClick = (node) => {
    props.handleClick(node)
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={
        <Icon
          icon="folder-open"
          iconSize={16}
          style={{ color: "var(--grey50)" }}
        />
      }
      defaultExpandIcon={
        <Icon
          icon="folder-close"
          iconSize={16}
          style={{ color: "var(--grey50)" }}
        />
      }
      expanded={props.expanded}
      onNodeToggle={handleToggle}
    >
      {props.data.map(node => (
        <CustomTree
          key={node.sectionId}
          data={node}
          ancestors={[props.ancestor, node.sectionId]}
          type={["SUBCHAPTER"]}
          handleDelete={props.handleDelete}
          onClick={handleClick}
        />
      ))}
    </TreeView>
  );
}

export default RecursiveTreeView;
