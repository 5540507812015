import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check, Close } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import {
  acceptAnnotationField,
  rejectAnnotationField,
} from '../../redux/actions/admin';

const useRowStyles = makeStyles({
  annotationInfo: {
    fontSize: '14px',
    margin: 0,
  },
  containerAnnotation: {
    marginBottom: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
    '&:last-of-type': {
      marginBottom: 'unset',
    },
  },
  buttonReject: {
    minWidth: 'unset',
    background: '#F44336',
    height: '32px',
    width: '32px',
    padding: '2px',
    fontSize: '14px',
    '&:hover': {
      background: '#F44336',
    },
    '&:disabled': {
      background: '#FAA9A3',
      color: '#fff',
    },
  },
  buttonAccept: {
    minWidth: 'unset',
    background: '#43A047',
    height: '32px',
    width: '32px',
    padding: '2px',
    marginRight: '10px',
    fontSize: '14px',
    '&:hover': {
      background: '#43A047',
    },
    '&:disabled': {
      background: '#A9D3AB',
      color: '#fff',
    },
  },
});
const TableRowCollapse = ({
  show = false,
  annotationId,
  parentIndex,
  annotations = [],
  className = '',
}) => {
  const dispatch = useDispatch();
  const classes = useRowStyles();
  const [annotationStatuses, setAnnotationStatuses] = useState(
    annotations.map((annotation) => annotation.status)
  );
  const mapping = useSelector(
    ({ admin }) => admin.variableOverview.appliedMapping
  );
  const changeAnnotationStatuses = (index, status) => {
    const copyStatuses = annotationStatuses.slice();
    copyStatuses[index] = status;
    setAnnotationStatuses(copyStatuses);
  };
  return (
    <Collapse in={show} timeout="auto" unmountOnExit>
      {annotations.map((annotation, index) => {
        const { rawData, dataTypeId: fieldType } = annotation;
        const content =
          fieldType === 'date-basic' && rawData
            ? format(new Date(rawData), 'dd/MM/yyyy')
            : rawData;
        return (
          <Grid
            key={`collapse-${index}`}
            container
            className={`${classes.containerAnnotation} ${className}`}
            alignItems="center"
          >
            <Grid item md={9}>
              <span>{content || 'N.A'}</span>
            </Grid>
            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonAccept}
                disabled={annotationStatuses[index] === 3}
                onClick={async () => {
                  const position =
                    parentIndex !== null ? [parentIndex, index] : [index];
                  const isSuccess = await dispatch(
                    acceptAnnotationField(annotationId, mapping, position)
                  );
                  if (isSuccess) changeAnnotationStatuses(index, 3);
                }}
              >
                <Check color="inherit" fontSize="inherit" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonReject}
                disabled={annotationStatuses[index] === 4}
                onClick={async () => {
                  const position =
                    parentIndex !== null ? [parentIndex, index] : [index];
                  const isSuccess = await dispatch(
                    rejectAnnotationField(annotationId, mapping, position)
                  );
                  if (isSuccess) changeAnnotationStatuses(index, 4);
                }}
              >
                <Close color="inherit" fontSize="inherit" />
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </Collapse>
  );
};

export default TableRowCollapse;
