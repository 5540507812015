import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class NotFound extends Component {
    render() {
        return (
                <div className="w-full text-center px-12 py-4">
                    <h1 className="text-xl font-bold text-gray-800">404</h1>
                    <p className="text-gray-800">Sorry, this page does not exist.</p>
                    <Link className="text-red-700 underline" to="/">Back to home</Link>
                </div>
        );
    }
}

export default NotFound;