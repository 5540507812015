import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import Skeleton from 'react-loading-skeleton';
import ActionMenu from './ActionMenu';
import ConfirmationDialog from './ConfirmationDialog';
import DynamicAvatar from '../../components/DynamicAvatar';
import styles from './Style.module.css';
import {
  acceptUser,
  changeRole,
  declineUser,
  deleteUser,
  getUserDetail,
  getUserTasks,
} from '../../redux/actions/admin';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import TableTask from './TableTask';
import { useParams } from 'react-router';
import { upperFirst } from 'lodash';
import { createLoadingSelector } from '../../redux/api/loading';
import { push } from 'connected-react-router';
import PATH_URL from '../../routers/path';

function UserDetail() {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const [menu, setMenu] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    id: null,
    uid: null,
    operation: '',
  });
  const userData = useSelector(({ admin }) => admin.users.selected);
  const loadingUser = useSelector(createLoadingSelector(['GET_USER_DETAIL']));
  const fetchTasks = useCallback(() => {
    const { id } = urlParams;
    if (userData.role) dispatch(getUserTasks(id, userData.role));
  }, [dispatch, urlParams, userData.role]);
  const handleToggleMenu = () => {
    setMenu((prev) => (prev ? null : true));
  };
  const handleCloseDialog = () => {
    setDialog({ open: false, id: null, uid: null, operation: '' });
  };
  const handleChangeRole = () => {
    const { id } = userData;
    return dispatch(changeRole(id, 'admin', { refetchAll: false }));
  };
  const handleAccept = async () => {
    const { id } = userData;
    dispatch(acceptUser(id, { refetchAll: false }));
  };
  const handleBlock = (id) => {
    return dispatch(declineUser(id, { refetchAll: false }));
  };
  const handleDelete = (id, uid) => {
    const isSuccess = dispatch(deleteUser(id, uid));
    if (isSuccess) dispatch(push(PATH_URL.USERS));
  };

  useEffect(() => {
    const { id } = urlParams;
    dispatch(getUserDetail(id));
  }, [dispatch, urlParams]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <div className={pageLayoutStyle.root}>
      <div style={{ marginTop: 16, marginBottom: 28 }}>
        <h6 className={styles.sectionHeader}>Profile</h6>
        <div className={styles.profilePanel}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
            }}
          >
            <div className={styles.profileImage}>
              {loadingUser ? (
                <Skeleton circle={true} width={64} height={64} />
              ) : (
                <DynamicAvatar
                  photoUrl={userData.photoUrl}
                  name={userData.fullName}
                  color="var(--green50)"
                  size={64}
                  textSize={24}
                />
              )}
            </div>
            <div className={styles.profileInfoWrapper}>
              <span
                className={styles.profileMainInfo}
                style={{ marginBottom: 4 }}
              >
                {loadingUser ? <Skeleton width={125} /> : userData.fullName}
              </span>
              <span className={styles.profileSubInfo}>
                {loadingUser ? <Skeleton width={160} /> : userData.email}
              </span>
            </div>
            <div className={styles.profileInfoWrapper}>
              <span
                className={styles.profileSubInfo}
                style={{ marginBottom: 4 }}
              >
                Role
              </span>
              <span className={styles.profileMainInfo}>
                {loadingUser ? (
                  <Skeleton width={90} />
                ) : (
                  upperFirst(userData.role)
                )}
              </span>
            </div>
            <div className={styles.profileInfoWrapper}>
              <span
                className={styles.profileSubInfo}
                style={{ marginBottom: 4 }}
              >
                Status
              </span>
              <span className={styles.profileMainInfo}>
                {loadingUser ? (
                  <Skeleton width={70} />
                ) : (
                  upperFirst(userData.status)
                )}
              </span>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <button className={styles.btnAction} onClick={handleToggleMenu}>
              <span>Action</span>
              <Icon icon="caret-down" iconSize={16} />
            </button>
            {menu && (
              <ActionMenu
                data={userData}
                setMenu={setMenu}
                setDialog={setDialog}
                onClose={() => setMenu(null)}
                accept={handleAccept}
                changeRole={handleChangeRole}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <h6 className={styles.sectionHeader}>All Tasks</h6>
        <TableTask />
      </div>
      <ConfirmationDialog
        data={dialog}
        onCloseDialog={handleCloseDialog}
        setMenu={setMenu}
        onDeleteUser={handleDelete}
        onBlockUser={handleBlock}
      />
    </div>
  );
}

export default UserDetail;
