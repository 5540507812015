import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import FlexWrapper from "../FlexWrapper";
import Typography from "../@setproduct-ui/core/Typography/Typography";

const Img = styled.img`
  height: 28px;
  width: 28px;
`;

const TextContainer = styled.div`
  color: gray;
`;

function Brand(props) {
  const dispatch = useDispatch();

  return (
    <FlexWrapper justifyContent="space-between" alignItems="center">
      <div style={{ marginRight: "12px", cursor: "pointer" }}>
        <Img src={props.logo} alt="brand-logo" onClick={() => dispatch(goBack())} />
      </div>
      <TextContainer>
        <Typography
          type="body_bold"
          colorStep={100}
        >
          {props.filename}
        </Typography>
        <Typography
          type="caption"
          colorStep={60}
        >
          {props.status}
        </Typography>
      </TextContainer>
    </FlexWrapper>
  );
}

Brand.propTypes = {
  logo: PropTypes.any.isRequired,
  filename: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default Brand;
