const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    overflowX: 'auto',
  },
  emptyResult: {
    backgroundColor: '#fff',
    height: '30vh',
    color: '#000',
    textAlign: 'center',
    padding: 0,
    border: 0,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'inline-block',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  submitWrapperForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '45px',
  },
  nameCell: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
  },
  firstCell: {
    backgroundColor: '#fff',
    verticalAlign: 'middle',
  },
  cell: {
    textAlign: 'center',
    backgroundColor: '#fff',
  },
  pills: {
    display: 'inline',
    textAlign: 'center',
    padding: theme.spacing(0.5, 0.8),
    borderRadius: '3rem',
  },
  action: {
    borderRadius: '50%',
    backgroundColor: '#ddd !important',
    boxShadow: 'none !important',
    display: 'inline',
    margin: theme.spacing(0, 0.5),
  },
}));

export { useStyles };
