import { makeStyles } from '@material-ui/core';
import Colors from '../../style/colors'


export default makeStyles(theme => ({

    wrapper: {
      position: "absolute",
      right: 0,
      top: 50,
      width: 300,
      minHeight: 100,
      zIndex: 1000
    },

    searchIcon: {
      color: "var(--grey30)",
      marginRight: 5
    },

    textField: {
      color: "var(--grey30)",
      border: 0,
      width: "100%",
      fontSize: "0.8rem",
    },

    title: {
      fontSize: "0.7rem",
      color: "var(--grey20)",
      margin: "15px 0"
    },


    resultWrapper: {
      maxHeight: 250,
      minHeight: 100,
      overflowY: 'auto',
      paddingRight: 10,
      marginTop: 13
    },

    resultDefault: {
      background: '#ffffff',
      padding: "4px 8px",
      margin: "5px 0",
      borderRadius: 5,
      cursor: "pointer",
      fontSize: 12
    },


    resultActive: {
      background: "var(--grey0)",
      fontWeight: "bold",
      '& $resultIcon': {
        border: `3px solid var(--blue40)`  
      }
    },

    resultIcon: {
      fontSize: 45,
      borderRadius: "50%",
      padding: 0,
      marginRight: 10,
      border: `3px solid #ffffff`
    },

    loadingIcon: {
      width: "15px !important",
      height: "15px !important",
      marginRight: 10,
      color: "var(--grey20)"
    },

    loadingText: {
      fontSize: "0.7rem",
      color: "var(--grey20)"
    }

})); 