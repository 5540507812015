import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Icon } from '@blueprintjs/core';
import AdvancedTableAction from '../../components/AdvancedTable/AdvancedTableAction';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import {
  getDocumentSummary,
  listDocument,
  deleteDocument,
  runOcr,
  runBulkOcr,
  selectDocument,
  changeDocumentFilter,
  changePageDataOnDocument,
  upload,
  estimateOcr,
  getAllDocuments,
} from '../../redux/actions/document';
import styles from './style.module.css';
import { Box, Paper } from '@material-ui/core';
import Select from 'react-select';
import Button from '../../components/@setproduct-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { createLoadingSelector } from '../../redux/api/loading';
import { useDropzone } from 'react-dropzone';
import { LoadingTable } from './Loading';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import stylesTables from '../../components/AdvancedTable/table.module.css';
import FlexWrapper from '../../components/FlexWrapper';
import SearchInput from '../../components/SearchInput';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import PATH_URL from '../../routers/path';
import { useStyles } from './use-styles';
import EnhancedMenu from './ActionMenu';
import DialogOCR from './DialogOCR';
import DialogDelete from './DialogDelete';
import Metrics from './Metrics';
import { convertFileSizeToReadableFormat } from '../../utils/uploadFile';
import UploadList from './UploadList';
import DialogDetailOCR from './DialogDetailOCR';
import DialogReplace from './DialogReplace';

const OCR_STATUS = {
  0: 'Failed',
  1: 'Unprocessed',
  2: 'Queued',
  3: 'In Progress',
  4: 'Done',
  5: 'Done GCS',
};

const [
  loadingDocumentSelector,
  loadingDeleteSelector,
  loadingOcrSelector,
  loadingBulkOcrSelector,
  loadingSummarySelector,
  loadingEstimateSelector,
  loadingGetAllDocumentsSelector,
] = [
  ['LIST_DOCUMENT'],
  ['DELETE_DOCUMENT'],
  ['RUN_OCR'],
  ['RUN_BULK_OCR'],
  ['GET_DOCUMENT_SUMMARY'],
  ['ESTIMATE_BULK_OCR'],
  ['GET_ALL_DOCUMENTS'],
].map(createLoadingSelector);

function DocumentManagement(props) {
  const classes = useStyles();
  const history = useHistory();
  const classesTab = useTableStyle();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('task_title');
  const [menu, setMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogOcr, setShowDialogOcr] = useState(false);
  const [ocrMode, setOcrMode] = useState(false);
  const [ocrEstimation, setOcrEstimation] = useState(0);
  const [showDialogDetailOCR, setShowDialogDetailOCR] = useState(false);
  const [showDialogReplace, setShowDialogReplace] = useState(false);
  const [detailOCRData, setDetailOCRData] = useState({ success: [], fail: [] });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'success',
  });
  const [selectAllMode, setSelectAllMode] = useState('select-in-page');
  const {
    list: documents,
    summary,
    filter,
    pageData,
  } = useSelector(({ document }) => document.documents);
  const { selectedDocuments } = useSelector(({ document }) => document);
  const loadingList = useSelector(loadingDocumentSelector);
  const loadingSummary = useSelector(loadingSummarySelector);
  const loadingDelete = useSelector(loadingDeleteSelector);
  const loadingOcr = useSelector(loadingOcrSelector);
  const loadingBulkOcr = useSelector(loadingBulkOcrSelector);
  const loadingEstimate = useSelector(loadingEstimateSelector);
  const loadingGetAllDocuments = useSelector(loadingGetAllDocumentsSelector);

  useEffect(() => {
    dispatch(getDocumentSummary());
    setMenu(null);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      listDocument({
        ...filter,
        limit: pageData.limit,
        page: pageData.currentPageNumber,
      })
    );
  }, [dispatch, filter, pageData.limit, pageData.currentPageNumber]);

  const reload = useCallback(() => {
    dispatch(getDocumentSummary());
    dispatch(
      listDocument({
        ...filter,
        limit: pageData.limit,
        page: pageData.currentPageNumber,
      })
    );
  }, [dispatch, filter, pageData.limit, pageData.currentPageNumber]);

  const handleCloseSnackbar = () =>
    setSnackbar((prev) => ({ ...prev, open: false }));

  const handleShowModalOcr = async (document) => {
    setOcrMode('single');
    setShowDialogOcr(true);
    setOcrEstimation(0);
    const estimation = await dispatch(estimateOcr([document]));
    setOcrEstimation(estimation);
  };

  const handleShowModalReplaceDocument = async (document) => {
    setShowDialogReplace(true);
  };

  const handleBatchEstimateOcr = async () => {
    setOcrMode('bulk');
    setShowDialogOcr(true);
    setOcrEstimation(0);
    const estimation = await dispatch(estimateOcr(selectedDocuments));
    setOcrEstimation(estimation);
  };

  const handleSubmitOcr = async (storeInDb) => {
    if (ocrMode === 'single') {
      const isSuccess = await dispatch(runOcr(selectedRow.id, storeInDb));
      if (isSuccess) {
        setShowDialogOcr(false);
        reload();
      }
      setSnackbar({
        open: true,
        message: isSuccess
          ? 'OCR successfully submitted'
          : 'OCR failed to submit',
        color: isSuccess ? 'success' : 'error',
      });
    } else {
      const data = await dispatch(runBulkOcr(selectedDocuments, storeInDb));
      // make failed documents stay selected
      const failIds = data.fail.map(({ id }) => id);
      dispatch(
        selectDocument(
          selectedDocuments.filter((doc) => failIds.includes(doc.id))
        )
      );
      setShowDialogOcr(false);
      setDetailOCRData(data);
      setShowDialogDetailOCR(true);
      reload();
    }
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const columns = [
    {
      id: 'filename',
      numeric: false,
      disablePadding: false,
      label: 'FILENAME',
    },
    {
      id: 'uploadedDate',
      numeric: false,
      disablePadding: false,
      label: 'UPLOADED DATE',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'TYPE',
    },
    {
      id: 'numPages',
      numeric: false,
      disablePadding: false,
      label: 'NUMBER OF PAGES',
    },
    {
      id: 'filesize',
      numeric: false,
      disablePadding: false,
      label: 'FILE SIZE',
    },
    {
      id: 'ocr_status',
      numeric: false,
      disablePadding: false,
      label: 'OCR STATUS',
    },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      dispatch(selectDocument(documents));
      setSelectAllMode('select-all-pages');
    } else {
      if (selectAllMode === 'select-all-pages') {
        const allDocuments = await dispatch(getAllDocuments(filter));
        dispatch(selectDocument(allDocuments));
        setSelectAllMode('select-in-page');
      } else dispatch(selectDocument([]));
    }
  };

  const handleClick = (event, doc) => {
    const selected = selectedDocuments.find(
      (selected) => selected.id === doc.id
    );
    if (!selected) {
      dispatch(selectDocument([...selectedDocuments, doc]));
    } else {
      const newSelected = selectedDocuments.filter(
        (selected) => selected.id !== doc.id
      );
      dispatch(selectDocument(newSelected));
    }
  };

  function getStatusPillColorState(status) {
    switch (status) {
      default:
      case 'unverified':
      case 'verified':
        return {
          backgroundColor: '#acf4bc',
          color: '#239639',
        };
    }
  }

  const handleChangePage = (event, newPage) => {
    // material-ui using zero-based page
    dispatch(changePageDataOnDocument({ currentPageNumber: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      changePageDataOnDocument({
        limit: parseInt(event.target.value, 10),
        currentPageNumber: 1,
      })
    );
  };

  const isSelected = (id) => {
    const selected = selectedDocuments.find((selected) => selected.id === id);
    return selected ? true : false;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      dispatch(upload(acceptedFiles, reload));
    },
    [dispatch, reload]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    accept: ['application/pdf'],
    noClick: true,
    noKeyboard: true,
  });

  const handleChangeType = (selected) => {
    dispatch(changeDocumentFilter({ type: selected.value }));
  };

  const handleChangeStatus = (selected) => {
    const value =
      selected.value === 'In Progress' ? 'InProgress' : selected.value;
    dispatch(changeDocumentFilter({ status: value }));
  };

  const getSelectedType = (type) => {
    const reformatted = type.replace('_', ' ').toUpperCase();
    if (type) return { value: type, label: reformatted };
    return { value: '', label: 'Type' };
  };

  const getSelectedStatus = (status) => {
    if (status) return { value: status, label: status.toUpperCase() };
    return { value: '', label: 'Status' };
  };

  return (
    <div className={pageLayoutStyle.root}>
      <Metrics data={summary} loading={loadingSummary} />
      <div className={stylesTables.tableContainer}>
        <div className={stylesTables.tableToolbar}>
          <h1 className={stylesTables.tableTitle}>All Documents</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <Button
              type="circle"
              view="smooth"
              color="primary"
              dense={true}
              icon="refresh"
              loading={loadingList}
              disabled={loadingList}
              style={{ marginRight: '15px' }}
              onClick={reload}
            />
            <SearchInput
              defaultValue={filter.q}
              onApplySearch={(value) => {
                dispatch(changeDocumentFilter({ q: value }));
              }}
              placeholder="Search"
              style={{ width: 170, marginRight: 10 }}
            />
            <Select
              name="type"
              value={getSelectedType(filter.type)}
              defaultValue={{ value: '', label: 'Type' }}
              onChange={(selected) => handleChangeType(selected)}
              options={[
                { value: '', label: 'Type' },
                { value: 'annual_report', label: 'ANNUAL REPORT' },
                {
                  value: 'sustainability_report',
                  label: 'SUSTAINABILITY REPORT',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Select
              name="status"
              value={getSelectedStatus(filter.status)}
              defaultValue={{ value: '', label: 'Status' }}
              onChange={(selected) => handleChangeStatus(selected)}
              options={[
                { value: '', label: 'STATUS' },
                { value: 'Queued', label: 'QUEUE' },
                {
                  value: 'In Progress',
                  label: 'IN PROGRESS',
                },
                {
                  value: 'Done',
                  label: 'DONE',
                },
                {
                  value: 'Unprocessed',
                  label: 'UNPROCESSED',
                },
                {
                  value: 'Failed',
                  label: 'FAILED',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Button
              color="primary"
              text={'Manage Groups'}
              loading={false}
              disabled={false}
              onClick={() => props.history.push(PATH_URL.DOCUMENT_GROUPS)}
              className={styles.mainButton}
            />
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Button
                onClick={open}
                className={styles.mainButton}
                color="primary"
                style={{
                  marginLeft: 10,
                }}
              >
                {'Upload Files'}
              </Button>
            </div>
          </FlexWrapper>{' '}
        </div>
        <TableContainer className={classesTab.container}>
          <Table
            className={classesTab.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <AdvancedTableHeader
              classes={classesTab}
              selectionCounter={selectedDocuments.length}
              order={order}
              orderBy={orderBy}
              onSelect={handleSelectAllClick}
              onSort={handleRequestSort}
              rowCount={documents.length}
              columns={columns}
            />
            <TableBody>
              {loadingList ? (
                <LoadingTable number="5" />
              ) : documents.length > 0 ? (
                stableSort(documents, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.filename}
                        selected={isItemSelected}
                        className={classesTab.rowContainer}
                        onClick={() => {
                          history.push(`/m/documents/${row.id}`);
                        }}
                      >
                        <TableCell
                          className={classesTab.checkbox}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell className={`${classesTab.cell}`}>
                          <Icon icon="document" iconSize={18} />
                          {row.filename}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {format(new Date(row.uploadedDate), 'd/M/yyyy')}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {row.metadata.type === 'annual_report'
                            ? 'Annual Report'
                            : 'Sustainability Report'}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {!row.numPages ? 'PARSING...' : row.numPages}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {convertFileSizeToReadableFormat(row.filesize)}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          <div
                            className={classes.pills}
                            style={getStatusPillColorState(row.ocr.status)}
                          >
                            {OCR_STATUS[row.ocr.status].toUpperCase()}
                          </div>
                        </TableCell>
                        <TableCell
                          className={classesTab.cell}
                          style={{ position: 'relative' }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (menu === index) setMenu(null);
                              else setMenu(index);
                            }}
                          >
                            <Icon icon="more" iconSize={16} />
                          </IconButton>
                          {menu === index && (
                            <EnhancedMenu
                              row={row}
                              onRunOCR={() => {
                                handleShowModalOcr(row);
                                setSelectedRow(row);
                                setMenu(null);
                              }}
                              onReplaceDocument={() => {
                                handleShowModalReplaceDocument(row);
                                setSelectedRow(row);
                                setMenu(null);
                              }}
                              onDeleteDocument={() => {
                                setShowDialogDelete(true);
                                setSelectedRow(row);
                                setMenu(null);
                              }}
                              onClose={() => setMenu(null)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              ) : (
                <TableRow>
                  <TableCell
                    className={classes.emptyResult}
                    colSpan={12}
                    align="center"
                  >
                    <div>No results found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>{' '}
        </TableContainer>
        <Paper className={classesTab.pagination} elevation={0}>
          {loadingGetAllDocuments && (
            <Box
              display="flex"
              alignItems="center"
              style={{ float: 'left', padding: 17 }}
            >
              <span style={{ fontSize: 14 }}>Selecting...</span>
            </Box>
          )}
          {selectedDocuments.length > 0 && !loadingGetAllDocuments && (
            <AdvancedTableAction
              selectionCounter={selectedDocuments.length}
              actions={[
                {
                  icon: 'document-open',
                  onClick: () => {
                    setOcrMode('bulk');
                    handleBatchEstimateOcr();
                  },
                },
              ]}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 30, 100, 500]}
            component="div"
            count={pageData.totalItems}
            rowsPerPage={pageData.limit}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            // material-ui using zero-based page
            page={pageData.currentPageNumber - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <UploadList />
        <DialogDelete
          type="default"
          view={'raised'}
          isOpen={showDialogDelete}
          onCancel={() => setShowDialogDelete(false)}
          onSubmit={async () => {
            const isSuccess = await dispatch(deleteDocument(selectedRow.id));
            if (isSuccess) {
              setShowDialogDelete(false);
              reload();
            }
          }}
          loading={loadingDelete}
        />
        <DialogOCR
          isOpen={showDialogOcr}
          onClose={() => setShowDialogOcr(false)}
          onSubmit={handleSubmitOcr}
          mode={ocrMode}
          selectedRow={selectedRow}
          selectedDocuments={selectedDocuments}
          estimation={ocrEstimation}
          loadingEstimate={loadingEstimate}
          loading={ocrMode === 'single' ? loadingOcr : loadingBulkOcr}
        />
        <DialogDetailOCR
          isOpen={showDialogDetailOCR}
          onClose={() => setShowDialogDetailOCR(false)}
          data={detailOCRData}
        />
        <DialogReplace
          isOpen={showDialogReplace}
          onClose={() => setShowDialogReplace(false)}
          documentId={selectedRow.id}
        />
        <Snackbar
          open={snackbar.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.color}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default DocumentManagement;
