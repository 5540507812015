import React from 'react';
import { Icon } from '@blueprintjs/core';
import { Box } from '@material-ui/core';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styles from '../style.module.css';
export default function CardInformation(props) {
  const { title, icon, loading } = props;
  return (
    <div className={styles.cardInformation}>
      <div className={styles.cardInformationContent}>
        <SkeletonTheme color="#EDF3F6" highlightColor="#d3dadd">
          <Box display="flex" alignContent="center" justifyItems="center">
            {loading ? (
              <Skeleton
                circle={true}
                height={12}
                width={12}
                className={styles.logo}
              />
            ) : icon ? (
              <Icon className={styles.logo} icon={icon} iconSize={12} />
            ) : null}
            <p className={styles.title} style={{ ...(props.style || {}) }}>
              {loading ? <Skeleton width={80} /> : title}
            </p>
          </Box>
        </SkeletonTheme>
      </div>
    </div>
  );
}
