import React from 'react';
import MetricCard from '../../components/MetricCard';
import MetricContainer from '../../components/MetricContainer';

export default function Metrics({ loading, data }) {
  return (
    <MetricContainer>
      <MetricCard
        title="ALL DOCS"
        number={data.all_docs}
        description="All Documents uploaded"
        iconBackgroundColor="var(--green50)"
        loading={loading}
        icon="document"
      />
      <MetricCard
        title="NEW DOCS"
        number={data.new_docs}
        description="Just uploaded"
        iconBackgroundColor="var(--red50)"
        loading={loading}
        icon="plus"
      />
      <MetricCard
        title="QUEUE"
        number={data.queue}
        description="In Queue"
        iconBackgroundColor="var(--green50)"
        loading={loading}
        icon="list"
      />
      <MetricCard
        title="IN PROGRESS"
        number={data.in_progress}
        description="Processing"
        iconBackgroundColor="var(--green50)"
        loading={loading}
        icon="play"
      />
      <MetricCard
        title="READY"
        number={data.ready}
        description="Ready to annotate"
        iconBackgroundColor="var(--green50)"
        loading={loading}
        icon="database"
      />
    </MetricContainer>
  );
}
