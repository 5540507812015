import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { countBy } from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ProgressBar from '../../components/@setproduct-ui/core/ProgressBar/ProgressBar';
import styles from './style.module.css';
import { removeUpload, upload } from '../../redux/actions/document';

export default function UploadList() {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = React.useState(false);
  const [filterStatus, setFilterStatus] = React.useState('all');
  const { files, progress, status, error } = useSelector(
    ({ document }) => document.upload
  );
  const allStatus = Object.values(status);
  const {
    uploading: totalUploading = 0,
    complete: totalComplete = 0,
    error: totalError = 0,
  } = countBy(allStatus);
  const fileNames = Object.keys(files).filter((name) => {
    if (filterStatus === 'all') return true;
    return status[name] === filterStatus;
  });
  const handleRemoveCurrentList = (e) => {
    e.stopPropagation();
    dispatch(removeUpload(fileNames));
    setFilterStatus('all');
  };

  return fileNames.length > 0 ? (
    <div className={styles.uploadListContainer}>
      <div
        onClick={() => setCollapsed((prev) => !prev)}
        className={styles.uploadListHeading}
      >
        <h3>Uploads</h3>
        <Stats
          all={allStatus.length}
          uploading={totalUploading}
          complete={totalComplete}
          error={totalError}
          selectedStatus={filterStatus}
          onChangeFilterStatus={setFilterStatus}
          onRemoveList={handleRemoveCurrentList}
        />
      </div>
      <Collapse in={collapsed}>
        <div className={styles.uploadListItemContainer}>
          {fileNames.map((name) => {
            return (
              <div key={name} className={styles.uploadListItem}>
                <div>
                  <Icon icon="document" iconSize={18} />
                  <span className={styles.uploadListName}>{name}</span>
                </div>
                {status[name] === 'init' && <Init />}
                {status[name] === 'uploading' && (
                  <Uploading progress={progress[name]} />
                )}
                {status[name] === 'complete' && <Complete />}
                {status[name] === 'error' && (
                  <Error error={error[name]} file={files[name]} />
                )}
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  ) : null;
}

function Stats({
  all,
  uploading,
  complete,
  error,
  selectedStatus,
  onChangeFilterStatus,
  onRemoveList,
}) {
  const isActive = (status) => status === selectedStatus;
  const getStyle = (status) => {
    return {
      opacity: selectedStatus === 'all' ? 1 : isActive(status) ? 1 : 0.4,
    };
  };
  const handleChange = (event, status) => {
    event.stopPropagation();
    if (status === selectedStatus) onChangeFilterStatus('all');
    else onChangeFilterStatus(status);
  };
  return (
    <div className={styles.uploadStatsContainer}>
      {['complete', 'error'].includes(selectedStatus) && (
        <button onClick={onRemoveList} className={styles.uploadRemoveAll}>
          Remove all
        </button>
      )}
      <Tooltip title="All" placement="top">
        <button
          className={styles.uploadStatAll}
          onClick={(e) => handleChange(e, 'all')}
          style={getStyle('all')}
        >
          {all}
        </button>
      </Tooltip>
      {uploading > 0 && (
        <Tooltip title="Uploading" placement="top">
          <button
            className={styles.uploadStatUploading}
            onClick={(e) => handleChange(e, 'uploading')}
            style={getStyle('uploading')}
          >
            {uploading}
          </button>
        </Tooltip>
      )}
      {complete > 0 && (
        <Tooltip title="Complete" placement="top">
          <button
            className={styles.uploadStatComplete}
            onClick={(e) => handleChange(e, 'complete')}
            style={getStyle('complete')}
          >
            {complete}
          </button>
        </Tooltip>
      )}
      {error > 0 && (
        <Tooltip title="Error" placement="top">
          <button
            className={styles.uploadStatError}
            onClick={(e) => handleChange(e, 'error')}
            style={getStyle('error')}
          >
            {error}
          </button>
        </Tooltip>
      )}
    </div>
  );
}

function Init() {
  return (
    <div className={styles.uploadListProgressContainer}>
      <span>Initializing...</span>
    </div>
  );
}

function Uploading({ progress }) {
  return (
    <div className={styles.uploadListProgressContainer}>
      <ProgressBar
        type="def"
        view="filled"
        color="primary"
        animate={progress < 100}
        stripes={false}
        style={{ width: '100%', borderRadius: 0 }}
        value={progress / 100}
      />
      {progress < 100 ? (
        <span>{progress}%</span>
      ) : (
        <div>
          <Icon
            icon="upload"
            iconSize={16}
            style={{ fill: 'currentcolor', color: 'var(--blue50)' }}
          />
        </div>
      )}
    </div>
  );
}

function Complete() {
  return (
    <div className={styles.uploadListProgressContainer}>
      <ProgressBar
        type="def"
        view="filled"
        color="primary"
        animate={false}
        stripes={false}
        style={{ width: '100%', borderRadius: 0 }}
        value={100}
      />
      <div>
        <Icon
          icon="tick-circle"
          iconSize={16}
          style={{ fill: 'currentcolor', color: 'var(--green50)' }}
        />
      </div>
    </div>
  );
}

function Error({ error, file }) {
  const dispatch = useDispatch();
  const retry = () => dispatch(upload([file]));
  return (
    <div className={styles.uploadListProgressContainer}>
      <div className={styles.uploadListError}>{error.message}</div>
      <button onClick={retry} title="Retry">
        <Icon icon="repeat" iconSize={14} />
      </button>
    </div>
  );
}
