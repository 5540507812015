import React from "react";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import AdvancedTableAction from "../../components/AdvancedTable/AdvancedTableAction";
import { makeStyles } from "@material-ui/core/styles";

function AdvancedTablePagination(props) {
  const classes = getClasses(props);
  const page = getPage(props);
  const rowCounter = getRowCounter(props);
  const selectionCounter = getSelectionCounter(props);
  const rowsPerPage = getRowPerPage(props);
  const rowsPerPageOptions = getRowPerPageOptions(props);
  const onChangePage = getOnChangePage(props);
  const onChangeRowsPerPage = getOnChangeRowsPerPage(props);

  return (
    <Paper className={classes.root} elevation={0}>
      {/* {selectionCounter > 0 && (
        <AdvancedTableAction selectionCounter={selectionCounter} />
      )} */}
      <TablePagination
        classes={{
          root: classes.pagination,
          selectRoot: classes.paginationLimitSelection,
          toolbar: classes.paginationToolbar,
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rowCounter}
        rowsPerPage={rowsPerPage}
        labelDisplayedRows={() => "rows"}
        labelRowsPerPage={"Show"}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Paper>
  );
}

function getClasses(props) {
  return props.classes !== undefined ? props.classes : defaultStyle();
}

function getSelectionCounter(props) {
  return props.selectionCounter !== undefined ? props.selectionCounter : 0;
}

function getRowCounter(props) {
  return props.rowCounter !== undefined ? props.rowCounter : 0;
}

function getRowPerPage(props) {
  return props.rowsPerPage !== undefined ? props.rowsPerPage : 0;
}

function getPage(props) {
  return props.page !== undefined ? props.page : 1;
}

function getRowPerPageOptions(props) {
  return props.rowsPerPageOptions !== undefined
    ? props.rowsPerPageOptions
    : [5, 10, 25];
}

function getOnChangePage(props) {
  return props.onChangePage !== undefined ? props.onChangePage : () => {};
}

function getOnChangeRowsPerPage(props) {
  return props.onChangeRowsPerPage !== undefined
    ? props.onChangeRowsPerPage
    : () => {};
}

const defaultStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "10px 0",
  },
  paginationLimitSelection: {
    marginRight: 0,
  },
  paginationToolbar: {
    height: "40px",
    minHeight: "40px",
  },
  pagination: {
    overflowY: "hidden",
  },
}));

export default AdvancedTablePagination;
