const initialState = {
  groups: [],
  members: [],
  member: null,
  group: null,
  removedMember: null,
};

export const actionType = {
  InitData: `DOCUMENT_GROUP::INIT_DATA_ACCEPT`,
  LoadDocuments: `DOCUMENT_GROUP::LOAD_DOCUMENT`,
  LoadGroups: `DOCUMENT_GROUP::LOAD_GROUPS`,

  LoadDocumentGroup: `DOCUMENT_GROUP::ACCEPT_SUCCESS`,
  LoadDocument: `DOCUMENT_GROUP::DOCUMENT_ACCEPT_SUCCESS`,
  CreateDocumentGroup: `DOCUMENT_GROUP::CREATE`,
  UpdateDocumentGroup: `DOCUMENT_GROUP::UPDATE_GROUP`,

  SelectGroupAndMember: `DOCUMENT_GROUP::SELECT_GROUP_AND_MEMBER`,
  SelectGroup: `DOCUMENT_GROUP::SELECT_DOCUMENT_GROUP`,
  ClearSelectedObject: `DOCUMENT_GROUP::CLEAR_SELECTED_OBJECT`,

  SelectedRemovedMember: `DOCUMENT_GROUP::SELECT_REMOVED_MEMBER`,
  RemoveMemberFromGroup: `DOCUMENT_GROUP::REMOVE_MEMBER_FROM_GROUP`,

  ReplaceList: `DOCUMENT_GROUP::REPLACE_DOCUMENT_GROUP`,

  AddMemberToGroup: `DOCUMENT_GROUP::ADD_MEMBER_TO_GROUP`,
};

export default (state = initialState, action) => {
  let data = null;
  switch (action.type) {
    case actionType.InitData:
      return {
        ...state,
        groups: action.payload.groups,
        members: action.payload.members,
      };

    case actionType.UpdateDocumentGroup + "_SUCCESS":
      data = state.groups.map((item) => {
        if (item.id === state.group.id) return state.group;
        return item;
      });

      return {
        ...state,
        groups: data,
      };

    case actionType.AddMemberToGroup + "_SUCCESS":
      state.group.registeredIds.push(state.member.id);
      state.group.members.push(state.member);
      state.group.totalMember += 1;

      data = state.groups.map((item) => {
        if (item.id === state.group.id) return state.group;
        return item;
      });

      return {
        ...state,
        groups: data,
      };

    case actionType.SelectGroupAndMember:
      return {
        ...state,
        member: action.member,
        group: action.group,
      };

    case actionType.ClearSelectedObject:
      return {
        ...state,
        member: null,
        group: null,
        removedMember: null,
      };

    case actionType.SelectedRemovedMember:
      return {
        ...state,
        removedMember: action.member,
        group: action.group,
      };

    case actionType.RemoveMemberFromGroup + "_SUCCESS":
      state.group.registeredIds = state.group.registeredIds.filter((id) => {
        if (id !== state.removedMember.id) return id;
      });

      state.group.members = state.group.members.filter((member) => {
        if (member.id !== state.removedMember.id) return member;
      });

      state.group.totalMember -= 1;
      state.group.totalMember =
        state.group.totalMember < 0 ? 0 : state.group.totalMember;

      data = state.groups.map((item) => {
        if (item.id === state.group.id) return state.group;
        return item;
      });

      return {
        ...state,
        groups: data,
      };

    case actionType.ReplaceList:
      return {
        ...state,
        groups: action.groups,
      };

    case actionType.SelectGroup:
      return {
        ...state,
        group: action.group,
      };

    default:
      return state;
  }
};
