import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

function AdvancedTableSeparator (props) {
  return (
    <TableBody>
      <TableRow
        style={{ height: props.height || 18 }}
      ></TableRow>
    </TableBody>
  );
}

export default AdvancedTableSeparator;
