import React from "react";
import Box from '@material-ui/core/Box';
import Button from "../../components/@setproduct-ui/core/Button";
import { Icon } from "@blueprintjs/core";

function AdvancedTableAction (props) {
  const selectionCounter = getSelectionCounter(props);
  const actions = props.actions || [];

  return (
    <Box display="flex" alignItems="center" style={{ float: "left", padding: actions.length ? 10 : 17 }}>
      {actions.map((action, index) => (
        <Button key={index} type="icon" view="smooth" color="default" onClick={action.onClick} style={{ marginRight: 10 }} >
          <Icon icon={action.icon}/>
        </Button>
      ))}
      <span style={{ fontSize: 14 }}>
        {`${selectionCounter} item${selectionCounter > 1 ? "s" : ""} selected`}
      </span>
    </Box>
  )
}

function getSelectionCounter(props) {
  return (
    props.selectionCounter !== undefined
      ? props.selectionCounter
      : 0
  )
}

export default AdvancedTableAction;
