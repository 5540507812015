import React from "react";
import DocumentGroupView from "./DocumentGroup";
import Confirm from "./components/ConfirmDialog";
import { connect } from "react-redux";
import { Action } from "../../redux/actions/documentGroup";
import { actionType } from "../../redux/reducers/documentGroup";
import { TableAction } from "../../redux/actions/tableAction";
import { createLoadingSelector } from "../../redux/api/loading";

const ConfirmAction = (action) => {
  const [removed, setRemoved] = React.useState({});

  const deleteHandler = () =>
    action
      .removeMemberFromGroup(removed.member, removed.group)
      .finally(() => setRemoved({}));

  const closeHandler = () => setRemoved({});

  return Object.freeze({
    ...removed,
    setRemoved,
    deleteHandler,
    closeHandler,
  });
};



const RemoveGroupConfirm = (action) => {
  const [group, setGroup] = React.useState(null)

  const deleteHandler = () => 
    action.deleteGroup(group)
    .then(() => setGroup(null))
  

  const closeHandler = () => setGroup(null)

  return Object.freeze({
    group,
    setGroup,
    deleteHandler,
    closeHandler
  })
}



function DocumentGroup(props) {
  const action = Action(props.dispatch);
  const tableAction = TableAction(props.dispatch);

  const confirm = ConfirmAction(action);
  const removeGroupConfirm = RemoveGroupConfirm(action)

  let groups = props.documentGroup.groups || [];
  let members = props.documentGroup.members || [];

  React.useEffect(() => {
    action.initData();
    action.clearSelectedObject();
  }, []);

  return (
    <>
      <DocumentGroupView
        {...props}
        action={action}
        groups={groups}
        confirm={confirm}
        groupConfirm={removeGroupConfirm}
        documents={members}
        tableAction={tableAction}
      />

      <Confirm
        {...props}
        isConfirmOpen={Boolean(confirm.member)}
        title={`Delete member`}
        text={`Are you sure to remove this member?`}
        deleteHandler={confirm.deleteHandler}
        closeHandler={confirm.closeHandler}
      />

      <Confirm
        {...props}
        isConfirmOpen={Boolean(removeGroupConfirm.group)}
        title={`Delete Group`}
        text={`Are you sure to remove this group?`}
        deleteHandler={removeGroupConfirm.deleteHandler}
        closeHandler={removeGroupConfirm.closeHandler}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    documentGroup: state.documentGroup,
    tableState: state.tableState,

    loadingPage: createLoadingSelector([
      actionType.LoadDocuments,
      actionType.LoadGroups,
    ])(state),

    loadingCreate: createLoadingSelector([actionType.CreateDocumentGroup])(
      state
    ),

    loadingAddMember: createLoadingSelector([actionType.AddMemberToGroup])(
      state
    ),

    loadingUpdate: createLoadingSelector([actionType.UpdateDocumentGroup])(
      state
    ),
  };
}

export default connect(mapStateToProps)(DocumentGroup);
