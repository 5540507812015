const initialState = {
  filename: "",
  ocrStatus: 0,
  uploadedDate: "",
  file: null,
  metadata: {
    type: "",
    year: "",
    companyId: "",
    companyName: ""
  },
  thumbnails: [],
  companies: [],
  boundingBox: [],
  pageBoundingBox: [],
  numPages: 0,
  currentPage: 1,
  pageScale: 1,
  paperSize: { height: 0, width: 0 },
  pageLoaded: false,
  boundingBoxType: [],
  annotationDisabled: false
};

const documentPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILENAME":
      return { ...state, filename: action.payload };
    case "SET_OCR_STATUS":
      return { ...state, ocrStatus: action.payload };
    case "SET_UPLOADED_DATE":
      return { ...state, uploadedDate: action.payload };
    case "SET_METADATA":
      return { ...state, metadata: { ...state.metadata, ...action.payload } };
    case "SET_COMPANY_NAME":
      return {
        ...state,
        metadata: { ...state.metadata, companyName: action.payload }
      };
    case "ZOOM_IN":
      return { ...state, pageScale: state.pageScale + 0.25 };
    case "ZOOM_OUT":
      return { ...state, pageScale: state.pageScale - 0.25 };
    case "RESET_PAGE_SCALE":
      return { ...state, pageScale: initialState.pageScale };
    case "SET_FILE":
      return { ...state, file: action.payload };
    case "SET_BOUNDING_BOX":
      return { ...state, boundingBox: action.payload };
    case "SET_PAGE_BOUNDING_BOX":
      return { ...state, pageBoundingBox: action.payload };
    case "SET_NUM_PAGES":
      return { ...state, numPages: action.payload };
    case "SET_PAPER_SIZE":
      return { ...state, paperSize: action.payload };
    case "SET_CURRENT_PAGE":
      return { ...state, currentPage: action.payload };
    case "NEXT_PAGE":
      return { ...state, currentPage: state.currentPage + 1 };
    case "PREV_PAGE":
      return { ...state, currentPage: state.currentPage - 1 };
    case "SET_PAGE_LOADED":
      return { ...state, pageLoaded: action.payload };
    case "CHANGE_BOUNDING_BOX_TYPE":
      return {
        ...state,
        boundingBoxType:
          state.boundingBoxType.indexOf(action.payload) === -1
            ? [...state.boundingBoxType, action.payload]
            : state.boundingBoxType.filter(curr => curr !== action.payload)
      };
    case "SET_BOUNDING_BOX_TYPE":
      return {
        ...state,
        boundingBoxType: action.payload
      };
    case "GET_COMPANY_LIST_SUCCESS":
      return {
        ...state,
        companies: action.payload
      };
    case "GET_TASK_DATA_SUCCESS":
      return {
        ...state,
        filename: action.payload.document.filename
      };
    case "RESET_BOUNDARIES":
      return {
        ...state,
        boundingBox: []
      };
    case "TOGGLE_ANNOTATION_DISABLE":
      return {
        ...state,
        annotationDisabled: !state.annotationDisabled
      };
    case "RESET_DOCUMENT_PREVIEW":
    case "LOGOUT_SUCCESS":
      return initialState;
    default:
      return state;
  }
};

export const documentPreviewSelector = state => ({ ...state.documentPreview });

export default documentPreviewReducer;
