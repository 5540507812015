import React from 'react';
import styles from './Style.module.css';

const SelectInput = React.forwardRef((props, ref) => {
  const {
    fieldName,
    currentData,
    handleFocusWrapper,
    handleRemoveBoundary,
    isReadOnly,
    isRejected,
    isFocused,
    placeholder,
    options,
  } = props;
  return (
    <select
      name={fieldName}
      ref={ref}
      defaultValue={currentData}
      className={`${styles.select_input} ${
        isFocused ? styles.input_focused : ''
      }`}
      autoComplete="off"
      onFocus={handleFocusWrapper}
      onClick={isReadOnly ? () => {} : handleRemoveBoundary}
      readOnly={isReadOnly}
      style={{
        background: isRejected ? 'var(--red20)' : 'none',
      }}
    >
      <option value="">{placeholder}</option>
      {options.map((item, index) => {
        const optionKey = fieldName + '-option-' + index;
        return (
          <option key={optionKey} value={item}>
            {item}
          </option>
        );
      })}
    </select>
  );
});

export default SelectInput;
