import React, { useState } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { push } from "connected-react-router";
import Input from "../../components/Input";
import FormMessage from "../../components/FormMessage";
import Button from "../../components/@setproduct-ui/core/Button";
import withAuthentication from "../../utils/withAuthentication";
import useViewport from "../../hooks/useViewport";
import { createLoadingSelector } from "../../redux/api/loading";
import { createErrorMessageSelector } from "../../redux/api/error";
import { forgotPassword } from "../../redux/actions/auth";

const FormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Required")
});

const loadingSelector = createLoadingSelector(["FORGOT_PASSWORD"]);
const errorMessageSelector = createErrorMessageSelector(["FORGOT_PASSWORD"]);

function ForgotPassword(props) {
  const [message, setMessage] = useState({ text: "", type: "" });
  const { screenWidth } = useViewport();

  return (
    <div
      style={{
        minWidth: screenWidth > 900 ? "500px" : 0,
        width: screenWidth > 900 ? "40%" : "100%",
        overflow: "auto"
      }}
    >
      <Formik
        enableReinitialize
        validationSchema={FormSchema}
        validateOnSubmit
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          email: ""
        }}
        onSubmit={async (values, actions) => {
          const { email } = values;
          const isSuccess = await props.forgotPassword(email);
          if (isSuccess) {
            setMessage({
              text:
                "A reset password instructions has been sent to your email address.",
              type: "success"
            });
          } else {
            setMessage({
              text: props.errorMessage,
              type: "error"
            });
          }
          if (!props.loading) {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              padding: "0 70px",
              textAlign: "center"
            }}
          >
            <h1 style={{ marginTop: 0 }}>Forgot Your Password?</h1>
            <p style={{ color: "var(--grey50)" }}>
              Please enter the e-mail address you use when creating your
              account, we’ll send you instruction to reset your password.
            </p>
            {message.type && (
              <div style={{ marginBottom: 10 }}>
                <FormMessage type={message.type}>{message.text}</FormMessage>
              </div>
            )}
            <Input
              name="email"
              type="text"
              view="outlined"
              color="default"
              fill={true}
              placeholder="Email"
              onBlur={handleBlur}
              onInput={handleChange}
              value={values.email}
              error={errors.hasOwnProperty("email")}
              errorMessage={errors.email}
            />
            <Button
              type="default"
              view="filled"
              color="primary"
              text="Send reset instructions"
              fill={true}
              onClick={handleSubmit}
              loading={isSubmitting || props.loading}
              style={{ marginTop: 20 }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  errorMessage: errorMessageSelector(state),
  urlParams: state.router.location.query
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(forgotPassword(email)),
  push: path => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(ForgotPassword));
