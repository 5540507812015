import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../style.module.css';
import { Box } from '@material-ui/core';
import Button from '../../../components/@setproduct-ui/core/Button';
import Select from 'react-select';
import SearchInput from './SearchInput';
import { tableSelectStyle } from '../../../components/Select/tableSelectStyle';
import {
  getSubChapterDocumentByTask,
  getSubChapterDocumentBySection,
  selectDocumentTasks,
  selectDocumentSection,
} from '../../../redux/actions/documentDetail';
import CustomTable from './CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../redux/api/loading';
import { Link } from 'react-router-dom';
import PATH_URL from '../../../routers/path';
import { useTableStyle } from '../../../components/AdvancedTable/tableStyle';
import Chips from '../../../components/@setproduct-ui/core/Chips';

export default function TabSubChapter(props) {
  const classes = useTableStyle();
  const paramsPage = useParams();
  const [activeFilterDocument, setActiveFilterDocument] = useState('task');
  const dispatch = useDispatch();
  const columnsTask = useMemo(
    () => [
      {
        id: 'task_name',
        numeric: false,
        disablePadding: false,
        label: 'TASK NAME',
        renderRow: (row) => (
          <Link to={PATH_URL.SPECTATE.replace(':task_id', row.id)}>
            {row.name}
          </Link>
        ),
      },
      {
        id: 'annotator',
        numeric: false,
        disablePadding: false,
        label: 'ANNOTATOR',
        renderRow: (row) => (
          <span className={classes.cell}>{row.annotator}</span>
        ),
      },
      {
        id: 'reviewer',
        numeric: false,
        disablePadding: false,
        label: 'REVIEWER',
        renderRow: (row) => (
          <span className={classes.cell}>{row.reviewer}</span>
        ),
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'STATUS',
        renderRow: (row) => (
          <Chips
            type="dense"
            color="success"
            tag={row.status.toUpperCase()}
            withTick={false}
            round={true}
          />
        ),
      },
    ],
    [classes.cell]
  );
  const columnsSection = useMemo(
    () => [
      {
        id: 'task_name',
        numeric: false,
        disablePadding: false,
        label: 'SUBCHAPTER NAME',
        renderRow: (row) => <>{row.subchapterName}</>,
      },
      {
        id: 'disclose',
        numeric: false,
        disablePadding: false,
        label: 'DISCLOSE',
        renderRow: (row) => <>{row.disclose ? 'YES' : 'NO'}</>,
      },
      {
        id: 'misplace',
        numeric: false,
        disablePadding: false,
        label: 'MISPLACE',
        renderRow: (row) => <>{row.misplace ? 'YES' : 'NO'}</>,
      },
    ],
    []
  );
  const displayData = useMemo(
    () => [
      {
        type: 'task',
        title: 'By Task',
        columns: columnsTask,
        getData: () => getSubChapterDocumentByTask(paramsPage.id),
        selectData: selectDocumentTasks,
        stateLoading: createLoadingSelector([
          'GET_SUB_CHAPTER_DOCUMENT_BY_TASK',
        ]),
        stateData: (state) => state.documentDetail.subChapterTasks,
        stateSelectedData: (state) => state.documentDetail.selectedTasks,
        buttonFilterOptions: [
          [
            { value: '', label: 'Status' },
            {
              value: '',
              label: 'All Task',
            },
            {
              value: 'todo',
              label: 'To Do',
            },
            {
              value: 'ongoing',
              label: 'On  Going',
            },
            {
              value: 'under review',
              label: 'Under Review',
            },
            {
              value: 'accepted',
              label: 'Accepted',
            },
            {
              value: 'rejected',
              label: 'Rejected',
            },
          ],
        ],
        filterBy: [(data) => data.status],
        searchPlaceholder: 'Search by task name',
        searchBy: (regex, data) =>
          regex.test(data.name) ||
          regex.test(data.annotator) ||
          regex.test(data.reviewer),
      },
      {
        type: 'section',
        title: 'By Sub Chapter',
        columns: columnsSection,
        getData: () => getSubChapterDocumentBySection(paramsPage.id),
        selectData: selectDocumentSection,
        stateLoading: createLoadingSelector([
          'GET_SUB_CHAPTER_DOCUMENT_BY_SECTION',
        ]),
        stateData: (state) => state.documentDetail.subChapterSection,
        stateSelectedData: (state) => state.documentDetail.selectedSection,
        buttonFilterOptions: [
          [
            { value: '', label: 'Disclose' },
            {
              value: '',
              label: 'All',
            },
            {
              value: 'yes',
              label: 'Disclose: Yes',
            },
            {
              value: 'no',
              label: 'Disclose: No',
            },
          ],
          [
            { value: '', label: 'Misplace' },
            {
              value: '',
              label: 'All',
            },
            {
              value: 'yes',
              label: 'Misplace: Yes',
            },
            {
              value: 'no',
              label: 'Misplace: No',
            },
          ],
        ],
        filterBy: [
          (data) => (data.disclose ? 'yes' : 'no'),
          (data) => (data.misplace ? 'yes' : 'no'),
        ],
        searchPlaceholder: 'Search by subchapter name',
        searchBy: (regex, data) => regex.test(data.subchapterName),
      },
    ],
    [paramsPage.id, columnsTask, columnsSection]
  );
  const activeData = displayData.filter(
    (v) => v.type === activeFilterDocument
  )[0];

  const [search, setSearch] = useState('');
  const [buttonFilter, setButtonFilter] = useState({});
  const loading = useSelector(activeData.stateLoading);
  const data = useSelector(activeData.stateData);
  const sectionData = useSelector(
    ({ documentDetail }) => documentDetail.subChapterSection
  );
  const selectedData = useSelector(activeData.stateSelectedData);
  const applyFilter = useCallback(
    (data, searchBy, filterBy, option) => {
      let isMatch = true;
      if (search) {
        const regex = RegExp(`${search}`, 'i');
        isMatch = isMatch && searchBy(regex, data);
      }
      if (option.value) isMatch = isMatch && filterBy[0](data) === option.value;
      return isMatch;
    },
    [search]
  );
  const activeListFilter = useMemo(
    () =>
      activeData.buttonFilterOptions.map((btnFilter, indexFilter) =>
        btnFilter
          .filter((_, i) => i > 0)
          .map((v) => ({
            ...v,
            label: `${v.label} ${`(${
              data.filter((d) =>
                applyFilter(d, activeData.searchBy, activeData.filterBy, v)
              ).length
            })`}`,
          }))
      ),
    [activeData, data, applyFilter]
  );
  useEffect(() => {
    setButtonFilter(
      activeData.buttonFilterOptions.reduce(
        (allFilter, v, i) => ({
          ...allFilter,
          [i]: v[0],
        }),
        {}
      )
    );
    setSearch('');
  }, [activeData]);
  useEffect(() => {
    dispatch(displayData[0].getData());
    dispatch(displayData[1].getData());
  }, [displayData, dispatch]);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableToolbar}>
        <Box display="flex" justifyContent="space-between">
          <Box width="auto" className={styles.containerButton}>
            {displayData.map((display, i) => (
              <Button
                key={`indexbtn-${i}`}
                view="outlined"
                color={
                  activeFilterDocument === display.type ? 'primary' : 'default'
                }
                text={`${display.title}${
                  display.type === 'section'
                    ? ` (${
                        (activeFilterDocument === 'section'
                          ? sectionData.filter((data) => {
                              let isMatch = true;
                              if (search) {
                                const regex = RegExp(`${search}`, 'i');
                                isMatch =
                                  isMatch && display.searchBy(regex, data);
                              }
                              if (buttonFilter[0].value) {
                                const disclosed = data.disclose ? 'yes' : 'no';
                                isMatch =
                                  isMatch &&
                                  disclosed === buttonFilter[0].value;
                              }
                              return isMatch;
                            })
                          : sectionData
                        ).length
                      })`
                    : ''
                }`}
                loading={false}
                disabled={false}
                className={styles.mainButton}
                onClick={() => setActiveFilterDocument(display.type)}
              />
            ))}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={styles.containerButton}
          >
            <SearchInput
              defaultValue={search}
              onApplySearch={(value) => {
                setSearch(value);
              }}
              placeholder={activeData.searchPlaceholder}
              style={{ width: '65%', marginRight: 10 }}
            />
            {activeListFilter.map((activeFilter, indexFilter) => (
              <Select
                key={`filter-${indexFilter}`}
                name={`type-${indexFilter}`}
                value={buttonFilter[indexFilter]}
                isSearchable={false}
                onChange={(selected) =>
                  setButtonFilter((prev) => ({
                    ...prev,
                    [indexFilter]: selected,
                  }))
                }
                styles={{
                  ...tableSelectStyle,
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#fff',
                    fontWeight: '500',
                    fontSize: 14,
                  }),
                }}
                className={styles.customSelect}
                options={activeFilter}
              />
            ))}
          </Box>
        </Box>
      </div>
      <CustomTable
        columns={activeData.columns}
        search={search}
        buttonFilter={buttonFilter}
        filterBy={activeData.filterBy}
        searchBy={activeData.searchBy}
        data={data}
        loading={loading !== false}
        selectData={activeData.selectData}
        selectedData={selectedData}
      />
    </div>
  );
}
