import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { errorReducer } from '../api/error';
import { loadingReducer } from '../api/loading';
import { connectRouter } from 'connected-react-router';
import userProfileReducer from './userProfile';
import adminReducer from './admin';
import documentPreviewReducer from './documentPreview';
import companiesReducer from './companies';
import documentsReducer from './document';
import annotatorTeamReducer from './annotatorTeam';
import annotationReducer from './annotation';
import documentGroupReducer from './documentGroup';
import reviewerReducer from './reviewer';
import tableReducer from './table';
import documentDetailReducer from './documentDetail';
import documentSearchReducer from './documentSearch';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    companies: companiesReducer,
    document: documentsReducer,
    reviewer: reviewerReducer,
    tableState: tableReducer,
    firebase: firebaseReducer,
    loading: loadingReducer,
    error: errorReducer,
    userProfile: userProfileReducer,
    admin: adminReducer,
    annotatorTeam: annotatorTeamReducer,
    documentGroup: documentGroupReducer,
    documentPreview: documentPreviewReducer,
    annotation: annotationReducer,
    documentDetail: documentDetailReducer,
    documentSearch: documentSearchReducer,
  });

export default rootReducer;
