import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { get, upperFirst } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import DynamicAvatar from '../../components/DynamicAvatar';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '../../components/@setproduct-ui/core/Menu';
import MenuItem from '../../components/@setproduct-ui/core/Menu/MenuItem';
import Chips from '../../components/@setproduct-ui/core/Chips';
import SearchInput from '../../components/@setproduct-ui/core/Inputs/SearchInput';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@blueprintjs/core';
import Button from '../../components/@setproduct-ui/core/Button';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import AdvancedTableAction from '../../components/AdvancedTable/AdvancedTableAction';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import FlexWrapper from '../../components/FlexWrapper';
import Dialog from '../../components/CustomDialog';
import styles from '../../components/AdvancedTable/table.module.css';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import {
  getUserList,
  selectUser,
  acceptUser,
  declineUser,
  acceptSelectedUser,
  declineSelectedUser,
  changeRole,
  deleteUser,
} from '../../redux/actions/admin';
import { createLoadingSelector } from '../../redux/api/loading';
import MetricCard from '../../components/MetricCard';
import MetricContainer from '../../components/MetricContainer';
import PATH_URL from '../../routers/path';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import { getComparator, stableSort } from '../../components/AdvancedTable';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import { ClickOutsideContainer } from '../../components/utils/ClickOutsideContainer';
import { useHistory } from 'react-router';

const loadingSelectorQuery = createLoadingSelector(['GET_USER_LIST']);
const loadingSelectorMutation = createLoadingSelector([
  'ACCEPT_USER',
  'DECLINE_USER',
  'CHANGE_USER_ROLE',
  'DELETE_USER',
]);

const columns = [
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'USER',
  },
  { id: 'email', numeric: false, disablePadding: false, label: 'EMAIL' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'CREATED' },
  // {
  //   id: 'signed_in',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'SIGNED IN',
  // },
  { id: 'role', numeric: false, disablePadding: false, label: 'ROLE' },
  { id: 'status', numeric: false, disablePadding: false, label: 'STATUS' },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

function EnhancedMenu(props) {
  const { row, setMenu, setDialog, onClose } = props;
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu view="smooth" type="dense" color="default" className={styles.menu}>
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Reset Password"
          onClick={() => {
            setDialog({
              open: true,
              id: row.email,
              operation: 'reset',
            });
            return onClose();
          }}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Set as admin"
          disabled={
            row.block_status || !row.emailVerified || row.role === 'admin'
          }
          onClick={async () => {
            const isSuccess = await props.changeRole(row.id, 'admin');
            if (isSuccess) {
              setMenu(null);
            }
          }}
        />
        {row.block_status ? (
          <MenuItem
            type="dense"
            view="smooth"
            color="default"
            text="Enable account"
            disabled={row.role === 'admin' || !row.emailVerified}
            onClick={() => {
              props.accept(row.id);
              setMenu(null);
            }}
          />
        ) : (
          <MenuItem
            type="dense"
            view="smooth"
            color="default"
            text="Disable account"
            disabled={row.role === 'admin' || !row.emailVerified}
            onClick={() => {
              setDialog({
                open: true,
                id: row.id,
                operation: 'block',
              });
              return onClose();
            }}
          />
        )}
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Delete user"
          onClick={() => {
            setDialog({
              open: true,
              id: row.profile.id,
              uid: row.uid,
              operation: 'delete',
            });
            return onClose();
          }}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}

function Users(props) {
  const classes = useTableStyle();
  const history = useHistory();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('task_title');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menu, setMenu] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    id: null,
    uid: null,
    operation: '',
  });
  const [search, setSearch] = useState({
    name: '',
    role: { value: '', label: 'Role' },
    status: { value: '', label: 'Status' },
  });
  const { getUserList } = props;

  const loadingQuery = useSelector((state) => loadingSelectorQuery(state));
  const loadingMutation = useSelector((state) =>
    loadingSelectorMutation(state)
  );
  const users = useSelector((state) => state.admin.users);
  const selectedUsers = useSelector((state) => state.admin.selectedUsers);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      props.selectUser(users.list);
    } else {
      props.selectUser([]);
    }
  };

  const handleClick = (event, user) => {
    const selected = selectedUsers.find((selected) => selected.id === user.id);
    if (!selected) {
      props.selectUser([...selectedUsers, user]);
    } else {
      const newSelected = selectedUsers.filter(
        (selected) => selected.id !== user.id
      );
      props.selectUser(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => {
    const selected = selectedUsers.find((selected) => selected.id === id);
    return selected ? true : false;
  };

  // const containAdmin = () => {
  //   const admin = selectedUsers.find(
  //     selected => selected.role === "admin"
  //   );
  //   return admin ? true : false;
  // };

  // const isUnverifiedAll = () => {
  //   return selectedUsers.reduce((acc, selected) => {
  //     if (selected.emailVerified) acc = false;
  //     return acc;
  //   }, true);
  // };

  // const generateSelectionActions = () => {
  //   return !containAdmin() && !isUnverifiedAll()
  //     ? [
  //         {
  //           icon: "tick",
  //           handler: props.acceptSelectedUser
  //         },
  //         {
  //           icon: "cross",
  //           handler: props.declineSelectedUser
  //         }
  //       ]
  //     : [];
  // };

  const displayStatus = (isVerified, isBlocked) => {
    if (isVerified) {
      if (isBlocked) return 'Blocked';
      return 'Active';
    }
    return 'Unverified';
  };

  const displayStatusColor = (isVerified, isBlocked) => {
    if (isVerified) {
      if (isBlocked) return 'danger';
      return 'success';
    }
    return 'primary';
  };

  const displayDialogTitle = () => {
    if (dialog.operation === 'delete') return 'Delete User';
    if (dialog.operation === 'block') return 'Block User';
    return 'Reset password?';
  };

  const displayDialogButton = () => {
    if (dialog.operation === 'delete') return 'Delete';
    if (dialog.operation === 'block') return 'Block';
    return 'Send';
  };

  const closeDialog = () => {
    setDialog({ open: false, id: null, uid: null, operation: '' });
  };

  const applySearch = (user) => {
    const { name, role, status } = search;
    let isMatch = true;
    if (name) {
      const regex = RegExp(`${name}`, 'i');
      isMatch =
        isMatch &&
        (regex.test(get(user, 'profile.name', '')) || regex.test(user.email));
    }
    if (role.value) {
      isMatch = isMatch && user.role === role.value;
    }
    if (status.value) {
      if (status.value === 'unverified') {
        isMatch = isMatch && !user.emailVerified;
      } else if (status.value === 'verified') {
        isMatch = isMatch && user.emailVerified;
      } else if (status.value === 'active') {
        isMatch = isMatch && !user.block_status;
      } else isMatch = isMatch && user.block_status;
    }
    return isMatch;
  };

  const handleRowClick = (row) => {
    const { id } = row;
    history.push(PATH_URL.USER_DETAIL.replace(':id', id));
  };

  return (
    <div className={pageLayoutStyle.root}>
      {/* DASHBOARD CARDS */}
      <MetricContainer>
        <MetricCard
          title="ALL USERS"
          number={users.summary.all_users}
          description="All users registered"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingQuery}
        />
        <MetricCard
          title="ADMIN"
          number={users.summary.admin}
          description="Administrators"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingQuery}
        />
        <MetricCard
          title="ANNOTATORS"
          number={users.summary.annotators}
          description="Annotate squads"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingQuery}
        />
        <MetricCard
          title="REVIEWERS"
          number={users.summary.reviewers}
          description="Reviewer squads"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingQuery}
        />
        <MetricCard
          title="BLOCKED"
          number={users.summary.block_status}
          description="Blocked users"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingQuery}
        />
      </MetricContainer>

      {/* TABLE */}

      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <h1 className={styles.tableTitle}>All Users</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              view="outlined"
              color="primary"
              value={search.name}
              onInput={(e) => setSearch({ ...search, name: e.target.value })}
              placeholder="Search"
              className={styles.searchInput}
            />
            <Select
              name="role"
              defaultValue={{ value: '', label: 'Role' }}
              value={search.role}
              onChange={(selected) => setSearch({ ...search, role: selected })}
              isSearchable={false}
              options={[
                { value: '', label: 'Role' },
                {
                  value: 'admin',
                  label: 'Admin',
                },
                {
                  value: 'annotator',
                  label: 'Annotator',
                },
                {
                  value: 'reviewer',
                  label: 'Reviewer',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Select
              name="status"
              defaultValue={{ value: '', label: 'Status' }}
              value={search.status}
              onChange={(selected) =>
                setSearch({ ...search, status: selected })
              }
              isSearchable={false}
              options={[
                { value: '', label: 'Status' },
                { value: 'unverified', label: 'Unverified' },
                { value: 'verified', label: 'Verified' },
                { value: 'active', label: 'Active' },
                { value: 'blocked', label: 'Blocked' },
              ]}
              styles={tableSelectStyle}
            />
            <Button
              view="filled"
              color="primary"
              text="Manage Teams"
              loading={false}
              disabled={false}
              onClick={() => props.history.push(PATH_URL.ANNOTATORS_TEAMS)}
              className={styles.mainButton}
              style={{
                background: 'var(--blue70)',
                height: 42,
                boxShadow: 'none',
              }}
            />
          </FlexWrapper>
        </div>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <AdvancedTableHeader
              classes={classes}
              selectionCounter={selectedUsers.length}
              order={order}
              orderBy={orderBy}
              onSelect={handleSelectAllClick}
              onSort={handleRequestSort}
              rowCount={users.list.length}
              columns={columns}
            />

            <TableBody>
              {loadingQuery ? (
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index} role="checkbox">
                      <TableCell className={classes.checkbox}>
                        <Skeleton
                          height={20}
                          width={20}
                          style={{ marginLeft: 10 }}
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Skeleton
                            circle={true}
                            height={30}
                            width={30}
                            style={{ marginRight: 10 }}
                          />
                          <Skeleton width={80} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={30} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : users.list.filter(applySearch).length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} className={classes.emptyResult}>
                    No results found
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(
                  users.list.filter(applySearch),
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell className={classes.checkbox}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          className={`${classes.firstCell} ${classes.cell} ${classes.clickable}`}
                          size="medium"
                          onClick={() => handleRowClick(row)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <DynamicAvatar
                              photoUrl={row.photoUrl}
                              name={row.profile.name}
                              color="var(--green50)"
                            />
                            <span className={classes.nameCell}>
                              {row.profile.name}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell
                          className={`${classes.cell} ${classes.clickable}`}
                          onClick={() => handleRowClick(row)}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          className={`${classes.cell} ${classes.clickable}`}
                          size="small"
                          onClick={() => handleRowClick(row)}
                        >
                          {row.createdAt &&
                            format(new Date(row.createdAt), 'LLL d, yyyy')}
                        </TableCell>
                        {/* <TableCell
                          className={`${classes.cell} ${classes.clickable}`}
                          size="small"
                          onClick={() => handleRowClick(row)}
                        >
                          {row.log_logins.length
                            ? format(
                                new Date(row.log_logins[0].createdAt),
                                'LLL d, yyyy'
                              )
                            : '-'}
                        </TableCell> */}
                        <TableCell
                          className={`${classes.cell} ${classes.clickable}`}
                          size="small"
                          onClick={() => handleRowClick(row)}
                        >
                          {upperFirst(row.role)}
                        </TableCell>
                        <TableCell
                          className={`${classes.cell} ${classes.clickable}`}
                          size="medium"
                          onClick={() => handleRowClick(row)}
                        >
                          <Chips
                            type="dense"
                            tag={displayStatus(
                              row.emailVerified,
                              row.block_status
                            )}
                            color={displayStatusColor(
                              row.emailVerified,
                              row.block_status
                            )}
                            round={true}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          size="medium"
                          style={{ position: 'relative' }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (menu === row.id) setMenu(null);
                              else setMenu(row.id);
                            }}
                          >
                            <Icon icon="more" iconSize={16} />
                          </IconButton>
                          {menu === row.id && (
                            <EnhancedMenu
                              row={row}
                              setMenu={setMenu}
                              setDialog={setDialog}
                              onClose={() => setMenu(null)}
                              accept={props.accept}
                              changeRole={props.changeRole}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          {selectedUsers.length > 0 && (
            <AdvancedTableAction
              selectionCounter={selectedUsers.length}
              actions={[
                {
                  icon: 'trash',
                  onClick: () => console.log('delete selected users'),
                },
              ]}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 30]}
            component="div"
            count={users.list.filter(applySearch).length}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      <Dialog
        view="raised"
        title={displayDialogTitle()}
        backdropOpacity={40}
        isOpen={dialog.open}
        onClose={closeDialog}
      >
        {dialog.operation === 'delete' || dialog.operation === 'block' ? (
          <p>Are you sure you want to {dialog.operation} this user?</p>
        ) : (
          <p>
            Send reset password email.
            <br />
            <br />
            User account
            <br />
            <b>{dialog.id}</b>
          </p>
        )}
        <FlexWrapper justifyContent="flex-end" margin="10px 0 0 0">
          <Button
            view="flat"
            color="default"
            dense={false}
            disabled={loadingMutation}
            onClick={closeDialog}
            text="Cancel"
            style={{ marginRight: 10 }}
          />
          <Button
            view="filled"
            color="danger"
            dense={false}
            onClick={async () => {
              let isSuccess;
              if (dialog.operation === 'delete') {
                const { id, uid } = dialog;
                isSuccess = await props.deleteUser(id, uid);
              } else {
                isSuccess = await props.decline(dialog.id);
              }
              if (isSuccess) {
                closeDialog();
                setMenu(null);
              }
            }}
            loading={loadingMutation}
            text={displayDialogButton()}
          />
        </FlexWrapper>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getUserList: () => dispatch(getUserList()),
  selectUser: (user) => dispatch(selectUser(user)),
  accept: (id) => dispatch(acceptUser(id)),
  decline: (id) => dispatch(declineUser(id)),
  acceptSelectedUser: () => dispatch(acceptSelectedUser()),
  declineSelectedUser: () => dispatch(declineSelectedUser()),
  changeRole: (id, role) => dispatch(changeRole(id, role)),
  deleteUser: (profileId, uid) => dispatch(deleteUser(profileId, uid)),
});

export default connect(null, mapDispatchToProps)(Users);
