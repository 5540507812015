import React  from "react";
import { connect } from "react-redux";
import FlexWrapper from "../../components/FlexWrapper";
import Input from "../../components/Input";
import Button from "../../components/@setproduct-ui/core/Button";
import { addSection } from "../../redux/actions/admin"
import CustomDialog from "../../components/CustomDialog";
import Select from "react-select";
import { Form, Formik } from "formik";
import styles from "./style.module.css";

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: "2.5rem"
  })
};

function TemplateForm(props) {
  return (
    <CustomDialog
      view="raised"
      title="Create New Template"
      backdropOpacity={40}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          templateName: '',
          modelReport: {value: 'annual_report', label: "Annual Report"}
        }}
        onSubmit={async (values, actions) => {
          const isSuccess = await props.addSection({
            name: values.templateName,
            modelReport: values.modelReport.value,
            type: "TEMPLATE",
            ancestors: [],
            parent: null
          });
          if (isSuccess) {
            props.onClose()
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center"
            }}
          >
            <label htmlFor="taskPrefix" className={styles.formLabel}>
              Template Name
            </label>
            <Input
              name="templateName"
              type="text"
              view="outlined"
              color="default"
              onBlur={handleBlur}
              onInput={handleChange}
              value={values.templateName}
              fill={true}
              error={errors.hasOwnProperty("templateName")}
              errorMessage={errors.templateName}
              style={{ marginBottom: 10 }}
            />
            <label htmlFor="type" className={styles.formLabel}>
              Type
            </label>
            <Select
              name="modelReport"
              value={values.modelReport}
              options={[
                {
                  value: "annual_report",
                  label: "Annual Report"
                },
                {
                  value: "sustainability_report",
                  label: "Sustainability Report"
                },
                {
                  value: "any",
                  label: "Any Report"
                }
              ]}
              onChange={selected => {
                setFieldValue("modelReport", selected);
              }}
              className={styles.select}
              styles={customSelectStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "black"
                }
              })}
            />
            <FlexWrapper justifyContent="flex-end">
              <Button
                view="flat"
                color="default"
                text="Cancel"
                disabled={isSubmitting || props.loading}
                onClick={props.onClose}
              />
              <Button
                view="filled"
                color="primary"
                text="Create"
                style={{ marginLeft: 20 }}
                loading={isSubmitting || props.loading}
                disabled={values.templateName === ""}
                onClick={handleSubmit}
              />
            </FlexWrapper>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  )
}

const mapDispatchToProps = dispatch => ({
  addSection: data => dispatch(addSection(data))
})

export default connect(null, mapDispatchToProps)(TemplateForm);
