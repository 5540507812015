import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { capitalize } from "lodash";
import FlexWrapper from "../../components/FlexWrapper";
import Heading from "../../components/Heading";
import Spinner from "../../components/Loading";
import TreeForm from "./TreeForm";
import { setSelectedSection } from "../../redux/actions/annotation";
import { createLoadingSelector } from "../../redux/api/loading";
import {
  getVariableDetail,
  resetSelectedVariableField,
  setSelectedVariableField
} from "../../redux/actions/annotation";
import styles from "./style.module.css";
import { Popover, Position } from "@blueprintjs/core";

function parseVariableType(type) {
  const typeArr = type.split("-");
  return capitalize(typeArr.slice(0, typeArr.length - 1).join(" "));
}

function AnnotationVariable(props) {
  const dispatch = useDispatch();
  const loadingVariableDetail = useSelector(state =>
    createLoadingSelector(["GET_VARIABLE_DETAIL", "GET_ANNOTATION_VARIABLE"])(
      state
    )
  );
  const sections = useSelector(state => state.annotation.sections);
  const selectedSection = useSelector(
    state => state.annotation.selectedSection
  );
  const selectedField = useSelector(state => state.annotation.selectedField);
  const annotatorId = useSelector(state => state.annotation.annotator.id);

  const renderTreeForm = () => {
    const currentVariable = sections[selectedSection];
    if (
      currentVariable &&
      currentVariable.structure &&
      currentVariable.annotationItems
    ) {
      if (currentVariable.isArray) {
        return currentVariable.annotationItems.map((annotation, index) => (
          <div
            key={currentVariable.name + "-" + index}
            className={styles.border_bottom}
          >
            <div
              id={`${currentVariable.dtype}-${index+1}`}
              className={styles.nested_header_wrapper}
              style={{ marginTop: 0, marginBottom: 10 }}
            >
              <h2 className={styles.nested_header} style={{ fontWeight: 700 }}>
                {parseVariableType(currentVariable.dtype)} {index + 1}
              </h2>
            </div>
            <TreeForm
              root={currentVariable}
              annotationItemIndex={index}
              data={annotation}
              parents={[]}
              focusPage={props.handleClickBoundary}
              selectedFieldName={selectedField.keyMap}
              handleSetDirty={props.handleSetDirty}
            />
          </div>
        ));
      }
      return (
        <TreeForm
          key={currentVariable.name}
          root={currentVariable}
          annotationItemIndex={0}
          data={currentVariable.annotationItems[0]}
          parents={[]}
          focusPage={props.handleClickBoundary}
          selectedFieldName={selectedField.keyMap}
          handleSetDirty={props.handleSetDirty}
        />
      );
    }
  };

  return (
    <>
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ fontSize: 12 }}
      >
        <div
          style={{
            maxHeight: "25vh",
            overflowY: "auto",
            borderBottom: "1px solid var(--grey10)"
          }}
        >
          {Object.keys(sections).map(sectionId => (
            <FlexWrapper
              key={sectionId}
              justifyContent="space-between"
              padding="5px 8px"
              style={{
                cursor: "pointer",
                background: selectedSection === sectionId ? "var(--grey0)" : "",
                fontStyle: props.dirty.includes(
                  compact(sections[sectionId].name)
                )
                  ? "italic"
                  : "normal"
              }}
              onClick={() => {
                // prevent user from changing selected variable while other variable is loading
                if (!loadingVariableDetail) {
                  dispatch(setSelectedSection(sectionId));
                  dispatch(resetSelectedVariableField());
                  const current = sections[sectionId];
                  dispatch(
                    setSelectedVariableField({ isArray: current.isArray })
                  );
                  if (!current.structure) {
                    dispatch(
                      getVariableDetail(sectionId, current.dtype, annotatorId)
                    );
                  }
                }
              }}
            >
              <div>
                <span className={styles.variable_name}>
                  {sections[sectionId].name}
                </span>
                <span className={styles.variable_type}>
                  {`${sections[sectionId].isArray ? "Array of " : ""}` +
                    parseVariableType(sections[sectionId].dtype)}
                </span>
              </div>
            </FlexWrapper>
          ))}
        </div>
      </FlexWrapper>
      {selectedSection ? (
        loadingVariableDetail ? (
          <FlexWrapper
            flexFlow="column"
            justifyContent="flex-start"
            alignItems="stretch"
            padding="10px"
          >
            <Spinner />
          </FlexWrapper>
        ) : (
          <>
            <div className={styles.nested_header_wrapper} style={{ margin: 0 }}>
              <Heading
                text={parseVariableType(sections[selectedSection].dtype)}
                style={{ marginTop: 10, marginLeft: 10 }}
              />
              {sections[selectedSection].isArray && (
                <div
                  className={styles.header_button_wrapper}
                  style={{ marginRight: 10 }}
                >
                  {sections[selectedSection].annotationItems && (
                    <Popover position={Position.BOTTOM_RIGHT}>
                      <span className={styles.count_badge}>
                        {sections[selectedSection].annotationItems.length}
                      </span>
                      <div className={styles.item_options}>
                        <ul>
                          {Array(sections[selectedSection].annotationItems.length).fill(null).map((item, index) => (
                            <li
                              onClick={() => {
                                const el = document.querySelector(`#${sections[selectedSection].dtype}-${index + 1}`);
                                el.scrollIntoView({ behavior: 'smooth' });
                              }}
                            >
                              {parseVariableType(sections[selectedSection].dtype)} {index + 1}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Popover>
                  )}
                </div>
              )}
            </div>
            <FlexWrapper
              flexFlow="column"
              justifyContent="flex-start"
              alignItems="stretch"
              padding="10px"
              style={{
                fontSize: 12,
                maxHeight: "50vh",
                overflowY: "auto",
                borderTop: "1px solid var(--grey20)"
              }}
            >
              {renderTreeForm()}
            </FlexWrapper>
          </>
        )
      ) : (
        <FlexWrapper
          flexFlow="column"
          justifyContent="flex-start"
          alignItems="stretch"
          padding="10px"
          style={{ fontSize: 12 }}
        >
          <span>No selected variable</span>
        </FlexWrapper>
      )}
    </>
  );
}

function compact(name) {
  return name.toLowerCase().replaceAll(" ", "-");
}

export default AnnotationVariable;
