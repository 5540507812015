import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchInput from "../../components/@setproduct-ui/core/Inputs/SearchInput";
import Typography from "@material-ui/core/Typography";
import { Icon } from "@blueprintjs/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import AdvancedTableHeader from "../../components/AdvancedTable/AdvancedTableHeader";
import AdvancedTableSeparator from "../../components/AdvancedTable/AdvancedTableSeparator";
import AdvancedTablePagination from "../../components/AdvancedTable/AdvancedTablePagination";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const reviewersDummyAPI = [
  {
    name: "#b8556d5a-fb48-49b4-a17d-6fda1fde108e",
    reviewer: {
      fullname: "Adi Surya Santoso",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    type: "SECTION",
    document: "AALI_AR_2018",
    section: "-",
    status: "IN PROGRESS",
  },
  {
    name: "#b8556d5a-fb48-49b4-a17d-6fda1fde108e",
    reviewer: {
      fullname: "Adi Surya Santoso",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    type: "SECTION",
    document: "AALI_AR_2018",
    section: "-",
    status: "ON REVIEW",
  },
  {
    name: "#b8556d5a-fb48-49b4-a17d-6fda1fde108e",
    reviewer: {
      fullname: "Adi Surya Santoso",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    type: "SUBSECTION",
    document: "AALI_AR_2018",
    section: "NAMA BAB",
    status: "DONE",
  },
  {
    name: "#b8556d5a-fb48-49b4-a17d-6fda1fde108e",
    reviewer: {
      fullname: "Adi Surya Santoso",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    type: "VARIABLE",
    document: "AALI_AR_2018",
    section: "-",
    status: "DONE",
  },
];

function AdminAnnotator(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  useEffect(() => {
    setRows(reviewersDummyAPI);
  }, []);

  const columns = [
    {
      id: "name",
      label: "TASK NAME",
    },
    {
      id: "reviewer",
      label: "REVIEWER",
    },
    {
      id: "type",
      label: "TYPE",
    },
    {
      id: "document",
      label: "DOCUMENT",
    },
    {
      id: "section",
      label: "SECTION",
    },
    {
      id: "status",
      label: "STATUS",
    },
  ];

  return (
    <div>
      <Grid container alignItems="center" className={classes.filter}>
        <Grid item xs={4}>
          <Grid container>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                color="inherit"
                style={{ fontWeight: "bold", fontSize: 30 }}
                variant="h5"
                component="h5"
              >
                Annotators
              </Typography>

              <Icon
                icon="plus"
                iconSize={24}
                color="#76a9ff"
                style={{ marginLeft: 10 }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container justify="flex-end" alignItems="center">
            <div className={classes.selecedCounter}>
              <span>3 selected</span>

              <IconButton aria-label="filter">
                <Icon icon="cross" iconSize={20} color="#4a4b57" />
              </IconButton>
            </div>

            <IconButton aria-label="filter">
              <Icon icon="trash" iconSize={20} color="#4a4b57" />
            </IconButton>

            <IconButton aria-label="filter">
              <Icon icon="filter" iconSize={20} color="#4a4b57" />
            </IconButton>

            <SearchInput placeholder="Search" />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        className={classes.filter}
        spacing={2}
      >
        <Grid item xs={7}>
          <Paper elevation={0} className={classes.card}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.cardTitle}
            >
              Tasks
            </Typography>

            <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={3}
            >
              <Grid item>
                <div className={classes.iconLabeled}>
                  <Icon
                    icon="person"
                    iconSize={24}
                    color="#76a9ff"
                    style={{ marginRight: 10 }}
                  />

                  <Typography variant="h5" component="h5">
                    18
                  </Typography>
                </div>

                <Typography component="p">Assigned</Typography>
              </Grid>

              <Grid item>
                <div className={classes.iconLabeled}>
                  <Icon
                    icon="annotation"
                    iconSize={24}
                    color="#76a9ff"
                    style={{ marginRight: 10 }}
                  />

                  <Typography variant="h5" component="h5">
                    18
                  </Typography>
                </div>

                <Typography component="p">Under Review</Typography>
              </Grid>

              <Grid item>
                <div className={classes.iconLabeled}>
                  <Icon
                    icon="thumbs-up"
                    iconSize={24}
                    color="#76a9ff"
                    style={{ marginRight: 10 }}
                  />

                  <Typography variant="h5" component="h5">
                    18%
                  </Typography>
                </div>

                <Typography component="p">Passed</Typography>
              </Grid>

              <Grid item>
                <div className={classes.iconLabeled}>
                  <Icon
                    icon="thumbs-down"
                    iconSize={24}
                    color="#76a9ff"
                    style={{ marginRight: 10 }}
                  />

                  <Typography variant="h5" component="h5">
                    21%
                  </Typography>
                </div>

                <Typography component="p">Declined</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <AdvancedTableHeader
            selectionCounter={0}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            rowCount={rows.length}
            columns={columns}
          />
          <AdvancedTableSeparator />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>

                    <TableCell>
                      <div className={classes.reviewer}>
                        <Avatar
                          alt={row.reviewer.fullname}
                          src={row.reviewer.avatar}
                        />
                        <span>{row.reviewer.fullname}</span>
                      </div>
                    </TableCell>

                    <TableCell>{row.type}</TableCell>

                    <TableCell>{row.document}</TableCell>

                    <TableCell>{row.section}</TableCell>

                    <TableCell>
                      <span className={classes.statusHighlight}>
                        {row.status}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <AdvancedTablePagination
          rowCounter={rows.length}
          page={page}
          rowsPerPageOptions={[10, 25, 30]}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default AdminAnnotator;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  filter: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 750,
    overflowX: "auto",
    "& tbody td": {
      backgroundColor: "#fff",
    },
  },
  card: {
    padding: theme.spacing(3),
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  iconLabeled: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      fontWeight: "bold",
    },
  },
  selecedCounter: {
    display: "flex",
    alignItems: "center",
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  reviewer: {
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: theme.spacing(2),
    },
  },
  statusHighlight: {
    background: "#ecf7ed",
    color: "#37833B",
    borderRadius: 20,
    padding: "2px 6px",
  },
}));
