import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFirebase } from 'react-redux-firebase';

const enhance = compose(withFirebase, connect(
    // Map redux state to component props
    ({ firebase: { auth, profile, authError } }) => ({
        auth,
        profile,
        authError
    })
))

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        render() {
            return <Component {...this.props} />;
        }
    }

    return enhance(WithAuthentication);
};

export default withAuthentication;