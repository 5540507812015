import React, { useState, Fragment } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Collapse from '@material-ui/core/Collapse';
import TableRowCollapse from './TableRowCollapse';
import { Link } from 'react-router-dom';
import PATH_URL from '../../routers/path';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  annotationInfo: {
    fontSize: '14px',
    margin: 0,
  },
  containerAnnotation: {
    marginBottom: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
    '&:last-of-type': {
      marginBottom: 'unset',
    },
  },
  containerAnnotationChild: {
    paddingLeft: '16px',
  },
  cell: {
    padding: '16px 16px 16px 0',
  },
});
const TableRowCustom = ({ rowData }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [childStatusOpen, setChildStatusOpen] = useState([]);
  const classes = useRowStyles();
  return (
    <>
      <TableRow
        onClick={() => setOpenCollapse((prev) => !prev)}
        style={{ cursor: 'pointer' }}
        className={classes.root}
      >
        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
          {openCollapse ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </TableCell>
        <TableCell className={classes.cell}>
          <Link
            to={PATH_URL.SPECTATE.replace(':task_id', rowData.taskId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.ticker}
          </Link>
        </TableCell>
        <TableCell className={classes.cell}>{rowData.companyName}</TableCell>
        <TableCell className={classes.cell}>{rowData.year}</TableCell>
        <TableCell className={classes.cell}>{rowData.annotator}</TableCell>
        <TableCell className={classes.cell}>{rowData.reviewer}</TableCell>
      </TableRow>
      {Array.isArray(rowData.annotations[0]) ? (
        <>
          {rowData.annotations.map((rowAnnotation, i) => (
            <Fragment key={i}>
              <TableRow
                className={classes.root}
                style={{ background: '#F5F8FF' }}
              >
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan="4"
                >
                  <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <Box
                      className={classes.containerAnnotation}
                      display="flex"
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (childStatusOpen.includes(i)) {
                          setChildStatusOpen((prev) =>
                            prev.filter((v) => v !== i)
                          );
                        } else {
                          setChildStatusOpen((prev) => [...prev, i]);
                        }
                      }}
                    >
                      {childStatusOpen.includes(i) ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                      <p
                        className={classes.annotationInfo}
                        style={{ marginLeft: '10px' }}
                      >{`Person ${i + 1}`}</p>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow
                className={classes.root}
                style={{ background: '#FFF8EB' }}
              >
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan="4"
                >
                  <TableRowCollapse
                    show={childStatusOpen.includes(i)}
                    annotationId={rowData.id}
                    parentIndex={i}
                    annotations={rowAnnotation}
                    className={classes.containerAnnotationChild}
                  />
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </>
      ) : (
        <TableRow className={classes.root} style={{ background: '#F5F8FF' }}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={2}
          ></TableCell>
          <TableCell
            className={classes.cell}
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan="4"
          >
            <TableRowCollapse
              show={openCollapse}
              annotationId={rowData.id}
              parentIndex={null}
              annotations={rowData.annotations}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableRowCustom;
