import React, { useState } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import Select from "react-select";
import Checkbox from "../../components/@setproduct-ui/core/CheckBox";
import FlexWrapper from "../../components/FlexWrapper";
import Input from "../../components/Input";
import FormMessage from "../../components/FormMessage";
import Button from "../../components/@setproduct-ui/core/Button";
import HorizontalDivider from "../../components/HorizontalDivider";
import googleLogo from "../../assets/images/google-logo.svg";
import withAuthentication from "../../utils/withAuthentication";
import useViewport from "../../hooks/useViewport";
import { register, registerGoogle } from "../../redux/actions/auth";
import { createLoadingSelector } from "../../redux/api/loading";
import { createErrorMessageSelector } from "../../redux/api/error";
import PATH_URL from "../../routers/path";

const loadingSelector = createLoadingSelector(["REGISTER"]);
const errorMessageSelector = createErrorMessageSelector(["REGISTER"]);

const RegisterSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  role: yup.object({
      value: yup.string().required(),
      label: yup.string().required()
    }).required(),
  password: yup
    .string()
    .min(6, "Password length must be at least 6 characters")
    .max(100, "Maximum 100 characters")
    .required("Required"),
  retypePassword: yup
    .string()
    .min(6, "Password length must be at least 6 characters")
    .max(100, "Maximum 100 characters")
    .required("Required")
});

function Register(props) {
  const [remember, setRemember] = useState(false);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const { screenHeight, screenWidth } = useViewport();

  return (
    <div
      style={{
        minWidth: screenWidth > 900 ? "500px" : 0,
        width: screenWidth > 900 ? "40%" : "100%",
        height: screenHeight < 795 ? "100%" : "auto",
        padding: screenHeight < 795 ? "50px 0" : "0",
        overflow: "auto"
      }}
    >
      <Formik
        enableReinitialize
        validationSchema={RegisterSchema}
        validateOnSubmit
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          name: "",
          email: "",
          role: { value: "", label: "" },
          password: "",
          retypePassword: ""
        }}
        onSubmit={async (values, actions) => {
          await props.register(values, remember);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          const firstErrorField = Object.keys(errors)[0];
          return (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "column",
                justifyContent: "center",
                padding: screenWidth <= 450 ? "0 20px" : "0 70px"
              }}
            >
              <h1 style={{ marginTop: 0 }}>Create Your Account</h1>
              {props.error !== "" && (
                <div style={{ marginBottom: 10 }}>
                  <FormMessage type="error">{props.error}</FormMessage>
                </div>
              )}
              <Input
                name="name"
                type="text"
                view="outlined"
                color="default"
                placeholder="Full Name"
                fill={true}
                onInput={handleChange}
                value={values.name}
                error={firstErrorField === "name"}
                errorMessage={errors.name}
                style={{ marginBottom: 20 }}
              />
              <Input
                name="email"
                type="text"
                view="outlined"
                color="default"
                placeholder="Email"
                fill={true}
                onInput={handleChange}
                value={values.email}
                error={firstErrorField === "email"}
                errorMessage={errors.email}
                style={{ marginBottom: 20 }}
              />
              <div style={{ marginBottom: 20 }}>
                <Select
                  name="role"
                  value={values.role.value === "" ? "" : values.role}
                  placeholder="Role"
                  onChange={selected => setFieldValue("role", selected)}
                  isSearchable={false}
                  options={[
                    {
                      value: "annotator",
                        label: "Annotator"
                    },
                    {
                      value: "reviewer",
                      label: "Reviewer"
                    }
                  ]}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: 40,
                      borderColor: "var(--grey30)"
                    }),
                    indicatorSeparator: provided => ({
                      ...provided,
                      width: 0
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                        primary: "var(--grey70)"
                    }
                  })}
                />
                {firstErrorField === "role" && <FormMessage type="error">Required</FormMessage>}
              </div>
              <Input
                name="password"
                type="password"
                view="outlined"
                color="default"
                placeholder="Password"
                fill={true}
                onInput={handleChange}
                value={values.password}
                error={firstErrorField === "password"}
                errorMessage={errors.password}
                style={{ marginBottom: 20 }}
              />
              <Input
                name="retypePassword"
                type="password"
                view="outlined"
                color="default"
                placeholder="Confirm Password"
                fill={true}
                onInput={handleChange}
                value={values.retypePassword}
                error={firstErrorField === "retypePassword"}
                errorMessage={errors.retypePassword}
              />
              <div style={{ margin: "20px 0" }}>
                <FlexWrapper justifyContent="space-between">
                  <Checkbox
                    type="def"
                    color="primary"
                    checked={remember}
                    label="Remember me"
                    helperText=""
                    rtl={false}
                    right={false}
                    onChange={() => setRemember(!remember)}
                  />
                  <Link
                    to={PATH_URL.FORGOT_PASSWORD}
                    style={{
                      display: "block",
                      color: "var(--blue90)",
                      fontWeight: "bold"
                    }}
                  >
                    Forgot password?
                  </Link>
                </FlexWrapper>
                <Checkbox
                  type="def"
                  color="primary"
                  checked={agreeTerm}
                  label="Agree Terms and Conditions"
                  helperText=""
                  rtl={false}
                  right={false}
                  onChange={() => setAgreeTerm(!agreeTerm)}
                />
              </div>
              <Button
                type="default"
                view="filled"
                color="primary"
                text="Register"
                fill={true}
                onClick={handleSubmit}
                disabled={!agreeTerm}
                loading={isSubmitting || props.loading}
              />
              <button type="submit" style={{ display: "none" }}>Submit</button>
              <HorizontalDivider text="Or" />
              <Button
                type="default"
                view="outlined"
                color="default"
                icon={
                  <img
                    src={googleLogo}
                    style={{ height: 30, width: 30 }}
                    alt="google-logo"
                  />
                }
                text="Register With Google"
                fill={true}
                style={{ marginTop: 10 }}
                disabled={isSubmitting || props.loading}
                onClick={() => props.registerGoogle()}
              />
              <p style={{ marginTop: 30, color: "var(--gray50)" }}>
                Already have an account?{" "}
                <Link
                  to={PATH_URL.LOGIN}
                  style={{ color: "var(--blue90)", fontWeight: "bold" }}
                >
                  Log in here
                </Link>
              </p>
            </Form>
          );
        }}  
      </Formik>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  error: errorMessageSelector(state)
});

const mapDispatchToProps = dispatch => ({
  register: (userData, isRemembered) =>
    dispatch(register(userData, isRemembered)),
  registerGoogle: () => dispatch(registerGoogle()),
  push: path => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(Register));
