import React from 'react';
import { useSelector } from 'react-redux';
import Wrapper from '../../components/BasicWrapper';
import Loading from '../../components/Loading';
import AnnotationSection from './AnnotationSection';
import AnnotationVariable from './AnnotationVariable';
import { createLoadingSelector } from '../../redux/api/loading';

const loadingSelectorQuery = createLoadingSelector([
  'GET_TASK_DATA',
  'GET_SECTIONS',
  'GET_ANNOTATION_CHAPTER',
  'GET_ANNOTATION_SUBCHAPTER',
  'GET_TAKEN_BOUNDARIES',
]);

function TabAnnotation(props) {
  const type = useSelector((state) => state.annotation.type);
  const loadingQuery = useSelector((state) => loadingSelectorQuery(state));

  return (
    <div
      role="tabpanel"
      hidden={props.tabIndex !== 0}
      id={`tabpanel-${0}`}
      aria-labelledby={`tab-${0}`}
    >
      {props.tabIndex === 0 && (
        <Wrapper width="100%">
          {loadingQuery ? (
            <Loading />
          ) : ['CHAPTER', 'SUBCHAPTER'].includes(type) ? (
            <AnnotationSection {...props} />
          ) : (
            <AnnotationVariable {...props} />
          )}
        </Wrapper>
      )}
    </div>
  );
}

export default TabAnnotation;
