import { apiRequest } from '../utils/apiRequest';

export const getDetailDocument = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_DETAIL_DOCUMENT',
    'get',
    `/annotation/document/${id}`
  );
};
export const getChapterDocumentByTask = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_CHAPTER_DOCUMENT_BY_TASK',
    'get',
    '/admin/dashboard',
    {
      doc: id,
      type: 'chapter',
      groupBy: 'tasks',
    }
  );
};
export const getChapterDocumentBySection = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_CHAPTER_DOCUMENT_BY_SECTION',
    'get',
    '/admin/dashboard',
    {
      doc: id,
      type: 'chapter',
      groupBy: 'section',
    }
  );
};
export const getSubChapterDocumentByTask = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_SUB_CHAPTER_DOCUMENT_BY_TASK',
    'get',
    '/admin/dashboard',
    {
      doc: id,
      type: 'subchapter',
      groupBy: 'tasks',
    }
  );
};
export const getSubChapterDocumentBySection = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_SUB_CHAPTER_DOCUMENT_BY_SECTION',
    'get',
    '/admin/dashboard',
    {
      doc: id,
      type: 'subchapter',
      groupBy: 'section',
    }
  );
};
export const selectDocumentTasks = (tasks) => ({
  type: 'SELECT_DOCUMENT_TASKS',
  payload: tasks,
});

export const selectDocumentSection = (tasks) => ({
  type: 'SELECT_DOCUMENT_SECTION',
  payload: tasks,
});
export const getVariableDocumentByTask = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_VARIABLE_DOCUMENT_BY_TASK',
    'get',
    '/admin/dashboard',
    {
      doc: id,
      type: 'variable',
      groupBy: 'tasks',
    }
  );
};
export const getVariableDocumentBySection = (id) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_VARIABLE_DOCUMENT_BY_SECTION',
    'get',
    '/admin/dashboard',
    {
      doc: id,
      type: 'variable',
      groupBy: 'section',
    }
  );
};
