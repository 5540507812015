import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router";
import routes from "./routers/routes";
import history from "./routers/history";
import { ConnectedRouter } from "connected-react-router";
import * as Layout from "./layouts";
import { UnverifiedEmailUser, UserIsApproved, UserIsNotAuthenticatedRedir, UserWaitingApproval } from "./utils/Routes/AuthRoutingHOC";
import AuthRoutes from "./utils/Routes/AuthRoutes";
import PrivateRoutes from "./utils/Routes/PrivateRoutesWithSidebar";
import PrivateRoutesWithSidebar from "./utils/Routes/PrivateRoutesWithSidebar";
import PrivateRoutesWithoutSidebar from "./utils/Routes/PrivateRoutesWithoutSidebar";
import NotFound from "./Pages/NotFound";
import PATH_URL from "./routers/path";
import EmailAction from "./Routes/EmailAction";
import VerifyEmail from "./Pages/VerifyEmail";
import UnverifiedEmail from "./Pages/UnverifiedEmail";
import WaitingApproval from "./Pages/WaitingApproval";
import { Helmet } from "react-helmet";

// import LogRocket from 'logrocket';
// import { useSelector } from "react-redux";
// LogRocket.init('esgi/esgi-annotation');


function App() {
  // const auth = useSelector(state => state.firebase.auth);
  // const { isLoaded, isEmpty } = useSelector(state => state.firebase.auth);
  
  // useEffect(() => {
  //   if(isLoaded && !isEmpty) {
  //     LogRocket.identify(auth.uid, {
  //       name: auth.displayName,
  //       email: auth.email
  //     });
  //   }
  // }, [isLoaded, isEmpty])
  return (
    <React.Fragment>
      <Helmet titleTemplate="%s - ESGI Annotation" defaultTitle="ESGI Annotation">
          <meta name="description" content="ESGI internal annotation tool" />
      </Helmet>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/"><Redirect to="/m" /></Route>
          <Route path="/a/:path?" component={UserIsNotAuthenticatedRedir(AuthRoutes)} />
          <Route path="/m" component={UserIsApproved(PrivateRoutesWithSidebar)} />
          <Route path="/d" component={UserIsApproved(PrivateRoutesWithoutSidebar)} />
          <Route exact path={PATH_URL.EMAIL_ACTION} component={EmailAction} />
          <Route exact path={PATH_URL.VERIFY_EMAIL} component={UnverifiedEmailUser(VerifyEmail)} />
          <Route exact path={PATH_URL.UNVERIFIED_EMAIL} component={UnverifiedEmailUser(UnverifiedEmail)} />
          <Route exact path={PATH_URL.WAITING_APPROVAL} component={UserWaitingApproval(WaitingApproval)} />
          <Route component={NotFound} />
        </Switch>
      </ConnectedRouter>
    </React.Fragment>
    
  );
}

export default App;
