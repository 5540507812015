import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Card,
  CardContent,
  OutlinedInput,
  InputLabel,
  Box,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import Button from "../../../../components/@setproduct-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import formStyle from "./styles";
import { SearchContent } from "../../../../components/LiveSearch";
import { GroupDocumentEntity, DOCUMENT_TYPES } from "../../entities";

function Form(props) {
  let { formOpen, onSave, onClose, defaultValue, members, action } = props;

  const init = {
    name: "Group Name",
    type: defaultValue || DOCUMENT_TYPES[2].id,
  };

  const [group, setGroup] = React.useState({});
  const [documents, setDocumentByType] = React.useState(members);
  const [groupType, setGroupType] = React.useState(init.type);
  const [groupName, setGroupName] = React.useState(init.name);
  const [requestLocked, setRequestLocked] = React.useState(false);

  const classes = formStyle();

  const eventOnKeyUp = (e) => {
    e.preventDefault();
    setGroupName(e.target.value);
  };

  const eventOnSelect = (e) => {
    setGroupType(e.target.value);
    let items = members.filter((item) => {
      if (item.documentType === e.target.value) return item;
    });
    setDocumentByType(items);
  };

  const eventOnSuccesAddMember = (data, callback) => {
    data.members = data.members.map((item) =>
      item.id === data.item.id ? data.item : item
    );
    if (typeof callback === "function") {
      callback(data.members);
    }
  };


  const __eventMembersInAddMode = async (member) => {
    if (group.registeredIds.indexOf(member.id) === -1) {
      group.members.push(member);
      group.registeredIds.push(member.id);
      group.totalMember += 1;
    } else {
      group.members = group.members.filter((item) => {
        if (item.id !== member.id) return item;
      });

      group.registeredIds = group.registeredIds.filter((id) => {
        if (id !== member.id) return id;
      });

      group.totalMember -= 1;
    }
    return true;
  };

  const eventAddMember = async (member) => {
    if (!group.id) {
      return __eventMembersInAddMode(member);
    }

    if (group.registeredIds.indexOf(member.id) > -1) {
      return props.removeMemberCallback(member, group);
    }

    return props.addMemberCallback(member, group);
  };

  const eventOnSave = () => {
    group.name = groupName;
    group.groupType = groupType;

    onSave(group).then(() => {
      resetGroup();
      onClose(null);
    });
  };

  const resetGroup = () => {
    const group = new GroupDocumentEntity({ _id: null });
    group.name = init.name;
    group.groupType = init.type;
    setGroupName(init.name);
    setGroupType(init.type);
    setGroup(group);
  };

  React.useEffect(() => {
    let items = members.filter((item) => {
      if (item.documentType === groupType) return item;
    });
    setDocumentByType(members);
  }, [members]);

  React.useEffect(() => {
    if (props.group) {
      setGroupName(props.group.name);
      setGroupType(props.group.groupType);
      setGroup(props.group);
      return () => false;
    }
    resetGroup();
  }, [props.group]);

  return (
    <Dialog
      className={`${classes.removeBackdrop}`}
      open={formOpen}
      disableBackdropClick={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      scroll="body"
      classes={{
        paperScrollPaper: classes.radius,
      }}
    >
      <Card>
        <CardContent classes={{ root: classes.spaces }}>
          <div className={classes.fromTitleWrapper}>
            <h2 className={classes.formTitle}>
              {group.id ? `Edit Group` : `Add New Group`}
            </h2>
            <CloseIcon onClick={onClose} />
          </div>

          <div className={classes.formGroup}>
            <InputLabel className={classes.formLabel}>Group Name:</InputLabel>
            <OutlinedInput
              placeholder="Group name"
              value={groupName}
              onChange={eventOnKeyUp}
              classes={{
                input: classes.formInput,
              }}
            />
          </div>

          <div className={classes.formGroup}>
            <InputLabel className={classes.formLabel}>Group Type:</InputLabel>
            <FormControl variant="outlined">
              <Select
                value={groupType}
                onChange={eventOnSelect}
                classes={{
                  select: classes.formInput,
                }}
              >
                {DOCUMENT_TYPES.map((item) => {
                  return (
                    <MenuItem
                      key={`document-types-form-${item.id}`}
                      value={item.id}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className={classes.formGroup}>
            <InputLabel
              className={classes.formLabel}
              style={{ marginBottom: 34 }}
            >
              Documents:
            </InputLabel>
            <Box display="flex">
              <SearchContent
                {...props}
                members={documents}
                group={group}
                addMemberCallback={eventAddMember}
                onSuccessCallback={eventOnSuccesAddMember}
              />
            </Box>
          </div>

          <div className={classes.formGroup}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.btnWrapper}
            >
              <Button
                onClick={onClose}
                className={classes.btnCancel}
                text="Cancel"
              />

              <Button
                loading={props.loadingCreate}
                onClick={eventOnSave}
                className={classes.btnSave}
                text={group.id ? "Update" : "Save"}
              />
            </Box>
          </div>
        </CardContent>
      </Card>
    </Dialog>
  );
}

Form.propTypes = {
  formOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  removeMemberCallback: PropTypes.func,
  addMemberCallback: PropTypes.func,
};
export default Form;
