
import React, { useState } from "react";
import { connect } from "react-redux";
import { Action } from "../../redux/actions/annotatorTeam";
import { actionType } from '../../redux/reducers/annotatorTeam';
import { TableAction } from "../../redux/actions/tableAction";
import { createLoadingSelector } from "../../redux/api/loading";


import AnnotatorTeamView from "./AnnotatorTeam";
import Confirm from "./components/ConfirmDialog";

const ConfirmAction = (action) => {
  const [removed, setRemoved] = React.useState({});

  const deleteHandler = () =>
    action
      .removeMemberFromGroup(removed.member, removed.group)
      .finally(() => setRemoved({}));

  const closeHandler = () => setRemoved({});

  return {
    ...removed,
    setRemoved,
    deleteHandler,
    closeHandler,
  };
};

const GroupConfirm = (action) => {
  const [group, setGroup] = React.useState(null);

  const deleteHandler = () =>
    action
      .deleteGroup(group)
      .finally(() => setGroup(null));

  const closeHandler = () => setGroup(null);

  return {
    group,
    setGroup,
    deleteHandler,
    closeHandler,
  };
};

function AnnotatorsTeam(props) {
  const action = Action(props.dispatch);
  const tableAction = TableAction(props.dispatch);

  const confirm = ConfirmAction(action);
  const groupConfirm = GroupConfirm(action);

  React.useEffect(() => {
    action.initData();
  }, []);

  return (
    <>
      <AnnotatorTeamView
        {...props}
        action={action}
        groups={props.annotatorTeam.groups}
        members={props.annotatorTeam.members}
        confirm={confirm}
        groupConfirm={groupConfirm}
        tableAction={tableAction}
        loadingQuery={props.loadingQuery}
        loadingMutation={props.loadingMutation}
      />

      <Confirm
        {...props}
        isConfirmOpen={Boolean(confirm.member)}
        title={`Delete member`}
        text={`Are you sure to remove this member?`}
        deleteHandler={confirm.deleteHandler}
        closeHandler={confirm.closeHandler}
      />

      <Confirm
        {...props}
        isConfirmOpen={Boolean(groupConfirm.group)}
        title={`Delete group`}
        text={`Are you sure to remove this group?`}
        deleteHandler={groupConfirm.deleteHandler}
        closeHandler={groupConfirm.closeHandler}
      />
    </>
  );
}

function MapStateToProps(state) {
  return {
    annotatorTeam: state.annotatorTeam,
    tableState: state.tableState,

    loadingQuery: createLoadingSelector([
      actionType.LoadListAnnotator,
      actionType.LoadListGroupAnnotator
    ])(state),

    loadingMutation: createLoadingSelector([actionType.CreateGroup])(state),
  };
}

export default connect(MapStateToProps)(AnnotatorsTeam);
