import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Navbar from "../../components/@setproduct-ui/core/Navbar";
import NavbarGroup from "../../components/@setproduct-ui/core/Navbar/Group";
import NavbarHeading from "../../components/@setproduct-ui/core/Navbar/Heading";
import FlexWrapper from "../../components/FlexWrapper";
import Button from "../../components/@setproduct-ui/core/Button";
import useViewport from "../../hooks/useViewport";
import logo from "../../assets/images/cesgs-logo-only.png";
import illustration from "../../assets/images/fill_form_illustration.svg";
import PATH_URL from "../../routers/path";

function AuthLayout(props) {
  const { screenWidth } = useViewport();

  return (
    <FlexWrapper
      width="100%"
      height="100vh"
      flexFlow="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Navbar
        type={screenWidth < 860 ? "mobile" : "desktop"}
        view="flat"
        color="primary"
      >
        <NavbarGroup align="left">
          <NavbarHeading style={{ marginRight: screenWidth < 600 ? 0 : 80 }}>
            <FlexWrapper>
              <img
                src={logo}
                alt="company-logo"
                style={{ height: 50, width: 50, marginRight: 10 }}
              />
              {screenWidth > 600 && <h2 style={{ margin: 0 }}>Annotation</h2>}
            </FlexWrapper>
          </NavbarHeading>
          <Link to="#" style={{ marginRight: 30, color: "var(--gray70)" }}>
            ESG Dataset
          </Link>
          <Link to="#" style={{ marginRight: 30, color: "var(--gray70)" }}>
            ESG Smart Reporting
          </Link>
          <Link to="#" style={{ color: "var(--gray70)" }}>
            CESGS
          </Link>
        </NavbarGroup>
        <NavbarGroup align="right">
          <Button
            type="default"
            view="outlined"
            color="default"
            text="Sign In"
            style={{ marginRight: 10 }}
            onClick={() => props.push(PATH_URL.LOGIN)}
          />
          <Button
            type="default"
            view="filled"
            color="default"
            text="Register"
            onClick={() => props.push(PATH_URL.REGISTER)}
          />
        </NavbarGroup>
      </Navbar>
      <FlexWrapper
        flexFlow={screenWidth < 600 ? "column" : "row"}
        height="100%"
        justifyContent={screenWidth < 600 ? "center" : "flex-end"}
        style={{ minHeight: "calc(100% - 4.5em)" }}
      >
        {props.children}
        {screenWidth > 900 && (
          <div style={{ width: "60%", height: "100%" }}>
            <img
              src={illustration}
              alt="fill-form-illustration"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "top center"
              }}
            />
          </div>
        )}
      </FlexWrapper>
    </FlexWrapper>
  );
}

export default connect(
  null,
  { push }
)(AuthLayout);
