import React, { useState, useEffect, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import styles from './Style.module.css';

export default function SearchInput(props) {
  const [value, setValue] = useState(props.defaultValue || '');
  const applySearch = useCallback(props.onApplySearch, []);
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      applySearch(value);
    }, 300);
    return () => clearTimeout(timeoutID);
  }, [value, applySearch]);
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        ...props.style,
      }}
    >
      <Icon
        icon="search"
        iconSize={16}
        style={{ position: 'absolute', left: 10, color: 'var(--grey30)' }}
      />
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={props.placeholder}
        className={styles.searchInput}
      />
    </div>
  );
}
