import React, { useState, useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@blueprintjs/core';
import styles from './MetricContainer.module.css';

export default function MetricContainer(props) {
  const metricContainerRef = useRef();
  const [isMetricOverflown, setIsMetricOverflown] = useState(false);
  const [metricScrollPos, setMetricScrollPos] = useState(0);
  const handleClickArrowRight = () => {
    if (metricContainerRef.current) {
      const metricContainer = metricContainerRef.current;
      metricContainer.scrollLeft = metricContainer.scrollWidth;
      setMetricScrollPos(metricContainer.scrollWidth);
    }
  };
  const handleClickArrowLeft = () => {
    if (metricContainerRef.current) {
      const metricContainer = metricContainerRef.current;
      metricContainer.scrollLeft = 0;
      setMetricScrollPos(0);
    }
  };

  useEffect(() => {
    function checkOverflow() {
      if (metricContainerRef.current) {
        const metricContainer = metricContainerRef.current;
        const isOverflowing =
          metricContainer.scrollWidth > metricContainer.clientWidth;
        setIsMetricOverflown(isOverflowing);
      }
    }
    checkOverflow();
    window.addEventListener('resize', checkOverflow, true);
    return () => {
      window.removeEventListener('resize', checkOverflow, true);
    };
  }, [props.children]);

  return (
    <div
      className={styles.container}
      style={{
        margin: !isMetricOverflown ? 'auto -8px' : 'auto 0px',
      }}
    >
      {isMetricOverflown && metricScrollPos > 0 && (
        <IconButton size="small" onClick={handleClickArrowLeft}>
          <Icon
            icon="circle-arrow-left"
            iconSize={30}
            className={styles.arrowButton}
          />
        </IconButton>
      )}
      <div
        ref={metricContainerRef}
        className={styles.metricContainer}
        style={{
          width: isMetricOverflown ? '95%' : '100%',
          margin: isMetricOverflown ? '16px -8px' : '16px 0px',
        }}
      >
        {props.children}
      </div>
      {isMetricOverflown && metricScrollPos === 0 && (
        <IconButton size="small" onClick={handleClickArrowRight}>
          <Icon
            icon="circle-arrow-right"
            iconSize={30}
            className={styles.arrowButton}
          />
        </IconButton>
      )}
    </div>
  );
}
