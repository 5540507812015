import { apiRequest } from "../utils/apiRequest";
import dispatchType from "./dispatchType";

export const actionGetCompanies = (params) => async (dispatch) => {
  const companies = await apiRequest(
    dispatch,
    "COMPANIES_ACCEPT",
    "get",
    "/annotation/companies",
    params
  );
  if (companies.type === "COMPANIES_ACCEPT_SUCCESS") {
    dispatch(dispatchType.companiesSuccess(companies.payload));
  }
};

export const actionCreateCompanies = (params, setIsShowModal) => async (
  dispatch
) => {
  const companies = await apiRequest(
    dispatch,
    "COMPANIES_CREATE",
    "post",
    "/annotation/company",
    params
  );
  if (companies.type === "COMPANIES_CREATE_SUCCESS") {
    dispatch(dispatchType.companiesCreateSuccess(companies));
    setIsShowModal(false);
  } else if (companies.type === "COMPANIES_CREATE_FAILURE") {
    dispatch(dispatchType.companiesCreateError(companies));
  }
};

export const actionEditCompanies = (
  company,
  paramsId,
  setIsShowModal
) => async (dispatch) => {
  const params = {
    company,
  };
  const companies = await apiRequest(
    dispatch,
    "COMPANIES_EDIT",
    "patch",
    `/annotation/company/${paramsId}`,
    params
  );
  if (companies.type === "COMPANIES_EDIT_SUCCESS") {
    dispatch(editCompaniesSuccess(companies));
    setIsShowModal(false);
  } else if (companies.type === "COMPANIES_EDIT_FAILURE") {
    dispatch(editCompaniesError(companies));
  }
};

const companiesRenderFirst = (authData) => {
  return {
    type: "COMPANIES_RENDER_FIRST",
    payload: authData,
  };
};

export const actionRenderFirst = () => async (dispatch) => {
  dispatch(companiesRenderFirst());
};

export const actionBulkCompaniesUpload = (params) => async (dispatch) => {
  const companiesBulkUpload = await apiRequest(
    dispatch,
    "COMPANIES_BULK_UPLOAD",
    "post",
    "/annotation/companies",
    params,
    "multipart/form-data"
  );
  if (companiesBulkUpload.type === "COMPANIES_BULK_UPLOAD_SUCCESS") {
    dispatch(bulkCompaniesSuccess(companiesBulkUpload));
  } else {
    dispatch(bulkCompaniesError(companiesBulkUpload));
  }
};

export const actionCompaniesDelete = (
  paramsId,
  setIsShowModalDialogDelete
) => async (dispatch) => {
  const companiesDelete = await apiRequest(
    dispatch,
    "COMPANIES_DELETE",
    "delete",
    `/annotation/company/${paramsId}`
  );
  if (companiesDelete.type === "COMPANIES_DELETE_SUCCESS") {
    dispatch(deleteCompaniesSuccess(companiesDelete));
    setIsShowModalDialogDelete(false);
  } else {
    dispatch(deleteCompaniesError(companiesDelete));
    setIsShowModalDialogDelete(true);
  }
};

export const actionSetSelectedCompanies = companies => ({
  type: "COMPANIES_SET_SELECTED",
  payload: companies
})

const bulkCompaniesSuccess = (authData) => {
  return {
    type: "COMPANIES_BULK_UPLOAD_SUCCESS",
    payload: authData,
  };
};

const bulkCompaniesError = (authData) => {
  return {
    type: "COMPANIES_BULK_UPLOAD_FAILURE",
    payload: authData,
  };
};

const deleteCompaniesSuccess = (authData) => {
  return {
    type: "COMPANIES_DELETE_SUCCESS",
    payload: authData,
  };
};

const deleteCompaniesError = (authData) => {
  return {
    type: "COMPANIES_DELETE_FAILURE",
    payload: authData,
  };
};

const editCompaniesSuccess = (authData) => {
  return {
    type: "COMPANIES_EDIT_SUCCESS",
    payload: authData,
  };
};

const editCompaniesError = (authData) => {
  return {
    type: "COMPANIES_EDIT_FAILURE",
    payload: authData,
  };
};
