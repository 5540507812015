import React from 'react';
import Select from 'react-select';
import Skeleton from '../../components/Skeleton';

const customSelectStyle = {
  container: (provided) => ({
    ...provided,
    marginRight: 15,
    borderRadius: 0,
  }),
  control: (provided) => ({
    ...provided,
    height: 42,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'var(--inter)',
    fontSize: 16,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    visibility: 'hidden',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#666',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
};
const CustomSelect = ({
  name = '',
  value = { value: '', label: '' },
  onChange = (selected) => {},
  options = [{ value: '', label: '' }],
  isLoading = false,
  disabled = false,
}) => {
  return (
    <div>
      {!isLoading ? (
        <Select
          name={name}
          defaultValue={options[0]}
          value={
            value && value.value ? value : { value: '', label: 'No Options' }
          }
          onChange={onChange}
          isSearchable
          options={options}
          styles={customSelectStyle}
          isDisabled={disabled}
        />
      ) : (
        <Skeleton height={40} width="90%" />
      )}
    </div>
  );
};
export default CustomSelect;
