import React from "react";
import { capitalize } from "lodash";
import styles from "./style.module.css";

function parseArrayField(str) {
  if (str.includes("[")) {
    const indexStr = str.match(/\[\d+\]/)[0];
    const number = parseInt(indexStr.match(/\d+/)[0]) + 1;
    return str.slice(0, str.indexOf("[")) + " " + number;
  }
  return str;
}

function BoundingBox(props) {
  const renderLabel = () => {
    return props.label.split(".").reduce((textArr, field, index) => {
      let str = capitalize(field.replaceAll("-", " "));
      if (index === 0) {
        if (props.isArray) {
          str = parseArrayField(str);
        } else str = str.replace(/\[\d+\]/g, "");
      } else {
        str = parseArrayField(str);
      }
      textArr = textArr.concat(str);
      return textArr;
    }, []).join(" / ");
  }

  return (
    <div
      style={{
        position: "absolute",
        top: props.topLeft.y,
        left: props.topLeft.x,
        height: props.height,
        width: props.width,
        cursor: "pointer",
        border: props.type === "annotation" ? props.highlighted ? "2px solid var(--red50)" : "2px solid var(--yellow40)" : "none",
        zIndex: props.highlighted ? 100 : "auto"
      }}
      onClick={props.onClick}
    >
      {props.label && (
        <div className={styles.userBoxWrapperBounding}>
          <span className={styles.userBox} style={{ background: props.highlighted ? "var(--red70)" : "var(--blue70)" }}>
            {renderLabel()}
          </span>
        </div>
      )}
    </div>
  );
}

export default BoundingBox;
