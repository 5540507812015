const initialState = {
  detail: {},
  chapterTasks: [],
  chapterSection: [],
  subChapterTasks: [],
  subChapterSection: [],
  selectedTasks: [],
  selectedSection: [],
  variableTasks: [],
  variableSections: [],
};

const documentDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DETAIL_DOCUMENT_SUCCESS':
      return {
        ...state,
        detail: action.payload || {},
      };
    case 'GET_CHAPTER_DOCUMENT_BY_TASK_SUCCESS':
      return {
        ...state,
        chapterTasks: action.payload || [],
      };
    case 'GET_CHAPTER_DOCUMENT_BY_SECTION_SUCCESS':
      return {
        ...state,
        chapterSection: action.payload || [],
      };
    case 'GET_SUB_CHAPTER_DOCUMENT_BY_TASK_SUCCESS':
      return {
        ...state,
        subChapterTasks: action.payload || [],
      };
    case 'GET_SUB_CHAPTER_DOCUMENT_BY_SECTION_SUCCESS':
      return {
        ...state,
        subChapterSection: action.payload || [],
      };
    case 'SELECT_DOCUMENT_TASKS':
      return {
        ...state,
        selectedTasks: action.payload,
      };
    case 'SELECT_DOCUMENT_SECTION':
      return {
        ...state,
        selectedSection: action.payload,
      };
    case 'GET_VARIABLE_DOCUMENT_BY_TASK_SUCCESS':
      return {
        ...state,
        variableTasks: action.payload,
      };
    case 'GET_VARIABLE_DOCUMENT_BY_SECTION_SUCCESS':
      return {
        ...state,
        variableSections: action.payload,
      };
    default:
      return state;
  }
};

export default documentDetailReducer;
