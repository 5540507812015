import React from "react";
import FlexWrapper from "../../../../components/FlexWrapper";
import Input from "../../../../components/Input";

import { Box, CircularProgress, IconButton } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";

function CustomInput(props) {
  let { group, inputRef, action, setIsLoading } = props;

  // Hacky Reference
  // I don't know why ForwardRef isn't work for this
  //
  let [text, setText] = React.useState(group.name);
  inputRef.value = text;

  const eventOnKeyUp = (e) => {
    setText(e.target.value);

    if (e.keyCode === 13) {
      group.name = e.target.value;

      setIsLoading(true);
      action.renameGroup(group);
    }
  };

  return (
    <Input
      name="chapterName"
      type="text"
      view="outlined"
      color="default"
      placeholder="Input your Team"
      value={text}
      onKeyUp={eventOnKeyUp}
      fill={true}
      error={false}
      dense={true}
      errorMessage={""}
      style={{ width: "95%" }}
    />
  );
}

function EditModeIcon(props) {
  let {
    group,
    rowState,
    setIsLoading,
    isLoading,
    inputRef,
    action,
  } = props;

  const eventRenameGroup = () => {
    group.name = inputRef.value;

    setIsLoading(true);
    action.renameGroup(group);
  };

  if (isLoading) {
    return (
      <IconButton size="small">
        <CircularProgress style={{ width: 20, height: 20 }} />
      </IconButton>
    );
  }

  console.log(rowState.nameEditMode)
  return rowState.nameEditMode ? (
    <IconButton size="small" onClick={() => eventRenameGroup()}>
      <CheckIcon style={{ fontSize: "1rem" }} />
    </IconButton>
  ) : (
    <IconButton size="small" onClick={() => rowState.setNameEditMode(true)}>
      <EditIcon style={{ fontSize: "1rem" }} />
    </IconButton>
  );
}

export default (props) => {
  let { group, classes } = props;
  let [isLoading, setIsLoading] = React.useState(false);

  const state = {
    isLoading,
    setIsLoading,
  };

  return (
    <FlexWrapper justifyContent="start">
      <Box className={classes.itemRowName}>
        {props.rowState.nameEditMode ? (
          <CustomInput {...props} {...state} />
        ) : (
          group.name
        )}
      </Box>
      <EditModeIcon {...props} {...state} />
    </FlexWrapper>
  );
};
