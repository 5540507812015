import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import { debounce } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Wrapper from '../../components/BasicWrapper';
import FlexWrapper from '../../components/FlexWrapper';
import Navbar from '../../components/@setproduct-ui/core/Navbar';
import NavbarGroup from '../../components/@setproduct-ui/core/Navbar/Group';
import NavbarHeading from '../../components/@setproduct-ui/core/Navbar/Heading';
import ProgressBar from '../../components/@setproduct-ui/core/ProgressBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Brand from '../../components/Brand';
import NavbarUserMenu from '../../components/NavbarUserMenuDocument';
import logo from '../../assets/images/cesgs-logo-only.png';
import Heading from '../../components/Heading';
import Spinner, { Loading } from '../../components/Loading';
import Toolbar from './Toolbar';
import TabAnnotation from './TabAnnotation';
import TabNote from './TabNote';
import BoundingBox from './BoundingBox';
import VariableBoundingBox from './VariableBoundingBox';
import FullLayout from '../../layouts/FullLayout';
import DocumentViewer from './DocumentViewer';
import styles from './style.module.css';
import {
  getDocumentData,
  getBoundaries,
  getPageBoundaries,
  zoomIn,
  zoomOut,
  resetPageScale,
  nextPage,
  prevPage,
  setNumPages,
  setCurrentPage,
  setPageLoaded,
  setPaperSize,
  setBoundingBox,
  changeBoundingBoxType,
  resetDocumentPreview,
} from '../../redux/actions/documentPreview';
import { documentPreviewSelector } from '../../redux/reducers/documentPreview';
import {
  getTaskData,
  getSections,
  setSelectedBoundingBox,
  setSelectedPage,
  getTaskNote,
  getTaskAnnotatorList,
  resetTaskData,
  saveReviewAnnotationVariable,
  submitReviewAnnotationVariable,
  getAnnotationTable,
} from '../../redux/actions/annotation';
import { annotationSelector } from '../../redux/reducers/annotation';
import { createLoadingSelector } from '../../redux/api/loading';
import TabSearch from './TabSearch';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdfOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const PageLoading = ({ height, width }) => (
  <div className={styles.loadingBackdrop}>
    <Loading />
  </div>
);

function fixUrl(url) {
  return !url.includes('http') ? `https://${url}` : url;
}

function Spectate({
  getDocumentData,
  getTaskData,
  getBoundaries,
  getPageBoundaries,
  getSections,
  getTaskNote,
  getTaskAnnotatorList,
  file,
  boundingBox,
  pageBoundingBox,
  numPages,
  currentPage,
  pageScale,
  paperSize,
  pageLoaded,
  boundingBoxType,
  selectedSection,
  documentId,
  sectionId,
  taskName,
  resetDocumentPreview,
  resetTaskData,
  ...props
}) {
  const thumbsWrapperRef = useRef();
  const pdfViewWrapperRef = useRef();
  const classes = useStyles();
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [progress, setProgress] = useState({ status: true, value: 0 });
  const { task_id } = useParams();
  const [dirty, setDirty] = useState([]);
  const documentSearch = useSelector((state) => state.documentSearch);

  useEffect(() => {
    getTaskData(task_id);
    getTaskNote(task_id);
  }, [getTaskData, task_id, getTaskNote]);

  const [dataAnnotationTable, setDataAnnotationTable] = useState([]);
  const dispatch = useDispatch();
  const annotationTable = useSelector(
    (state) => state.annotation.annotationTable
  );

  useEffect(() => {
    if (documentId && props.type === 'TABLE')
      dispatch(getAnnotationTable(documentId));
  }, [documentId, props.type, dispatch]);

  useEffect(() => {
    const canvasData = document.getElementById('canvasData');
    if (canvasData != null) {
      const ctx = canvasData.getContext('2d');
      ctx.clearRect(0, 0, canvasData.width, canvasData.height);
      dataAnnotationTable.forEach(({ coordinates, pageIndex, title }) => {
        const [firstCoord, secondCoord] = coordinates;
        if (pageIndex === currentPage) {
          ctx.strokeStyle = 'rgba(79,145,255,1)';
          ctx.strokeRect(
            firstCoord.x * Math.floor(paperSize.width * pageScale),
            firstCoord.y * Math.floor(paperSize.height * pageScale),
            (secondCoord.x - firstCoord.x) *
              Math.floor(paperSize.width * pageScale),
            (secondCoord.y - firstCoord.y) *
              Math.floor(paperSize.height * pageScale)
          );
          if (boundingBoxType[0] === 'FIELD') {
            ctx.fillStyle = '#2979FF';
            ctx.fillRect(
              (secondCoord.x - firstCoord.x) *
                Math.floor(paperSize.width * pageScale) +
                firstCoord.x * Math.floor(paperSize.width * pageScale) -
                title.length * 5 * pageScale,
              firstCoord.y * Math.floor(paperSize.height * pageScale) -
                15 * pageScale,
              title.length * 5 * pageScale,
              15 * pageScale
            );
            ctx.fillStyle = 'white';
            ctx.font = `${10 * pageScale}px Arial`;
            ctx.fillText(
              title,
              (secondCoord.x - firstCoord.x) *
                Math.floor(paperSize.width * pageScale) +
                firstCoord.x * Math.floor(paperSize.width * pageScale) -
                title.length * 5 * pageScale,
              firstCoord.y * Math.floor(paperSize.height * pageScale) -
                4 * pageScale
            );
          }
        }
      });
    }
  }, [
    dataAnnotationTable,
    boundingBoxType,
    currentPage,
    pageScale,
    documentLoaded,
    pageLoaded,
  ]);

  //convert database to data
  useEffect(() => {
    if (annotationTable !== undefined) {
      const data = annotationTable.map(({ refId, dataTables, status, _id }) => {
        return {
          coordinates: dataTables.coordinates,
          pageIndex: dataTables.pageNumber,
          status,
          title: refId,
          _id,
        };
      });
      // setFixDatas(data);
      setDataAnnotationTable([...data].reverse());
    }
  }, [annotationTable]);

  useEffect(() => {
    if (documentId) getDocumentData(documentId);
    if (taskName && ['CHAPTER', 'SUBCHAPTER', 'TABLE'].includes(props.type))
      getTaskAnnotatorList(taskName);
  }, [documentId, taskName, getDocumentData, getTaskAnnotatorList, props.type]);

  useEffect(() => {
    if (props.ocrStatus === 4) {
      getPageBoundaries(documentId);
    }
  }, [props.ocrStatus, getPageBoundaries, documentId]);

  useEffect(() => {
    if (documentId && props.ocrStatus === 4 && !boundingBox[currentPage - 1]) {
      const debounced = debounce(getBoundaries, 500);
      debounced(documentId, currentPage);
      return () => {
        debounced.cancel();
      };
    }
  }, [currentPage, props.ocrStatus, boundingBox, getBoundaries, documentId]);

  useEffect(() => {
    return () => {
      resetDocumentPreview();
      resetTaskData();
    };
  }, []);

  useEffect(() => {
    function preventReload(e) {
      if (props.type === 'VARIABLE' && dirty.length > 0) {
        e.returnValue = '';
        return '';
      }
      return null;
    }
    window.addEventListener('beforeunload', preventReload);
    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, [props.type, dirty]);

  const generateAnnotation = (pageData, page) => {
    const { type: taskType } = props;
    let boxes = [];
    if (['CHAPTER', 'SUBCHAPTER'].includes(props.type)) {
      const displayedBoundingBox = props.displayedAnnotators.reduce(
        (acc, annotator) => {
          props.selectedBoundingBox[selectedSection][annotator].forEach(
            (node) => {
              if (acc[node.id]) {
                acc[node.id].annotators.push(annotator);
              } else {
                acc[node.id] = { annotators: [annotator] };
              }
            }
          );
          return acc;
        },
        {}
      );
      boxes = boundingBox[page - 1]
        .filter(
          (box) =>
            box.type !== 'PAGE' &&
            Object.keys(displayedBoundingBox).indexOf(box.nodeId) !== -1
        )
        .map((box) => {
          const scaledBox = box.attributes.vertices.map((vertices) => {
            return {
              x: vertices.x * pageData.width * pageScale,
              y: vertices.y * pageData.height * pageScale,
            };
          });
          // calculate div size
          const width = scaledBox[1].x - scaledBox[0].x;
          const height = scaledBox[3].y - scaledBox[0].y;
          // position
          const topLeft = scaledBox[0];
          return (
            <BoundingBox
              key={box.nodeId}
              nodeId={box.nodeId}
              topLeft={topLeft}
              height={height}
              width={width}
              isSelected={true}
              annotators={displayedBoundingBox[box.nodeId].annotators}
            />
          );
        });
    } else if (
      boundingBoxType.indexOf('FIELD') !== -1 &&
      taskType === 'VARIABLE' &&
      props.annotatedField[selectedSection]
    ) {
      boxes = boxes.concat(
        props.annotatedField[selectedSection]
          .filter((field) => field.pageNumber === currentPage)
          .map((field) => {
            const scaledBox = field.coordinates.map((vertices) => {
              return {
                x: vertices.x * pageData.width * pageScale,
                y: vertices.y * pageData.height * pageScale,
              };
            });
            // calculate div size
            const width = scaledBox[1].x - scaledBox[0].x;
            const height = scaledBox[3].y - scaledBox[0].y;
            // position
            const topLeft = scaledBox[0];
            return (
              <VariableBoundingBox
                key={field.keyMap}
                nodeId={field.keyMap}
                coordinates={field.coordinates}
                isArray={props.selectedField.isArray}
                label={field.keyMap}
                topLeft={topLeft}
                height={height}
                width={width}
                type="annotation"
                highlighted={
                  props.selectedField.keyMap &&
                  field.keyMap === props.selectedField.keyMap
                }
              />
            );
          })
      );
    }
    const Container = (
      <div
        id="annotation-layer"
        style={{
          position: 'absolute',
          display: 'inline-block',
          zIndex: 1,
          height: paperSize.height * pageScale,
          width: paperSize.width * pageScale,
        }}
      >
        <div
          style={{
            position: 'relative',
            height: paperSize.height * pageScale,
            width: paperSize.width * pageScale,
          }}
        >
          {boxes}
        </div>
      </div>
    );
    return Container;
  };

  const displaySearchHighlightLayer = (pageData, page) => {
    const { searchTerm, resultsGrouped } = documentSearch;
    const currentPageResults = resultsGrouped[page];
    const searchTermArr = searchTerm.split(' ').map((w) => w.toLowerCase());
    let boxes = boundingBox[page - 1]
      .filter((box) => box.type === 'WORD')
      .filter((box) => {
        // get only paragraph ancestor
        const paragraphAncestor = box.ancestors.find((a) =>
          a.includes('paragraph')
        );
        // check if it's match with search result
        const matchAncestor =
          paragraphAncestor && currentPageResults
            ? currentPageResults.includes(paragraphAncestor)
            : false;
        if (matchAncestor) {
          // find which word boundary should be highlighted
          let isMatch = false;
          let i = 0;
          let searchTermLen = searchTermArr.length;
          while (i < searchTermLen && !isMatch) {
            isMatch = box.text.toLowerCase().includes(searchTermArr[i]);
            i++;
          }
          return isMatch;
        }
        return false;
      })
      .map((box) => {
        const scaledBox = box.attributes.vertices.map((vertices) => {
          return {
            x: vertices.x * pageData.width * pageScale,
            y: vertices.y * pageData.height * pageScale,
          };
        });
        // calculate div size
        const width = scaledBox[1].x - scaledBox[0].x;
        const height = scaledBox[3].y - scaledBox[0].y;
        // position
        const topLeft = scaledBox[0];
        return (
          <BoundingBox
            key={box.nodeId}
            nodeId={box.nodeId}
            topLeft={topLeft}
            height={height}
            width={width}
            isSelected={false}
            onClick={() => {}}
            annotators={[]}
            style={{
              background: 'rgba(232, 255, 114, 0.5)',
              border: 'none',
            }}
          />
        );
      });
    return (
      <div
        id="search-layer"
        style={{
          position: 'absolute',
          display: 'inline-block',
          zIndex: 1,
          height: paperSize.height * pageScale,
          width: paperSize.width * pageScale,
        }}
      >
        <div
          style={{
            position: 'relative',
            height: paperSize.height * pageScale,
            width: paperSize.width * pageScale,
          }}
        >
          {boxes}
        </div>
      </div>
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setDocumentLoaded(true);
    setProgress({ status: false, value: 0 });
    props.setNumPages(numPages);
  };

  const onPageLoadSuccess = (page) => {
    const pageOriginalRotation = page._pageInfo.rotate;
    const [horMargin, verMargin] = page._pageInfo.view;
    // count height & width with margin size
    const origHeight = page.originalHeight - verMargin;
    const origWidth = page.originalWidth - horMargin;
    // if rotation is not 0 or 180 then swap height and width
    const height = [0, 180].includes(pageOriginalRotation)
      ? origHeight
      : origWidth;
    const width = [0, 180].includes(pageOriginalRotation)
      ? origWidth
      : origHeight;
    props.setPageLoaded(true);
    props.setPaperSize({ height, width });
  };

  const handleNextPage = () => {
    props.setPageLoaded(false);
    props.nextPage();
  };

  const handlePrevPage = () => {
    props.setPageLoaded(false);
    props.prevPage();
  };

  const generatePage = (nodeId) => {
    if (['CHAPTER', 'SUBCHAPTER'].includes(props.type)) {
      const displayedPage = props.displayedAnnotators.reduce(
        (acc, annotator) => {
          props.selectedPage &&
            props.selectedPage[selectedSection][annotator].forEach((page) => {
              if (acc[page.id]) {
                acc[page.id].annotators.push(annotator);
              } else {
                acc[page.id] = { annotators: [annotator] };
              }
            });
          return acc;
        },
        {}
      );
      return (
        <DocumentViewer
          ref={thumbsWrapperRef}
          file={fixUrl(file)}
          currentPage={currentPage}
          setCurrentPage={props.setCurrentPage}
          pageBoundingBox={pageBoundingBox}
          selectedPage={props.selectedPage[selectedSection]}
          selectedBoundingBox={props.selectedBoundingBox[selectedSection]}
          displayedPage={displayedPage}
          displayedAnnotators={props.displayedAnnotators}
          annotators={props.annotators}
          currentAnnotator={props.annotator.id}
        />
      );
    }
    return (
      <DocumentViewer
        ref={thumbsWrapperRef}
        file={fixUrl(file)}
        currentPage={currentPage}
        setCurrentPage={props.setCurrentPage}
        pageBoundingBox={pageBoundingBox}
        selectedPage={props.selectedPage[selectedSection]}
        selectedBoundingBox={
          props.type === 'VARIABLE'
            ? props.annotatedField[selectedSection]
            : props.selectedBoundingBox[selectedSection]
        }
      />
    );
  };

  const focusPage = (pageNumber) => {
    if (thumbsWrapperRef.current) {
      const listRef = thumbsWrapperRef.current.listRef;
      if (listRef && listRef.current) {
        listRef.current.scrollToItem(pageNumber - 1, 'center');
        const pdfWrapper = pdfViewWrapperRef.current;
        if (currentPage !== pageNumber) pdfWrapper.scrollTo(0, 0);
        props.setCurrentPage(pageNumber);
      }
    }
  };

  const handleSetDirty = (variableName) => {
    if (!dirty.includes(variableName)) {
      setDirty(dirty.concat(variableName));
    }
  };

  return (
    <FullLayout>
      {props.type === 'VARIABLE' && (
        <Prompt
          when={dirty.length > 0}
          message="Changes you made may not be saved. Continue?"
        />
      )}
      <Wrapper height="100vh">
        <Navbar type="desktop" view="flat">
          <NavbarGroup>
            <NavbarHeading>
              <Brand
                logo={logo}
                filename={props.filename}
                status={`Last updated ${
                  props.lastUpdateDate &&
                  formatDistanceToNow(new Date(props.lastUpdateDate))
                } ago - Task #${props.taskId}`}
              />
            </NavbarHeading>
          </NavbarGroup>
          <NavbarGroup align="right">
            <NavbarHeading>
              <NavbarUserMenu />
            </NavbarHeading>
          </NavbarGroup>
        </Navbar>
        <FlexWrapper
          height="calc(100vh - 64px)"
          width="100%"
          alignItems="flex-start"
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flex: '0 1 240px',
              borderRight: '1px solid var(--grey20)',
              flexFlow: 'column nowrap',
            }}
          >
            <FlexWrapper
              justifyContent="space-between"
              style={{
                padding: 5,
                borderTop: '1px solid var(--grey10)',
                borderLeft: '1px solid var(--grey10)',
                borderBottom: '1px solid var(--grey10)',
              }}
            >
              <Heading text="Page Thumbnails" />
            </FlexWrapper>
            <div ref={thumbsWrapperRef} className={styles.thumbWrapper}>
              {!documentLoaded ? <Spinner /> : file && generatePage()}
            </div>
          </div>
          <FlexWrapper
            height="100%"
            width="calc(100% - 25% - 240px)"
            flexFlow="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Toolbar
              data={{
                pageScale,
                currentPage,
                numPages,
                boundingBoxType,
                pageLoaded,
              }}
              taskType={props.type}
              ocrStatus={props.ocrStatus}
              zoomIn={props.zoomIn}
              zoomOut={props.zoomOut}
              resetPageScale={props.resetPageScale}
              setCurrentPage={props.setCurrentPage}
              prevPage={handlePrevPage}
              nextPage={handleNextPage}
              handlePageChange={(e) => {
                const { value } = e.target;
                if (/\d+/.test(value)) {
                  const targetPage = parseInt(value);
                  if (targetPage <= numPages && targetPage > 0) {
                    props.setCurrentPage(parseInt(value));
                    focusPage(parseInt(value));
                  }
                }
              }}
              changeBoundingBoxType={props.changeBoundingBoxType}
            />
            <div ref={pdfViewWrapperRef} className={styles.pdf_viewer}>
              {progress.status && !documentLoaded && (
                <div className={styles.progress}>
                  <ProgressBar
                    view="filled"
                    color="primary"
                    stripes={false}
                    value={progress.value}
                  />
                </div>
              )}
              {file && (
                <Document
                  className="pdf-doc"
                  file={fixUrl(file)}
                  loading={<PageLoading />}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={({ loaded, total }) =>
                    setProgress({ status: true, value: loaded / total })
                  }
                  options={pdfOptions}
                >
                  <div
                    className={styles.pdf_page}
                    style={{
                      height: Math.floor(paperSize.height * pageScale),
                      width: Math.floor(paperSize.width * pageScale),
                    }}
                  >
                    {props.type === 'TABLE' && (
                      <canvas
                        id="canvasData"
                        width={Math.floor(paperSize.width * pageScale)}
                        height={Math.floor(paperSize.height * pageScale)}
                        style={{
                          backgroundColor: 'transparent',
                          zIndex: 2,
                          position: 'absolute',
                          height: Math.floor(paperSize.height * pageScale),
                          width: Math.floor(paperSize.width * pageScale),
                        }}
                      />
                    )}
                    {documentLoaded &&
                      pageLoaded &&
                      boundingBox[currentPage - 1] &&
                      props.selectedBoundingBox[selectedSection] &&
                      generateAnnotation(paperSize, currentPage)}
                    {documentLoaded &&
                      pageLoaded &&
                      boundingBox[currentPage - 1] &&
                      displaySearchHighlightLayer(paperSize, currentPage)}
                    <Page
                      className="pdf-page"
                      loading={<PageLoading />}
                      onLoadSuccess={onPageLoadSuccess}
                      scale={pageScale}
                      pageNumber={currentPage}
                      renderMode="canvas"
                      renderAnnotationLayer={false}
                      renderInteractiveForms={false}
                      renderTextLayer={false}
                    />
                  </div>
                </Document>
              )}
            </div>
          </FlexWrapper>
          <Wrapper width="25%">
            <FlexWrapper
              justifyContent="space-between"
              style={{
                padding: 5,
                borderTop: '1px solid var(--grey10)',
                borderLeft: '1px solid var(--grey10)',
                borderBottom: '1px solid var(--grey10)',
              }}
            >
              <Heading
                text={`Task #${props.taskId} - ${props.annotator.name}`}
              />
            </FlexWrapper>
            <ThemeProvider theme={theme}>
              <Tabs
                value={tabIndex}
                variant="fullWidth"
                onChange={(e, idx) => setTabIndex(idx)}
                classes={{ flexContainer: classes.flexContainer }}
                TabIndicatorProps={{ style: { background: 'var(--blue70)' } }}
              >
                <Tab
                  label="Annotation"
                  id="tab-1"
                  aria-controls="tabpanel-1"
                  disableRipple={true}
                  classes={{ root: classes.button, wrapper: classes.wrapper }}
                />
                <Tab
                  label="Notes"
                  id="tab-2"
                  aria-controls="tabpanel-2"
                  disableRipple={true}
                  classes={{ root: classes.button, wrapper: classes.wrapper }}
                />
                <Tab
                  label="Search"
                  id="tab-3"
                  aria-controls="tabpanel-3"
                  disableRipple={true}
                  classes={{ root: classes.button, wrapper: classes.wrapper }}
                />
              </Tabs>
              <TabAnnotation
                dataAnnotationTable={dataAnnotationTable}
                documentId={documentId}
                tabIndex={tabIndex}
                handleClickBoundary={focusPage}
                dirty={dirty}
                handleSetDirty={handleSetDirty}
              />
              <TabNote tabIndex={tabIndex} />
              <TabSearch tabIndex={tabIndex} handleClick={focusPage} />
            </ThemeProvider>
          </Wrapper>
        </FlexWrapper>
      </Wrapper>
    </FullLayout>
  );
}

const mapStateToProps = (state) => ({
  ...documentPreviewSelector(state),
  ...annotationSelector(state),
  userProfile: state.userProfile,
  location: state.router.location,
  loadingUpdateAnnotation: createLoadingSelector([
    'UPDATE_ANNOTATION_VARIABLE',
  ])(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTaskData: (taskId) => dispatch(getTaskData(taskId)),
  getDocumentData: (documentId) => dispatch(getDocumentData(documentId)),
  getBoundaries: (documentId, page) =>
    dispatch(getBoundaries(documentId, page)),
  getPageBoundaries: (documentId) => dispatch(getPageBoundaries(documentId)),
  getSections: (sectionId, mode) => dispatch(getSections(sectionId, mode)),
  zoomIn: () => dispatch(zoomIn()),
  zoomOut: () => dispatch(zoomOut()),
  resetPageScale: () => dispatch(resetPageScale()),
  setNumPages: (pageCount) => dispatch(setNumPages(pageCount)),
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  setPageLoaded: (status) => dispatch(setPageLoaded(status)),
  setPaperSize: (paperSize) => dispatch(setPaperSize(paperSize)),
  nextPage: () => dispatch(nextPage()),
  prevPage: () => dispatch(prevPage()),
  setBoundingBox: (data) => dispatch(setBoundingBox(data)),
  changeBoundingBoxType: (boundingBoxType) =>
    dispatch(changeBoundingBoxType(boundingBoxType)),
  setSelectedBoundingBox: (sectionId, nodeIds) =>
    dispatch(setSelectedBoundingBox(sectionId, nodeIds)),
  setSelectedPage: (sectionId, nodeIds) =>
    dispatch(setSelectedPage(sectionId, nodeIds)),
  getTaskNote: (taskId) => dispatch(getTaskNote(taskId)),
  getTaskAnnotatorList: (taskName) => dispatch(getTaskAnnotatorList(taskName)),
  resetDocumentPreview: () => dispatch(resetDocumentPreview()),
  resetTaskData: () => dispatch(resetTaskData()),
  saveReviewAnnotationVariable: () => dispatch(saveReviewAnnotationVariable()),
  submitReviewAnnotationVariable: () =>
    dispatch(submitReviewAnnotationVariable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spectate);

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    height: 35,
    borderBottom: '1px solid var(--grey30)',
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 'var(--font_size_body_small)',
    height: 35,
  },
  button: {
    minWidth: 100,
    fontWeight: 'normal',
  },
  deselectButton: {
    position: 'absolute',
    top: -8,
    right: -8,
    background: '#fff',
    boxShadow: '0px 2px 4px rgb(27, 78, 163, 0.2)',
    '&:hover': {
      background: '#fff',
    },
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        height: 35,
        minHeight: 35,
      },
      fixed: {
        height: 35,
      },
    },
    MuiTab: {
      root: {
        minHeight: 35,
        padding: 0,
      },
    },
    MuiButtonBase: {
      root: {
        padding: 0,
      },
    },
  },
});
