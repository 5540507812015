import React, { Component } from 'react';

export class ClickOutsideContainer extends Component {
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        if(this.node.contains(e.target)) {
            return;
        }
        this.props.onClose();
    }

    render() {
        // const children = React.Children.map(this.props.children, child => {
        //     return React.cloneElement(child, {
        //         onClose: () => this.props.onClose()
        //     });
        // });
        // const {open, onClose} = this.props;
        return (
            <div ref={node => this.node = node}>
                {this.props.children}
            </div>
        )
    }
}

export class ClickContainer extends Component {
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        // if (this.node.contains(e.target)) {
        //     return;
        // }
        this.props.onClose();
    }

    render() {
        // const children = React.Children.map(this.props.children, child => {
        //     return React.cloneElement(child, {
        //         onClose: () => this.props.onClose()
        //     });
        // });
        // const {open, onClose} = this.props;
        return (
            <div ref={node => this.node = node}>
                {this.props.children}
            </div>
        )
    }
}

export class ClickOutsideFirstChildContainer extends Component {
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.props.onClose();
    }

    render() {
        // const children = React.Children.map(this.props.children, child => {
        //     return React.cloneElement(child, {
        //         onClose: () => this.props.onClose()
        //     });
        // });
        // const {open, onClose} = this.props;
        return (
            <div>
                <div ref={node => this.node = node}>
                    {this.props.children[0]}
                </div>
                {this.props.children[1]}
            </div>
        )
    }
}