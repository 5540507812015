import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/@setproduct-ui/core/Button';
import Dialog from '../../components/CustomDialog';
import { createLoadingSelector } from '../../redux/api/loading';
import styles from './Style.module.css';

export default function ConfirmationDialog(props) {
  const { data, onCloseDialog, setMenu } = props;
  const loadingMutation = useSelector(
    createLoadingSelector([
      'ACCEPT_USER',
      'DECLINE_USER',
      'CHANGE_USER_ROLE',
      'DELETE_USER',
    ])
  );

  return (
    <Dialog
      view="raised"
      title={data.operation === 'delete' ? 'Delete User' : 'Block User'}
      backdropOpacity={40}
      isOpen={data.open}
      onClose={onCloseDialog}
    >
      <p>Are you sure you want to {data.operation} this user?</p>
      <div className={styles.dialogWrapper}>
        <Button
          view="flat"
          color="default"
          dense={false}
          disabled={loadingMutation}
          onClick={onCloseDialog}
          text="Cancel"
          style={{ marginRight: 10 }}
        />
        <Button
          view="filled"
          color="danger"
          dense={false}
          onClick={async () => {
            let isSuccess;
            if (data.operation === 'delete') {
              const { id, uid } = data;
              isSuccess = await props.onDeleteUser(id, uid);
            } else {
              isSuccess = await props.onBlockUser(data.id);
            }
            if (isSuccess) {
              onCloseDialog();
              setMenu(null);
            }
          }}
          loading={loadingMutation}
          text={data.operation === 'delete' ? 'Delete' : 'Block'}
        />
      </div>
    </Dialog>
  );
}
