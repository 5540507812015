import React from "react";
import DefaultInput from "../components/@setproduct-ui/core/Inputs/DefaultInput";
import FormMessage from "./FormMessage";

function Input(props) {
  const { labelStyle, style, errorMessage, ...rest } = props;
  return (
    <div style={{ ...style }}>
      {props.label && (
        <label style={{ ...labelStyle }} htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <DefaultInput {...rest} label="" />
      {props.error && <FormMessage type="error">{errorMessage}</FormMessage>}
    </div>
  );
}

export default Input;
