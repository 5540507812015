const initialState = {
  page: 0,
  order: "asc",
  orderBy: "task_title",
  rowsPerPage: 10,
};

export const actionType = {
  SetOrder: `TABLE_SET_ORDER`,
  SetOrderBy: `TABLE_SET_ORDER_BY`,
  SetPage: `TABLE_SET_PAGE`,
  SetRowsPerPage: `TABLE_SET_ROWS_PER_PAGE`,
  SetOrderAndOrderBy: `TABLE_SET_ORDER_AND_ORDER_BY`,
  SetRowsPerPageAndPage: `TABLE_SET_ROWS_PERPAGE_AND_PAGE`,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SetPage:
      return {
        ...state,
        page: action.payload,
      };

    case actionType.SetRowsPerPage:
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    case actionType.SetOrder:
      return {
        ...state,
        order: action.payload,
      };

    case actionType.SetOrderBy:
      return {
        ...state,
        orderBy: action.payload,
      };

    case actionType.SetOrderAndOrderBy:
      if (state.orderBy === action.property && state.order === "asc") {
        state = { ...state, order: "desc" };
      } else {
        state = { ...state, order: "asc" };
      }
      return { ...state, orderBy: action.property };

    case actionType.SetRowsPerPageAndPage:
      return { ...state, page: action.page, rowsPerPage: action.rowsPerPage };

    default:
      return state;
  }
};
