import { createStore, applyMiddleware /*compose*/ } from "redux";
import { routerMiddleware } from "connected-react-router";
import { getFirebase } from "react-redux-firebase";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import LogRocket from 'logrocket';
import history from "../../routers/history";
const { REACT_APP_API_STAGE } = process.env;

// const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// enable trace. DISABLE IT ON PRODUCTION

const optionsDev = { trace: true, traceLimit: 25 };
const optionsStaging = {};
const options = REACT_APP_API_STAGE === "staging" ? optionsStaging : optionsDev;

const composeEnhancers = composeWithDevTools(options);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userProfile"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

function configureStore() {
  const store = createStore(
    persistedReducer,
    REACT_APP_API_STAGE === 'production' ?
      applyMiddleware(
        routerMiddleware(history),
        thunk.withExtraArgument(getFirebase),
        LogRocket.reduxMiddleware()
      )
    :
      composeEnhancers(
        applyMiddleware(
          routerMiddleware(history),
          thunk.withExtraArgument(getFirebase),
          LogRocket.reduxMiddleware()
        )
      )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default configureStore;
