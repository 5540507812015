import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import NotFound from "../Pages/NotFound";
import PATH_URL from "../routers/path";

function EmailAction({ location, urlParams }) {
  const { mode } = urlParams;
  if (mode === "verifyEmail") {
    return <Redirect to={{ ...location, pathname: PATH_URL.VERIFY_EMAIL }} />;
  } else if (mode === "resetPassword") {
    return <Redirect to={{ ...location, pathname: PATH_URL.RESET_PASSWORD }} />;
  }
  return <Route component={NotFound} />;
}

const mapStateToProps = state => ({
  urlParams: state.router.location.query,
  location: state.router.location
});

export default connect(
  mapStateToProps,
  null
)(EmailAction);
