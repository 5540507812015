const initialState = {
  searchTerm: '',
  page: 1,
  canNextPage: false,
  results: [],
  resultsGrouped: {},
};

const documentSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'SEARCH_DOCUMENT_INIT_SUCCESS': {
      const { results, canNextPage } = action.payload;
      const resultsGrouped = results.reduce((acc, result) => {
        if (Array.isArray(acc[result.page])) {
          const existing = acc[result.page];
          acc = { ...acc, [result.page]: existing.concat(result.id) };
        } else acc = { ...acc, [result.page]: [result.id] };
        return acc;
      }, {});
      return {
        ...state,
        canNextPage,
        results,
        resultsGrouped,
      };
    }
    case 'SEARCH_DOCUMENT_APPEND_SUCCESS': {
      const { results, canNextPage } = action.payload;
      const resultsGrouped = results.reduce((acc, result) => {
        if (Array.isArray(acc[result.page])) {
          const existing = acc[result.page];
          acc = { ...acc, [result.page]: existing.concat(result.id) };
        } else acc = { ...acc, [result.page]: [result.id] };
        return acc;
      }, state.resultsGrouped);
      return {
        ...state,
        page: state.page + 1,
        canNextPage,
        results: state.results.concat(results),
        resultsGrouped,
      };
    }
    case 'RESET_SEARCH_RESULTS':
      return initialState;
    default:
      return state;
  }
};

export default documentSearchReducer;
