import React from 'react'
import { Helmet } from 'react-helmet'

export default function HelmetHOC(props) {
    const { title } = props;
    return (
        <React.Fragment>
            <Helmet>
                <title>{title ? `${title}` : null}</title>
            </Helmet>
            {props.children}
        </React.Fragment>
    )
}
