import React from "react";
import annotateLogo from "../../assets/images/cesgs-logo-only.png";
import Spinner from "../../components/Loading";

export default function SplashScreen() {
  return <div className="splash-screen-container">
    <div className="splash-screen-content">
      <img src={annotateLogo} alt="" />
      <Spinner />
    </div>
  </div>;
}
