import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { parse, resolve } from '@estilles/expression-parser';
import styles from './Style.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

export default function DateInput(props) {
  const {
    control,
    fieldName,
    currentData,
    isReadOnly,
    isFocused,
    isRejected,
    placeholder,
    handleFocusWrapper,
    handleStoreFormula,
    setValue,
  } = props;
  const calculate = (value) => {
    const operationStr = value.slice(1);
    try {
      const result = resolve(parse(operationStr));
      handleStoreFormula(fieldName, value);
      setValue(fieldName, new Date(`01/01/${result}`), { shouldDirty: true });
    } catch (error) {
      // handle error
    }
  };
  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={currentData ? new Date(currentData) : ''}
      render={({ onChange, onBlur, value }) => (
        <DatePicker
          selected={value}
          onChange={!isReadOnly ? onChange : () => {}}
          onFocus={() => handleFocusWrapper({ target: { name: fieldName } })}
          className={`${styles.variable_text_input} ${
            isFocused ? styles.input_focused : ''
          } ${isRejected ? styles.date_rejected : ''}`}
          placeholderText={placeholder}
          onKeyDown={(event) => {
            // press enter
            const { value } = event.target;
            if (event.keyCode === 13 && value && value[0] === '=') {
              calculate(value);
              event.preventDefault();
            }
          }}
          popperPlacement="top"
        />
      )}
    />
  );
}
