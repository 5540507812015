import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Card,
  CardContent,
} from "@material-ui/core";

import { bodyCallbackListener } from "../../utils/bodyListener";

import SearchIcon from "@material-ui/icons/Search";

import FlexWrapper from "../FlexWrapper";
import liveSearchStyle from "./styles";

import Child from "./Child";

export function SearchContent(props) {
  const members = props.members;

  const classes = liveSearchStyle();

  const [rows, setRows] = React.useState(members);
  const [query, setQuery] = React.useState("");

  const eventKeyUp = (e) => {
    setQuery(e.target.value);
    let items = members;

    if (e.target.value) {
      items = members.filter((item) => {
        const name = item.name.toLowerCase();
        if (name.indexOf(query) > -1) return item;
      });
    }

    setRows(items);
  };

  React.useEffect(() => {
    setRows(members);
  }, [members]);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <FlexWrapper>
        <SearchIcon className={classes.searchIcon} />
        <input
          placeholder="Search..."
          className={classes.textField}
          onChange={eventKeyUp}
          value={query}
        />
      </FlexWrapper>

      {props.label && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p className={classes.title}>{props.label}</p>
        </Box>
      )}

      <Child {...props} setRows={setRows} classes={classes} members={rows} />
    </Box>
  );
}

function LiveSearchBox(props) {
  let { id, onClose, anchorEl } = props;

  const classes = liveSearchStyle();

  const eventOnClose = () => {
    onClose(null);
  };

  bodyCallbackListener("liveSearch", "live-search-box", eventOnClose);

  let opened = { display: "none" };
  if (anchorEl) {
    const targetId = anchorEl.getAttribute("data-target");
    const boundElm = anchorEl.getBoundingClientRect();
    window.elm = anchorEl;
    
    const viewPortH =
      window.innerHeight || document.documentElement.clientHeight;

    const bottomView = viewPortH - viewPortH / 3;

    if (id === targetId) {
      opened = { display: "block" };
      let height = boundElm.height || anchorEl.getAttribute("height");

      if (anchorEl.getAttribute("height") || boundElm.y > bottomView) {
        opened.top = "auto";
        opened.bottom = parseInt(height) + 5;
      }
    }
  }

  return (
    <Box className={`live-search-box ${classes.wrapper}`} style={opened}>
      <Card>
        <CardContent>
          <SearchContent {...props} />
        </CardContent>
      </Card>
    </Box>
  );
}

LiveSearchBox.propType = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  updateGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  addMemberCallback: PropTypes.func.isRequired,
  onSuccessCallback: PropTypes.func.isRequired,
  anchorEl: PropTypes.object.isRequired,
};

export default LiveSearchBox;
