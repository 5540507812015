export class AnnotatorGroupEntity {
  constructor({ _id, name, thumb, members }) {
    this.id = _id;
    this.name = name;
    this.thumb = thumb || `icon`;
    this.members = members || [];

    this.totalMember = members ? members.length : 0;
    this.deleted = false;
    this.registeredIds = [];
  }
}

export class AnnotatorTeamEntity {
  constructor({ id, name, email, thumb }) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.thumb = thumb;
  }

  asData() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
    };
  }
}

// export class GroupEntity {

//   constructor(id, name, icon, totalMember, members) {

//       this.id             = id
//       this.name           = name
//       this.icon           = icon
//       this.totalMember    = totalMember
//       this.members        = members

//       this.deleted        = false
//       this.registeredIds  = []

//   }

// }

// export class AnnotatorEntity {

//   constructor(id, name, email, thumb) {
//       this.id             = id
//       this.name           = name
//       this.email          = email
//       this.thumb          = thumb
//   }

// }
