import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@blueprintjs/core';
import { CircularProgress } from '@material-ui/core';

function ReviewButtons({ status, onAccept, onReject, onUndoAccept, loading }) {
  return loading ? (
    <CircularProgress size={16} />
  ) : (
    <>
      <IconButton
        size="small"
        disabled={status === 4}
        onClick={onReject}
        style={{
          background: status === 4 ? 'var(--grey30)' : 'var(--grey0)',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <Icon
          icon="cross"
          iconSize={12}
          style={{ color: status === 4 ? 'var(--grey0)' : 'var(--grey50)' }}
        />
      </IconButton>
      <IconButton
        size="small"
        disabled={status === 3}
        onClick={onAccept}
        style={{ background: status === 3 ? 'var(--grey30)' : 'var(--grey0)' }}
      >
        <Icon
          icon="tick"
          iconSize={12}
          style={{ color: status === 3 ? 'var(--grey0)' : 'var(--grey50)' }}
        />
      </IconButton>
    </>
  );
}

export default ReviewButtons;
