import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeNoteScrollPosition } from '../../redux/actions/annotation';
import styles from './Style.module.css';

export default function Note(props) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const noteScrollPosition = useSelector(
    ({ annotation }) => annotation.noteScrollPosition
  );

  useEffect(() => {
    const textarea = ref.current;
    if (textarea) {
      textarea.scrollTop = noteScrollPosition;
    }
  }, [noteScrollPosition]);

  useEffect(() => {
    const textarea = ref.current;
    if (textarea) {
      return () => {
        dispatch(storeNoteScrollPosition(textarea.scrollTop));
      };
    }
  }, [dispatch]);

  return (
    <textarea
      ref={ref}
      name=""
      value={props.value}
      onChange={props.onChange || (() => {})}
      className={styles.inputNotes}
      readOnly={!!props.readOnly}
    />
  );
}
