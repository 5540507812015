import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import IconButton from '@material-ui/core/IconButton';
import { useFormContext } from 'react-hook-form';
import ReviewButtons from './ReviewButtons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Input from '../../components/FormAnnotation/Input';
import { isEnum, isDerivative, compact } from '../../utils/annotation';
import {
  addVariableItem,
  removeVariableItem,
  setSelectedVariableField,
  setAnnotatedVariableField,
  acceptAnnotationVariable,
  rejectAnnotationVariable,
  undoAcceptAnnotationVariable,
  storeFormulatedVariableField,
  moveAnnotationFieldPosition,
  storeFormData,
  setExpandedAnnotationFields,
} from '../../redux/actions/annotation';
import styles from './style.module.css';
import { Collapse } from '@material-ui/core';

function TreeForm({
  root,
  annotationItemIndex,
  data,
  parents,
  focusPage,
  autofill,
  handleSetDirty,
}) {
  const { watch, reset, setValue, getValues } = useFormContext();
  const dispatch = useDispatch();
  const annotatedField = useSelector(
    (state) => state.annotation.annotatedField
  );
  const selectedVariable = useSelector(
    (state) => state.annotation.selectedSection
  );
  const selectedField = useSelector((state) => state.annotation.selectedField);
  const storedExpandedFields = useSelector(
    ({ annotation }) => annotation.expandedAnnotationFields
  );
  const [expanded, setExpanded] = React.useState(storedExpandedFields);
  const isReadOnly = [3, 'accepted'].includes(root.annotation.status);

  const handleFocus = (e, type) => {
    const { name } = e.target;
    const newSelectedField = { annotationItemIndex, keyMap: name, type };
    const selectedAnnotated = annotatedField[selectedVariable].find(
      (annotated) => annotated.keyMap === name
    );
    autofill(name);
    if (selectedAnnotated) {
      focusPage(selectedAnnotated.pageNumber);
    }
    if (JSON.stringify(selectedField) !== JSON.stringify(newSelectedField)) {
      dispatch(setSelectedVariableField(newSelectedField));
    }
  };

  const handleAddItem = (annotationItemIndex, fieldName) => {
    const keyMap = parents.concat(compact(fieldName));
    dispatch(addVariableItem(annotationItemIndex, keyMap));
    handleSetDirty(compact(root.name));
  };

  const handleRemoveItem = (
    annotationItemIndex,
    fieldName,
    index,
    length,
    derivative = false
  ) => {
    const rootName = `${compact(root.name)}[${annotationItemIndex}]`;
    const keyMap = parents.concat(compact(fieldName));
    const formikKeymap = rootName + '.' + keyMap.join('.');
    const regex = RegExp(`${_.escapeRegExp(formikKeymap)}\\[\\d+\\]`);
    const keyPosition = formikKeymap.split('.').length - 1;
    const splitResult = annotatedField[selectedVariable].reduce(
      (acc, annotation) => {
        if (regex.test(annotation.keyMap))
          acc = { ...acc, matched: acc.matched.concat(annotation) };
        else acc = { ...acc, unmatched: acc.unmatched.concat(annotation) };
        return acc;
      },
      { matched: [], unmatched: [] }
    );
    const matched = splitResult.matched
      .map((annotation) => {
        const keyMapArr = annotation.keyMap.split('.');
        const index = parseInt(
          keyMapArr[keyPosition].match(/\[\d+\]/)[0].match(/\d+/)
        );
        return { ...annotation, index };
      })
      .sort((a, b) => a.index - b.index)
      .reduce(
        (acc, annotation) => {
          if (annotation.index < index)
            acc = { ...acc, pre: acc.pre.concat(annotation) };
          else if (annotation.index > index)
            acc = { ...acc, post: acc.post.concat(annotation) };
          return acc;
        },
        { pre: [], post: [] }
      );
    // remove `index` key from pre result
    const matchPre = matched.pre.map(({ keyMap, coordinates, pageNumber }) => ({
      keyMap,
      coordinates,
      pageNumber,
    }));
    const matchPost = [];
    let iter = -1;
    let prev = null;
    matched.post.forEach((annotation, idx) => {
      if (prev !== annotation.index) {
        iter++;
        prev = annotation.index;
      }
      const keyMapArr = annotation.keyMap.split('.');
      // shifting index
      keyMapArr[keyPosition] = keyMapArr[keyPosition].replace(
        /\[\d+\]/,
        `[${index + iter}]`
      );
      // remove `index` key
      matchPost.push({
        keyMap: keyMapArr.join('.'),
        coordinates: annotation.coordinates,
        pageNumber: annotation.pageNumber,
      });
    });
    if (!derivative) {
      const data = Array(length)
        .fill(null)
        .map((i, idx) => getValues(`${formikKeymap}[${idx}]`))
        .concat('');
      const newData = data.filter((_, idx) => idx !== index);
      setValue(formikKeymap, newData);
    } else {
      let formData = Object.assign({}, watch());
      const parentData = _.get(formData, formikKeymap);
      const updatedParentData = parentData.filter((i, idx) => idx !== index);
      formData = _.set(formData, formikKeymap, updatedParentData);
      reset(formData);
    }
    const result = splitResult.unmatched.concat(matchPre, matchPost);
    dispatch(removeVariableItem(annotationItemIndex, keyMap, index));
    dispatch(setAnnotatedVariableField(selectedVariable, result));
    handleSetDirty(compact(root.name));
  };

  const handleRemoveBoundary = (e) => {
    const { name } = e.target;
    if (e.shiftKey) {
      const updatedFields = annotatedField[selectedVariable].filter(
        (annotated) => {
          return annotated.keyMap !== name;
        }
      );
      setValue(name, '');
      dispatch(setAnnotatedVariableField(selectedVariable, updatedFields));
      dispatch(storeFormulatedVariableField(selectedVariable, name, ''));
    }
  };

  const handleAcceptAnnotation = (fieldName) => {
    dispatch(acceptAnnotationVariable(annotationItemIndex, fieldName));
    handleSetDirty(compact(root.name));
  };

  const handleRejectAnnotation = (fieldName) => {
    dispatch(rejectAnnotationVariable(annotationItemIndex, fieldName));
    handleSetDirty(compact(root.name));
  };

  const handleUndoAcceptAnnotation = (fieldName) => {
    dispatch(undoAcceptAnnotationVariable(annotationItemIndex, fieldName));
    handleSetDirty(compact(root.name));
  };

  if (data.objAttr) {
    return data.objAttr.map((field) => {
      if (!isDerivative(field.dtype)) {
        const { attr } = field.child;
        const rootName = `${compact(root.name)}[${annotationItemIndex}]`;
        const fieldName =
          rootName + '.' + [...parents, compact(field.name)].join('.');

        if (field.array) {
          return (
            <div
              key={fieldName}
              style={{
                borderBottom: '1px solid var(--grey10)',
                marginBottom: 5,
              }}
            >
              <div className={styles.nested_header_wrapper}>
                <h2
                  className={styles.nested_header}
                  style={{ marginBottom: 5 }}
                >
                  {field.name}
                </h2>
                <div className={styles.header_button_wrapper}>
                  <span className={styles.count_badge}>
                    {field.children.length}
                  </span>
                  {!isReadOnly && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleAddItem(annotationItemIndex, field.name)
                      }
                    >
                      <Icon
                        icon="plus"
                        iconSize={16}
                        style={{ color: 'var(--grey70)' }}
                      />
                    </IconButton>
                  )}
                  <ReviewButtons
                    status={null}
                    onAccept={() => handleAcceptAnnotation(fieldName)}
                    onReject={() => handleRejectAnnotation(fieldName)}
                    onUndoAccept={() => handleUndoAcceptAnnotation(fieldName)}
                  />
                </div>
              </div>
              <DragDropContext
                onDragStart={() => {
                  const variableName = compact(root.name);
                  const formData = watch(variableName);
                  dispatch(storeFormData({ [variableName]: formData }));
                }}
                onDragEnd={(result) => {
                  const { destination, source } = result;
                  if (!destination) return;
                  if (source.index === destination.index) return;
                  dispatch(
                    moveAnnotationFieldPosition(
                      root.id,
                      annotationItemIndex,
                      field.name,
                      source.index,
                      destination.index
                    )
                  );
                  handleSetDirty(compact(root.name));
                }}
              >
                <Droppable droppableId={`${fieldName}-list`}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {field.children.map((child, index) => (
                        <Draggable
                          key={`${child.name.toLowerCase()}-${index}`}
                          draggableId={`${child.name.toLowerCase()}-${index}`}
                          index={index}
                          isDragDisabled={isReadOnly}
                        >
                          {(provided) => (
                            <div
                              key={fieldName + '-' + index}
                              ref={provided.innerRef}
                              className={styles.input_wrapper}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Input
                                type={isEnum(child.attr) ? 'enum' : field.dtype}
                                data={{
                                  fieldName: `${fieldName}[${index}]`,
                                  defaultValue: child ? child.rawData : '',
                                  getValues,
                                  handleFocus,
                                  handleRemoveBoundary,
                                  attr: isEnum(child.attr) ? child.attr : null,
                                  placeholder: field.name,
                                  isFocused:
                                    selectedField.keyMap ===
                                    `${fieldName}[${index}]`,
                                  isReadOnly,
                                  isRejected: child
                                    ? child.status === 4
                                    : false,
                                  dataType: data.name,
                                }}
                              />
                              {!isReadOnly && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleRemoveItem(
                                      annotationItemIndex,
                                      field.name,
                                      index,
                                      field.children.length
                                    )
                                  }
                                  disabled={field.children.length === 1}
                                  style={{ marginLeft: 5 }}
                                >
                                  <Icon
                                    icon="minus"
                                    iconSize={16}
                                    style={{
                                      color:
                                        field.children.length > 1
                                          ? 'var(--grey70)'
                                          : 'var(--grey30)',
                                    }}
                                  />
                                </IconButton>
                              )}
                              <ReviewButtons
                                status={child.status}
                                onAccept={() =>
                                  handleAcceptAnnotation(
                                    `${fieldName}[${index}]`
                                  )
                                }
                                onReject={() =>
                                  handleRejectAnnotation(
                                    `${fieldName}[${index}]`
                                  )
                                }
                                onUndoAccept={() =>
                                  handleUndoAcceptAnnotation(
                                    `${fieldName}[${index}]`
                                  )
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          );
        } else {
          if (isEnum(attr)) {
            return (
              <div key={fieldName} className={styles.input_main_wrapper}>
                <label>{field.name}</label>
                <div className={styles.input_wrapper}>
                  <Input
                    type="enum"
                    data={{
                      fieldName,
                      defaultValue: field.child ? field.child.rawData : '',
                      attr,
                      handleFocus,
                      handleRemoveBoundary,
                      placeholder: field.name,
                      isFocused: selectedField.keyMap === fieldName,
                      isReadOnly,
                      isRejected: field.child
                        ? field.child.status === 4
                        : false,
                      dataType: data.name,
                    }}
                  />
                  <ReviewButtons
                    status={field.child.status}
                    onAccept={() => handleAcceptAnnotation(fieldName)}
                    onReject={() => handleRejectAnnotation(fieldName)}
                    onUndoAccept={() => handleUndoAcceptAnnotation(fieldName)}
                  />
                </div>
              </div>
            );
          }
          return (
            <div key={fieldName} className={styles.input_main_wrapper}>
              <label>{field.name}</label>
              <div key={fieldName} className={styles.input_wrapper}>
                <Input
                  type={field.dtype}
                  data={{
                    fieldName,
                    defaultValue: field.child ? field.child.rawData : '',
                    handleFocus,
                    handleRemoveBoundary,
                    placeholder: field.name,
                    isFocused: selectedField.keyMap === fieldName,
                    isReadOnly,
                    isRejected: field.child ? field.child.status === 4 : false,
                    dataType: data.name,
                  }}
                />
                <ReviewButtons
                  status={field.child.status}
                  onAccept={() => handleAcceptAnnotation(fieldName)}
                  onReject={() => handleRejectAnnotation(fieldName)}
                  onUndoAccept={() => handleUndoAcceptAnnotation(fieldName)}
                />
              </div>
            </div>
          );
        }
      } else {
        const nestedParentKey = compact(field.name) + '-nested';
        const rootName = `${compact(root.name)}[${annotationItemIndex}]`;
        const fieldName =
          rootName + '.' + [...parents, compact(field.name)].join('.');
        const collapseId = nestedParentKey + '-tree-';

        return (
          <div
            key={nestedParentKey}
            className={`${styles.nested_parent_wrapper}`}
          >
            <div className={styles.nested_header_wrapper}>
              <h2 className={styles.nested_header}>{field.name}</h2>
              <div className={styles.header_button_wrapper}>
                {field.array && (
                  <>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const childrenCollapseId = field.children.map(
                          (_, index) => collapseId + index
                        );
                        const updated = expanded
                          .filter((id) => !id.includes(collapseId))
                          .concat(childrenCollapseId);
                        setExpanded(updated);
                        dispatch(setExpandedAnnotationFields(updated));
                      }}
                      style={{ marginRight: 4 }}
                    >
                      <Icon
                        icon="expand-all"
                        iconSize={14}
                        style={{ color: 'var(--grey60)' }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const updated = expanded.filter(
                          (id) => !id.includes(collapseId)
                        );
                        setExpanded(updated);
                        dispatch(setExpandedAnnotationFields(updated));
                      }}
                      style={{ marginRight: 4 }}
                    >
                      <Icon
                        icon="collapse-all"
                        iconSize={14}
                        style={{ color: 'var(--grey60)' }}
                      />
                    </IconButton>
                    <span className={styles.count_badge}>
                      {field.children.length}
                    </span>
                    {!isReadOnly && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddItem(annotationItemIndex, field.name);
                        }}
                        style={{ marginRight: 5 }}
                      >
                        <Icon
                          icon="plus"
                          iconSize={16}
                          style={{ color: 'var(--grey70)' }}
                        />
                      </IconButton>
                    )}
                  </>
                )}
                <ReviewButtons
                  status={null}
                  onAccept={(e) => {
                    handleAcceptAnnotation(fieldName);
                    e.stopPropagation();
                  }}
                  onReject={(e) => {
                    handleRejectAnnotation(fieldName);
                    e.stopPropagation();
                  }}
                  onUndoAccept={(e) => {
                    handleUndoAcceptAnnotation(fieldName);
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
            {field.array ? (
              <DragDropContext
                onDragStart={() => {
                  const variableName = compact(root.name);
                  const formData = watch(variableName);
                  dispatch(storeFormData({ [variableName]: formData }));
                }}
                onDragEnd={(result) => {
                  const { destination, source } = result;
                  if (!destination) return;
                  if (source.index === destination.index) return;
                  dispatch(
                    moveAnnotationFieldPosition(
                      root.id,
                      annotationItemIndex,
                      field.name,
                      source.index,
                      destination.index
                    )
                  );
                  handleSetDirty(compact(root.name));
                }}
              >
                <Droppable droppableId="annotation-item-list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {field.children.map((child, index) => (
                        <Draggable
                          key={`annotation-item-${index}`}
                          draggableId={`annotation-item-${index}`}
                          index={index}
                          isDragDisabled={isReadOnly}
                        >
                          {(provided) => (
                            <div
                              key={nestedParentKey + '-tree-' + index}
                              ref={provided.innerRef}
                              className={styles.nested_wrapper}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                className={styles.nested_header_wrapper}
                                onClick={() => {
                                  const id = nestedParentKey + '-tree-' + index;
                                  const updated = expanded.includes(id)
                                    ? expanded.filter((p) => p !== id)
                                    : expanded.concat(id);
                                  setExpanded(updated);
                                  dispatch(
                                    setExpandedAnnotationFields(updated)
                                  );
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 5,
                                  }}
                                >
                                  <Icon
                                    icon={
                                      expanded.includes(
                                        nestedParentKey + '-tree-' + index
                                      )
                                        ? 'chevron-down'
                                        : 'chevron-right'
                                    }
                                    iconSize={16}
                                    style={{
                                      color: 'var(--grey70)',
                                      cursor: 'pointer',
                                      marginRight: 8,
                                    }}
                                  />
                                  <h2 className={styles.nested_header}>
                                    {field.name} {index + 1}
                                  </h2>
                                </div>
                                <div className={styles.header_button_wrapper}>
                                  {field.children.length > 1 && !isReadOnly && (
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleRemoveItem(
                                          annotationItemIndex,
                                          field.name,
                                          index,
                                          field.children.length,
                                          true
                                        )
                                      }
                                    >
                                      <Icon
                                        icon="minus"
                                        iconSize={16}
                                        style={{ color: 'var(--grey70)' }}
                                      />
                                    </IconButton>
                                  )}
                                  <ReviewButtons
                                    status={null}
                                    onAccept={() =>
                                      handleAcceptAnnotation(
                                        `${fieldName}[${index}]`
                                      )
                                    }
                                    onReject={() =>
                                      handleRejectAnnotation(
                                        `${fieldName}[${index}]`
                                      )
                                    }
                                    onUndoAccept={() =>
                                      handleUndoAcceptAnnotation(
                                        `${fieldName}[${index}]`
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <Collapse
                                key={nestedParentKey + '-tree-' + index}
                                in={expanded.includes(
                                  nestedParentKey + '-tree-' + index
                                )}
                                timeout="auto"
                              >
                                <TreeForm
                                  root={root}
                                  annotationItemIndex={annotationItemIndex}
                                  data={child}
                                  parents={parents.concat(
                                    `${compact(field.name)}[${index}]`
                                  )}
                                  focusPage={focusPage}
                                  autofill={autofill}
                                  handleSetDirty={handleSetDirty}
                                />
                              </Collapse>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <TreeForm
                key={nestedParentKey + '-tree'}
                root={root}
                annotationItemIndex={annotationItemIndex}
                data={field.child}
                parents={parents.concat(compact(field.name))}
                focusPage={focusPage}
                autofill={autofill}
                handleSetDirty={handleSetDirty}
              />
            )}
          </div>
        );
      }
    });
  } else {
    return (
      <div key={compact(data.name)} className={styles.input_wrapper}>
        <Input
          type={isEnum(data.attr) ? 'enum' : root.dtype}
          data={{
            fieldName: `${compact(root.name)}[${annotationItemIndex}].${compact(
              data.name
            )}`,
            defaultValue: data ? data.rawData : '',
            handleFocus,
            handleRemoveBoundary,
            attr: isEnum(data.attr) ? data.attr : null,
            placeholder: data.name,
            isFocused:
              selectedField.keyMap ===
              `${compact(root.name)}[${annotationItemIndex}].${compact(
                data.name
              )}`,
            isReadOnly,
            isRejected: data ? data.status === 4 : false,
            dataType: data.name,
          }}
        />
        <ReviewButtons
          status={data.status}
          onAccept={() => handleAcceptAnnotation(`${compact(root.name)}[0]`)}
          onReject={() => handleRejectAnnotation(`${compact(root.name)}[0]`)}
          onUndoAccept={() =>
            handleUndoAcceptAnnotation(`${compact(root.name)}[0]`)
          }
        />
      </div>
    );
  }
}

export default TreeForm;
